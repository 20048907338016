<template>
  <div id="positioner">
    <ActivityHeader
      :activity-type=activityType
      activity-name="R-Controlled Vowels"
      :back-button-title=backButtonTitle
      :show-nav-buttons=showNavButtons
      :back-button-route=backButtonRoute></ActivityHeader>
    <div id="rc1">
      <div id="r-controlled-container" data-automationId="reference_posters_r-controlled_vowels_with_img">
        <div v-for="card in getCardsForDisplay" :key="card.cardId" class="r-controlled-wrapper">
          <div class="r-controlled" :id="card.secondaryLabel">
            <div class="card-label">
              <span>{{ card.secondaryLabel }}</span>
            </div>
            <div class="image-positioner">
              <img v-bind:src="card.image" :alt="card.keyword" />
            </div>
            <span>{{ card.keyword }}</span>
            <span v-bind:aria-label="accessibleLabel(card)">/{{ card.label }}/</span>
          </div>
        </div>
      </div>
    </div>
    <PrevNextButtons v-if="showNavButtons" activity="RC"></PrevNextButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';

import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'RControlledVowels',
  components: { ActivityHeader, PrevNextButtons },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },

  data() {
    return {
      prevRoute: null,
      backButtonTitle: '',
      backButtonRoute: '',
      activityType: '',
      showNavButtons: false,
    };
  },

  beforeRouteEnter(to, from, next) {

    next((vm) => {
      vm.prevRoute = from;
      if (from !== null) {
        if (from.name === 'referenceposters' || to.params.unitId === undefined || to.params.unitId === '0') {
          vm.backButtonTitle = 'Posters';
          vm.backButtonRoute = from.name === 'referenceposters' ? 'chooseposters' : '';
          vm.activityType = 'Reference Posters';
          vm.showNavButtons = false;
        } else {
          vm.backButtonTitle = 'Drill Sounds';
          vm.backButtonRoute = 'choosedrillsounds';
          vm.activityType = "<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>";
          vm.showNavButtons = true;
        }
      }
    });
  },
  methods: {
    accessibleLabel(card) {
      return card.label.match(/.{1,1}/g).join('-');
    },
  },
  computed: {
    ...mapState('rcontrolled', ['rcontrolledData']),
    ...mapGetters('dataStore', ['weekNumber', 'dayNumber']),

    // intentionally left commented for future use

    getCardsForDisplay() {
      const programData = this.rcontrolledData.find((c) => c.program === this.programId);
      // const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      // const dayNumber = this.DayNumber(this.programId, this.unitId, this.weekId, this.dayId);

      // const lookup = parseFloat(`${weekNumber}.${this.dayId}`);
      const lookup = this.prevRoute && this.prevRoute.name === 'referenceposters' ? 365 : this.dayNumber(this.programId, this.unitId, this.weekId, this.dayId, false);
      if (programData) {
        let cardsToShow = programData.cardData.cards;
        cardsToShow = cardsToShow.filter((f) => f.include <= lookup);
        return cardsToShow;
        // return programData.cardData.cards;
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
#rc1 {
  width: 100%;
  max-width: 40rem;
  margin: auto;
}
#r-controlled-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .r-controlled-wrapper {
    padding: 2% 0;
  }
  .r-controlled {
    font-family: wBookman, serif;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-template-rows: 5fr 2fr;
    @extend %yellow-tile;
    transition: all 450ms ease-in-out;
    padding: 2%;
    box-sizing: border-box;

    span,
    img {
      transition: all 450ms ease-in-out;
    }
    .card-label {
      grid-row: 1 / span 2;
      grid-column: 1;
      display: flex;
      align-content: center;
      justify-content: center;

      background: #edc7bc;
      border: solid #e68978 0.1rem;
      border-radius: 0.5rem;
      box-sizing: border-box;

      span {
        font-size: 310%;
        text-align: center;
        align-self: center;
        line-height: calc(1.5rem + 80%);
      }
    }
    .image-positioner {
      grid-row: 1;
      grid-column: 2;
      width: 100%;
      position: relative;
      img {
        bottom: 0;
        display: block;
        position: absolute;
      }
    }
    span:nth-child(3) {
      grid-row: 2;
      grid-column: 2;
      align-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      padding: 0;
      font-size: 1.4rem;
      line-height: 1;
      align-self: flex-end;
    }
    span:nth-child(4) {
      grid-row: 1 / span 2;
      grid-column: 3;
      font-size: 4.5rem;
      text-align: center;
      line-height: 1.5;
      position: relative;
      align-self: center;
    }
    &:hover {
      transform: scale(1.03);

      img {
        transform: translateY(0.5rem);
      }
      span {
        &:nth-child(1) {
          transform: translateX(0.5rem);
        }
        &:nth-child(3) {
          transform: scale(1.2) translateY(0.1rem);
        }
        &:nth-child(4) {
          transform: translateX(-0.5rem);
        }
      }
    }
    &#ar img {
      width: 70%;
      left: 15%;
    }
    &#or img {
      width: 35%;
      left: 32.5%;
    }
    &#er img {
      width: 60%;
      left: 20%;
    }
    &#ir img {
      width: 40%;
      left: 30%;
    }
    &#ur img {
      width: 40%;
      left: 30%;
    }
  }
}
@media (max-height: 825px) {
  #rc1 {
    max-width: 30rem;
  }
  #r-controlled-container {
    .r-controlled {
      .card-label {
        span {
          font-size: 210%;
        }
      }

      span:nth-child(4) {
        font-size: 3.5rem;
      }
    }
  }
}
@media (max-height: 640px) {
  #rc1 {
    max-width: 30rem;
  }
  #r-controlled-container {
    .r-controlled {
      &#ar img {
        width: 60%;
        left: 20%;
      }
      &#or img {
        width: 30%;
        left: 35%;
      }
      &#er img {
        width: 50%;
        left: 25%;
      }
      &#ir img {
        width: 30%;
        left: 35%;
      }
      &#ur img {
        width: 30%;
        left: 35%;
      }
      .card-label {
        span {
          font-size: 150%;
        }
      }
      span:nth-child(3) {
        font-size: 1rem;
      }
      span:nth-child(4) {
        font-size: 2.5rem;
      }
    }
  }
}
@media (max-width: 500px) {
  #r-controlled-container {
    .r-controlled {
      height: 20%;
      span:nth-child(1) {
        font-size: 210%;
      }

      span:nth-child(4) {
        grid-row: 1 / span 2;
        grid-column: 3;
        font-size: 3rem;
      }
    }
  }
}
</style>
