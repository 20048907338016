<template>
  <div id="positioner">
    <ActivityHeader activity-name="Dry Erase Tablet"
     activity-type="Dictation"
     back-button-title="Dictation"
     back-button-route="choosedictation" />
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner">
        <div id="cards-container" class="dry-erase-tablet">
          <div id="grid-boxes">
            <div class="grid-box"></div>
            <div class="grid-box"></div>
            <div class="grid-box"></div>
          </div>
          <transition name="cardfade" appear>
            <div id="grid-lines-container">
              <div class="grid-lines">
                <div id="sky"></div>
                <div id="sky-line">
                  <span></span>
                </div>
                <div id="plane"></div>
                <div id="plane-line">
                  <span></span>
                </div>
                <div id="grass"></div>
                <div id="grass-line">
                  <span></span>
                </div>
                <div id="worm"></div>
                <div id="worm-line">
                  <span></span>
                </div>
              </div>
              <div class="grid-lines">
                <div id="sky"></div>
                <div id="sky-line">
                  <span></span>
                </div>
                <div id="plane"></div>
                <div id="plane-line">
                  <span></span>
                </div>
                <div id="grass"></div>
                <div id="grass-line">
                  <span></span>
                </div>
                <div id="worm"></div>
                <div id="worm-line">
                  <span></span>
                </div>
              </div>
              <div class="grid-lines">
                <div id="sky"></div>
                <div id="sky-line">
                  <span></span>
                </div>
                <div id="plane"></div>
                <div id="plane-line">
                  <span></span>
                </div>
                <div id="grass"></div>
                <div id="grass-line">
                  <span></span>
                </div>
                <div id="worm"></div>
                <div id="worm-line">
                  <span></span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <PrevNextButtons activity="DictDry"></PrevNextButtons>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'DryEraseTablet',
  components: { ActivityHeader, PrevNextButtons },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
};
</script>

<style lang="scss" scoped>
#card-positioner {
  right: 0;
}
.dry-erase-tablet {
  display: grid;
  grid-template-rows: 1fr 3.5fr;
  height: 100%;
}
#grid-boxes {
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%;
  .grid-box {
    border: $blue solid 0.3rem;
  }
}
#grid-lines-container {
  margin-top: 2rem;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 3%;
}
.grid-lines {
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 8rem auto;
  width: 100%;
  height: 100%;
  display: grid;
  grid-column-gap: 1%;

  #sky {
    grid-area: 1 / 1 / span 1 / span 1;
    background-image: url('/images/dictation/sky.svg');
  }
  #sky-line {
    grid-area: 1 / 2 / span 1 / span 1;
    span {
      height: 0;
      margin: auto 0;
      border: solid 0.2rem black;
      width: 100%;
    }
  }
  #plane {
    grid-area: 2 / 1 / span 1 / span 1;

    background-image: url('/images/dictation/plane.svg');
  }
  #plane-line {
    display: flex;
    grid-area: 2 / 2 / span 1 / span 1;

    span {
      border-style: dashed;
      border-width: 0.1rem;
      margin: auto 0;
    }
  }
  #grass {
    grid-area: 3 / 1 / span 1 / span 1;

    background-image: url('/images/dictation/grass.svg');
  }
  #grass-line {
    grid-area: 3 / 2 / span 1 / span 1;
    span {
      height: 0;
      margin: auto 0;
      border: solid 0.05rem black;
      width: 100%;
    }
  }
  #worm {
    grid-area: 4 / 1 / span 1 / span 1;

    background-image: url('/images/dictation/worm.svg');
  }
  #worm-line {
    grid-area: 4 / 2 / span 1 / span 1;
    span {
      height: 0;
      margin: auto 0;
      border: solid 0.05rem black;
      width: 100%;
    }
  }
  #sky,
  #plane,
  #grass,
  #worm {
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
  }
  #sky-line,
  #plane-line,
  #grass-line,
  #worm-line {
    display: flex;
    & > * {
      width: 100%;
    }
  }
}
</style>
