const DRAG_SUPPORT_KEY = 'nativeDragTriggerable';

// check if device is on iOS
function isDeviceOniOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

export default {
  isNativeDragSupported: () => localStorage.getItem(DRAG_SUPPORT_KEY) === 'true',
  setNativeDragSupport: () => {
    localStorage.setItem(DRAG_SUPPORT_KEY, !isDeviceOniOS() && !!window.DragEvent);
  },
};
