import Vue from 'vue';
import Router from 'vue-router';

import apiService from '@/api/apiService';

import Callback from '@/views/Callback.vue';
import ChooseProgram from '@/views/ChooseProgram.vue';
import ChooseUnit from '@/views/ChooseUnit.vue';
import ChooseWeek from '@/views/ChooseWeek.vue';
import ChooseActivity from '@/views/ChooseActivity.vue';
import ChooseDay from '@/views/ChooseDay.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';

import ReferencePosters from '@/views/activities/ReferencePosters.vue';
import SpellingOptions from '@/views/activities/referenceposters/SpellingOptions.vue';
import BasicKeywords from '@/views/activities/referenceposters/BasicKeywords.vue';
import MarkMyWords from '@/views/activities/referenceposters/MarkMyWords.vue';
import SyllableTypes from '@/views/activities/referenceposters/SyllableTypes.vue';
import SpellingRules from '@/views/activities/referenceposters/SpellingRules.vue';
import WeldedSounds from '@/views/activities/referenceposters/WeldedSounds.vue';
import LetterFormation from '@/views/activities/referenceposters/LetterFormation.vue';

import LargeLetterFormationGrid from '@/views/activities/referenceposters/LargeLetterFormationGrid.vue';
import LargeDictationGrid from '@/views/activities/referenceposters/LargeDictationGrid.vue';
import VowelExtension from '@/views/activities/referenceposters/VowelExtension.vue';

import store from '@/store';
import BuildWords from '@/views/activities/BuildWords.vue';
import Feedback from '@/views/Feedback.vue';
import DrillSounds from '@/views/activities/DrillSounds.vue';
import StoryTime from '@/views/activities/StoryTime.vue';
import Cursive from '@/views/activities/Cursive.vue';
import LargeSoundCards from '@/views/activities/drillsounds/LargeSoundCards.vue';
import StandardSoundCards from '@/views/activities/drillsounds/StandardSoundCards.vue';
import SuffixCards from '@/views/activities/drillsounds/SuffixCards.vue';
import TrickWords from '@/views/activities/drillsounds/TrickWords.vue';
import GuessWhichOne from '@/views/activities/drillsounds/GuessWhichOne.vue';
import SoundAlike from '@/views/activities/drillsounds/SoundAlike.vue';
import VowelSounds from '@/views/activities/drillsounds/VowelSounds.vue';
import VowelTeams from '@/views/activities/drillsounds/VowelTeams.vue';
import RControlledVowels from '@/views/activities/drillsounds/RControlledVowels.vue';
import SilentLetters from '@/views/activities/drillsounds/SilentLetters.vue';
import Dictation from '@/views/activities/Dictation.vue';
import CompositionBook from '@/views/activities/dictation/CompositionBook.vue';
import DryEraseTablet from '@/views/activities/dictation/DryEraseTablet.vue';


import LetterFormationGrids from '@/views/activities/LetterFormationGrids.vue';
import SentenceFrames from '@/views/activities/SentenceFrames.vue';

import Welcome from '@/views/Welcome.vue';
import Funhub from '@/views/Funhub.vue';

import Unauthorized from '@/views/Unauthorized.vue';

import mixpanel from 'mixpanel-browser';

Vue.use(Router);

function mixpanelTrack(to, next) {
  mixpanel.track('Activity Selected',
    {
      Unit: to.params.unitId,
      Week: to.params.weekId,
      Day: to.params.dayId,
      Collection: to.meta.parent,
      Activity: to.meta.title,
    });
  next();
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: { title: 'Azure Functions Test' },
    },
    {
      path: '/funhub',
      name: 'funhub',
      component: Funhub,
    },
    {
      path: '/callback',
      name: 'callback',
      component: Callback,
    },
    {
      path: '/program/:programId/referenceposters',
      name: 'referenceposters',
      component: ReferencePosters,
      props: true,
      meta: { title: 'Reference Posters', showBgImages: true },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/vowelextension',
      name: 'vowelextension',
      component: VowelExtension,
      props: true,
      meta: { title: 'Vowel Extension', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/largeletterformationgrid',
      name: 'largeletterformationgrid',
      component: LargeLetterFormationGrid,
      props: true,
      meta: { title: 'Large Letter Formation Grid', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/largedictationgrid',
      name: 'largedictationgrid',
      component: LargeDictationGrid,
      props: true,
      meta: { title: 'Large Dictation Grid', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/spellingoptions',
      name: 'spellingoptions',
      component: SpellingOptions,
      props: true,
      meta: { title: 'Spelling Options', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/markmywords',
      name: 'markmywords',
      component: MarkMyWords,
      props: true,
      meta: { title: 'Mark My Words', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/letterformation',
      name: 'letterformation',
      component: LetterFormation,
      props: true,
      meta: { title: 'Cursive Letter Formation', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/syllabletypes',
      name: 'syllabletypes',
      component: SyllableTypes,
      props: true,
      meta: { title: 'Syllable Types', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/spellingrules',
      name: 'spellingrules',
      component: SpellingRules,
      props: true,
      meta: { title: 'Spelling Rules', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/basickeywords',
      name: 'basickeywords',
      component: BasicKeywords,
      props: true,
      meta: { title: 'Basic Keywords', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },

    {
      path: '/program/:programId/referenceposters/vowelsounds',
      name: 'vowelsoundsposter',
      component: VowelSounds,
      props: true,
      meta: { title: 'Vowel Sounds', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/referenceposters/weldedsounds',
      name: 'weldedsounds',
      component: WeldedSounds,
      props: true,
      meta: { title: 'Welded Sounds', parent: 'Reference Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/buildwords',
      name: 'buildwords',
      component: BuildWords,
      props: true,
      meta: { title: 'Introduce New Concepts' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: Feedback,
      meta: { title: '' },
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/storytime',
      name: 'storytime',
      component: StoryTime,
      props: true,
      meta: { title: 'Storytime' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId?/week/:weekId?/day/:dayId?/cursive',
      name: 'cursive',
      component: Cursive,
      props: true,
      meta: { title: 'Cursive' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds',
      name: 'drillsounds',
      component: DrillSounds,
      props: true,
      meta: { title: 'Drill Sounds Posters' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/largesoundcards',
      name: 'largesoundcards',
      component: LargeSoundCards,
      props: true,
      meta: { title: 'Large Sound Cards', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId?/week/:weekId?/day/:dayId?/drillsounds/rcontrolledvowels',
      name: 'rcontrolledvowels',
      component: RControlledVowels,
      props: true,
      meta: { title: 'R-Controlled Vowels', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId?/week/:weekId?/day/:dayId?/drillsounds/silentletters',
      name: 'silentletters',
      component: SilentLetters,
      props: true,
      meta: { title: 'Silent Letters', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/standardsoundcards',
      name: 'standardsoundcards',
      component: StandardSoundCards,
      props: true,
      meta: { title: 'Standard Sound Cards', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/suffixcards',
      name: 'suffixcards',
      component: SuffixCards,
      props: true,
      meta: { title: 'Suffix Cards', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/trickwords',
      name: 'trickwords',
      component: TrickWords,
      props: true,
      meta: { title: 'Trick Words', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/guesswhichone',
      name: 'guesswhichone',
      component: GuessWhichOne,
      props: true,
      meta: { title: 'Guess Which One', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/soundalike',
      name: 'soundalike',
      component: SoundAlike,
      props: true,
      meta: { title: 'Sound Alike', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/drillsounds/vowelsounds',
      name: 'vowelsounds',
      component: VowelSounds,
      props: true,
      meta: { title: 'Vowel Sounds', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId?/week/:weekId?/day/:dayId?/drillsounds/vowelteams',
      name: 'vowelteams',
      component: VowelTeams,
      props: true,
      meta: { title: 'Vowel Teams', parent: 'Drill Sounds' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/sentenceframes/',
      name: 'sentenceframes',
      component: SentenceFrames,
      props: true,
      meta: { title: 'Sentence Frames' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/letterformationGrids/',
      name: 'letterformationgrids',
      component: LetterFormationGrids,
      props: true,
      meta: { title: 'Letter Formation Grids' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/',
      name: 'chooseprogram',
      component: ChooseProgram,
      meta: { title: 'Select A Program', showBgImages: true },
    },
    {
      path: '/program/:programId/chooseunit/',
      name: 'chooseunit',
      component: ChooseUnit,
      props: true,
      meta: { title: 'Select Unit', showBgImages: true },
    },
    {
      path: '/program/:programId/unit/:unitId/chooseweek',
      name: 'chooseweek',
      component: ChooseWeek,
      props: true,
      meta: { title: 'Select Week', showBgImages: true },
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/chooseday',
      name: 'chooseday',
      component: ChooseDay,
      props: true,
      meta: { title: 'Select Day', showBgImages: true },
    },
    {
      path: '/program/:programId/unit/:unitId?/week/:weekId?/day/:dayId?/chooseactivity',
      name: 'chooseactivity',
      component: ChooseActivity,
      props: true,
      meta: { title: 'Select Activity', showBgImages: true },
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/dictation',
      name: 'dictation',
      component: Dictation,
      props: true,
      meta: { title: 'Dictation' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/dictation/compositionbook',
      name: 'compositionbook',
      component: CompositionBook,
      props: true,
      meta: { title: 'CompositionBook', parent: 'Diction' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      path: '/program/:programId/unit/:unitId/week/:weekId/day/:dayId/dictation/dryerasetablet',
      name: 'dryerasetablet',
      component: DryEraseTablet,
      props: true,
      meta: { title: 'Dry Erase Tablet', parent: 'Diction' },
      beforeEnter: (to, _from, next) => mixpanelTrack(to, next),
    },
    {
      name: 'Unauthorized',
      path: '/unauthorized',
      component: Unauthorized,
    },
    {
      name: 'NotFound',
      path: '*',
      component: NotFoundPage,
      meta: { title: 'Page Not Found' },
    },
  ],
});

router.beforeEach((to, _from, next) => {
  router.app.$ga.set('user_id', store.state.authentication.userId);
  const isAuthenticated = store.getters['authentication/isAuthenticated']();
  const isExpired = store.getters['authentication/isTokenExpired']();

  if (to.name === 'funhub') {
    // admintest123 = admin code
    // 000014v0h0 = customer code
    const acceptedCodes = ['admintest123', '000014v0h0'];

    if (document.referrer.includes('wilsonacademy.com') && acceptedCodes.includes(to.query.code)) {
      store.dispatch('authentication/setFunHub', 'true');
      next();
    }
    else {
      // invalid code, do regular login
      store.dispatch('authentication/setFunHub', null);
      store.dispatch('authentication/login', null);
      next(false);
    }
  } else if (to.name === 'callback' || to.name === 'Unauthorized') {
    next();
  } else if (isExpired && isAuthenticated) {
    store.dispatch('authentication/renewSession', to).then(() => {
      if (to.meta && to.meta.title) {
        document.title = to.meta.title;
      }
      next();
    })
      .catch((err) => {
        if (err.code === 'login_required') {
          store.dispatch('authentication/login', to);
          next(false);
        }
      });
  } else if (isAuthenticated) {

    if (to.meta && to.meta.title) {
      document.title = to.meta.title;
    }
    next();
  } else {
    // const redirectPath = to.name;
    store.dispatch('authentication/login', to);
    next(false);
  }
});

export default router;
