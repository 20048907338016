<template>
  <div :class="this.frameType"
    :frameIndex="this.frameIndex"
    draggable
    dropzone
    @dragstart.self="onDrag($event)"
    @drop.self="onDropItem"
    @dragenter.self="onDragEnter"
    @dragend.self="onDragEnd"

    @touchstart.self="startTouchDrag($event, 'frame')"
    @touchmove.self="onTouchDrag($event, 'frame')"
    @touchend.self="onTouchEnd($event, 'frame')"
  >
    <div
      v-for="(cardData, $index) in letterCards"
      :style="{ 'left': `${cardData.offset}%` }"
      class="sc move"
      :key="$index"
      :itemType="'card'"
      :class="[{ hwcard: cardData.card.cardId < 0 }, cardData.card.color]"
      :data-index="$index"
      @dragstart.self="onCardDrag"
      @dragend="onCardDragEnd"
      @dragenter.self="onCardDragEnter"
      @dragleave.self="onCardDragLeave"
      draggable
      :data-cardId="cardData.card.cardId"

      @touchstart="startTouchDrag"
      @touchmove="onTouchDrag"
      @touchend="onTouchEnd"
    >
      <div class="letter-holder">
        <span class="sc-letter" v-if="cardData.card.cardId < 900 && !cardData.card.isBlank" v-html="cardData.card.label"></span>
        <span class="sc-letter" v-else>&nbsp;</span>
      </div>
    </div>
    <div class="close" @click="closeClicked($event)" v-if="location==='wordline'">X</div>
  </div>
</template>

<script>
import dragHelper from '@/helpers/checkNativeDrag';

export default {
  name: 'CardFrame',
  props: {
    itemType: { type: String, default: 'frame' },
    frameType: { type: String, default: '' },
    frameIndex: { type: Number, default: -1 },
    location: { type: String, default: '' },
    initialCards: { type: Array, default: () => [] },
  },
  data() {
    return {
      letterCards: this.initialCards,
      cardIndexBeingDragged: -1,
      cardBeingDragged: false,
      inCard: false,
      touchTimeout: null,
      clone: null,
      dragEvent: null,
    };
  },

  mounted() {
    if (this.initialCards && this.initialCards.length > 0) {
      this.letterCards = this.initialCards;
    }

    // add touch enter listener
    const el = document.querySelector(`[frameIndex='${this.frameIndex}']`);
    el.ontouchenter = this.onDragEnter;
  },

  methods: {
    closeClicked(e) {
      this.$emit('frameClose', { cards: this.letterCards, shiftKey: e.shiftKey });
    },

    runTouchTimeout() {
      this.touchTimeout = setTimeout(() => {
        this.stopTouchTimeout();
      }, 250);
    },

    stopTouchTimeout() {
      clearTimeout(this.touchTimeout);
      this.touchTimeout = null;
    },

    onDragEnter(e) {
      const dragType = e.dataTransfer.getData('sourceType');
      let effect = 'none';
      if (dragType !== 'frame') {
        e.dataTransfer.dropEffect = 'copy';
        e.dataTransfer.effectAllowed = 'copy';
        effect = 'copy';
        e.preventDefault();
      }
      return effect;
    },

    startTouchDrag(e, mode = 'card') {
      if (!dragHelper.isNativeDragSupported()) return;
      e.stopPropagation();

      // stop the touch from being mapped to a click
      if (e.cancelable) e.preventDefault();

      // make sure it's not a click
      this.runTouchTimeout();

      // if there is another clone, don't do it
      const existingClone = document.querySelector('.ghost-element');
      if (existingClone) return;

      // grab the whole card/frame
      const selector = mode === 'card' ? 'data-cardId' : 'frameIndex';
      let element = e.srcElement;
      while (!element.hasAttribute(selector)) {
        element = element.parentElement;
      }

      // clone the element and add it to the DOM
      const clone = element.cloneNode(true);
      let crtRawHeight = document.getElementsByClassName('drop-row')[0].offsetHeight - 17;
      let crtRawWidth = crtRawHeight * (element.offsetWidth / element.offsetHeight);
      clone.classList.add('ghost-element');
      clone.style.opacity = '0.8';
      clone.style.pointerEvents = 'none';
      clone.style.position = 'absolute';

      if (mode === 'frame') {
        crtRawHeight = document.getElementsByClassName('drop-row')[0].offsetHeight - 11;
        if (this.frameType === 'sylFrame') {
          crtRawWidth = document.getElementsByClassName('drop-row')[0].offsetWidth * 0.22;
        } else if (this.frameType === 'sufFrame') {
          crtRawWidth = document.getElementsByClassName('drop-row')[0].offsetWidth * 0.15;
        }
      }

      const elementTop = element.getBoundingClientRect().top;
      const elementLeft = element.getBoundingClientRect().left;

      clone.style.top = `${elementTop}px`;
      clone.style.left = `${elementLeft}px`;
      clone.style.height = `${crtRawHeight}px`;
      clone.style.width = `${crtRawWidth}px`;
      clone.style.opacity = '0';

      const cursorXoffset = e.touches[0].clientX - elementLeft;
      const cursorYoffset = e.touches[0].clientY - elementTop;

      clone.setAttribute('data-offsetX', cursorXoffset);
      clone.setAttribute('data-offsetY', cursorYoffset);
      this.clone = clone;

      document.body.appendChild(clone);
    },

    onTouchDrag(e, mode = 'card') {
      if (!dragHelper.isNativeDragSupported()) return;
      e.stopPropagation();

      // stop a timeout if it's going
      this.stopTouchTimeout();

      // grab the original element
      let element = e.target;
      const selector = mode === 'card' ? 'data-cardId' : 'frameIndex';
      while (!element.hasAttribute(selector)) {
        element = element.parentElement;
      }

      // if there is a clone, move it around
      // and create a drag event to match
      if (this.clone) {
        const cursorXoffset = e.touches[0].clientX - +this.clone.getAttribute('data-offsetX');
        const cursorYoffset = e.touches[0].clientY - +this.clone.getAttribute('data-offsetY');
        this.clone.style.left = `${cursorXoffset}px`;
        this.clone.style.top = `${cursorYoffset}px`;
        this.clone.style.opacity = '1';

        // create a drag event
        this.dragEvent = new DragEvent('dragstart', {
          clientX: e.touches[0].clientX,
          clientY: e.touches[0].clientY,
          dataTransfer: new DataTransfer(),
        });

        // set the item type
        this.dragEvent.dataTransfer.setData('itemType', mode);
        this.dragEvent.dataTransfer.effectAllowed = 'copy';

        // trigger the drag
        element.dispatchEvent(this.dragEvent);

        // get any elements the current one is over
        const overElement = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
        if (!overElement.hasAttribute('dropzone')) return;

        // check if ghost is over originating element
        if (mode === 'card') this.inCard = +overElement.getAttribute('frameIndex') === this.frameIndex;

        if (overElement.ontouchenter) {
          // trigger a drag over event
          const effect = overElement.ontouchenter(this.dragEvent);
          this.dragEvent.dataTransfer.effectAllowed = effect;

          const evt = new DragEvent('dragenter', {
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY,
            dataTransfer: this.dragEvent.dataTransfer,
            target: overElement,
          });
          overElement.dispatchEvent(evt);
        }
      }
    },

    onTouchEnd(e, mode = 'card') {
      if (!dragHelper.isNativeDragSupported()) return;
      e.stopPropagation();

      // grab the whole card/frame
      let item = e.srcElement;
      const selector = mode === 'card' ? 'data-cardId' : 'frameIndex';
      while (!item.hasAttribute(selector)) {
        item = item.parentElement;
      }
      // stop the timeout
      if (this.touchTimeout) {
        this.stopTouchTimeout();

        // remove the clone
        item.classList.remove('hide');
        this.dragEvent = null;
        document.body.removeChild(this.clone);
        this.clone = null;
        return;
      }

      if (this.clone) {
        // grab the current ghost element coords
        const coords = this.clone.getBoundingClientRect();
        const { clientX } = e.changedTouches[0];
        const clientY = coords.top;
        const offsetX = e.changedTouches[0].clientX - coords.left;

        // find any elements that the clone is over
        const element = document.elementFromPoint(clientX, clientY);
        if (element.hasAttribute('dropzone')) {
          // create a drop event
          const dropEvent = new DragEvent('drop', {
            clientX,
            clientY,
            dataTransfer: this.dragEvent ? this.dragEvent.dataTransfer : new DataTransfer(),
          });

          const index = item.getAttribute('data-index');
          dropEvent.dataTransfer.setData('cursorOffset', offsetX);
          if (mode === 'card') {
            dropEvent.dataTransfer.setData('cardIndex', index);
            dropEvent.dataTransfer.setData('cardPayload', JSON.stringify(this.letterCards[index]));
          }
          dropEvent.dataTransfer.setData('dragSource', 'frame');
          dropEvent.dataTransfer.setData('sourceType', 'frame');
          dropEvent.dataTransfer.setData('itemType', mode);
          dropEvent.dataTransfer.setData('frameIndex', this.frameIndex);

          // trigger the drop event on that element
          element.dispatchEvent(dropEvent);
        }

        // remove the clone
        item.classList.remove('hide');
        this.dragEvent = null;
        document.body.removeChild(this.clone);
        this.clone = null;
        this.onCardDragEnd();
      }
    },

    onCardDrag(e) {
      setTimeout(() => {
        e.srcElement.classList.add('hide');
      });
      const cursorXoffset = e.clientX - e.srcElement.getBoundingClientRect().left;
      const cursorYoffset = e.clientY - e.srcElement.getBoundingClientRect().top;
      e.dataTransfer.setDragImage(e.srcElement, cursorXoffset, cursorYoffset);
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      const index = e.srcElement.getAttribute('data-index');
      e.dataTransfer.setData('cardPayload', JSON.stringify(this.letterCards[index]));
      e.dataTransfer.setData('cardIndex', index);
      e.dataTransfer.setData('cursorOffset', cursorXoffset);
      e.dataTransfer.setData('dragSource', 'frame');
      e.dataTransfer.setData('sourceType', 'frame');
      e.dataTransfer.setData('frameIndex', this.frameIndex);
      this.cardIndexBeingDragged = index;
      this.cardBeingDragged = true;
    },

    onCardDragEnter() {
      this.inCard = true;
    },
    onCardDragLeave() {
      this.inCard = false;
    },

    // If dragging card, remove original
    onCardDragEnd() {
      if (this.inCard) {
        return;
      }
      if (this.cardIndexBeingDragged > -1) {
        this.letterCards.splice(this.cardIndexBeingDragged, 1);
        this.cardIndexBeingDragged = -1;
      }
      this.cardBeingDragged = false;
    },

    onDrag(e) {
      const cursorXoffset = e.clientX - e.srcElement.getBoundingClientRect().left;
      const cursorYoffset = e.clientY - e.srcElement.getBoundingClientRect().top;

      if (e.srcElement.parentElement.id === 'sylFrames' || e.srcElement.parentElement.id === 'sufFrames') {
        // dragging from frame pile
        const crt = e.srcElement.cloneNode(true);
        crt.style.position = 'absolute';
        crt.style.top = '-150px';
        const crtRawHeight = document.getElementsByClassName('drop-row')[0].offsetHeight - 11;
        crt.style.height = `${crtRawHeight}px`;

        if (this.frameType === 'sylFrame') {
          crt.style.width = `${document.getElementsByClassName('drop-row')[0].offsetWidth * 0.22}px`;
        } else if (this.frameType === 'sufFrame') {
          crt.style.width = `${document.getElementsByClassName('drop-row')[0].offsetWidth * 0.15}px`;
        }

        if (!this.clone) document.body.appendChild(crt);

        e.dataTransfer.setDragImage(crt, cursorXoffset, cursorYoffset);
        e.dataTransfer.setData('cursorOffset', cursorXoffset);
        e.dataTransfer.effectAllowed = 'copy';
        e.dataTransfer.dropEffect = 'copy';
        const payload = { frameType: this.frameType, frameIndex: this.frameIndex, itemType: this.itemType, location: this.location };
        e.dataTransfer.setData('cardPayload', JSON.stringify(payload));
        e.dataTransfer.setData('sourceType', 'frame');
        e.dataTransfer.setData('itemType', 'frame');
      } else {
        // dragging within letter line
        const index = e.srcElement.getAttribute('data-index');
        e.dataTransfer.setData('cardIndex', index);
        e.dataTransfer.setDragImage(e.srcElement, cursorXoffset, cursorYoffset);
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('cursorOffset', cursorXoffset);
        e.dataTransfer.setData('sourceType', 'frame');
        e.dataTransfer.setData('itemType', 'frame');
        const payload = { frameType: this.frameType, frameIndex: this.frameIndex, itemType: this.itemType, location: this.location };
        e.dataTransfer.setData('cardPayload', JSON.stringify(payload));
      }
      document.getElementById('rht-1').classList.add('standby-for-in-use');
      document.getElementById('rht-2').classList.add('standby-for-in-use');
      const element = e.target;
      setTimeout(() => {
        element.classList.add('hide');
      });
    },

    onDragEnd(e) {
      if (e.srcElement === e.toElement) {
        e.srcElement.classList.remove('hide');
      }
    },

    convertToPercentageInFrame(position, frame) {
      return `${(position / parseFloat(getComputedStyle(frame).width, 10)) * 100}`;
    },

    onDropItem(e) {
      const sourceType = e.dataTransfer.getData('sourceType');
      const itemType = e.dataTransfer.getData('itemType');
      if (itemType === 'frame') {
        return; // can't drop a frame on a frame
      }

      this.$emit('cardDroppedOnFrame');
      const cardIndex = +e.dataTransfer.getData('cardIndex') || 0;
      const offsetVal = this.convertToPercentageInFrame(e.clientX - e.srcElement.getBoundingClientRect().left - e.dataTransfer.getData('cursorOffset'), e.target);
      const cardData = { card: JSON.parse(e.dataTransfer.getData('cardPayload')), offset: offsetVal };

      switch (sourceType) {
        case 'row': // dropped card from word line to frame
          this.letterCards.push(cardData);
          this.cardDropped(cardData.card, cardIndex);
          break;
        case 'frame': // dropped card from frame - this or another
          if (this.cardBeingDragged) {
            // card was dropped within this frame
            this.letterCards[cardIndex].offset = offsetVal;
            this.cardIndexBeingDragged = -1;

            // unhide the card

          } else {
            // card dropped from another frame
            cardData.card.offset = offsetVal;
            this.letterCards.push(cardData.card);
            this.cardDropped(cardData.card, cardIndex);
          }
          break;
        default:
          // dragged card from deck of letter cards
          cardData.card.offset = offsetVal;
          this.letterCards.push(cardData);

      }
    },

    cardDropped(card, cardIndex) {
      card.cardIndex = cardIndex;
      this.$emit('cardDropped', card);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/standardcards';
  @import url('https://fonts.googleapis.com/css?family=Patrick+Hand&display=swap');
  .sylFrame,
  .sufFrame {
    @extend .draggables-shadow;
    .sc {
      top: 4%;
      bottom: 6%;
      font-size: unset;
    }
  }
  .sylFrame {
    .sc {
      width: 18%;
      &.hwcard {
        border: none;
        box-shadow: none;
        font-family: "Patrick Hand, sans-serif";
        letter-spacing: .4rem;
        width: auto;
        margin: 0 auto;
        position: relative;
        .letter-holder{
          position: relative;
          width: auto;
        }
        &:after{
          content: unset;
        }
      }
      &.suffix-yellow{
        width: 30%;
      }
    }
  }
  .sufFrame {
    .sc {
      width: 27%;
    }
  }
  .sc {
    top: 0.5rem;
    bottom: 0.5rem;
    position: absolute;
    z-index: 10;

    .sc-letter {
      position: relative;
      display: inline-block;
    }
  }

  #drop-rows {
    .sc{
      &.hw-card{
        font-size: 5rem;
      }

    }
  }

  .large {
    font-size: 2em;
  }

  .close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 99;
  }
  .hide {
    transform: translateX(-9999px);
  }

</style>
