<template>
  <div id="positioner" class="drillsounds">
    <ActivityHeader
      :activity-type=activityType
      activity-name="Silent Letters"
      :back-button-title=backButtonTitle
      :show-nav-buttons=showNavButtons
      :back-button-route=backButtonRoute>
    </ActivityHeader>
      <div id="silentletters">
        <div v-for="card in getCardsForDisplay" :key="card.cardId" class="silent-letter" :id="card.label">
          <span class="pair">{{ card.label }}</span>
          <div class="center-keyword">
            <div class="image-positioner" data-automationId="reference_posters_silent_letters_with_img">
              <img v-bind:src="card.image" alt="card keyword" />
            </div>
            <span>{{ card.keyword }}</span>
          </div>
            <span v-if="card.label === 'wr'||card.label === 'rh'" class="slashes">/r/</span>
            <span v-else-if="card.label === 'gn'||card.label === 'kn'" class="slashes">/n/</span>
            <span v-else-if="card.label === 'mn'||card.label === 'mb'" class="slashes">/m/</span>
            <span v-else-if="card.label === 'gh'" class="slashes">/g/</span>
        </div>
      </div>
    <PrevNextButtons v-if="showNavButtons" activity="SL"></PrevNextButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'SilentLetters',
  components: { ActivityHeader, PrevNextButtons },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  data() {
    return {
      prevRoute: null,
      backButtonTitle: '',
      backButtonRoute: '',
      activityType: '',
      showNavButtons: false,
    };
  },
  beforeRouteEnter(to, from, next) {

    next((vm) => {
      vm.prevRoute = from;
      if (from !== null) {
        if (from.name === 'referenceposters' || to.params.unitId === undefined || to.params.unitId === '0') {
          vm.backButtonTitle = 'Posters';
          vm.backButtonRoute = from.name === 'referenceposters' ? 'chooseposters' : '';
          vm.activityType = 'Reference Posters';
          vm.showNavButtons = false;
        } else {
          vm.backButtonTitle = 'Drill Sounds';
          vm.backButtonRoute = 'choosedrillsounds';
          vm.activityType = "<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>";
          vm.showNavButtons = true;
        }
      }
    });
  },
  computed: {
    ...mapState('silentletters', ['silentletterData']),
    ...mapGetters('dataStore', ['weekNumber']),

    getCardsForDisplay() {
      const programData = this.silentletterData.find((c) => c.program === this.programId);
      // const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);

      let weekNumber = 0;
      if (this.weekId === undefined || this.weekId === '0') {
        weekNumber = this.weekNumber(this.programId, '13', '2');
      } else {
        weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      }
      if (programData) {
        let cardsToShow = programData.cardData.cards;
        cardsToShow = cardsToShow.filter((f) => f.include === weekNumber);

        return cardsToShow;
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
#silentletters {
  display: grid;
  grid-gap: 2rem;
  margin: auto;
  width: 100%;
  max-width: 1000px;

  .silent-letter {
    @extend %purple-tile;
    font-family: wBookman;
    display: flex;
    .center-keyword {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .image-positioner {
        position: relative;
        flex: 1 0 auto;

        img {
          max-width: 100%;
          max-height: 120%;
          position: absolute;
          bottom: 0;
        }
      }
      span {
        align-self: center;
      }
    }
    &:after {
      display: inline-block;
      padding-bottom: 17%;
      height: 0;
      grid-row: 1 / span 3;
      content: '';
    }
    .pair {
      flex: 0 0 25%;
      font-size: 5rem;
      text-align: center;
      line-height: 1.4;
    }
    .slashes {
      flex: 0 0 35%;
      line-height: 1.4;
      font-size: 5rem;
      text-align: center;
    }
    &#wr {
      grid-area: 1 / 1 / span 1 / span 1;
    }
    &#rh {
      grid-area: 2 / 1 / span 1 / span 1;
    }
    &#gn {
      grid-area: 3 / 1 / span 1 / span 1;
    }
    &#kn {
      grid-area: 4 / 1 / span 1 / span 1;
    }
    &#mn {
      grid-area: 1 / 2 / span 1 / span 1;
    }
    &#mb {
      grid-area: 2 / 2 / span 1 / span 1;
    }
    &#gh {
      grid-area: 3 / 2 / span 1 / span 1;
    }
  }
}
@media (max-width: 950px) {
  #silentletters {
    .silent-letter {
      .slashes,
      .pair {
        line-height: 1.7;
        font-size: 4rem;
      }
    }
  }
}
@media (max-width: 700px) {
  #silentletters {
    .silent-letter {
      &#wr {
        grid-area: 1 / 1 / span 1 / span 1;
      }
      &#rh {
        grid-area: 2 / 1 / span 1 / span 1;
      }
      &#gn {
        grid-area: 3 / 1 / span 1 / span 1;
      }
      &#kn {
        grid-area: 4 / 1 / span 1 / span 1;
      }
      &#mn {
        grid-area: 5 / 1 / span 1 / span 1;
      }
      &#mb {
        grid-area: 6 / 1 / span 1 / span 1;
      }
      &#gh {
        grid-area: 7 / 1 / span 1 / span 1;
      }
    }
  }
}
</style>
