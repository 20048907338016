<template>
  <div>
    <h2>Testing Azure Functions</h2>
    <div><button @click="callApi">Test Api</button></div>
    <div>API Results: {{ apiResults }}</div>
  </div>
</template>

<script>
import apiService from '@/api/apiService';

export default {
  name: 'welcome',
  data() {
    return { apiResults: '' };
  },
  methods: {
    callApi() {
      console.log('calling api');
      const id = JSON.parse(sessionStorage.getItem('idToken_payload'))['https://wilsonacademy.com/contactId'];

      apiService.callValidateUserApi(id)
        .then((response) => {
          this.apiResults = response;
        })
        .catch((err) => {
          const responseData = err.response.data;
          const responseError = err.response.status;
          console.error(responseError, responseData);
        });

    },
  },
};
</script>

<style>
button {
  outline: none;
}
</style>
