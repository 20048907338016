<template>
  <div id="positioner">
    <ActivityHeader activity-type="Select Day" back-button-title="Weeks" :show-nav-buttons="true" back-button-route="chooseweek" />
    <div class="wiz-mod-heading">
      <h1 class="wiz-heading">Select a Day</h1>
    </div>
    <ul class="weeks-container wiz-buttons">
      <li v-for="day in [1, 2, 3, 4, 5]" :key="day">
        <div class="week wiz-button" v-bind:aria-label="'day ' + day" @click="clickDay(day)">Day {{ day }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';
import router from '@/router';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'ChooseDay',
  components: { ActivityHeader },
  // data() {
  //   const { programId, weekId, unitId } = this.$route.params;
  //   return { programId, unitId, weekId };
  // },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
  },
  methods: {
    clickDay(day) {
      mixpanel.track('Day Selected', { Unit: this.unitId, Week: this.weekId, Day: day });
      router.push({
        name: 'chooseactivity',
        params: { programId: this.programId, unitId: this.unitId, weekId: this.weekId, dayId: day.toString() },
      });
    },
  },
};
</script>
