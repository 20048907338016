export default {
  namespaced: true,
  getters: {
    availableActivities: (state) => (programId, unitWeekDay) => {
      // TODO: Remove when Fun3 data has been loaded.
      // if (programId === 'fun3') return [];
      const program = state.programs.find((c) => c.program === programId);
      const activities = program.xref.find((c) => c.UnitWeekDay === unitWeekDay);
      if (activities) {
        return activities.activities;
      }
      return [];
    },
    nextPrevActivity: (state) => (programId, activity, unitWeekDay) => {
      const program = state.programs.find((c) => c.program === programId);
      const { order } = program;
      const activities = program.xref.find((c) => c.UnitWeekDay === unitWeekDay);
      let prevActivity = '';
      let nextActivity = '';

      if (activities) {
        const activityPos = order.findIndex((f) => f === activity);
        if (activityPos < order.length - 1) {
          let pos = activityPos;
          while (pos < order.length) {
            const next = order[pos + 1];
            if (activities.activities.includes(next)) {
              nextActivity = next;
              break;
            }
            pos += 1;
          }
        }

        if (activityPos > 0) {
          let pos = activityPos;
          while (pos > 0) {
            const prev = order[pos - 1];
            if (activities.activities.includes(prev)) {
              prevActivity = prev;
              break;
            }
            pos -= 1;
          }
        }
      }
      return { prevActivity, nextActivity };
    },
  },
  state: {
    programs: [
      {
        program: 'fun2',
        order: ['INC',
          'LC',
          'SC',
          'TW',
          'SUFF',
          'VT',
          'RC',
          'VS',
          'ST',
          'DictComp',
          'DictDry'],
        xref: [
          { UnitWeekDay: '1-1-1', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-1-2', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-1-3', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-1-4', activities: ['LC', 'SC', 'DictDry'] },
          { UnitWeekDay: '1-1-5', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-2-1', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-2-2', activities: ['LC', 'SC', 'DictDry'] },
          { UnitWeekDay: '1-2-3', activities: ['SC', 'INC', 'DictDry'] },
          { UnitWeekDay: '1-2-4', activities: ['LC', 'SC', 'DictComp'] },
          { UnitWeekDay: '1-2-5', activities: ['LC', 'SC'] },

          { UnitWeekDay: '2-1-1', activities: ['SC', 'INC'] },
          { UnitWeekDay: '2-1-2', activities: ['SC', 'INC', 'ST'] },
          { UnitWeekDay: '2-1-3', activities: ['LC', 'SC', 'TW', 'INC', 'DictDry'] },
          { UnitWeekDay: '2-1-4', activities: ['LC', 'SC', 'RC', 'TW', 'ST', 'DictComp'] },
          { UnitWeekDay: '2-1-5', activities: ['LC', 'SC', 'RC', 'DictComp'] },

          { UnitWeekDay: '2-2-1', activities: ['LC', 'SC', 'RC', 'INC'] },
          { UnitWeekDay: '2-2-2', activities: ['LC', 'SC', 'RC', 'ST', 'DictDry'] },
          { UnitWeekDay: '2-2-3', activities: ['LC', 'SC', 'RC', 'ST'] },
          { UnitWeekDay: '2-2-4', activities: ['LC', 'SC', 'RC', 'TW', 'INC', 'DictComp'] },
          { UnitWeekDay: '2-2-5', activities: ['LC', 'SC', 'RC', 'TW'] },

          { UnitWeekDay: '3-1-1', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '3-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '3-1-3', activities: ['LC', 'SC', 'INC', 'VT', 'DictDry'] },
          { UnitWeekDay: '3-1-4', activities: ['SC', 'RC', 'VT', 'INC', 'ST', 'DictComp'] },
          { UnitWeekDay: '3-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '4-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '4-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '4-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'ST', 'DictDry'] },
          { UnitWeekDay: '4-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'ST', 'DictComp'] },
          { UnitWeekDay: '4-1-5', activities: ['LC', 'SC', 'TW', 'INC', 'DictComp'] },

          { UnitWeekDay: '4-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '4-2-2', activities: ['LC', 'SC', 'RC', 'VT', 'INC', 'DictDry'] },
          { UnitWeekDay: '4-2-3', activities: ['SC', 'INC', 'ST'] },
          { UnitWeekDay: '4-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST', 'DictComp'] },
          { UnitWeekDay: '4-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '5-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '5-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC', 'ST'] },
          { UnitWeekDay: '5-1-3', activities: ['LC', 'SC', 'INC', 'ST', 'DictDry'] },
          { UnitWeekDay: '5-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '5-1-5', activities: ['LC', 'SC', 'TW', 'DictComp'] },

          { UnitWeekDay: '5-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '5-2-2', activities: ['SC', 'SUFF', 'ST', 'DictDry'] },
          { UnitWeekDay: '5-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF'] },
          { UnitWeekDay: '5-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST', 'DictComp'] },
          { UnitWeekDay: '5-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '6-1-1', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '6-1-2', activities: ['LC', 'SC', 'INC'] },
          { UnitWeekDay: '6-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC', 'ST', 'DictDry'] },
          { UnitWeekDay: '6-1-4', activities: ['LC', 'SC', 'INC', 'DictComp'] },
          { UnitWeekDay: '6-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictComp'] },

          { UnitWeekDay: '6-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '6-2-2', activities: ['LC', 'SC', 'TW', 'INC'] },
          { UnitWeekDay: '6-2-3', activities: ['LC', 'SC', 'DictDry'] },
          { UnitWeekDay: '6-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST', 'DictComp'] },
          { UnitWeekDay: '6-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '7-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '7-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '7-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST'] },
          { UnitWeekDay: '7-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'TW', 'INC'] },
          { UnitWeekDay: '7-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },

          { UnitWeekDay: '7-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '7-2-2', activities: ['LC', 'SC'] },
          { UnitWeekDay: '7-2-3', activities: ['LC', 'SC', 'DictDry'] },
          { UnitWeekDay: '7-2-4', activities: ['LC', 'SC', 'TW', 'ST', 'DictComp'] },
          { UnitWeekDay: '7-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },

          { UnitWeekDay: '7-3-1', activities: ['LC', 'SC', 'SUFF', 'INC'] },
          { UnitWeekDay: '7-3-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictDry'] },
          { UnitWeekDay: '7-3-3', activities: ['LC', 'SC', 'SUFF'] },
          { UnitWeekDay: '7-3-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '7-3-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '8-1-1', activities: ['SC', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '8-1-2', activities: ['LC', 'SC', 'SUFF', 'INC'] },
          { UnitWeekDay: '8-1-3', activities: ['LC', 'SC', 'RC', 'TW', 'VT', 'ST', 'DictDry'] },
          { UnitWeekDay: '8-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '8-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '9-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'DictDry'] },
          { UnitWeekDay: '9-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictDry'] },
          { UnitWeekDay: '9-1-3', activities: ['LC', 'SC', 'SUFF', 'TW', 'ST'] },
          { UnitWeekDay: '9-1-4', activities: ['LC', 'SC', 'SUFF', 'TW', 'ST', 'DictComp'] },
          { UnitWeekDay: '9-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },

          { UnitWeekDay: '9-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '9-2-2', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST'] },
          { UnitWeekDay: '9-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictDry'] },
          { UnitWeekDay: '9-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictComp'] },
          { UnitWeekDay: '9-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '10-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '10-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC'] },
          { UnitWeekDay: '10-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC', 'DictDry'] },
          { UnitWeekDay: '10-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '10-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST', 'DictComp'] },

          { UnitWeekDay: '10-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '10-2-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF'] },
          { UnitWeekDay: '10-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST', 'DictDry'] },
          { UnitWeekDay: '10-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '10-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'TW'] },

          { UnitWeekDay: '11-1-1', activities: ['LC', 'SC', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '11-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '11-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC', 'ST', 'DictDry'] },
          { UnitWeekDay: '11-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '11-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'TW', 'DictComp'] },

          { UnitWeekDay: '11-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '11-2-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '11-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'DictDry'] },
          { UnitWeekDay: '11-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '11-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '12-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '12-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '12-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictDry'] },
          { UnitWeekDay: '12-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '12-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST'] },

          { UnitWeekDay: '13-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '13-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '13-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC', 'DictDry'] },
          { UnitWeekDay: '13-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST', 'DictComp'] },
          { UnitWeekDay: '13-1-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictComp'] },

          { UnitWeekDay: '13-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST'] },
          { UnitWeekDay: '13-2-2', activities: ['LC', 'SC', 'SUFF', 'INC'] },
          { UnitWeekDay: '13-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictComp'] },
          { UnitWeekDay: '13-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST', 'DictComp'] },
          { UnitWeekDay: '13-2-5', activities: ['LC', 'SC', 'SUFF', 'TW'] },

          { UnitWeekDay: '14-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '14-1-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '14-1-3', activities: ['LC', 'SC', 'ST', 'DictDry'] },
          { UnitWeekDay: '14-1-4', activities: ['LC', 'SC', 'SUFF', 'ST', 'DictComp'] },
          { UnitWeekDay: '14-1-5', activities: ['LC', 'SC', 'SUFF', 'TW', 'DictComp'] },

          { UnitWeekDay: '14-2-1', activities: ['LC', 'SC'] },
          { UnitWeekDay: '14-2-2', activities: ['LC', 'SC', 'SUFF', 'ST', 'DictDry'] },
          { UnitWeekDay: '14-2-3', activities: ['LC', 'SC', 'SUFF', 'TW', 'ST', 'DictDry'] },
          { UnitWeekDay: '14-2-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '14-2-5', activities: ['LC', 'SC', 'RC', 'VT', 'TW'] },

          { UnitWeekDay: '15-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '15-1-2', activities: ['LC', 'SC', 'TW', 'INC'] },
          { UnitWeekDay: '15-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST', 'DictDry'] },
          { UnitWeekDay: '15-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '15-1-5', activities: ['LC', 'SC', 'TW', 'ST', 'DictComp'] },

          { UnitWeekDay: '15-2-1', activities: ['LC', 'SC', 'SUFF', 'ST'] },
          { UnitWeekDay: '15-2-2', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'ST'] },
          { UnitWeekDay: '15-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'DictDry'] },
          { UnitWeekDay: '15-2-4', activities: ['LC', 'SC', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '15-2-5', activities: ['LC', 'SC', 'SUFF', 'TW'] },

          { UnitWeekDay: '16-1-1', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'INC', 'ST'] },
          { UnitWeekDay: '16-1-2', activities: ['LC', 'SC', 'SUFF', 'INC'] },
          { UnitWeekDay: '16-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST', 'DictDry'] },
          { UnitWeekDay: '16-1-4', activities: ['LC', 'SC', 'SUFF', 'DictComp'] },
          { UnitWeekDay: '16-1-5', activities: ['LC', 'SC', 'TW'] },

          { UnitWeekDay: '17-1-1', activities: ['SC', 'RC', 'VT', 'SUFF', 'INC'] },
          { UnitWeekDay: '17-1-2', activities: ['LC', 'SC', 'SUFF', 'INC'] },
          { UnitWeekDay: '17-1-3', activities: ['LC', 'SC', 'RC', 'VT', 'SUFF', 'DictDry'] },
          { UnitWeekDay: '17-1-4', activities: ['LC', 'SC', 'RC', 'VT', 'ST', 'TW', 'DictComp'] },
          { UnitWeekDay: '17-1-5', activities: ['LC', 'SC', 'SUFF', 'TW', 'DictComp'] },

          { UnitWeekDay: '17-2-1', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'INC', 'ST', 'DictDry'] },
          { UnitWeekDay: '17-2-2', activities: ['LC', 'SC', 'TW', 'ST', 'DictDry'] },
          { UnitWeekDay: '17-2-3', activities: ['LC', 'SC', 'RC', 'VT', 'TW', 'ST'] },
          { UnitWeekDay: '17-2-4', activities: ['LC', 'SC', 'SUFF', 'ST', 'DictComp'] },
          { UnitWeekDay: '17-2-5', activities: ['SC', 'TW'] },
        ],
      },
      {
        program: 'fun3',
        order: [
          'INC',
          'LC',
          'SC',
          'SUFF',
          'VT',
          'RC',
          'VS',
          // NOTE:  the GWO activity was requeted to be removed, however I have concerns that there have been communication lapses and this might be added back in at some point in the future.  Please leave these areas where GWO activities have been commented out.
          // /* 'GWO', */
          'SA',
          'SL',
          'CURSIVE',
          'DictComp',
          'DictDry',
        ],
        xref: [
          { UnitWeekDay: '1-1-1', activities: ['CURSIVE', 'SC', 'INC'] },
          { UnitWeekDay: '1-1-2', activities: ['CURSIVE', 'SC', 'INC'] },
          { UnitWeekDay: '1-1-3', activities: ['CURSIVE', 'LC', 'SC', /* 'GWO', */ 'DictDry'] },
          { UnitWeekDay: '1-1-4', activities: ['CURSIVE', 'LC', 'SC', 'DictComp'] },
          { UnitWeekDay: '1-1-5', activities: ['CURSIVE', 'LC', 'SC', 'DictComp'] },

          { UnitWeekDay: '1-2-1', activities: ['CURSIVE', 'LC', 'SC', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '1-2-2', activities: ['CURSIVE', 'LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-2-3', activities: ['CURSIVE', 'LC', 'SC', 'INC'] },
          { UnitWeekDay: '1-2-4', activities: ['CURSIVE', 'LC', 'SC', 'DictComp'] },
          { UnitWeekDay: '1-2-5', activities: ['CURSIVE', 'LC', 'SC'] },

          { UnitWeekDay: '2-1-1', activities: ['CURSIVE', 'SUFF', 'SC', 'INC'] },
          { UnitWeekDay: '2-1-2', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'INC'] },
          { UnitWeekDay: '2-1-3', activities: ['CURSIVE', 'SUFF', 'SC', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '2-1-4', activities: ['CURSIVE', 'SUFF', 'SC', 'INC', 'DictDry'] },
          { UnitWeekDay: '2-1-5', activities: ['CURSIVE', 'SUFF', 'SC', 'INC', 'DictComp'] },

          { UnitWeekDay: '2-2-1', activities: ['CURSIVE', 'SC', 'INC'] },
          { UnitWeekDay: '2-2-2', activities: ['CURSIVE', 'SC', 'INC'] },
          { UnitWeekDay: '2-2-3', activities: ['CURSIVE', 'SC', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '2-2-4', activities: ['CURSIVE', 'SC', 'DictComp'] },
          { UnitWeekDay: '2-2-5', activities: ['CURSIVE', 'SUFF', 'SC', 'DictComp'] },

          { UnitWeekDay: '2-3-1', activities: ['CURSIVE', 'SC', 'INC'] },
          { UnitWeekDay: '2-3-2', activities: ['CURSIVE', 'SUFF', 'SC', 'INC', 'GWO'] },
          { UnitWeekDay: '2-3-3', activities: ['CURSIVE', 'SUFF', 'SC', 'DictDry'] },
          { UnitWeekDay: '2-3-4', activities: ['CURSIVE', 'SUFF', 'SC', 'DictComp'] },
          { UnitWeekDay: '2-3-5', activities: ['CURSIVE', 'SC', 'DictComp'] },

          { UnitWeekDay: '3-1-1', activities: ['CURSIVE', 'SC', 'INC'] },
          { UnitWeekDay: '3-1-2', activities: ['CURSIVE', 'SC', 'VS', 'INC'] },
          { UnitWeekDay: '3-1-3', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '3-1-4', activities: ['CURSIVE', 'SC', 'VS', 'INC', 'DictComp'] },
          { UnitWeekDay: '3-1-5', activities: ['CURSIVE', 'SC', 'VS'] },

          { UnitWeekDay: '4-1-1', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '4-1-2', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'GWO'] },
          { UnitWeekDay: '4-1-3', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'INC', 'DictDry'] },
          { UnitWeekDay: '4-1-4', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '4-1-5', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },

          { UnitWeekDay: '4-2-1', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '4-2-2', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'DictDry'] },
          { UnitWeekDay: '4-2-3', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'INC', 'DictComp'] },
          { UnitWeekDay: '4-2-4', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '4-2-5', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT'] },

          { UnitWeekDay: '5-1-1', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '5-1-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '5-1-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'DictDry'] },
          { UnitWeekDay: '5-1-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '5-1-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT'] },

          { UnitWeekDay: '5-2-1', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '5-2-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '5-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: '5-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '5-2-5', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'RC', 'VT'] },

          { UnitWeekDay: '6-1-1', activities: ['CURSIVE', 'SC', 'VS', 'INC'] },
          { UnitWeekDay: '6-1-2', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '6-1-3', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'INC'] },
          { UnitWeekDay: '6-1-4', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'INC', 'DictComp'] },
          { UnitWeekDay: '6-1-5', activities: ['CURSIVE', 'SC', 'VS', 'DictComp'] },

          { UnitWeekDay: '6-2-1', activities: ['CURSIVE', 'SC', 'SA', 'INC'] },
          { UnitWeekDay: '6-2-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '6-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'DictComp'] },
          { UnitWeekDay: '6-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'DictComp'] },
          { UnitWeekDay: '6-2-5', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'DictComp'] },

          { UnitWeekDay: '6-3-1', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '6-3-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'INC', 'DictDry'] },
          { UnitWeekDay: '6-3-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '6-3-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'DictComp'] },
          { UnitWeekDay: '6-3-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA'] },

          { UnitWeekDay: 'Bonus-1-1', activities: ['CURSIVE', 'SC', 'VS', 'INC'] },
          { UnitWeekDay: 'Bonus-1-2', activities: ['CURSIVE', 'SC', 'VS', 'INC'] },
          { UnitWeekDay: 'Bonus-1-3', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'INC'] },
          { UnitWeekDay: 'Bonus-1-4', activities: ['CURSIVE', 'SC', 'VS', 'DictDry'] },
          { UnitWeekDay: 'Bonus-1-5', activities: ['CURSIVE', 'SC', 'VS', 'DictDry'] },

          { UnitWeekDay: 'Bonus-2-1', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT'] },
          { UnitWeekDay: 'Bonus-2-2', activities: ['CURSIVE', 'SC', 'VS', 'INC', 'DictDry'] },
          { UnitWeekDay: 'Bonus-2-3', activities: ['CURSIVE', 'SC', 'VS', 'INC'] },
          { UnitWeekDay: 'Bonus-2-4', activities: ['CURSIVE', 'SC', 'VS', 'INC', 'DictDry'] },
          { UnitWeekDay: 'Bonus-2-5', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'LR'] },

          { UnitWeekDay: '7-1-1', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '7-1-2', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '7-1-3', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'DictDry'] },
          { UnitWeekDay: '7-1-4', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'SA', 'DictComp'] },
          { UnitWeekDay: '7-1-5', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '7-2-1', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '7-2-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '7-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'DictDry'] },
          { UnitWeekDay: '7-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'DictComp'] },
          { UnitWeekDay: '7-2-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'RC', 'VT'/* 'GWO', */] },

          { UnitWeekDay: '8-1-1', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '8-1-2', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '8-1-3', activities: ['CURSIVE', 'SC', 'VS', 'INC', 'DictComp'] },
          { UnitWeekDay: '8-1-4', activities: ['CURSIVE', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '8-1-5', activities: ['CURSIVE', 'SC', 'VS', 'SA', /* 'GWO', */ 'DictComp'] },

          { UnitWeekDay: '8-2-1', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', 'RC', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '8-2-2', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'RC', 'VT', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '8-2-3', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'RC', 'VT', 'DictDry'] },
          { UnitWeekDay: '8-2-4', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'RC', 'VT', 'DictComp'] },
          { UnitWeekDay: '8-2-5', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'RC', 'VT', 'SA', 'DictComp'] },

          { UnitWeekDay: '8-3-1', activities: ['CURSIVE', 'SUFF', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '8-3-2', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'RC', 'VT', 'INC', 'DictDry'] },
          { UnitWeekDay: '8-3-3', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'DictComp'] },
          { UnitWeekDay: '8-3-4', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '8-3-5', activities: ['CURSIVE', 'SUFF', 'LC', 'SC', 'VS'] },

          { UnitWeekDay: '9-1-1', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '9-1-2', activities: ['CURSIVE', 'SC', 'VS', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '9-1-3', activities: ['CURSIVE', 'SC', 'VS', 'INC', 'DictDry'] },
          { UnitWeekDay: '9-1-4', activities: ['CURSIVE', 'SC', 'VS', 'DictComp'] },
          { UnitWeekDay: '9-1-5', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '9-2-1', activities: ['CURSIVE', 'SC', 'VS', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '9-2-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '9-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA'] },
          { UnitWeekDay: '9-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'DictComp'] },
          { UnitWeekDay: '9-2-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '9-3-1', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '9-3-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '9-3-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'INC', 'DictComp'] },
          { UnitWeekDay: '9-3-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'DictComp'] },
          { UnitWeekDay: '9-3-5', activities: ['CURSIVE', 'LC', 'SC', 'VS'] },

          { UnitWeekDay: '10-1-1', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '10-1-2', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '10-1-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'INC', 'DictDry'] },
          { UnitWeekDay: '10-1-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'DictComp'] },
          { UnitWeekDay: '10-1-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'DictComp'] },

          { UnitWeekDay: '10-2-1', activities: ['CURSIVE', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '10-2-2', activities: ['CURSIVE', 'LC', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '10-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictDry'] },
          { UnitWeekDay: '10-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'DictComp'] },
          { UnitWeekDay: '10-2-5', activities: ['CURSIVE', 'LC', 'VT', 'DictComp'] },

          { UnitWeekDay: '10-3-1', activities: ['CURSIVE', 'SC', 'VT', 'INC'] },
          { UnitWeekDay: '10-3-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', /* 'GWO', */ 'INC', 'DictDry'] },
          { UnitWeekDay: '10-3-3', activities: ['CURSIVE', 'LC', 'VT', 'SA', 'INC', 'DictComp'] },
          { UnitWeekDay: '10-3-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'DictDry'] },
          { UnitWeekDay: '10-3-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '11-1-1', activities: ['CURSIVE', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '11-1-2', activities: ['CURSIVE', 'SC', 'VS'] },
          { UnitWeekDay: '11-1-3', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictDry'] },
          { UnitWeekDay: '11-1-4', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '11-1-5', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '11-2-1', activities: ['CURSIVE', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '11-2-2', activities: ['CURSIVE', 'SC', 'VS', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '11-2-3', activities: ['CURSIVE', 'VT', 'SA', 'INC', 'DictDry'] },
          { UnitWeekDay: '11-2-4', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '11-2-5', activities: ['CURSIVE', 'VT', 'SA'] },

          { UnitWeekDay: '12-1-1', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '12-1-2', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '12-1-3', activities: ['CURSIVE', 'SC', 'VS', 'VT', 'SA', 'DictDry'] },
          { UnitWeekDay: '12-1-4', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '12-1-5', activities: ['CURSIVE', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '12-2-1', activities: ['CURSIVE', 'SC', 'VS', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '12-2-2', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '12-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'INC'] },
          { UnitWeekDay: '12-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'DictComp'] },
          { UnitWeekDay: '12-2-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA'] },

          { UnitWeekDay: '13-1-1', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '13-1-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'SA', 'INC'] },
          { UnitWeekDay: '13-1-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'INC', 'DictDry'] },
          { UnitWeekDay: '13-1-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '13-1-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '13-2-1', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '13-2-2', activities: ['CURSIVE', 'SL', 'LC', 'SC', 'VS', 'SA', 'INC'] },
          { UnitWeekDay: '13-2-3', activities: ['CURSIVE', 'SL', 'LC', 'SC', 'VS', 'VT', 'DictDry'] },
          { UnitWeekDay: '13-2-4', activities: ['CURSIVE', 'SL', 'LC', 'SC', 'VS', 'VT', 'SA', 'DictComp'] },
          { UnitWeekDay: '13-2-5', activities: ['CURSIVE', 'SL', 'LC', 'SC', 'VS', 'VT', 'SA'] },

          { UnitWeekDay: '14-1-1', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '14-1-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'INC', 'DictDry'] },
          { UnitWeekDay: '14-1-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'SA', 'DictComp'] },
          { UnitWeekDay: '14-1-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'SA', 'DictComp'] },
          { UnitWeekDay: '14-1-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },

          { UnitWeekDay: '14-2-1', activities: ['CURSIVE', 'SC', 'VS', /* 'GWO', */ 'INC'] },
          { UnitWeekDay: '14-2-2', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'INC', 'DictDry'] },
          { UnitWeekDay: '14-2-3', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '14-2-4', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'SA', 'DictComp'] },
          { UnitWeekDay: '14-2-5', activities: ['CURSIVE', 'LC', 'SC', 'VS', 'VT', 'SA'] },
        ],
      },
    ],
  },
};
