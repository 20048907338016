<template>
  <div id="positioner">
    <ActivityHeader
      v-if="programId === 'fun2' || programId === 'fun3'"
      activity-type="Select Activity"
      back-button-title="Days"
      :show-nav-buttons="true"
      :back-button-route="backButtonRoute"
    />

    <div id="wiz-shortcuts">
      <div v-if="programId === 'fun3'" class="button wiz-shortcut" id="cursive-shortcut" @click="selectActivity('cursive')">
        <img alt="" src="/images/poster-tiles/letter-formation.svg" />
        <span>Cursive Letter Formation</span>
      </div>

      <div
        @click="selectActivity('referenceposters')"
        class="button wiz-shortcut"
        id="reference-posters-shortcut"
        aria-label="reference posters"
      >
        <img alt="" src="/images/poster-tiles/posters.svg" />
        <span>Reference Posters</span>
      </div>
    </div>

    <template v-if="programId === 'fun2' || programId === 'fun3'">
      <div class="wiz-mod-heading">
        <h1 class="wiz-heading">Select an Activity</h1>
      </div>
      <ul class="activities-container wiz-buttons">
        <li><a class="activity wiz-button" @click="selectActivity('drillsounds')">Drill Sounds/Warm-Up</a></li>
        <li v-if="isAvailable('INC')"><a class="activity wiz-button" @click="selectActivity('buildwords')">Introduce New Concepts</a></li>
        <!-- intentionally left commented for future use -->
        <!-- <li v-if="programId === 'fun3'"><a class="activity wiz-button" @click="selectActivity('cursive')">Cursive Writing</a></li> -->
        <li v-if="isAvailable('DictDry') || isAvailable('DictComp')">
          <a class="activity wiz-button" @click="selectActivity('dictation')">Dictation</a>
        </li>
        <li v-if="programId === 'fun2' && isAvailable('ST')">
          <a class="activity wiz-button" @click="selectActivity('storytime')">Storytime</a>
        </li>
      </ul>
    </template>

    <template v-else>
      <span class="get-started">Pick an activity to get started.</span>
      <div v-if="programId === 'funk' || programId === 'fun1'" class="activity-tiles">
        <router-link
          :to="{ name: 'largesoundcards', params: { programId: this.programId, weekId: '0', unitId: '0', dayId: '0' } }"
          class="activity-tile drillsounds-activity-tile"
          id="largesoundcards"
        >
          <img alt="" src="/images/poster-tiles/large-sound-cards.svg" />
          <span
            >Large
            <br />
            <span>Sound Cards</span>
          </span>
        </router-link>

        <router-link
          :to="{ name: 'standardsoundcards', params: { programId: this.programId, weekId: '0', unitId: '0', dayId: '0' } }"
          class="activity-tile drillsounds-activity-tile"
          id="standardsoundcards"
        >
          <img alt="" src="/images/poster-tiles/standard-sound-cards.svg" />
          <span
            >Standard
            <br />
            <span>Sound Cards</span>
          </span>
        </router-link>
        <router-link
          :to="{ name: 'buildwords', params: { programId: this.programId, weekId: '0', unitId: '0', dayId: '0' } }"
          class="activity-tile drillsounds-activity-tile"
          id="buildwords"
        >
          <img alt="" src="/images/poster-tiles/build-words.svg" />
          <span
            >Make Words
            <br />
            <span>For Decoding</span>
          </span>
        </router-link>
        <router-link
          :to="{ name: 'letterformationgrids', params: { programId: this.programId } }"
          class="activity-tile dictation-activity-tile"
          id="letterformationgrids"
        >
          <img alt="" src="/images/poster-tiles/letter-formation-grids.svg" />
          <span
            >Letter Formation
            <br />
            <span>Grids</span>
          </span>
        </router-link>

        <router-link
          :to="{ name: 'sentenceframes', params: { programId: this.programId } }"
          class="activity-tile drillsounds-activity-tile"
          id="sentenceframes"
        >
          <img alt="" src="/images/poster-tiles/sentence-frames.svg" />
          <span
            >Sentence &
            <br />
            <span>Syllable Frames</span>
          </span>
        </router-link>
        <router-link
          :to="{ name: 'trickwords', params: { programId: this.programId, weekId: '0', unitId: '0', dayId: '0' } }"
          class="activity-tile fun2"
          id="trickwords"
        >
          <img alt="" src="/images/poster-tiles/trick-words.svg" />
          <span
            >Trick Words
            <br />
            <span>Flashcards</span>
          </span>
        </router-link>

        <router-link
          v-if="programId === 'fun1'"
          :to="{ name: 'storytime', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
          class="activity-tile drillsounds-activity-tile"
          id="storytime"
        >
          <img alt="" src="/images/poster-tiles/storytime.svg" />
          <span
            >Storytime
            <br />
            <span>&#8203;</span>
          </span>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';
import ActivityHeader from '@/components/ActivityHeader.vue';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'ChooseActivity',
  components: { ActivityHeader },
  data() {
    const available = [];
    const backButtonRoute = '';
    return { available, backButtonRoute };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  computed: {
    ...mapGetters('activityControl', ['availableActivities']),
  },
  methods: {
    ...mapActions('appState', ['setLastLesson']),
    isAvailable(activity) {
      if (this.available.length === 0) {
        return true;
      }
      return this.available.includes(activity);
    },
    selectActivity(activity) {
      router.push({ name: activity, params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } });
    },
  },
  mounted() {
    if (this.programId === 'fun2' || this.programId === 'fun3') {
      const lastLesson = { program: this.programId, unit: this.unitId, week: this.weekId, day: this.dayId };
      this.setLastLesson(lastLesson);
      this.available = this.availableActivities(this.programId, `${this.unitId}-${this.weekId}-${this.dayId}`);
      this.backButtonRoute = 'chooseday';
    } else {
      this.backButtonRoute = 'chooseprogram';
    }
  },
};
</script>

<style scoped lang="scss">
#wiz-shortcuts {
  width: 100%;
  max-width: unset;
}
@media (max-height: 830px) {
  .activity-tiles {
    max-width: 600px;
  }
}
@media (max-height: 680px) {
  #wiz-shortcuts {
    margin-bottom: 0;
  }
}
</style>
