// import buildwordshelper from '@/helpers/buildwordshelper.js';

export default {
  namespaced: true,

  mutations: {

    SET_DISPLAY_POSITION: (state, { cards }) => {
      cards.forEach((c) => {
        const card = c;
        card.displayPosition = { row: c.row, col: c.col };
      });
    },
    SET_OVERRIDE_POSITION: (state, { cards, overrides }) => {
      overrides.forEach((o) => {
        const card = cards.find((c) => c.cardId === o.cardId);
        if (card) {
          card.displayPosition = { row: o.row, col: o.col };
        }
      });
    },
    SET_NEW_POSITION: (state, { card, row, col }) => {
      card.displayPosition = { row, col };
    },
  },
  actions: {
    setDisplayPosition: (context, payload) => {
      context.commit('SET_DISPLAY_POSITION', payload);
    },
    setOverridePosition: (context, payload) => {
      context.commit('SET_OVERRIDE_POSITION', payload);
    },
    setNewPosition: (context, payload) => {
      context.commit('SET_NEW_POSITION', payload);
    },
  },

  state: {
    programCardData: [
      {
        program: 'fun2',
        cardData: {
          cards: [
            { soundFile: 'a.mp3', cardId: 1, label: 'a', color: 'red-card', vowel: 1, col: 1, row: 1 },
            { soundFile: 'a.mp3', cardId: 2, label: 'b', color: 'yellow-card', vowel: 0, col: 2, row: 1 },
            { soundFile: 'a.mp3', cardId: 3, label: 'c', color: 'yellow-card', vowel: 0, col: 3, row: 1 },
            { soundFile: 'a.mp3', cardId: 4, label: 'd', color: 'yellow-card', vowel: 0, col: 4, row: 1 },
            { soundFile: 'a.mp3', cardId: 5, label: 'e', color: 'red-card', vowel: 1, col: 5, row: 1 },
            { soundFile: 'a.mp3', cardId: 6, label: 'f', color: 'yellow-card', vowel: 0, col: 6, row: 1 },
            { soundFile: 'a.mp3', cardId: 7, label: 'g', color: 'yellow-card', vowel: 0, col: 1, row: 2 },
            { soundFile: 'a.mp3', cardId: 8, label: 'h', color: 'yellow-card', vowel: 0, col: 2, row: 2 },
            { soundFile: 'a.mp3', cardId: 9, label: 'i', color: 'red-card', vowel: 1, col: 3, row: 2 },
            { soundFile: 'a.mp3', cardId: 10, label: 'j', color: 'yellow-card', vowel: 0, col: 4, row: 2 },
            { soundFile: 'a.mp3', cardId: 11, label: 'k', color: 'yellow-card', vowel: 0, col: 5, row: 2 },
            { soundFile: 'a.mp3', cardId: 12, label: 'l', color: 'yellow-card', vowel: 0, col: 6, row: 2 },
            { soundFile: 'a.mp3', cardId: 13, label: 'm', color: 'yellow-card', vowel: 0, col: 1, row: 3 },
            { soundFile: 'a.mp3', cardId: 14, label: 'n', color: 'yellow-card', vowel: 0, col: 2, row: 3 },
            { soundFile: 'a.mp3', cardId: 15, label: 'o', color: 'red-card', vowel: 1, col: 3, row: 3 },
            { soundFile: 'a.mp3', cardId: 16, label: 'p', color: 'yellow-card', vowel: 0, col: 4, row: 3 },
            { soundFile: 'a.mp3', cardId: 17, label: 'qu', color: 'yellow-card', vowel: 0, col: 5, row: 3 },
            { soundFile: 'a.mp3', cardId: 18, label: 'r', color: 'yellow-card', vowel: 0, col: 6, row: 3 },
            { soundFile: 'a.mp3', cardId: 19, label: 's', color: 'yellow-card', vowel: 0, col: 7, row: 3 },
            { soundFile: 'a.mp3', cardId: 20, label: 't', color: 'yellow-card', vowel: 0, col: 1, row: 4 },
            { soundFile: 'a.mp3', cardId: 21, label: 'u', color: 'red-card', vowel: 1, col: 2, row: 4 },
            { soundFile: 'a.mp3', cardId: 22, label: 'v', color: 'yellow-card', vowel: 0, col: 3, row: 4 },
            { soundFile: 'a.mp3', cardId: 23, label: 'w', color: 'yellow-card', vowel: 0, col: 4, row: 4 },
            { soundFile: 'a.mp3', cardId: 24, label: 'x', color: 'yellow-card', vowel: 0, col: 5, row: 4 },
            { soundFile: 'a.mp3', cardId: 25, label: 'y', color: 'yellow-card', vowel: 0, col: 6, row: 4 },
            { soundFile: 'a.mp3', cardId: 26, label: 'z', color: 'yellow-card', vowel: 0, col: 7, row: 4 },
            { soundFile: 'a.mp3', cardId: 27, label: 'wh', color: 'yellow-card', vowel: 0, col: 1, row: 5 },
            { soundFile: 'a.mp3', cardId: 29, label: 'ch', color: 'yellow-card', vowel: 0, col: 2, row: 5 },
            { soundFile: 'a.mp3', cardId: 30, label: 'sh', color: 'yellow-card', vowel: 0, col: 3, row: 5 },
            { soundFile: 'a.mp3', cardId: 31, label: 'th', color: 'yellow-card', vowel: 0, col: 4, row: 5 },
            { soundFile: 'a.mp3', cardId: 32, label: 'ck', color: 'yellow-card', vowel: 0, col: 5, row: 5 },
          ],
          cardXref: [
            {
              days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              cards: [901, 902],
              newCards: [17, 24, 25, 27, 29, 30, 31, 32],
              positionOverride: [
                { cardId: 901, row: 6, col: 1 },
                { cardId: 902, row: 6, col: 2 },

              ],
            },

            {
              days: [13],
              alternatePosters: true,
              cards: [36, 39, 40, 41, 42, 43, 44, 45, 46, 901, 902, 903],

              positionOverride: [
                { cardId: 901, row: 7, col: 1 },
                { cardId: 902, row: 7, col: 2 },
                { cardId: 903, row: 7, col: 3 },

              ],
            },
            {
              days: [11, 12, 14, 15],
              cards: [36, 39, 40, 41, 42, 43, 44, 45, 46, 901, 902, 903],
              newCards: [36, 39, 40, 41, 42, 43, 44, 45, 46],
              positionOverride: [
                { cardId: 901, row: 7, col: 1 },
                { cardId: 902, row: 7, col: 2 },
                { cardId: 903, row: 7, col: 3 },
              ],
            },
            {
              days: [19],
              alternatePosters: true,
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 901, 902, 903],
              newCards: [37, 38],
              positionOverride: [
                { cardId: 901, row: 7, col: 1 },
                { cardId: 902, row: 7, col: 2 },
                { cardId: 903, row: 7, col: 3 },
              ],
            },
            {
              days: [16],
              cards: [36, 39, 40, 41, 42, 43, 44, 45, 46, 901, 902, 903],
              newCards: [39, 40, 41, 42, 43, 44, 45, 46],
              positionOverride: [
                { cardId: 901, row: 7, col: 1 },
                { cardId: 902, row: 7, col: 2 },
                { cardId: 903, row: 7, col: 3 },
              ],
            },
            {
              days: [17, 18, 20],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 901, 902, 903],
              newCards: [37, 38, 39, 40, 41, 42, 43, 44, 45, 46],
              positionOverride: [
                { cardId: 901, row: 7, col: 1 },
                { cardId: 902, row: 7, col: 2 },
                { cardId: 903, row: 7, col: 3 },
              ],
            },

            // unit 3
            {
              days: [22, 23, 24],
              alternatePosters: true,
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 901, 902, 903],
              newCards: [47, 48, 49, 50, 51],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [21, 25],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 901, 902, 903],
              newCards: [47, 48, 49, 50, 51],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // unit 4 (days 26-35)
            {
              days: [26, 27, 28, 29],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 901, 902, 903,
                906, 907, 904, 905],
              newCards: [52, 53, 54, 55, 56, 57],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [30],
              alternatePosters: true,
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 55, 56, 57, 901, 902, 903,
                906, 907, 904, 905],
              newCards: [52, 53, 54, 55, 56, 57, 83, 84, 85], // ing ed, er, est,

              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [31, 32],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 901, 902, 903,
                906, 907, 904, 905],
              newCards: [52, 53, 54, 55, 56, 57, 83, 84, 85],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [33, 34, 35],
              alternatePosters: true,
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 901, 902, 903,
                906, 907, 904, 905],
              newCards: [52, 53, 54, 55, 56, 57, 83, 84, 85],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // U5/W1
            {
              days: [36, 37, 38, 39, 40],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 901, 902, 903,
                906, 907, 904, 905],
              newCards: [90, 91, 58, 59, 60, 61, 62, 63, 64],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // U5 / W2
            {
              days: [41, 42, 43, 44, 45],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
                58, 59, 60, 61, 62, 63, 64, 901, 902, 903],
              newCards: [58, 59, 60, 61, 62, 63, 64, 90, 91],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // Unit 6/W1
            {
              days: [46, 47, 48, 49, 50],
              cards: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
                62, 63, 64, 901, 902, 903],
              newCards: [],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // 6/W2
            {
              days: [51, 52, 53, 54, 55],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                901, 902, 903,
              ],
              newCards: [65],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            // 7/W1
            {
              days: [56],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
                69, 70, 901, 902, 903,
              ],
              newCards: [67, 68, 69, 70],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [57],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
                901, 902, 903,
              ],
              newCards: [],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [58, 59, 60],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 901, 902, 903,
              ],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [61, 62, 63, 64, 65],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 901, 902, 903,
              ],
              newCards: [],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [66, 67, 68, 69, 70],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 901, 902, 903,
              ],
              newCards: [],
              positionOverride: [
                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },
            {
              days: [71, 72, 73, 74, 75],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 901, 902, 903,
              ],
              newCards: [71, 72],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [76, 77, 78, 79, 80, 81, 82, 83, 84, 85],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 901, 902, 903,
              ],
              newCards: [73, 74, 75],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [86, 87, 88, 89, 90, 91, 92, 93, 94, 95],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 901, 902, 903,
              ],
              newCards: [76, 77],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 901, 902, 903,
              ],
              newCards: [78, 79, 80],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [106, 107, 108, 109, 110],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 901, 902, 903,
              ],
              newCards: [81, 82],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [111, 112, 113, 114, 115, 116, 117, 118, 119, 120],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 901, 902, 903,
              ],
              newCards: [83, 84, 85],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [121, 122, 123, 124, 125, 126, 127, 128, 129, 130],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 901, 902, 903, 904, 905,
              ],
              newCards: [85, 86],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [131, 132, 133, 134, 135, 136, 137, 138, 139, 140],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 901, 902, 903, 904, 905,
              ],
              newCards: [87, 88, 89],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [141, 142, 143, 144, 145],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 901, 902,
                903, 904, 905,
              ],
              newCards: [90, 91],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
            {
              days: [146, 147, 148, 149, 150, 151, 152, 153, 154, 155],
              cards: [36,
                37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 901, 902,
                903, 904, 905,
              ],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },
          ],
          restOfTheCards: [
            { soundFile: 'a.mp3', cardId: 33, label: 'f', color: 'yellow-card', vowel: 0, col: 1, row: 6 },
            { soundFile: 'a.mp3', cardId: 34, label: 'l', color: 'yellow-card', vowel: 0, col: 2, row: 6 },
            { soundFile: 'a.mp3', cardId: 35, label: 's', color: 'yellow-card', vowel: 0, col: 3, row: 6 },
            { soundFile: 'a.mp3', cardId: 36, label: 'all', color: 'green-card', vowel: 0, col: 1, row: 6 },

            { soundFile: 'a.mp3', cardId: 37, label: 'am', color: 'green-card', vowel: 0, col: 2, row: 6 },
            { soundFile: 'a.mp3', cardId: 38, label: 'an', color: 'green-card', vowel: 0, col: 3, row: 6 },
            { soundFile: 'a.mp3', cardId: 39, label: 'ang', color: 'green-card', vowel: 0, col: 8, row: 1 },
            { soundFile: 'a.mp3', cardId: 40, label: 'ank', color: 'green-card', vowel: 0, col: 9, row: 1 },
            { soundFile: 'a.mp3', cardId: 41, label: 'ing', color: 'green-card', vowel: 0, col: 8, row: 2 },
            { soundFile: 'a.mp3', cardId: 42, label: 'ink', color: 'green-card', vowel: 0, col: 9, row: 2 },
            { soundFile: 'a.mp3', cardId: 43, label: 'ong', color: 'green-card', vowel: 0, col: 8, row: 3 },
            { soundFile: 'a.mp3', cardId: 44, label: 'onk', color: 'green-card', vowel: 0, col: 9, row: 3 },
            { soundFile: 'a.mp3', cardId: 45, label: 'ung', color: 'green-card', vowel: 0, col: 8, row: 4 },
            { soundFile: 'a.mp3', cardId: 46, label: 'unk', color: 'green-card', vowel: 0, col: 9, row: 4 },

            { soundFile: 'a.mp3', cardId: 47, label: 'ind', color: 'green-card', vowel: 0, col: 1, row: 7 },
            { soundFile: 'a.mp3', cardId: 48, label: 'ild', color: 'green-card', vowel: 0, col: 2, row: 7 },
            { soundFile: 'a.mp3', cardId: 49, label: 'old', color: 'green-card', vowel: 0, col: 3, row: 7 },
            { soundFile: 'a.mp3', cardId: 50, label: 'olt', color: 'green-card', vowel: 0, col: 4, row: 7 },
            { soundFile: 'a.mp3', cardId: 51, label: 'ost', color: 'green-card', vowel: 0, col: 5, row: 7 },

            { suffix: true, soundFile: 'a.mp3', cardId: 52, label: 'es', color: 'suffix-yellow', vowel: 0, col: 11, row: 1 },
            { suffix: true, soundFile: 'a.mp3', cardId: 53, label: 'ing', color: 'suffix-yellow', vowel: 0, col: 11, row: 2 },
            { suffix: true, soundFile: 'a.mp3', cardId: 54, label: 'ed', color: 'suffix-yellow', vowel: 0, col: 11, row: 3 },
            { suffix: true, soundFile: 'a.mp3', cardId: 55, label: 'er', color: 'suffix-yellow', vowel: 0, col: 11, row: 4 },
            { suffix: true, soundFile: 'a.mp3', cardId: 56, label: 'est', color: 'suffix-yellow', vowel: 0, col: 11, row: 5 },
            { suffix: true, soundFile: 'a.mp3', cardId: 57, label: 's', color: 'suffix-yellow', vowel: 0, col: 13, row: 1 },

            { suffix: true, soundFile: 'a.mp3', cardId: 58, label: 'ful', color: 'suffix-yellow', vowel: 0, col: 13, row: 2 },
            { suffix: true, soundFile: 'a.mp3', cardId: 59, label: 'less', color: 'suffix-yellow', vowel: 0, col: 13, row: 3 },
            { suffix: true, soundFile: 'a.mp3', cardId: 60, label: 'ment', color: 'suffix-yellow', vowel: 0, col: 13, row: 5 },
            { suffix: true, soundFile: 'a.mp3', cardId: 61, label: 'ness', color: 'suffix-yellow', vowel: 0, col: 13, row: 4 },
            { suffix: true, soundFile: 'a.mp3', cardId: 62, label: 'ish', color: 'suffix-yellow', vowel: 0, col: 11, row: 6 },
            { suffix: true, soundFile: 'a.mp3', cardId: 63, label: 'en', color: 'suffix-yellow', vowel: 0, col: 11, row: 7 },
            { suffix: true, soundFile: 'a.mp3', cardId: 64, label: 'able', color: 'suffix-yellow', vowel: 0, col: 11, row: 8 },

            { soundFile: 'a.mp3', cardId: 65, label: 'ive', color: 'green-card', vowel: 0, col: 6, row: 7 },
            { suffix: true, soundFile: 'a.mp3', cardId: 66, label: 'ive', color: 'suffix-yellow', vowel: 0, col: 11, row: 9 },

            { soundFile: 'a.mp3', cardId: 67, label: 'y', color: 'red-card', vowel: 1, col: 6, row: 5 },

            { suffix: true, soundFile: 'a.mp3', cardId: 68, label: 'y', color: 'suffix-yellow', vowel: 0, col: 11, row: 10 },
            { suffix: true, soundFile: 'a.mp3', cardId: 69, label: 'ly', color: 'suffix-yellow', vowel: 0, col: 13, row: 6 },
            { suffix: true, soundFile: 'a.mp3', cardId: 70, label: 'ty', color: 'suffix-yellow', vowel: 0, col: 13, row: 7 },

            { soundFile: 'a.mp3', cardId: 71, label: 'ar', color: 'red-card', vowel: 0, col: 1, row: 8 },
            { soundFile: 'a.mp3', cardId: 72, label: 'or', color: 'red-card', vowel: 0, col: 2, row: 8 },

            { soundFile: 'a.mp3', cardId: 73, label: 'er', color: 'red-card', vowel: 0, col: 3, row: 8 },
            { soundFile: 'a.mp3', cardId: 74, label: 'ir', color: 'red-card', vowel: 0, col: 4, row: 8 },
            { soundFile: 'a.mp3', cardId: 75, label: 'ur', color: 'red-card', vowel: 0, col: 5, row: 8 },

            { soundFile: 'a.mp3', cardId: 76, label: 'ai', color: 'red-card', vowel: 0, col: 8, row: 5 },
            { soundFile: 'a.mp3', cardId: 77, label: 'ay', color: 'red-card', vowel: 0, col: 9, row: 5 },

            { soundFile: 'a.mp3', cardId: 78, label: 'ee', color: 'red-card', vowel: 0, col: 7, row: 6 },
            { soundFile: 'a.mp3', cardId: 79, label: 'ea', color: 'red-card', vowel: 0, col: 8, row: 6 },
            { soundFile: 'a.mp3', cardId: 80, label: 'ey', color: 'red-card', vowel: 0, col: 9, row: 6 },

            { soundFile: 'a.mp3', cardId: 81, label: 'oi', color: 'red-card', vowel: 0, col: 8, row: 7 },
            { soundFile: 'a.mp3', cardId: 82, label: 'oy', color: 'red-card', vowel: 0, col: 9, row: 7 },

            { soundFile: 'a.mp3', cardId: 83, label: 'oa', color: 'red-card', vowel: 0, col: 7, row: 8 },
            { soundFile: 'a.mp3', cardId: 84, label: 'oe', color: 'red-card', vowel: 0, col: 8, row: 8 },
            { soundFile: 'a.mp3', cardId: 85, label: 'ow', color: 'red-card', vowel: 0, col: 9, row: 8 },

            { soundFile: 'a.mp3', cardId: 86, label: 'ou', color: 'red-card', vowel: 0, col: 9, row: 9 },

            { soundFile: 'a.mp3', cardId: 87, label: 'oo', color: 'red-card', vowel: 0, col: 6, row: 9 },
            { soundFile: 'a.mp3', cardId: 88, label: 'ue', color: 'red-card', vowel: 0, col: 7, row: 9 },
            { soundFile: 'a.mp3', cardId: 89, label: 'ew', color: 'red-card', vowel: 0, col: 8, row: 9 },

            { soundFile: 'a.mp3', cardId: 90, label: 'au', color: 'red-card', vowel: 0, col: 6, row: 10 },
            { soundFile: 'a.mp3', cardId: 91, label: 'aw', color: 'red-card', vowel: 0, col: 7, row: 10 },
            { soundFile: 'a.mp3', cardId: 92, label: '&#601;', color: 'red-card', vowel: 0, col: 7, row: 1 },

            { soundFile: 'a.mp3', cardId: 901, label: '', color: 'yellow-card', vowel: 0, col: 1, row: 9 },
            { soundFile: 'a.mp3', cardId: 902, label: '', color: 'red-card', vowel: 0, col: 2, row: 9 },
            { soundFile: 'a.mp3', cardId: 903, label: '', color: 'green-card', vowel: 0, col: 3, row: 9 },
            // { soundFile: 'a.mp3', cardId: 904, label: '', color: 'red-card', vowel: 0, col: 4, row: 9 },
            // { soundFile: 'a.mp3', cardId: 905, label: '', color: 'green-card', vowel: 0, col: 5, row: 9 },
            // { soundFile: 'a.mp3', cardId: 906, label: '', color: 'yellow-card', vowel: 0, col: 3, row: 9 },
            // { soundFile: 'a.mp3', cardId: 907, label: '', color: 'yellow-card', vowel: 0, col: 3, row: 9 },

          ],
        },
      },
      {
        program: 'fun3',
        cardData: {
          cards: [
            { soundFile: 'a.mp3', cardId: 1, label: 'a', color: 'red-card', vowel: 1, col: 1, row: 1 },
            { soundFile: 'a.mp3', cardId: 2, label: 'b', color: 'yellow-card', vowel: 0, col: 2, row: 1 },
            { soundFile: 'a.mp3', cardId: 3, label: 'c', color: 'yellow-card', vowel: 0, col: 3, row: 1 },
            { soundFile: 'a.mp3', cardId: 4, label: 'd', color: 'yellow-card', vowel: 0, col: 4, row: 1 },
            { soundFile: 'a.mp3', cardId: 5, label: 'e', color: 'red-card', vowel: 1, col: 5, row: 1 },
            { soundFile: 'a.mp3', cardId: 6, label: 'f', color: 'yellow-card', vowel: 0, col: 6, row: 1 },
            { soundFile: 'a.mp3', cardId: 7, label: 'g', color: 'yellow-card', vowel: 0, col: 1, row: 2 },
            { soundFile: 'a.mp3', cardId: 8, label: 'h', color: 'yellow-card', vowel: 0, col: 2, row: 2 },
            { soundFile: 'a.mp3', cardId: 9, label: 'i', color: 'red-card', vowel: 1, col: 3, row: 2 },
            { soundFile: 'a.mp3', cardId: 10, label: 'j', color: 'yellow-card', vowel: 0, col: 4, row: 2 },
            { soundFile: 'a.mp3', cardId: 11, label: 'k', color: 'yellow-card', vowel: 0, col: 5, row: 2 },
            { soundFile: 'a.mp3', cardId: 12, label: 'l', color: 'yellow-card', vowel: 0, col: 6, row: 2 },
            { soundFile: 'a.mp3', cardId: 13, label: 'm', color: 'yellow-card', vowel: 0, col: 1, row: 3 },
            { soundFile: 'a.mp3', cardId: 14, label: 'n', color: 'yellow-card', vowel: 0, col: 2, row: 3 },
            { soundFile: 'a.mp3', cardId: 15, label: 'o', color: 'red-card', vowel: 1, col: 3, row: 3 },
            { soundFile: 'a.mp3', cardId: 16, label: 'p', color: 'yellow-card', vowel: 0, col: 4, row: 3 },
            { soundFile: 'a.mp3', cardId: 17, label: 'qu', color: 'yellow-card', vowel: 0, col: 5, row: 3 },
            { soundFile: 'a.mp3', cardId: 18, label: 'r', color: 'yellow-card', vowel: 0, col: 6, row: 3 },
            { soundFile: 'a.mp3', cardId: 19, label: 's', color: 'yellow-card', vowel: 0, col: 7, row: 3 },
            { soundFile: 'a.mp3', cardId: 20, label: 't', color: 'yellow-card', vowel: 0, col: 1, row: 4 },
            { soundFile: 'a.mp3', cardId: 21, label: 'u', color: 'red-card', vowel: 1, col: 2, row: 4 },
            { soundFile: 'a.mp3', cardId: 22, label: 'v', color: 'yellow-card', vowel: 0, col: 3, row: 4 },
            { soundFile: 'a.mp3', cardId: 23, label: 'w', color: 'yellow-card', vowel: 0, col: 4, row: 4 },
            { soundFile: 'a.mp3', cardId: 24, label: 'x', color: 'yellow-card', vowel: 0, col: 5, row: 4 },
            { soundFile: 'a.mp3', cardId: 25, label: 'y', color: 'yellow-card', vowel: 0, col: 6, row: 4 },
            { soundFile: 'a.mp3', cardId: 26, label: 'z', color: 'yellow-card', vowel: 0, col: 7, row: 4 },
            { soundFile: 'a.mp3', cardId: 27, label: 'wh', color: 'yellow-card', vowel: 0, col: 1, row: 5 },
            { soundFile: 'a.mp3', cardId: 29, label: 'ch', color: 'yellow-card', vowel: 0, col: 2, row: 5 },
            { soundFile: 'a.mp3', cardId: 30, label: 'sh', color: 'yellow-card', vowel: 0, col: 3, row: 5 },
            { soundFile: 'a.mp3', cardId: 31, label: 'th', color: 'yellow-card', vowel: 0, col: 4, row: 5 },
            { soundFile: 'a.mp3', cardId: 32, label: 'ck', color: 'yellow-card', vowel: 0, col: 5, row: 5 },
            // { soundFile: 'a.mp3', cardId: 33, label: 'f', color: 'yellow-card', vowel: 0, col: 1, row: 6 },
            // { soundFile: 'a.mp3', cardId: 34, label: 'l', color: 'yellow-card', vowel: 0, col: 2, row: 6 },
            // { soundFile: 'a.mp3', cardId: 35, label: 's', color: 'yellow-card', vowel: 0, col: 3, row: 6 },
            { soundFile: 'a.mp3', cardId: 36, label: 'all', color: 'green-card', vowel: 0, col: 1, row: 6 },
            { soundFile: 'a.mp3', cardId: 37, label: 'am', color: 'green-card', vowel: 0, col: 2, row: 6 },
            { soundFile: 'a.mp3', cardId: 38, label: 'an', color: 'green-card', vowel: 0, col: 3, row: 6 },
            { soundFile: 'a.mp3', cardId: 39, label: 'ang', color: 'green-card', vowel: 0, col: 8, row: 1 },
            { soundFile: 'a.mp3', cardId: 40, label: 'ank', color: 'green-card', vowel: 0, col: 9, row: 1 },
            { soundFile: 'a.mp3', cardId: 41, label: 'ing', color: 'green-card', vowel: 0, col: 8, row: 2 },
            { soundFile: 'a.mp3', cardId: 42, label: 'ink', color: 'green-card', vowel: 0, col: 9, row: 2 },
            { soundFile: 'a.mp3', cardId: 43, label: 'ong', color: 'green-card', vowel: 0, col: 8, row: 3 },
            { soundFile: 'a.mp3', cardId: 44, label: 'onk', color: 'green-card', vowel: 0, col: 9, row: 3 },
            { soundFile: 'a.mp3', cardId: 45, label: 'ung', color: 'green-card', vowel: 0, col: 8, row: 4 },
            { soundFile: 'a.mp3', cardId: 46, label: 'unk', color: 'green-card', vowel: 0, col: 9, row: 4 },
            { soundFile: 'a.mp3', cardId: 47, label: 'ind', color: 'green-card', vowel: 0, col: 1, row: 7 },
            { soundFile: 'a.mp3', cardId: 48, label: 'ild', color: 'green-card', vowel: 0, col: 2, row: 7 },
            { soundFile: 'a.mp3', cardId: 49, label: 'old', color: 'green-card', vowel: 0, col: 3, row: 7 },
            { soundFile: 'a.mp3', cardId: 50, label: 'olt', color: 'green-card', vowel: 0, col: 4, row: 7 },
            { soundFile: 'a.mp3', cardId: 51, label: 'ost', color: 'green-card', vowel: 0, col: 5, row: 7 },

            { soundFile: 'a.mp3', cardId: 901, label: '', color: 'yellow-card', vowel: 0, col: 1, row: 8 },
            { soundFile: 'a.mp3', cardId: 902, label: '', color: 'red-card', vowel: 0, col: 2, row: 8 },
            { soundFile: 'a.mp3', cardId: 903, label: '', color: 'green-card', vowel: 0, col: 3, row: 8 },
            // { soundFile: 'a.mp3', cardId: 922, label: '', color: 'yellow-card', vowel: 0, col: 4, row: 8 },
            // { soundFile: 'a.mp3', cardId: 923, label: '', color: 'yellow-card', vowel: 0, col: 5, row: 8 },
            // { soundFile: 'a.mp3', cardId: 924, label: '', color: 'red-card', vowel: 0, col: 6, row: 8 },
            // { soundFile: 'a.mp3', cardId: 925, label: '', color: 'green-card', vowel: 0, col: 7, row: 8 },
          ],
          cardXref: [

            // The 'card base' is in effect on days 1-6 (U1/2/1)

            // U 1/1/2 (introduce tch)
            {
              days: [2, 3, 4, 5, 6, 7, 8, 9, 10],
              excluseBase: false,
              cards: [
                311,
              ],
              newCards: [311],
            },
            // U 2/1/1
            {
              days: [11],
              excludeBase: false,
              cards: [
                311,
                // suffixes:
                52, 57,
              ],
              newCards: [311, 52, 57],
            },

            // U 2/1/2
            {
              days: [12],
              excludeBase: false,
              cards: [
                311,
                // suffixes:
                52, 53, 54, 57,
              ],
              newCards: [311, 52, 53, 54, 57],
            },

            // U 2/1/3
            {
              days: [13],
              excludeBase: false,
              cards: [
                311,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59,
              ],
              newCards: [311, 52, 53, 54, 55, 56, 57, 58, 59],
            },

            // U 2/1/4 through the end of the unit
            {
              days: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
              excludeBase: false,
              cards: [
                311,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [311, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70],
            },

            // unit 3
            {
              days: [26, 27, 28, 29, 30],
              excludeBase: false,
              cards: [
                311,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
            },

            // U4/1/1
            {
              days: [31, 32, 36, 37, 38, 39, 40],
              excludeBase: false,
              cards: [
                311, 65,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [65],
            },

            // U5/1/1 (introduce schwa)
            {
              days: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
              excludeBase: false,
              cards: [
                311, 65, 93,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [93],
            },

            // U6/1/1
            {
              days: [51],
              excludeBase: false,
              cards: [
                311, 65, 93,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
            },

            // U6/1/2 through U6(introduce vowel-y)
            {
              days: [52, 53, 54, 55, 56, 57, 58, 59,
                60, 61, 62, 63, 64, 65],
              excludeBase: false,
              cards: [
                311, 65, 93, 67,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [67],
            },

            // Bonus Unit through 8/1/5
            {
              days: [66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90],
              excludeBase: false,
              cards: [
                311, 65, 93, 67,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
            },

            // U8/2/1
            {
              days: [91, 92, 93, 94, 95],
              excludeBase: false,
              cards: [
                311, 65, 93, 94, 67,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [94],
            },

            // U8/3/1 (add tion, sion)
            {
              days: [96, 97, 98, 99, 100],
              excludeBase: false,
              cards: [
                311, 65, 93, 94, 67, 95, 96,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [94, 95, 96],
            },

            // U9/1/1
            {
              days: [101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96, 71, 72, 73, 74, 75,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [71, 72, 73, 74, 75],
              positionOverride: [
                { cardId: 901, row: 9, col: 1 },
                { cardId: 902, row: 9, col: 2 },
                { cardId: 903, row: 9, col: 3 },
              ],
            },

            // U10/1/1
            {
              days: [116],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96,
                71, 72, 73, 74, 75,
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91],
              positionOverride: [
                { cardId: 71, row: 1, col: 11 },
                { cardId: 73, row: 1, col: 12 },
                { cardId: 74, row: 1, col: 13 },
                { cardId: 72, row: 1, col: 14 },
                { cardId: 75, row: 1, col: 15 },
                { cardId: 52, row: 1, col: 17 },
                { cardId: 57, row: 1, col: 19 },

                { cardId: 76, row: 2, col: 11 },
                { cardId: 77, row: 2, col: 12 },
                { cardId: 323, row: 2, col: 13 },
                { cardId: 324, row: 2, col: 14 },
                { cardId: 53, row: 2, col: 17 },
                { cardId: 58, row: 2, col: 19 },

                { cardId: 78, row: 3, col: 11 },
                { cardId: 80, row: 3, col: 12 },
                { cardId: 327, row: 3, col: 13 },
                { cardId: 79, row: 3, col: 14 },
                { cardId: 54, row: 3, col: 17 },
                { cardId: 59, row: 3, col: 19 },

                { cardId: 329, row: 4, col: 11 },
                { cardId: 83, row: 4, col: 12 },
                { cardId: 84, row: 4, col: 13 },
                { cardId: 85, row: 4, col: 14 },
                { cardId: 55, row: 4, col: 17 },
                { cardId: 61, row: 4, col: 19 },

                { cardId: 86, row: 5, col: 11 },
                { cardId: 334, row: 5, col: 12 },
                { cardId: 88, row: 5, col: 13 },
                { cardId: 89, row: 5, col: 14 },
                { cardId: 87, row: 5, col: 15 },
                { cardId: 56, row: 5, col: 17 },
                { cardId: 60, row: 5, col: 19 },

                { cardId: 81, row: 6, col: 11 },
                { cardId: 82, row: 6, col: 12 },
                { cardId: 90, row: 6, col: 13 },
                { cardId: 91, row: 6, col: 14 },
                { cardId: 62, row: 6, col: 17 },
                { cardId: 69, row: 6, col: 19 },

                { cardId: 94, row: 7, col: 7 },
                { cardId: 95, row: 7, col: 8 },
                { cardId: 96, row: 7, col: 9 },
                { cardId: 63, row: 7, col: 17 },
                { cardId: 70, row: 7, col: 19 },

                { cardId: 64, row: 8, col: 17 },
                { cardId: 66, row: 9, col: 17 },
                { cardId: 68, row: 10, col: 17 },

                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },

              ],
            },
            // U10/1/2 (introduce eigh, ei, ea)
            {
              days: [117, 118, 119, 120],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96,
                71, 72, 73, 74, 75,
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324,
              ],
              positionOverride: [
                { cardId: 71, row: 1, col: 11 },
                { cardId: 73, row: 1, col: 12 },
                { cardId: 74, row: 1, col: 13 },
                { cardId: 72, row: 1, col: 14 },
                { cardId: 75, row: 1, col: 15 },
                { cardId: 52, row: 1, col: 17 },
                { cardId: 57, row: 1, col: 19 },

                { cardId: 76, row: 2, col: 11 },
                { cardId: 77, row: 2, col: 12 },
                { cardId: 323, row: 2, col: 13 },
                { cardId: 324, row: 2, col: 14 },
                { cardId: 53, row: 2, col: 17 },
                { cardId: 58, row: 2, col: 19 },

                { cardId: 78, row: 3, col: 11 },
                { cardId: 80, row: 3, col: 12 },
                { cardId: 327, row: 3, col: 13 },
                { cardId: 79, row: 3, col: 14 },
                { cardId: 54, row: 3, col: 17 },
                { cardId: 59, row: 3, col: 19 },

                { cardId: 329, row: 4, col: 11 },
                { cardId: 83, row: 4, col: 12 },
                { cardId: 84, row: 4, col: 13 },
                { cardId: 85, row: 4, col: 14 },
                { cardId: 55, row: 4, col: 17 },
                { cardId: 61, row: 4, col: 19 },

                { cardId: 86, row: 5, col: 11 },
                { cardId: 334, row: 5, col: 12 },
                { cardId: 88, row: 5, col: 13 },
                { cardId: 89, row: 5, col: 14 },
                { cardId: 87, row: 5, col: 15 },
                { cardId: 56, row: 5, col: 17 },
                { cardId: 60, row: 5, col: 19 },

                { cardId: 81, row: 6, col: 11 },
                { cardId: 82, row: 6, col: 12 },
                { cardId: 90, row: 6, col: 13 },
                { cardId: 91, row: 6, col: 14 },
                { cardId: 62, row: 6, col: 17 },
                { cardId: 69, row: 6, col: 19 },

                { cardId: 94, row: 7, col: 7 },
                { cardId: 95, row: 7, col: 8 },
                { cardId: 96, row: 7, col: 9 },
                { cardId: 63, row: 7, col: 17 },
                { cardId: 70, row: 7, col: 19 },

                { cardId: 64, row: 8, col: 17 },
                { cardId: 66, row: 9, col: 17 },
                { cardId: 68, row: 10, col: 17 },

                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },

              ],
            },

            // U10/2/1 -10/2/5
            {
              days: [121, 122, 123, 124, 125],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96,
                71, 72, 73, 74, 75,
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324, 327,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324, 327,
              ],
              positionOverride: [
                { cardId: 71, row: 1, col: 11 },
                { cardId: 73, row: 1, col: 12 },
                { cardId: 74, row: 1, col: 13 },
                { cardId: 72, row: 1, col: 14 },
                { cardId: 75, row: 1, col: 15 },
                { cardId: 52, row: 1, col: 17 },
                { cardId: 57, row: 1, col: 19 },

                { cardId: 76, row: 2, col: 11 },
                { cardId: 77, row: 2, col: 12 },
                { cardId: 323, row: 2, col: 13 },
                { cardId: 324, row: 2, col: 14 },
                { cardId: 53, row: 2, col: 17 },
                { cardId: 58, row: 2, col: 19 },

                { cardId: 78, row: 3, col: 11 },
                { cardId: 80, row: 3, col: 12 },
                { cardId: 327, row: 3, col: 13 },
                { cardId: 79, row: 3, col: 14 },
                { cardId: 54, row: 3, col: 17 },
                { cardId: 59, row: 3, col: 19 },

                { cardId: 329, row: 4, col: 11 },
                { cardId: 83, row: 4, col: 12 },
                { cardId: 84, row: 4, col: 13 },
                { cardId: 85, row: 4, col: 14 },
                { cardId: 55, row: 4, col: 17 },
                { cardId: 61, row: 4, col: 19 },

                { cardId: 86, row: 5, col: 11 },
                { cardId: 334, row: 5, col: 12 },
                { cardId: 88, row: 5, col: 13 },
                { cardId: 89, row: 5, col: 14 },
                { cardId: 87, row: 5, col: 15 },
                { cardId: 56, row: 5, col: 17 },
                { cardId: 60, row: 5, col: 19 },

                { cardId: 81, row: 6, col: 11 },
                { cardId: 82, row: 6, col: 12 },
                { cardId: 90, row: 6, col: 13 },
                { cardId: 91, row: 6, col: 14 },
                { cardId: 62, row: 6, col: 17 },
                { cardId: 69, row: 6, col: 19 },

                { cardId: 94, row: 7, col: 7 },
                { cardId: 95, row: 7, col: 8 },
                { cardId: 96, row: 7, col: 9 },
                { cardId: 63, row: 7, col: 17 },
                { cardId: 70, row: 7, col: 19 },

                { cardId: 64, row: 8, col: 17 },
                { cardId: 66, row: 9, col: 17 },
                { cardId: 68, row: 10, col: 17 },

                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },

              ],
            },
            // U10/3/1
            {
              days: [
                126, 127, 128, 129, 130,
              ],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96,
                71, 72, 73, 74, 75,
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324, 327, 329, 334,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [334],
              positionOverride: [
                { cardId: 71, row: 1, col: 11 },
                { cardId: 73, row: 1, col: 12 },
                { cardId: 74, row: 1, col: 13 },
                { cardId: 72, row: 1, col: 14 },
                { cardId: 75, row: 1, col: 15 },
                { cardId: 52, row: 1, col: 17 },
                { cardId: 57, row: 1, col: 19 },

                { cardId: 76, row: 2, col: 11 },
                { cardId: 77, row: 2, col: 12 },
                { cardId: 323, row: 2, col: 13 },
                { cardId: 324, row: 2, col: 14 },
                { cardId: 53, row: 2, col: 17 },
                { cardId: 58, row: 2, col: 19 },

                { cardId: 78, row: 3, col: 11 },
                { cardId: 80, row: 3, col: 12 },
                { cardId: 327, row: 3, col: 13 },
                { cardId: 79, row: 3, col: 14 },
                { cardId: 54, row: 3, col: 17 },
                { cardId: 59, row: 3, col: 19 },

                { cardId: 329, row: 4, col: 11 },
                { cardId: 83, row: 4, col: 12 },
                { cardId: 84, row: 4, col: 13 },
                { cardId: 85, row: 4, col: 14 },
                { cardId: 55, row: 4, col: 17 },
                { cardId: 61, row: 4, col: 19 },

                { cardId: 86, row: 5, col: 11 },
                { cardId: 334, row: 5, col: 12 },
                { cardId: 88, row: 5, col: 13 },
                { cardId: 89, row: 5, col: 14 },
                { cardId: 87, row: 5, col: 15 },
                { cardId: 56, row: 5, col: 17 },
                { cardId: 60, row: 5, col: 19 },

                { cardId: 81, row: 6, col: 11 },
                { cardId: 82, row: 6, col: 12 },
                { cardId: 90, row: 6, col: 13 },
                { cardId: 91, row: 6, col: 14 },
                { cardId: 62, row: 6, col: 17 },
                { cardId: 69, row: 6, col: 19 },

                { cardId: 94, row: 7, col: 7 },
                { cardId: 95, row: 7, col: 8 },
                { cardId: 96, row: 7, col: 9 },
                { cardId: 63, row: 7, col: 17 },
                { cardId: 70, row: 7, col: 19 },

                { cardId: 64, row: 8, col: 17 },
                { cardId: 66, row: 9, col: 17 },
                { cardId: 68, row: 10, col: 17 },

                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // U 11 & 12/2/1

            {
              days: [
                131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
                141, 142, 142, 143, 144, 145, 146,
              ],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96,
                71, 72, 73, 74, 75,
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324, 327, 329, 334,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              positionOverride: [
                { cardId: 71, row: 1, col: 11 },
                { cardId: 73, row: 1, col: 12 },
                { cardId: 74, row: 1, col: 13 },
                { cardId: 72, row: 1, col: 14 },
                { cardId: 75, row: 1, col: 15 },
                { cardId: 52, row: 1, col: 17 },
                { cardId: 57, row: 1, col: 19 },

                { cardId: 76, row: 2, col: 11 },
                { cardId: 77, row: 2, col: 12 },
                { cardId: 323, row: 2, col: 13 },
                { cardId: 324, row: 2, col: 14 },
                { cardId: 53, row: 2, col: 17 },
                { cardId: 58, row: 2, col: 19 },

                { cardId: 78, row: 3, col: 11 },
                { cardId: 80, row: 3, col: 12 },
                { cardId: 327, row: 3, col: 13 },
                { cardId: 79, row: 3, col: 14 },
                { cardId: 54, row: 3, col: 17 },
                { cardId: 59, row: 3, col: 19 },

                { cardId: 329, row: 4, col: 11 },
                { cardId: 83, row: 4, col: 12 },
                { cardId: 84, row: 4, col: 13 },
                { cardId: 85, row: 4, col: 14 },
                { cardId: 55, row: 4, col: 17 },
                { cardId: 61, row: 4, col: 19 },

                { cardId: 86, row: 5, col: 11 },
                { cardId: 334, row: 5, col: 12 },
                { cardId: 88, row: 5, col: 13 },
                { cardId: 89, row: 5, col: 14 },
                { cardId: 87, row: 5, col: 15 },
                { cardId: 56, row: 5, col: 17 },
                { cardId: 60, row: 5, col: 19 },

                { cardId: 81, row: 6, col: 11 },
                { cardId: 82, row: 6, col: 12 },
                { cardId: 90, row: 6, col: 13 },
                { cardId: 91, row: 6, col: 14 },
                { cardId: 62, row: 6, col: 17 },
                { cardId: 69, row: 6, col: 19 },

                { cardId: 94, row: 7, col: 7 },
                { cardId: 95, row: 7, col: 8 },
                { cardId: 96, row: 7, col: 9 },
                { cardId: 63, row: 7, col: 17 },
                { cardId: 70, row: 7, col: 19 },

                { cardId: 64, row: 8, col: 17 },
                { cardId: 66, row: 9, col: 17 },
                { cardId: 68, row: 10, col: 17 },

                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },
              ],
            },

            // U12/2/2 - 12/2/5

            {
              days: [147, 148, 149, 150],
              excludeBase: false,
              cards: [
                311, 65, 93, 67, 94, 95, 96,
                71, 72, 73, 74, 75,
                76, 77, 78, 79, 80, 83, 84, 85,
                86, 87, 88, 89, 81, 82, 90, 91,
                323, 324, 327, 329, 334, 347,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [347],
              positionOverride: [
                { cardId: 71, row: 1, col: 11 },
                { cardId: 73, row: 1, col: 12 },
                { cardId: 74, row: 1, col: 13 },
                { cardId: 72, row: 1, col: 14 },
                { cardId: 75, row: 1, col: 15 },
                { cardId: 52, row: 1, col: 17 },
                { cardId: 57, row: 1, col: 19 },

                { cardId: 76, row: 2, col: 11 },
                { cardId: 77, row: 2, col: 12 },
                { cardId: 323, row: 2, col: 13 },
                { cardId: 324, row: 2, col: 14 },
                { cardId: 53, row: 2, col: 17 },
                { cardId: 58, row: 2, col: 19 },

                { cardId: 78, row: 3, col: 11 },
                { cardId: 80, row: 3, col: 12 },
                { cardId: 327, row: 3, col: 13 },
                { cardId: 79, row: 3, col: 14 },
                { cardId: 54, row: 3, col: 17 },
                { cardId: 59, row: 3, col: 19 },

                { cardId: 329, row: 4, col: 11 },
                { cardId: 83, row: 4, col: 12 },
                { cardId: 84, row: 4, col: 13 },
                { cardId: 85, row: 4, col: 14 },
                { cardId: 55, row: 4, col: 17 },
                { cardId: 61, row: 4, col: 19 },

                { cardId: 67, row: 5, col: 8 },
                { cardId: 86, row: 5, col: 11 },
                { cardId: 334, row: 5, col: 12 },
                { cardId: 88, row: 5, col: 13 },
                { cardId: 89, row: 5, col: 14 },
                { cardId: 87, row: 5, col: 15 },
                { cardId: 56, row: 5, col: 17 },
                { cardId: 60, row: 5, col: 19 },

                { cardId: 81, row: 6, col: 11 },
                { cardId: 82, row: 6, col: 12 },
                { cardId: 90, row: 6, col: 13 },
                { cardId: 91, row: 6, col: 14 },
                { cardId: 62, row: 6, col: 17 },
                { cardId: 69, row: 6, col: 19 },

                { cardId: 94, row: 7, col: 7 },
                { cardId: 95, row: 7, col: 8 },
                { cardId: 96, row: 7, col: 9 },
                { cardId: 63, row: 7, col: 17 },
                { cardId: 70, row: 7, col: 19 },

                { cardId: 901, row: 8, col: 1 },
                { cardId: 902, row: 8, col: 2 },
                { cardId: 903, row: 8, col: 3 },

                { cardId: 64, row: 8, col: 17 },
                { cardId: 66, row: 9, col: 17 },
                { cardId: 68, row: 10, col: 17 },

              ],
            },

            // U 13/1/1 & 2
            {
              days: [151, 152],
              excludeBase: false,
              cards: [
                311, 93, 67, 93,
                347,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [29],
              excludeFromBase: [
                36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 919, 920, 921, 922, 923, 924, 925,
              ],
              positionOverride: [
                { cardId: 32, row: 5, col: 6 },
                { cardId: 67, row: 4, col: 8 },
                { cardId: 347, row: 5, col: 8 },
                { cardId: 311, row: 5, col: 7 },
                { cardId: 901, row: 6, col: 1 },
                { cardId: 902, row: 6, col: 2 },
                { cardId: 903, row: 6, col: 3 },
              ],
            },

            // U 13/1/3
            {
              days: [153, 154, 155],
              excludeBase: false,
              cards: [
                311, 93, 67, 93,
                347, 356,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [29, 356],
              excludeFromBase: [
                36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 919, 920, 921, 922, 923, 924, 925,
              ],
              positionOverride: [
                { cardId: 32, row: 5, col: 6 },
                { cardId: 67, row: 4, col: 8 },
                { cardId: 347, row: 5, col: 8 },
                { cardId: 311, row: 5, col: 7 },
                { cardId: 901, row: 6, col: 1 },
                { cardId: 902, row: 6, col: 2 },
                { cardId: 903, row: 6, col: 3 },
              ],
            },

            // U 13/2/1
            {
              days: [156, 157, 158, 159, 160],
              excludeBase: false,
              cards: [
                311, 93, 67, 93,
                347, 349, 350, 351, 352, 353, 354, 355, 356,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [29, 349, 350, 351, 352, 353, 354, 355, 356],
              excludeFromBase: [
                36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 919, 920, 921, 922, 923, 924, 925,
              ],
              positionOverride: [
                { cardId: 32, row: 5, col: 6 },
                { cardId: 67, row: 4, col: 8 },
                { cardId: 347, row: 5, col: 8 },
                { cardId: 349, row: 7, col: 2 },
                { cardId: 350, row: 7, col: 3 },
                { cardId: 351, row: 7, col: 4 },
                { cardId: 352, row: 7, col: 1 },
                { cardId: 353, row: 7, col: 5 },
                { cardId: 354, row: 7, col: 6 },
                { cardId: 355, row: 7, col: 7 },
                { cardId: 311, row: 5, col: 7 },
                { cardId: 901, row: 6, col: 1 },
                { cardId: 902, row: 6, col: 2 },
                { cardId: 903, row: 6, col: 3 },
              ],
            },

            // U14/1/1 (ture, tu)
            {
              days: [161, 162, 163, 164, 165, 166],
              excludeBase: false,
              cards: [
                311, 93, 67, 93, 95, 96,
                347, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [357, 358],
              excludeFromBase: [
                36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 919, 920, 921, 922, 923, 924, 925,
              ],
              positionOverride: [
                { cardId: 32, row: 5, col: 6 },
                { cardId: 67, row: 4, col: 8 },
                { cardId: 347, row: 5, col: 8 },
                { cardId: 349, row: 7, col: 2 },
                { cardId: 350, row: 7, col: 3 },
                { cardId: 351, row: 7, col: 4 },
                { cardId: 352, row: 7, col: 1 },
                { cardId: 353, row: 7, col: 5 },
                { cardId: 354, row: 7, col: 6 },
                { cardId: 355, row: 7, col: 7 },
                { cardId: 311, row: 5, col: 7 },
                { cardId: 95, row: 8, col: 1 },
                { cardId: 96, row: 8, col: 2 },
                { cardId: 901, row: 6, col: 1 },
                { cardId: 902, row: 6, col: 2 },
                { cardId: 903, row: 6, col: 3 },
              ],
            },

            // U14/2/2 (ci, ti)
            {
              days: [167, 168, 169, 170, 171],
              excludeBase: false,
              cards: [
                311, 93, 67, 93, 95, 96,
                347, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360,
                // suffixes:
                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 68, 69, 70,
              ],
              newCards: [357, 358, 359, 360],
              excludeFromBase: [
                36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 919, 920, 921, 922, 923, 924, 925,
              ],
              positionOverride: [
                { cardId: 32, row: 5, col: 6 },
                { cardId: 67, row: 4, col: 8 },
                { cardId: 347, row: 5, col: 8 },
                { cardId: 349, row: 7, col: 2 },
                { cardId: 350, row: 7, col: 3 },
                { cardId: 351, row: 7, col: 4 },
                { cardId: 352, row: 7, col: 1 },
                { cardId: 353, row: 7, col: 5 },
                { cardId: 354, row: 7, col: 6 },
                { cardId: 355, row: 7, col: 7 },
                { cardId: 311, row: 5, col: 7 },
                { cardId: 95, row: 8, col: 1 },
                { cardId: 96, row: 8, col: 2 },
                { cardId: 901, row: 6, col: 1 },
                { cardId: 902, row: 6, col: 2 },
                { cardId: 903, row: 6, col: 3 },
              ],
            },
          ],
          restOfTheCards: [
            { suffix: true, soundFile: 'a.mp3', cardId: 52, label: 'es', color: 'suffix-yellow', vowel: 0, col: 11, row: 1 },
            { suffix: true, soundFile: 'a.mp3', cardId: 53, label: 'ing', color: 'suffix-yellow', vowel: 0, col: 11, row: 2 },
            { suffix: true, soundFile: 'a.mp3', cardId: 54, label: 'ed', color: 'suffix-yellow', vowel: 0, col: 11, row: 3 },
            { suffix: true, soundFile: 'a.mp3', cardId: 55, label: 'er', color: 'suffix-yellow', vowel: 0, col: 11, row: 4 },
            { suffix: true, soundFile: 'a.mp3', cardId: 56, label: 'est', color: 'suffix-yellow', vowel: 0, col: 11, row: 5 },
            { suffix: true, soundFile: 'a.mp3', cardId: 57, label: 's', color: 'suffix-yellow', vowel: 0, col: 13, row: 1 },

            { suffix: true, soundFile: 'a.mp3', cardId: 58, label: 'ful', color: 'suffix-yellow', vowel: 0, col: 13, row: 2 },
            { suffix: true, soundFile: 'a.mp3', cardId: 59, label: 'less', color: 'suffix-yellow', vowel: 0, col: 13, row: 3 },
            { suffix: true, soundFile: 'a.mp3', cardId: 60, label: 'ment', color: 'suffix-yellow', vowel: 0, col: 13, row: 5 },
            { suffix: true, soundFile: 'a.mp3', cardId: 61, label: 'ness', color: 'suffix-yellow', vowel: 0, col: 13, row: 4 },
            { suffix: true, soundFile: 'a.mp3', cardId: 62, label: 'ish', color: 'suffix-yellow', vowel: 0, col: 11, row: 6 },
            { suffix: true, soundFile: 'a.mp3', cardId: 63, label: 'en', color: 'suffix-yellow', vowel: 0, col: 11, row: 7 },
            { suffix: true, soundFile: 'a.mp3', cardId: 64, label: 'able', color: 'suffix-yellow', vowel: 0, col: 11, row: 8 },

            { soundFile: 'a.mp3', cardId: 65, label: 'ive', color: 'green-card', vowel: 0, col: 6, row: 7 },
            { suffix: true, soundFile: 'a.mp3', cardId: 66, label: 'ive', color: 'suffix-yellow', vowel: 0, col: 11, row: 9 },

            { soundFile: 'a.mp3', cardId: 67, label: 'y', color: 'red-card', vowel: 1, col: 7, row: 5 },

            { suffix: true, soundFile: 'a.mp3', cardId: 68, label: 'y', color: 'suffix-yellow', vowel: 0, col: 11, row: 10 },
            { suffix: true, soundFile: 'a.mp3', cardId: 69, label: 'ly', color: 'suffix-yellow', vowel: 0, col: 13, row: 6 },
            { suffix: true, soundFile: 'a.mp3', cardId: 70, label: 'ty', color: 'suffix-yellow', vowel: 0, col: 13, row: 7 },

            { soundFile: 'a.mp3', cardId: 71, label: 'ar', color: 'red-card', vowel: 0, col: 1, row: 8 },
            { soundFile: 'a.mp3', cardId: 72, label: 'or', color: 'red-card', vowel: 0, col: 4, row: 8 },

            { soundFile: 'a.mp3', cardId: 73, label: 'er', color: 'red-card', vowel: 0, col: 2, row: 8 },
            { soundFile: 'a.mp3', cardId: 74, label: 'ir', color: 'red-card', vowel: 0, col: 3, row: 8 },
            { soundFile: 'a.mp3', cardId: 75, label: 'ur', color: 'red-card', vowel: 0, col: 5, row: 8 },
            //
            { soundFile: 'a.mp3', cardId: 76, label: 'ai', color: 'red-card', vowel: 0, col: 8, row: 5 },
            { soundFile: 'a.mp3', cardId: 77, label: 'ay', color: 'red-card', vowel: 0, col: 9, row: 5 },

            { soundFile: 'a.mp3', cardId: 78, label: 'ee', color: 'red-card', vowel: 0, col: 7, row: 6 },
            { soundFile: 'a.mp3', cardId: 79, label: 'ea', color: 'red-card', vowel: 0, col: 8, row: 6 },
            { soundFile: 'a.mp3', cardId: 80, label: 'ey', color: 'red-card', vowel: 0, col: 9, row: 6 },

            { soundFile: 'a.mp3', cardId: 81, label: 'oi', color: 'red-card', vowel: 0, col: 8, row: 7 },
            { soundFile: 'a.mp3', cardId: 82, label: 'oy', color: 'red-card', vowel: 0, col: 9, row: 7 },

            { soundFile: 'a.mp3', cardId: 83, label: 'oa', color: 'red-card', vowel: 0, col: 7, row: 8 },
            { soundFile: 'a.mp3', cardId: 84, label: 'oe', color: 'red-card', vowel: 0, col: 8, row: 8 },
            { soundFile: 'a.mp3', cardId: 85, label: 'ow', color: 'red-card', vowel: 0, col: 9, row: 8 },

            { soundFile: 'a.mp3', cardId: 86, label: 'ou', color: 'red-card', vowel: 0, col: 6, row: 9 },

            { soundFile: 'a.mp3', cardId: 87, label: 'oo', color: 'red-card', vowel: 0, col: 7, row: 9 },
            { soundFile: 'a.mp3', cardId: 88, label: 'ue', color: 'red-card', vowel: 0, col: 8, row: 9 },
            { soundFile: 'a.mp3', cardId: 89, label: 'ew', color: 'red-card', vowel: 0, col: 9, row: 9 },

            { soundFile: 'a.mp3', cardId: 90, label: 'au', color: 'red-card', vowel: 0, col: 6, row: 10 },
            { soundFile: 'a.mp3', cardId: 91, label: 'aw', color: 'red-card', vowel: 0, col: 7, row: 10 },
            { soundFile: 'a.mp3', cardId: 93, label: '&#601;', color: 'red-card', vowel: 0, col: 7, row: 1 },

            { soundFile: 'a.mp3', cardId: 94, label: 'stle', color: 'green-card', vowel: 0, col: 7, row: 7 },
            { soundFile: 'a.mp3', cardId: 95, label: 'tion', color: 'green-card', vowel: 0, col: 8, row: 7 },
            { soundFile: 'a.mp3', cardId: 96, label: 'sion', color: 'green-card', vowel: 0, col: 9, row: 7 },

            { soundFile: 'a.mp3', cardId: 300, label: 'a', color: 'red-card', vowel: 1, col: 1, row: 1 },
            { soundFile: 'a.mp3', cardId: 301, label: 'e', color: 'red-card', vowel: 1, col: 2, row: 1 },
            { soundFile: 'a.mp3', cardId: 302, label: 'i', color: 'red-card', vowel: 1, col: 3, row: 1 },
            { soundFile: 'a.mp3', cardId: 303, label: 'o', color: 'red-card', vowel: 1, col: 4, row: 1 },
            { soundFile: 'a.mp3', cardId: 304, label: 'u', color: 'red-card', vowel: 1, col: 5, row: 1 },
            { soundFile: 'a.mp3', cardId: 305, label: 'y', color: 'red-card', vowel: 1, col: 6, row: 1 },
            // { soundFile: 'a.mp3', cardId: 348, label: '&#601;', color: 'red-card', vowel: 0, col: 7, row: 1 },

            { soundFile: 'a.mp3', cardId: 306, label: 'wh', color: 'yellow-card', vowel: 0, col: 1, row: 2 },
            { soundFile: 'a.mp3', cardId: 307, label: 'ch', color: 'yellow-card', vowel: 0, col: 2, row: 2 },
            { soundFile: 'a.mp3', cardId: 308, label: 'sh', color: 'yellow-card', vowel: 0, col: 3, row: 2 },
            { soundFile: 'a.mp3', cardId: 309, label: 'th', color: 'yellow-card', vowel: 0, col: 4, row: 2 },
            { soundFile: 'a.mp3', cardId: 310, label: 'ck', color: 'yellow-card', vowel: 0, col: 5, row: 2 },
            { soundFile: 'a.mp3', cardId: 311, label: 'tch', color: 'yellow-card', vowel: 0, col: 6, row: 5 },

            { soundFile: 'a.mp3', cardId: 312, label: 'ive', color: 'green-card', vowel: 0, col: 1, row: 3 },
            { soundFile: 'a.mp3', cardId: 313, label: 'stle', color: 'green-card', vowel: 0, col: 2, row: 3 },
            { soundFile: 'a.mp3', cardId: 314, label: 'tion', color: 'green-card', vowel: 0, col: 3, row: 3 },
            { soundFile: 'a.mp3', cardId: 315, label: 'sion', color: 'green-card', vowel: 0, col: 4, row: 3 },

            { soundFile: 'a.mp3', cardId: 316, label: 'ar', color: 'red-card', vowel: 0, col: 1, row: 4 },
            { soundFile: 'a.mp3', cardId: 317, label: 'er', color: 'red-card', vowel: 0, col: 2, row: 4 },
            { soundFile: 'a.mp3', cardId: 318, label: 'ir', color: 'red-card', vowel: 0, col: 3, row: 4 },
            { soundFile: 'a.mp3', cardId: 319, label: 'or', color: 'red-card', vowel: 0, col: 4, row: 4 },
            { soundFile: 'a.mp3', cardId: 320, label: 'ur', color: 'red-card', vowel: 0, col: 5, row: 4 },
            { soundFile: 'a.mp3', cardId: 321, label: 'ai', color: 'red-card', vowel: 0, col: 1, row: 5 },
            { soundFile: 'a.mp3', cardId: 322, label: 'ay', color: 'red-card', vowel: 0, col: 2, row: 5 },
            { soundFile: 'a.mp3', cardId: 323, label: 'eigh', color: 'red-card', vowel: 0, col: 3, row: 5 },
            { soundFile: 'a.mp3', cardId: 324, label: 'ei', color: 'red-card', vowel: 0, col: 4, row: 5 },
            { soundFile: 'a.mp3', cardId: 325, label: 'ee', color: 'red-card', vowel: 0, col: 1, row: 6 },
            { soundFile: 'a.mp3', cardId: 326, label: 'ey', color: 'red-card', vowel: 0, col: 2, row: 6 },
            { soundFile: 'a.mp3', cardId: 327, label: 'ie', color: 'red-card', vowel: 0, col: 3, row: 6 },
            { soundFile: 'a.mp3', cardId: 328, label: 'ea', color: 'red-card', vowel: 0, col: 4, row: 6 },
            { soundFile: 'a.mp3', cardId: 329, label: 'igh', color: 'red-card', vowel: 0, col: 1, row: 7 },
            { soundFile: 'a.mp3', cardId: 330, label: 'oa', color: 'red-card', vowel: 0, col: 2, row: 7 },
            { soundFile: 'a.mp3', cardId: 331, label: 'oe', color: 'red-card', vowel: 0, col: 3, row: 7 },
            { soundFile: 'a.mp3', cardId: 332, label: 'ow', color: 'red-card', vowel: 0, col: 4, row: 7 },
            { soundFile: 'a.mp3', cardId: 333, label: 'ou', color: 'red-card', vowel: 0, col: 1, row: 8 },
            { soundFile: 'a.mp3', cardId: 334, label: 'ui', color: 'red-card', vowel: 0, col: 2, row: 8 },
            { soundFile: 'a.mp3', cardId: 335, label: 'ue', color: 'red-card', vowel: 0, col: 3, row: 8 },
            { soundFile: 'a.mp3', cardId: 336, label: 'ew', color: 'red-card', vowel: 0, col: 4, row: 8 },
            { soundFile: 'a.mp3', cardId: 337, label: 'oo', color: 'red-card', vowel: 0, col: 5, row: 8 },
            { soundFile: 'a.mp3', cardId: 338, label: 'oi', color: 'red-card', vowel: 0, col: 1, row: 9 },
            { soundFile: 'a.mp3', cardId: 339, label: 'oy', color: 'red-card', vowel: 0, col: 2, row: 9 },
            { soundFile: 'a.mp3', cardId: 340, label: 'au', color: 'red-card', vowel: 0, col: 3, row: 9 },
            { soundFile: 'a.mp3', cardId: 341, label: 'aw', color: 'red-card', vowel: 0, col: 4, row: 9 },

            { soundFile: 'a.mp3', cardId: 342, label: 'c', color: 'yellow-card', vowel: 0, col: 1, row: 2 },
            { soundFile: 'a.mp3', cardId: 343, label: 's', color: 'yellow-card', vowel: 0, col: 2, row: 2 },
            { soundFile: 'a.mp3', cardId: 344, label: 'g', color: 'yellow-card', vowel: 0, col: 3, row: 2 },
            { soundFile: 'a.mp3', cardId: 345, label: 'j', color: 'yellow-card', vowel: 0, col: 4, row: 2 },
            { soundFile: 'a.mp3', cardId: 346, label: 'n', color: 'yellow-card', vowel: 0, col: 5, row: 2 },
            { soundFile: 'a.mp3', cardId: 347, label: 'dge', color: 'yellow-card', vowel: 0, col: 7, row: 5 },

            { soundFile: 'a.mp3', cardId: 352, label: 'wr', color: 'yellow-card', vowel: 0, col: 1, row: 7 },
            { soundFile: 'a.mp3', cardId: 349, label: 'rh', color: 'yellow-card', vowel: 0, col: 2, row: 7 },
            { soundFile: 'a.mp3', cardId: 350, label: 'gn', color: 'yellow-card', vowel: 0, col: 3, row: 7 },
            { soundFile: 'a.mp3', cardId: 351, label: 'kn', color: 'yellow-card', vowel: 0, col: 4, row: 7 },
            { soundFile: 'a.mp3', cardId: 353, label: 'mn', color: 'yellow-card', vowel: 0, col: 5, row: 7 },
            { soundFile: 'a.mp3', cardId: 354, label: 'mb', color: 'yellow-card', vowel: 0, col: 6, row: 7 },
            { soundFile: 'a.mp3', cardId: 355, label: 'gh', color: 'yellow-card', vowel: 0, col: 7, row: 7 },
            { soundFile: 'a.mp3', cardId: 356, label: 'ph', color: 'yellow-card', vowel: 0, col: 5, row: 5 },

            { soundFile: 'a.mp3', cardId: 357, label: 'ture', color: 'green-card', vowel: 0, col: 3, row: 8 },
            { soundFile: 'a.mp3', cardId: 358, label: 'tu', color: 'green-card', vowel: 0, col: 4, row: 8 },
            { soundFile: 'a.mp3', cardId: 359, label: 'ti', color: 'yellow-card', vowel: 0, col: 5, row: 8 },
            { soundFile: 'a.mp3', cardId: 360, label: 'ci', color: 'yellow-card', vowel: 0, col: 6, row: 8 },
          ],
        },
      },
    ],
  },
};
