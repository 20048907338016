import mixpanel from 'mixpanel-browser';
import mixpanelMixin from '@/mixins/mixpanel';

export default {
  namespaced: true,
  mixins: [mixpanelMixin],
  state: {
    displayedSpellingOptions: localStorage.getItem('displayedSpellingOptions') || false,
    currentProgram: localStorage.getItem('currentProgram') || 'fun',
    lastLesson: [
      {
        program: 'fun2',
        unit: localStorage.getItem('fun2/unit') || '',
        week: localStorage.getItem('fun2/week') || '',
        day: localStorage.getItem('fun2/day') || '',
        activity: localStorage.getItem('fun2/activity') || '',
      },
      {
        program: 'fun3',
        unit: localStorage.getItem('fun3/unit') || '',
        week: localStorage.getItem('fun3/week') || '',
        day: localStorage.getItem('fun3/day') || '',
        activity: localStorage.getItem('fun3/activity') || '',
      },
    ],
    largeSoundCards: {
      selectedCards: [],
    },
    standardSoundCards: [
      { programId: 'funk', cards: localStorage.getItem('funk/ssc') === null ? [] : JSON.parse(localStorage.getItem('funk/ssc')) },
      { programId: 'fun1', cards: localStorage.getItem('fun1/ssc') === null ? [] : JSON.parse(localStorage.getItem('fun1/ssc')) },
      { programId: 'fun2', cards: localStorage.getItem('fun2/ssc') === null ? [] : JSON.parse(localStorage.getItem('fun2/ssc')) },
      { programId: 'fun3', cards: localStorage.getItem('fun3/ssc') === null ? [] : JSON.parse(localStorage.getItem('fun3/ssc')) },
    ],

    suffixCards: {
      selectedCards: [],
    },
    postersPrevPage: '',
  },

  mutations: {
    SET_LASTLESSON: (state, { program, unit, week, day }) => {
      const lastLesson = state.lastLesson.find((f) => f.program === program);
      state.currentProgram = program;
      lastLesson.unit = unit;
      lastLesson.week = week;
      lastLesson.day = day;

      localStorage.setItem('currentProgram', program);
      localStorage.setItem(`${program}/unit`, unit);
      localStorage.setItem(`${program}/week`, week);
      localStorage.setItem(`${program}/day`, day);
    },
    SET_CURRENTPROGRAM: (state, { program }) => {
      localStorage.setItem('currentProgram', program);
      state.currentProgram = program;
    },

    SET_SELECTEDLARGECARDS: (state, { selectedCards }) => {
      state.largeSoundCards.selectedCards = selectedCards;
    },
    ADD_SELECTEDLARGECARD: (state, { cardId }) => {
      if (!state.largeSoundCards.selectedCards.find((c) => c === cardId)) {
        state.largeSoundCards.selectedCards.push(cardId);
      }
    },
    REMOVE_SELECTEDLARGECARD: (state, { cardId }) => {
      const index = state.largeSoundCards.selectedCards.findIndex((c) => c === cardId);
      if (index > -1) {
        state.largeSoundCards.selectedCards.splice(index, 1);
      }
    },
    CLEAR_SELECTEDLARGECARDS: (state) => {
      state.largeSoundCards.selectedCards = [];
    },

    SET_SELECTEDSUFFIXCARDS: (state, { selectedCards }) => {
      state.suffixCards.selectedCards = selectedCards;
    },
    ADD_SELECTEDSUFFIXCARD: (state, { cardId }) => {
      if (!state.suffixCards.selectedCards.find((c) => c === cardId)) {
        state.suffixCards.selectedCards.push(cardId);
      }
    },
    REMOVE_SELECTEDSUFFIXCARD: (state, { cardId }) => {
      const index = state.suffixCards.selectedCards.findIndex((c) => c === cardId);
      if (index > -1) {
        state.suffixCards.selectedCards.splice(index, 1);
      }
    },
    CLEAR_SELECTEDSUFFIXCARDS: (state) => {
      state.suffixCards.selectedCards = [];
    },

    SET_SELECTEDSTANDARDCARDS: (state, { selectedCards }) => {
      state.standardSoundCards.selectedCards = selectedCards;
    },
    ADD_SELECTEDSTANDARDCARD: (state, { cardId }) => {
      const selectedCardsForUnit = state.standardSoundCards.find((p) => p.programId === state.currentProgram);

      if (!selectedCardsForUnit.cards.find((c) => c === cardId)) {
        selectedCardsForUnit.cards.push(cardId);
        localStorage.setItem(`${state.currentProgram}/ssc`, JSON.stringify(selectedCardsForUnit.cards));
      }
    },
    REMOVE_SELECTEDSTANDARDCARD: (state, { cardId }) => {
      const selectedCardsForUnit = state.standardSoundCards.find((p) => p.programId === state.currentProgram);
      const index = selectedCardsForUnit.cards.findIndex((c) => c === cardId);
      if (index > -1) {
        selectedCardsForUnit.cards.splice(index, 1);
        localStorage.setItem(`${state.currentProgram}/ssc`, JSON.stringify(selectedCardsForUnit.cards));
      }
    },
    CLEAR_SELECTEDSTANDARDCARDS: (state) => {
      const selectedCardsForUnit = state.standardSoundCards.find((p) => p.programId === state.currentProgram);
      selectedCardsForUnit.cards = [];
      localStorage.setItem(`${state.currentProgram}/ssc`, JSON.stringify([]));
    },

    UPDATE_SPELLING_OPTIONS_DISPLAY: (state, shownFlag) => {
      state.displayedSpellingOptions = shownFlag;
      localStorage.setItem('displayedSpellingOptions', shownFlag);
    },

    SET_POSTERPREVPAGE: (state, prevPage) => {
      if (prevPage === 'chooseunit' || prevPage === 'chooseactivity' || state.posterPrevPage === '') {
        state.postersPrevPage = prevPage;
      }
    },
  },

  actions: {
    updateSpellingOptionsDisplay: (context, payload) => {
      context.commit('UPDATE_SPELLING_OPTIONS_DISPLAY', payload);
    },

    setLastLesson: (context, payload) => {
      context.commit('SET_LASTLESSON', payload);
    },
    setCurrentProgram: (context, payload) => {
      context.commit('SET_CURRENTPROGRAM', payload);

      // log current program in mixpanel
      mixpanel.register({ 'Triggered From Program': mixpanelMixin.methods.getMixpanelLevel(payload.program) });
    },

    setSelectedLargeCards: (context, payload) => {
      context.commit('SET_SELECTEDLARGECARDS', payload);
    },
    addSelectedLargeCard: (context, payload) => {
      context.commit('ADD_SELECTEDLARGECARD', payload);
    },
    removeSelectedLargeCard: (context, payload) => {
      context.commit('REMOVE_SELECTEDLARGECARD', payload);
    },
    clearSelectedLargeCards: (context) => {
      context.commit('CLEAR_SELECTEDLARGECARDS');
    },

    setSelectedSuffixCards: (context, payload) => {
      context.commit('SET_SELECTEDSUFFIXCARDS', payload);
    },
    addSelectedSuffixCard: (context, payload) => {
      context.commit('ADD_SELECTEDSUFFIXCARD', payload);
    },
    removeSelectedSuffixCard: (context, payload) => {
      context.commit('REMOVE_SELECTEDSUFFIXCARD', payload);
    },
    clearSelectedSuffixCards: (context) => {
      context.commit('CLEAR_SELECTEDSUFFIXCARDS');
    },

    setSelectedStandardCards: (context, payload) => {
      context.commit('SET_SELECTEDSTANDARDCARDS', payload);
    },
    addSelectedStandardCard: (context, payload) => {
      context.commit('ADD_SELECTEDSTANDARDCARD', payload);
    },
    removeSelectedStandardCard: (context, payload) => {
      context.commit('REMOVE_SELECTEDSTANDARDCARD', payload);
    },
    clearSelectedStandardCards: (context) => {
      context.commit('CLEAR_SELECTEDSTANDARDCARDS');
    },

    setPosterPrevPage: (context, payload) => {
      context.commit('SET_POSTERPREVPAGE', payload);
    },
  },

  getters: {
    lastLesson: (state) => {
      if (state.currentProgram !== '') {
        const lastLesson = state.lastLesson.find((f) => f.program === state.currentProgram);
        return lastLesson;
      }
      return '';
    },
    nextLesson: (state, getters, rootState, rootGetters) => {
      if (state.currentProgram !== '') {
        const lastLesson = state.lastLesson.find((f) => f.program === state.currentProgram);
        if (!lastLesson) {
          return null;
        }
        let day = lastLesson.day ? parseInt(lastLesson.day, 10) : 1;
        let { week } = lastLesson;
        let { unit } = lastLesson;
        if (day < 5) {
          day += 1;
        } else {
          day = 1;
          let weekNumber = rootGetters['dataStore/weekNumber'](lastLesson.program, lastLesson.unit, lastLesson.week);
          weekNumber += 1;
          const unitWeek = rootGetters['dataStore/unitWeek'](lastLesson.program, weekNumber);
          if (unitWeek) {
            ({ unit } = unitWeek);
            ({ week } = unitWeek);
          } else {
            return null;
          }
        }
        return { program: lastLesson.program, unit, week, day };
      }
      return null;
    },

    currentProgram: (state) => state.currentProgram,

    selectedLargeCards: (state) => state.largeSoundCards.selectedCards,
    selectedStandardCards: (state) => {
      const cards = state.standardSoundCards.find((p) => p.programId === state.currentProgram);
      return cards.cards;
    },
    selectedSuffixCards: (state) => state.suffixCards.selectedCards,

  },
};
