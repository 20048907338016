export default {
  namespaced: true,
  state: {
    vowelteamsData: [
      {
        program: 'fun1',
        cards: [
          { cardId: 1, label: 'ai', keyword: 'bait', image: '/images/Fun2/illustrations/bait.svg', include: 22 },
          { cardId: 2, label: 'ay', keyword: 'play', image: '/images/Fun2/illustrations/play.svg', include: 22 },
          { cardId: 3, label: 'ee', keyword: 'jeep', image: '/images/Fun2/illustrations/jeep.svg', include: 23 },
          { cardId: 4, label: 'ea', keyword: 'eat', image: '/images/Fun2/illustrations/eat.svg', include: 23 },
          { cardId: 5, label: 'ey', keyword: 'key', image: '/images/Fun2/illustrations/key.svg', include: 23 },
          { cardId: 6, label: 'oi', keyword: 'coin', image: '/images/Fun2/illustrations/coin.svg', include: 24 },
          { cardId: 7, label: 'oy', keyword: 'boy', image: '/images/Fun2/illustrations/boy.svg', include: 24 },
          { cardId: 8, label: 'oa', keyword: 'boat', image: '/images/Fun2/illustrations/boat.svg', include: 31 },
          { cardId: 9, label: 'oe', keyword: 'toe', image: '/images/Fun2/illustrations/toe.svg', include: 31 },
          { cardId: 10, label: 'ow', keyword: 'snow plow', image: '/images/Fun2/illustrations/snowplow.svg', include: 31 },
          { cardId: 11, label: 'ou', keyword: 'trout soup', image: '/images/Fun2/illustrations/troutsoup.svg', include: 33 },
          { cardId: 12, label: 'oo', keyword: 'school book', image: '/images/Fun3/illustrations/school_book.svg', include: 33 },
          { cardId: 13, label: 'ue', keyword: 'blue rescue', image: '/images/Fun2/illustrations/ue_bluerescue.svg', include: 33 },
          { cardId: 14, label: 'ew', keyword: 'chew', image: '/images/Fun2/illustrations/chew.svg', include: 33 },
          { cardId: 15, label: 'au', keyword: 'August', image: '/images/Fun2/illustrations/august.svg', include: 36 },
          { cardId: 16, label: 'aw', keyword: 'saw', image: '/images/Fun2/illustrations/saw.svg', include: 36 },
        ],
      },
      {
        program: 'fun2',
        cards: [
          { cardId: 1, label: 'ai', keyword: 'bait', image: '/images/Fun2/illustrations/bait.svg', include: 22 },
          { cardId: 2, label: 'ay', keyword: 'play', image: '/images/Fun2/illustrations/play.svg', include: 22 },
          { cardId: 3, label: 'ee', keyword: 'jeep', image: '/images/Fun2/illustrations/jeep.svg', include: 23 },
          { cardId: 4, label: 'ea', keyword: 'eat', image: '/images/Fun2/illustrations/eat.svg', include: 23 },
          { cardId: 5, label: 'ey', keyword: 'key', image: '/images/Fun2/illustrations/key.svg', include: 23 },
          { cardId: 6, label: 'oi', keyword: 'coin', image: '/images/Fun2/illustrations/coin.svg', include: 24 },
          { cardId: 7, label: 'oy', keyword: 'boy', image: '/images/Fun2/illustrations/boy.svg', include: 24 },
          { cardId: 8, label: 'oa', keyword: 'boat', image: '/images/Fun2/illustrations/boat.svg', include: 31 },
          { cardId: 9, label: 'oe', keyword: 'toe', image: '/images/Fun2/illustrations/toe.svg', include: 31 },
          { cardId: 10, label: 'ow', keyword: 'snow plow', image: '/images/Fun2/illustrations/snowplow.svg', include: 31 },
          { cardId: 11, label: 'ou', keyword: 'trout soup', image: '/images/Fun2/illustrations/troutsoup.svg', include: 33 },
          { cardId: 12, label: 'oo', keyword: 'school book', image: '/images/Fun3/illustrations/school_book.svg', include: 33 },
          { cardId: 13, label: 'ue', keyword: 'blue rescue', image: '/images/Fun2/illustrations/ue_bluerescue.svg', include: 33 },
          { cardId: 14, label: 'ew', keyword: 'chew', image: '/images/Fun2/illustrations/chew.svg', include: 33 },
          { cardId: 15, label: 'au', keyword: 'August', image: '/images/Fun2/illustrations/august.svg', include: 36 },
          { cardId: 16, label: 'aw', keyword: 'saw', image: '/images/Fun2/illustrations/saw.svg', include: 36 },
        ],
      },
      {
        program: 'fun3',
        cards: [
          { cardId: 1, label: 'ai', keyword: 'bait', image: '/images/Fun2/illustrations/bait.svg', include: 31 },
          { cardId: 2, label: 'ay', keyword: 'play', image: '/images/Fun2/illustrations/play.svg', include: 31 },
          { cardId: 3, label: 'eigh', keyword: 'eight', image: '/images/Fun3/illustrations/eight.svg', include: 119 },
          { cardId: 4, uniqueLabel: 'ei1', label: 'ei', keyword: 'vein', image: '/images/Fun3/illustrations/vein.svg', include: 122 },
          { cardId: 5, uniqueLabel: 'ea1', label: 'ea', keyword: 'steak', image: '/images/Fun3/illustrations/steak.svg', include: 119 },

          { cardId: 6, label: 'ee', keyword: 'jeep', image: '/images/Fun2/illustrations/jeep.svg', include: 31 },
          { cardId: 7, label: 'ey', keyword: 'key', image: '/images/Fun2/illustrations/key.svg', include: 31 },
          { cardId: 8, label: 'ie', keyword: 'piece', image: '/images/Fun3/illustrations/piece.svg', include: 122 },
          { cardId: 9, uniqueLabel: 'ei2', label: 'ei', keyword: 'ceiling', image: '/images/Fun3/illustrations/ceiling.svg', include: 122 },
          { cardId: 10, uniqueLabel: 'ea2', label: 'ea', keyword: 'eat', image: '/images/Fun2/illustrations/eat.svg', include: 31 },
          { cardId: 11, uniqueLabel: 'ea3', label: 'ea', keyword: 'bread', image: '/images/Fun3/illustrations/bread.svg', include: 119 },

          { cardId: 12, label: 'igh', keyword: 'light', image: '/images/Fun3/illustrations/light.svg', include: 31 },
          { cardId: 13, label: 'oa', keyword: 'boat', image: '/images/Fun2/illustrations/boat.svg', include: 31 },
          { cardId: 14, label: 'oe', keyword: 'toe', image: '/images/Fun2/illustrations/toe.svg', include: 31 },
          { cardId: 15, label: 'ow', keyword: 'snow plow', image: '/images/Fun2/illustrations/snowplow.svg', include: 31 },
          { cardId: 16, label: 'ou', keyword: 'trout soup', image: '/images/Fun2/illustrations/troutsoup.svg', include: 31 },
          { cardId: 17, label: 'ui', keyword: 'suit', image: '/images/Fun3/illustrations/suit.svg', include: 128 },
          { cardId: 18, label: 'ue', keyword: 'blue rescue', image: '/images/Fun2/illustrations/ue_bluerescue.svg', include: 31 },
          { cardId: 19, label: 'ew', keyword: 'chew', image: '/images/Fun2/illustrations/chew.svg', include: 31 },
          { cardId: 20, label: 'oo', keyword: 'school book', image: '/images/Fun3/illustrations/school_book.svg', include: 31 },

          { cardId: 21, label: 'oi', keyword: 'coin', image: '/images/Fun2/illustrations/coin.svg', include: 31 },
          { cardId: 22, label: 'oy', keyword: 'boy', image: '/images/Fun2/illustrations/boy.svg', include: 31 },
          { cardId: 23, label: 'au', keyword: 'August', image: '/images/Fun2/illustrations/august.svg', include: 31 },
          { cardId: 24, label: 'aw', keyword: 'saw', image: '/images/Fun2/illustrations/saw.svg', include: 31 },
        ],
      },
    ],
  },
};
