export default {
  namespaced: true,
  state: {
    vowelsoundsData: [
      {
        program: 'fun1',
        cards: [
          {
            cardId: 1,
            label: 'a',
            include: 1,
            lks: [
              {
                keyword: 'apple /&abreve;/',
                image: '/images/Fun1/illustrations/apple.svg',
                alt: 'apple',
              },
              {
                keyword: 'safe /&amacr;/',
                image: '/images/Fun2/illustrations/safe.svg',
                alt: 'safe',
              },
            ],
          },
          {
            cardId: 2,
            label: 'e',
            lks: [
              {
                keyword: 'Ed /&#277;/',
                image: '/images/Fun1/illustrations/ed.svg',
                alt: 'Ed',
              },
              {
                keyword: 'Pete /&emacr;/',
                image: '/images/Fun1/illustrations/pete.svg',
                alt: 'Pete',
              },
            ],
            include: 1,
          },
          {
            cardId: 3,
            label: 'i',
            lks: [
              {
                keyword: 'itch /&#301;/',
                image: '/images/Fun1/illustrations/itch.svg',
                alt: 'itch',
              },
              {
                keyword: 'pine /&imacr;/',
                image: '/images/Fun1/illustrations/pine.svg',
                alt: 'pine',
              },
            ],
            include: 1,
          },
          {
            cardId: 4,
            label: 'o',
            lks: [
              {
                keyword: 'octopus /&#335;/',
                image: '/images/Fun1/illustrations/octopus.svg',
                alt: 'octopus',
              },
              {
                keyword: 'home /&omacr;/',
                image: '/images/Fun1/illustrations/home.svg',
                alt: 'home',
              },
            ],
            include: 1,
          },
          {
            cardId: 5,
            label: 'u',
            lks: [
              {
                keyword: 'up /&ubreve;/',
                image: '/images/Fun1/illustrations/up.svg',
                alt: 'up',
              },
              {
                keyword: 'mule /&umacr;/',
                image: '/images/Fun1/illustrations/mule.svg',
                alt: 'mule',
              },
              {
                keyword: 'rule /&uuml;/',
                image: '/images/Fun1/illustrations/rule.svg',
                alt: 'rule',
              },
            ],
            include: 1,
          },
        ],
      },
      {
        program: 'fun2',
        cards: [
          {
            cardId: 1,
            label: 'a',
            include: 5,
            lks: [
              {
                keyword: 'apple /&abreve;/',
                image: '/images/Fun1/illustrations/apple.svg',
                alt: 'apple',
              },
              {
                keyword: 'safe /&amacr;/',
                image: '/images/Fun2/illustrations/safe.svg',
                alt: 'safe',
              },
              {
                keyword: 'acorn /&amacr;/',
                image: '/images/Fun2/illustrations/acorn.svg',
                alt: 'acorn',
              },
            ],
          },
          {
            cardId: 2,
            label: 'e',
            lks: [
              {
                keyword: 'Ed /&#277;/',
                image: '/images/Fun1/illustrations/ed.svg',
                alt: 'ed',
              },
              {
                keyword: 'Pete /&emacr;/',
                image: '/images/Fun1/illustrations/pete.svg',
                alt: 'pete',
              },
              {
                keyword: 'me /&emacr;/',
                image: '/images/Fun2/illustrations/me.svg',
                alt: 'me',
              },
            ],
            include: 5,
          },
          {
            cardId: 3,
            label: 'i',
            lks: [
              {
                keyword: 'itch /&#301;/',
                image: '/images/Fun1/illustrations/itch.svg',
                alt: 'itch',
              },
              {
                keyword: 'pine /&imacr;/',
                image: '/images/Fun1/illustrations/pine.svg',
                alt: 'pine',
              },
              {
                keyword: 'hi /&imacr;/',
                image: '/images/Fun2/illustrations/hi.svg',
                alt: 'hi',
              },
            ],
            include: 5,
          },
          {
            cardId: 4,
            label: 'o',
            lks: [
              {
                keyword: 'octopus /&#335;/',
                image: '/images/Fun1/illustrations/octopus.svg',
                alt: 'octopus',
              },
              {
                keyword: 'home /&omacr;/',
                image: '/images/Fun1/illustrations/home.svg',
                alt: 'home',
              },
              {
                keyword: 'no /&omacr;/',
                image: '/images/Fun2/illustrations/no.svg',
                alt: 'no',
              },
            ],
            include: 5,
          },
          {
            cardId: 5,
            label: 'u',
            lks: [
              {
                keyword: 'up /&ubreve;/',
                image: '/images/Fun1/illustrations/up.svg',
                alt: 'up',
              },
              {
                keyword: 'mule /&umacr;/',
                image: '/images/Fun1/illustrations/mule.svg',
                alt: 'mule',
              },
              {
                keyword: 'rule /&uuml;/',
                image: '/images/Fun1/illustrations/rule.svg',
                alt: 'rule',
              },
              {
                keyword: 'pupil /&umacr;/',
                image: '/images/Fun2/illustrations/student.svg',
                alt: 'pupil',
              },
              {
                keyword: 'flu /&uuml;/',
                image: '/images/Fun2/illustrations/flu.svg',
                alt: 'flu',
              },
            ],
            include: 5,
          },
          {
            cardId: 6,
            label: 'y',
            lks: [
              {
                keyword: 'cry /&imacr;/<br>baby /&emacr;/',
                image: '/images/Fun2/illustrations/babycry.svg',
                alt: 'cry baby',
              },
            ],
            include: 5,
          },
        ],
      },
      {
        program: 'fun3',
        cards: [
          {
            cardId: 1,
            label: 'a',
            include: 6,
            lks: [
              {
                keyword: 'apple /&abreve;/',
                image: '/images/Fun1/illustrations/apple.svg',
                alt: 'apple',
              },
              {
                keyword: 'safe /&amacr;/',
                image: '/images/Fun2/illustrations/safe.svg',
                alt: 'safe',
              },
              {
                keyword: 'acorn /&amacr;/',
                image: '/images/Fun2/illustrations/acorn.svg',
                alt: 'acorn',
              },
              {
                keyword: 'wash squash /&#559;/',
                image: '/images/Fun3/illustrations/wash_squash.svg',
                alt: 'wash squash',
              },
              {
                keyword: 'Alaska /&ubreve;/',
                image: '/images/Fun3/illustrations/Alaska.svg',
                alt: 'Alaska',
              },
            ],
          },
          {
            cardId: 2,
            label: 'e',
            lks: [
              {
                keyword: 'Ed /&#277;/',
                image: '/images/Fun1/illustrations/ed.svg',
                alt: 'Ed',
              },
              {
                keyword: 'Pete /&emacr;/',
                image: '/images/Fun1/illustrations/pete.svg',
                alt: 'Pete',
              },
              {
                keyword: 'me /&emacr;/',
                image: '/images/Fun2/illustrations/me.svg',
                alt: 'me',
              },
            ],
            include: 6,
          },
          {
            cardId: 3,
            label: 'i',
            lks: [
              {
                keyword: 'itch /&#301;/',
                image: '/images/Fun1/illustrations/itch.svg',
                alt: 'itch',
              },
              {
                keyword: 'pine /&imacr;/',
                image: '/images/Fun1/illustrations/pine.svg',
                alt: 'pine',
              },
              {
                keyword: 'hi /&imacr;/',
                image: '/images/Fun2/illustrations/hi.svg',
                alt: 'hi',
              },
              {
                keyword: 'animal /&ubreve;/ or /&#301;/',
                image: '/images/Fun3/illustrations/animal.svg',
                alt: 'animal',
              },
              {
                keyword: 'champion /&emacr;/',
                image: '/images/Fun3/illustrations/champion.svg',
                alt: 'champion',
              },
            ],
            include: 6,
          },
          {
            cardId: 4,
            label: 'o',
            lks: [
              {
                keyword: 'octopus /&#335;/',
                image: '/images/Fun1/illustrations/octopus.svg',
                alt: 'octopus',
              },
              {
                keyword: 'home /&omacr;/',
                image: '/images/Fun1/illustrations/home.svg',
                alt: 'home',
              },
              {
                keyword: 'no /&omacr;/',
                image: '/images/Fun2/illustrations/no.svg',
                alt: 'no',
              },
            ],
            include: 6,
          },
          {
            cardId: 5,
            label: 'u',
            lks: [
              {
                keyword: 'up /&ubreve;/',
                image: '/images/Fun1/illustrations/up.svg',
                alt: 'up',
              },
              {
                keyword: 'mule /&umacr;/',
                image: '/images/Fun1/illustrations/mule.svg',
                alt: 'mule',
              },
              {
                keyword: 'rule /&uuml;/',
                image: '/images/Fun1/illustrations/rule.svg',
                alt: 'rule',
              },
              {
                keyword: 'pupil /&umacr;/',
                image: '/images/Fun2/illustrations/student.svg',
                alt: 'pupil',
              },
              {
                keyword: 'flu /&uuml;/',
                image: '/images/Fun2/illustrations/flu.svg',
                alt: 'flu',
              },
            ],
            include: 6,
          },
          {
            cardId: 6,
            label: 'y',
            lks: [
              {
                keyword: 'cry /&imacr;/<br>baby /&emacr;/',
                image: '/images/Fun2/illustrations/babycry.svg',
                alt: 'cry',
              },
            ],
            include: 6,
          },
        ],
      },
      // {
      //   program: 'fun1',
      //   cards: [
      //     {
      //       cardId: 1,
      //       label: 'a',
      //       include: 6,
      //       lks: [
      //         {
      //           keyword: 'apple /&abreve;/',
      //           image: '/images/Fun1/illustrations/apple.svg',
      //         },
      //         {
      //           keyword: 'safe /&amacr;/',
      //           image: '/images/Fun2/illustrations/safe.svg',
      //         },
      //         {
      //           keyword: 'acorn /&amacr;/',
      //           image: '/images/Fun2/illustrations/acorn.svg',
      //         },
      //         {
      //           keyword: 'wash squash /&#559;/',
      //           image: '/images/Fun3/illustrations/wash_squash.svg',
      //         },
      //         {
      //           keyword: 'Alaska /&ubreve;/',
      //           image: '/images/Fun3/illustrations/Alaska.svg',
      //         },
      //       ],
      //     },
      //     {
      //       cardId: 2,
      //       label: 'e',
      //       lks: [
      //         {
      //           keyword: 'Ed /&#277;/',
      //           image: '/images/Fun1/illustrations/ed.svg',
      //         },
      //         {
      //           keyword: 'Pete /&emacr;/',
      //           image: '/images/Fun1/illustrations/pete.svg',
      //         },
      //         {
      //           keyword: 'me /&emacr;/',
      //           image: '/images/Fun2/illustrations/me.svg',
      //         },
      //       ],
      //       include: 6,
      //     },
      //     {
      //       cardId: 3,
      //       label: 'i',
      //       lks: [
      //         {
      //           keyword: 'itch /&#301;/',
      //           image: '/images/Fun1/illustrations/itch.svg',
      //         },
      //         {
      //           keyword: 'pine /&imacr;/',
      //           image: '/images/Fun1/illustrations/pine.svg',
      //         },
      //         {
      //           keyword: 'hi /&imacr;/',
      //           image: '/images/Fun2/illustrations/hi.svg',
      //         },
      //         {
      //           keyword: 'animal /&ubreve;/ or /&#301;/',
      //           image: '/images/Fun3/illustrations/animal.svg',
      //         },
      //         {
      //           keyword: 'champion /&emacr;/',
      //           image: '/images/Fun3/illustrations/champion.svg',
      //         },
      //       ],
      //       include: 6,
      //     },
      //     {
      //       cardId: 4,
      //       label: 'o',
      //       lks: [
      //         {
      //           keyword: 'octopus &#335;',
      //           image: '/images/Fun1/illustrations/octopus.svg',
      //         },
      //         {
      //           keyword: 'home /&omacr;/',
      //           image: '/images/Fun1/illustrations/home.svg',
      //         },
      //         {
      //           keyword: 'no /&omacr;/',
      //           image: '/images/Fun2/illustrations/no.svg',
      //         },
      //       ],
      //       include: 6,
      //     },
      //     {
      //       cardId: 5,
      //       label: 'u',
      //       lks: [
      //         {
      //           keyword: 'up /&ubreve;/',
      //           image: '/images/Fun1/illustrations/up.svg',
      //         },
      //         {
      //           keyword: 'mule /&umacr;/',
      //           image: '/images/Fun1/illustrations/mule.svg',
      //         },
      //         {
      //           keyword: 'rule /&uuml;/',
      //           image: '/images/Fun1/illustrations/rule.svg',
      //         },
      //         {
      //           keyword: 'pupil /&umacr;/',
      //           image: '/images/Fun2/illustrations/student.svg',
      //         },
      //         {
      //           keyword: 'flu /&uuml;/',
      //           image: '/images/Fun2/illustrations/flu.svg',
      //         },
      //       ],
      //       include: 6,
      //     },
      //     {
      //       cardId: 6,
      //       label: 'y',
      //       lks: [
      //         {
      //           keyword: 'cry /&imacr;/<br>baby /&emacr;/',
      //           image: '/images/Fun2/illustrations/babycry.svg',
      //         },
      //       ],
      //       include: 6,
      //     },
      //   ],
      // },
    ],
  },
};
