<template>
  <div id="positioner">
    <ActivityHeader activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
      back-button-title="Activities"
      back-button-route="chooseactivity" />
    <p class="get-started">Pick an activity to get started.</p>

    <div class="activity-tiles drillsounds-menu">
      <router-link
        v-if="isAvailable('LC')"
        :to="{ name: 'largesoundcards', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile drillsounds-activity-tile"
        id="largesoundcards"
      >
        <img alt="" src="/images/poster-tiles/large-sound-cards.svg" />
        <span
          >Large
          <br />
          <span>Sound Cards</span>
        </span>
      </router-link>

      <router-link
        v-if="isAvailable('SC')"
        :to="{ name: 'standardsoundcards', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile drillsounds-activity-tile"
        id="standardsoundcards"
      >
        <img alt="" src="/images/poster-tiles/standard-sound-cards.svg" />
        <span
          >Standard
          <br />
          <span>Sound Cards</span>
        </span>
      </router-link>

      <router-link
        v-if="(programId === 'fun2' && (parseInt(unitId, 10) >= 6 && parseInt(weekId, 10) >= 1)) || (programId === 'fun3' && (isAvailable('VS')))"
        :to="{ name: 'vowelsounds', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile fun2"
        id="vowelsounds"
      >
        <img alt="" v-if="programId === 'fun2'" src="/images/poster-tiles/vowel-sounds-2.svg" />
        <img alt="" v-if="programId === 'fun3'" src="/images/poster-tiles/vowel-sounds-3.svg" />
        <span
          >Vowel
          <br />
          <span>Sounds</span>
        </span>
      </router-link>

      <router-link
        v-if="isAvailable('TW')"
        :to="{ name: 'trickwords', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile fun2"
        id="trickwords"
      >
        <img  alt="" src="/images/poster-tiles/trick-words.svg" />
        <span
          >Trick
          <br />
          <span>Words</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: 'suffixcards', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile drillsounds-activity-tile"
        id="suffixcards"
        v-if="isAvailable('SUFF')"
      >
        <img  alt="" src="/images/poster-tiles/suffix-cards.svg" />
        <span
          >Suffix
          <br />
          <span>Frames</span>
        </span>
      </router-link>
      <router-link
        v-if="isAvailable('SA')"
        :to="{ name: 'soundalike', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile fun3"
        id="soundalike"
      >
        <img  alt="" src="/images/poster-tiles/sound-alike.svg" />
        <span
          >Sound
          <br />
          <span>Alike</span>
        </span>
      </router-link>
      <router-link
        v-if="isAvailable('VT')"
        :to="{ name: 'vowelteams', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId  } }"
        :class="vowelTeamsClass"
        class="activity-tile"
        id="vowelteams"
      >
        <img alt="" :src="'/images/poster-tiles/vowel-teams-' + programNumber + '.svg'">
        <span
          >Vowel
          <br />
          <span>Teams</span>
        </span>
      </router-link>

      <router-link
        v-if="appropriateLesson"
        :to="{ name: 'rcontrolledvowels', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="rcontrolledvowels"
      >
        <img alt="" src="/images/poster-tiles/r-controlled.svg" />
        <span
          >R-Controlled
          <br />
          <span>Vowels</span>
        </span>
      </router-link>

      <router-link
        v-if="isAvailable('SL')"
        :to="{ name: 'silentletters', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile fun3"
        id="silentletters"
      >
        <img alt="" src="/images/poster-tiles/silent-letters.svg" />
        <span
          >Silent
          <br />
          <span>Letters</span>
        </span>
      </router-link>
      <router-link
        v-if="isAvailable('GWO')"
        :to="{ name: 'guesswhichone', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile fun3"
        id="guesswhichone"
      >
        <img alt="" src="/images/poster-tiles/sound-alike.svg" />
        <span
          >Guess
          <br />
          <span>Which One</span>
        </span>
      </router-link>
    </div>
    <div id="menu-bg-image"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'DrillSounds',
  components: { ActivityHeader },
  data() {
    const available = [];
    const programNumber = this.programId.replace(/\D/g, '');
    return { available, programNumber };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  mounted() {
    this.available = this.availableActivities(this.programId, `${this.unitId}-${this.weekId}-${this.dayId}`);
  },
  computed: {
    ...mapGetters('dataStore', ['weekNumber']),
    ...mapGetters('activityControl', ['availableActivities']),
    ...mapState('trickWords', ['trickWords']),

    appropriateLesson() {
      if ((this.isAvailable('RC') && this.programId === 'fun3') || (this.available.includes('RC') && this.programId === 'fun2' && this.unitId >= 2)) {
        return true;
      }
      return false;
    },
    checkTrickWords() {
      const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      const trickWordsForProgram = this.trickWords.find((c) => c.program === this.programId);
      if (trickWordsForProgram) {
        const list = trickWordsForProgram.words.filter((w) => w.weekXref === weekNumber);
        return list.length > 0;
      }
      return false;
    },
    vowelTeamsClass() {
      return ((this.programNumber === '3') ? '' : 'all-fun');
    },
  },
  methods: {
    isAvailable(activity) {
      if (this.available.length === 0) {
        return true;
      }
      return this.available.includes(activity);
    },
  },
};
</script>

<style scoped lang="scss">
#menu-bg-image {
  background-image: url('/images/Fun1/illustrations/man.svg');
  right: 0;
}
.activity-tiles {
  &.drillsounds-menu {
    .fun2 &,
    .fun3 & {
      grid-template-columns: repeat(4, minmax(15rem, 25rem));
      width: 80%;
      .activity-tile {
        // @at-root .activity-tiles .activity-tile.drillsounds-activity-tile span {
        //   background-color: $blue;
        // }
        &#largesoundcards {
          grid-column: auto / span 1;
          grid-row: 1 / span 1;
          img {
            width: 60%;
            left: 20%;
            @extend %svg-shadow;
          }
        }
        &#standardsoundcards {
          grid-column: auto / span 1;
          grid-row: 1 / span 1;
          img {
            width: 40%;
            left: 25%;
            top: 8%;
            @extend %svg-shadow;
          }
        }
        &#suffixcards {
          grid-column: auto / span 1;
          grid-row: 1 / span 1;
          img {
            @extend %svg-shadow;
            width: 60%;
            top: 13%;
            left: 20%;
          }
        }
        &#trickwords,
        &#soundalike,
        &#guesswhichone {
          grid-column: auto / span 1;
          grid-row: 2 / span 1;
          img {
            @extend %svg-shadow;
            width: 50%;
            top: 10%;
            left: 25%;
          }
        }
        &#vowelteams {
          grid-column: auto / span 1;
          grid-row: 2 / span 1;
          img {
            .fun2 &,
            .fun1 & {
              @extend %svg-shadow;
              width: 70%;
              left: 15%;
              .fun3 & {
                width: 50% !important;
                left: 25% !important;
                top: -7%;
              }
            }
          }
        }
        &#rcontrolledvowels {
          grid-column: auto / span 1;
          grid-row: 2 / span 1;
          img {
            width: 105%;
            top: 20%;
          }
        }
        &#vowelsounds {
          grid-column: auto / span 1;
          grid-row: 2 / span 1;
          img {
            width: 105%;
            top: 13%;
          }
        }
        &#silentletters {
          grid-column: auto / span 1;
          grid-row: 2 / span 1;
          img {
            width: 105%;
            top: 19%;
          }
        }
      }
    }
  }
}
</style>
