<template>
  <div id="positioner">
    <ActivityHeader activity-type="StoryTime"
      back-button-title="Activities"
      back-button-route="chooseactivity" />
    <article class="stories" v-for="story in getStoryForDisplay" :key="story.storyId" :class="{ story, scoopsActive: scoopsActive }">
      <nav>
        <h1>{{ story.title }}</h1>
        <button class="button-sm" @click="toggleScoops">Toggle All Scoops</button>
        <span class="tap-instruction"><span>tap phrases to show individual scoops</span></span>
      </nav>
      <div style="position: relative; flex: 1 0 auto;">
        <div id="story-positioner">
          <div id="story-text" data-automationId="story_text" style="font-size: 10px" v-html="story.text"></div>
        </div>
      </div>
    </article>
    <div id="pages-selector" v-if="this.pages > 1">
      <button id="back-page" :disabled="isBackDisabled" @click="reversePage"></button>
      <span>Pages ({{ currentPage }}/{{ pages }})</span>
      <button id="next-page" :disabled="isForwardDisabled" @click="advancePage"></button>
    </div>
    <nav v-if="!isModalVisible">
      <button id="showStoryPicker" v-if="weekId === '0' && programId !== 'fun2'" @click="isModalVisible=true">Select Story</button>
    </nav>
    <PrevNextButtons activity="ST" v-if="weekId!=='0'"></PrevNextButtons>

    <modal-dialog v-show="isModalVisible" @close="closeModal">
      <template v-slot:header>
        <h2>
          Select A Story
        </h2>
      </template>
      <template v-slot:body>
        <div class="story-picker">
          <div class="selectUnit" v-for="story in getAllStories" :key="story.storyId" @click="selectUnit(story.unit)">
            <h3>
              Unit: {{ story.unit }} - <span class="story-picker-title" data-automationId="story_picker">{{ story.title }}</span>
            </h3>
          </div>
        </div>
      </template>
    </modal-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';
import ModalDialog from '@/components/ModalDialog.vue';

export default {
  name: 'Storytime',
  components: { ActivityHeader, PrevNextButtons, ModalDialog },
  data() {
    const currentPage = 1;
    const pages = 0;
    const tallestPage = 1;
    const initialResize = 0;
    const isBackDisabled = true;
    const isForwardDisabled = false;
    const isModalVisible = false;
    const currentUnit = this.unitId;
    return { currentUnit, isModalVisible, initialResize, pages, currentPage, isBackDisabled, isForwardDisabled, tallestPage, scoopsActive: false };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  computed: {
    ...mapState('storytime', ['storytimeData']),
    getStoryForDisplay() {
      const programData = this.storytimeData.find((c) => c.program === this.programId);
      if (programData) {
        const storiesToShow = programData.storyData.stories;
        let week = '';
        if (this.weekId === '0') {
          // linked from "quick links"
          week = '1';
        } else {
          week = this.weekId;
        }
        const xref = programData.storyData.storyXref.find((f) => f.weeks.includes(`${this.currentUnit}-${week}`));
        if (xref) {
          const storyToShow = storiesToShow.filter((f) => xref.stories.includes(f.storyId));
          return storyToShow;
        }
      }
      return [];
    },
    getAllStories() {
      const programData = this.storytimeData.find((c) => c.program === this.programId);
      if (programData) {
        let storiesToShow = [];
        const { stories } = programData.storyData;
        const { storyXref } = programData.storyData;
        storiesToShow = storyXref.map((xref) => {
          const story = stories.find((s) => s.storyId === xref.stories[0]);
          const unit = xref.weeks[0].split('-')[0];
          return { storyId: story.storyId, unit, title: story.title };
        });
        return storiesToShow;
      }
      return [];
    },
  },
  methods: {
    initialResizeTest() {
      if (this.initialResize === 0) {
        this.resizeMethods();
      }
      this.initialResize = 1;
    },
    toggleScoops() {
      this.scoopsActive = !this.scoopsActive;
    },
    resizeStory() {
      const el = document.getElementById('story-positioner');
      if (el) {
        let pageHeight = el.clientHeight;
        let fontSize;
        const storyText = document.getElementById('story-text');

        const allPages = document.getElementsByClassName('story-page');
        let tallestPageHeight;
        if (allPages.length > 0) {

          const allPageHeights = [];
          for (let i = 0; i < allPages.length; i += 1) {
            allPageHeights.push(allPages[i].clientHeight);
          }
          this.tallestPage = document.getElementById(allPageHeights.indexOf(Math.max(...allPageHeights)));

          if (this.tallestPage === null) {
            this.tallestPage = document.getElementById('1');
          }
          tallestPageHeight = this.tallestPage.clientHeight;
        } else {
          this.tallestPage = document.getElementById('story-text');
          tallestPageHeight = this.tallestPage.clientHeight;
        }

        if (pageHeight < tallestPageHeight) {
          while (pageHeight < tallestPageHeight) {
            pageHeight = el.clientHeight;
            tallestPageHeight = this.tallestPage.clientHeight;
            fontSize = parseFloat(getComputedStyle(storyText).fontSize.replace(/px/g, ''));
            storyText.style.fontSize = `${fontSize - 0.25}px`;
            // re-get container heights
            pageHeight = el.clientHeight;
            tallestPageHeight = this.tallestPage.clientHeight;
          }
          // the above loop will keep increasing the font size until the page pops out of it's comtainer.  Now we must take it back down one size to get it back inside the container.
          // storyText.style.fontSize = parseFloat(getComputedStyle(storyText).fontSize.replace(/px/g, '')) + 0.25 + 'px';
        } else if (pageHeight > tallestPageHeight) {
          while (pageHeight > tallestPageHeight) {
            fontSize = parseFloat(getComputedStyle(storyText).fontSize.replace(/px/g, ''));
            storyText.style.fontSize = `${fontSize + 0.25}px`;
            // re-get container heights
            pageHeight = el.clientHeight;
            tallestPageHeight = this.tallestPage.clientHeight;
          }
          // the above loop will keep increasing the font size until the page pops out of it's comtainer.  Now we must take it back down one size to get it back inside the container.
          storyText.style.fontSize = `${parseFloat(getComputedStyle(storyText).fontSize.replace(/px/g, '')) - 0.25}px`;
        }
      }

    },

    resizeMethods() {
      setTimeout(() => { this.resizeStory(); }, 10);
    },

    selectUnit(unit) {
      this.currentUnit = unit;
      this.closeModal();
      this.initialResize = 0;
      setTimeout(() => {
        setTimeout(() => this.setupScoopableRegions(), 0);

        this.setupPages();
        setTimeout(() => this.resizeStory(), 0);
      }, 0);
    },

    closeModal() {
      this.isModalVisible = false;
    },

    // setup page counter, and back/forward arrows
    setupPages() {
      this.currentPage = 1;
      this.pages = document.getElementsByClassName('story-page').length;
    },

    // mechanisms for hiding/showing & toggling between pages
    advancePage() {
      this.currentPage += 1;
      this.isBackDisabled = false;
      this.toggleActivePage();

      if (this.currentPage === this.pages) {
        this.isForwardDisabled = true;
      } else {
        this.isForwardDisabled = false;
      }
    },
    reversePage() {
      this.currentPage -= 1;
      this.isForwardDisabled = false;
      this.toggleActivePage();
      if (this.currentPage === 1) {
        this.isBackDisabled = true;
      } else {
        this.isBackDisabled = false;
      }
    },
    toggleActivePage() {
      const x = document.getElementsByClassName('story-page');
      for (let i = 0; i < x.length; i += 1) {
        x[i].setAttribute('style', 'visibility:  hidden');
      }
      document.getElementById(this.currentPage).setAttribute('style', 'visibility: visible;');
    },

    setupScoopableRegions() {
      const scoopables = Array.from(document.getElementsByClassName('scoopable'));
      scoopables.forEach((scoop) => {
        scoop.onclick = (itm) => {
          let el = itm.srcElement;
          while (!el.classList.contains('scoopable')) {
            el = el.parentNode;
          }
          el.classList.toggle('scoopActive');
        };
      });
    },
  },
  created() {
    const st = this;
    window.addEventListener('load', () => {
      st.resizeMethods();
    });
    window.addEventListener('resize', this.resizeMethods);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  mounted() {
    if (this.weekId !== '0' || this.programId === 'fun2') {
      this.setupPages();
      this.resizeMethods();
      this.setupScoopableRegions();
    } else {
      this.isModalVisible = true;
    }
  },
  updated() {
    this.initialResizeTest();
  },
};
</script>

<style  lang="scss">
#story-positioner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.stories{
  nav {
    display: flex;
    align-items: center;
    button {
      flex-grow: 0;
      margin: auto 1rem;
    }
  }
}
.story {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.selectUnit {
  cursor: pointer;
  .story-picker-title{
    text-decoration: underline;
    padding-left: 30px;
    margin-left: 5px;
    background-image: url('/images/story-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
  }
}
.story-picker{
  padding: 0 20px;
}
#story-text {
  font-family: wBookman, serif;
  line-height: 3;
  text-indent: 5rem;
    .scoopable {
      background: transparent;
      border-radius: 75%;
      box-shadow: none;
      padding: 0 0 1% 0;
      .scoopsActive & {
        box-shadow: 0 2px 0 0 #08f;
      }
      &.scoopActive {
        box-shadow: 0 2px 0 0 #08f;
      }
    }
    .story-page {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

}
.tap-instruction {
  background-image: url('/images/pointer.svg');
  background-size: 25px;
  background-position: center left;
  background-repeat: no-repeat;
  padding: 1rem 0 1rem 3rem;
  font-size: 2rem;
  margin-left: 1rem;
  span {
    background-image: radial-gradient(circle, #fffca7, white);
  }
}
#pages-selector {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  margin: 0 auto -3rem auto;

  span {
    font-size: 2rem;
    font-weight: 700;
    line-height: 0.8;
    margin: 0 1rem;
  }
  #next-page,
  #back-page {
    width: 0px;
    height: 0px;
    border-bottom: 10px solid white;
    border-top: 10px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 50px;
      height: 25px;
      z-index: 2;
      background-color: white;
      opacity: 0.3;
    }
  }
  #next-page {
    border-left: #009be9 solid 14px;
    border-right: 14px solid white;
    &:after {
      right: 0;
      left: -25px;
    }
  }
  #back-page {
    border-left: 14px solid white;
    border-right: #009be9 solid 14px;
    &:after {
      left: 0;
    }
  }
}
</style>
