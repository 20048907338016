export default {
  namespaced: true,

  state: {
    storytimeData: [
      {
        program: 'funk',
        storyData: {
          stories: [
            {
              storyId: 1,
              title: 'Echo Finds Dinner',
              text: `
                <p>
                  <span class="scoopable">One day</span>
                  <span class="scoopable">Echo was deep</span>
                  <span class="scoopable">in the forest.</span>
                  <span class="scoopable">She sat</span>
                  <span class="scoopable">on a branch</span>
                  <span class="scoopable">of a very tall tree.</span>
                  <span class="scoopable">Echo</span>
                  <span class="scoopable">had a problem.</span>
                  <span class="scoopable">She was very hungry!</span>
                  <span class="scoopable">Echo had to find food</span>
                  <span class="scoopable">so she searched</span>
                  <span class="scoopable">and searched,</span>
                  <span class="scoopable">looking all around the forest.</span>
                  <span class="scoopable">Echo saw something move!</span>
                  <span class="scoopable">It was a mouse.</span>
                  <span class="scoopable">Echo was fast.</span>
                  <span class="scoopable">She flew down</span>
                  <span class="scoopable">and scooped it up.</span>
                  <span class="scoopable">Echo ate the mouse</span> <span class="scoopable">and was happy.</span>
                </p>
              `,
            },
            {
              storyId: 2,
              title: 'Baby Echo Flies',
              text: `
              <p>
                <span class="scoopable">Baby Echo sat on a branch</span>
                <span class="scoopable">snuggled in the shelter</span>
                <span class="scoopable">of his mother’s wings.</span>
                <span class="scoopable">It was warm</span>
                <span class="scoopable">and safe there.</span>
                <span class="scoopable">Baby Echo was happy.</span>
                <span class="scoopable">Then</span>
                <span class="scoopable">Echo looked down at Baby Echo</span>
                <span class="scoopable">and said,</span>
                <span class="scoopable">"It is time for you to fly."</span>
                <span class="scoopable">Baby Echo snuggled closer</span>
                <span class="scoopable">to his mother</span>
                <span class="scoopable">and said,</span>
                <span class="scoopable">"But I don’t know how to fly!"</span>
                <span class="scoopable">Echo said,</span>
                <span class="scoopable">"Watch me,</span>
                <span class="scoopable">I will teach you,"</span>
                <span class="scoopable">and she flapped her wings.</span>
                <span class="scoopable">Baby Echo was brave.</span>
                <span class="scoopable">He followed his mother</span>
                <span class="scoopable">and landed next to her.</span>
                <span class="scoopable">"I did it!"</span>
                <span class="scoopable">cried Baby Echo.</span>
                <span class="scoopable">"Yes you did</span>
                <span class="scoopable">and I am proud,"</span>
                <span class="scoopable">said Echo.</span>
                <span class="scoopable">"Hooray for you."</span>
              </p>
              `,
            },
            {
              storyId: 3,
              title: 'Baby Echo Finds Echo at Last',
              text: `
                <p>
                <span class="scoopable">One day</span>
                <span class="scoopable">Baby Echo was looking</span>
                <span class="scoopable">for his mother.</span>
                <span class="scoopable">He looked</span>
                <span class="scoopable">and looked</span>
                <span class="scoopable">but he couldn’t see her.</span>
                <span class="scoopable">So he hopped over</span>
                <span class="scoopable">to the middle of</span>
                <span class="scoopable">the big branch.</span>
                <span class="scoopable">He looked</span>
                <span class="scoopable">and looked there,</span>
                <span class="scoopable">but he still couldn’t see her.</span>
                <span class="scoopable">So he hopped over</span>
                <span class="scoopable">to the very edge</span>
                <span class="scoopable">of the big long branch.</span>
                <span class="scoopable">He looked</span>
                <span class="scoopable">and looked there,</span>
                <span class="scoopable">but he still couldn’t see her.</span>
                <span class="scoopable">He sat up</span>
                <span class="scoopable">and thought,</span>
                <span class="scoopable">"Where is my mother?"</span>
                <span class="scoopable">Just then</span>
                <span class="scoopable">he heard a noise behind him.</span>
                <span class="scoopable">He turned around</span>
                <span class="scoopable">and looked down.</span>
                <span class="scoopable">Baby Echo saw his mother.</span>
                <span class="scoopable">At last</span>
                <span class="scoopable">he was happy.</span>
              </p>
              `,
            },
            {
              storyId: 4,
              title: 'Cod Fish',
              text: `
              <p>
                <span class="scoopable">Beth got Rick</span>
                <span class="scoopable">a red net</span>
                <span class="scoopable">at the shop.</span>
                <span class="scoopable">Then Rick</span>
                <span class="scoopable">got a big fish</span>
                <span class="scoopable">in the net.</span>
                <span class="scoopable">It was a cod fish.</span>
                <span class="scoopable">Rick fed the fish</span>
                <span class="scoopable">to his pet cat,</span>
                <span class="scoopable">Chip.</span>
                <span class="scoopable">Chip had the dish</span>
                <span class="scoopable">of cod fish.</span>
                <span class="scoopable">Yum! Yum!</span>
              </p>
              `,
            },
          ],
          storyXref: [
            {
              weeks: ['1-1'],
              stories: [1],
            },
            {
              weeks: ['2-1'],
              stories: [2],
            },
            {
              weeks: ['3-1'],
              stories: [3],
            },
            {
              weeks: ['4-1'],
              stories: [4],
            },
          ],
        },
      },
      {
        program: 'fun1',
        storyData: {
          stories: [
            {
              storyId: 1,
              title: 'Cod Fish',
              text: `
              <p>
                <span class="scoopable">Jack had a net</span>
                <span class="scoopable">to get a cod fish for Jed,</span>
                <span class="scoopable">the cat.</span>
                <span class="scoopable">Jack sat on a rock</span>
                <span class="scoopable">with his net.</span>
                <span class="scoopable">Jed sat on a log.</span>
                <span class="scoopable">Jack had the luck!</span>
                <span class="scoopable">He was quick with the net.</span>
                <span class="scoopable">He got the cod fish.</span>
                <span class="scoopable">Jed had a dish of fish.</span>
                <span class="scoopable">Yum!</span>
              </p>
              `,
            },
            {
              storyId: 2,
              title: 'The Big Mess',
              text: `
              <p>
                <span class="scoopable">The hall</span>
                <span class="scoopable">was such a big mess!</span>
                <span class="scoopable">Mom was not mad.</span>
                <span class="scoopable">She did not yell,</span>
                <span class="scoopable">but she said,</span>
                <span class="scoopable">"Pick this up!"</span>
                <span class="scoopable">"<strong>What</strong> a mess!"</span>
                <span class="scoopable">said Mom.</span>
                <span class="scoopable">"<strong>Put</strong> your ball in the box</span>
                <span class="scoopable">and get this mud</span>
                <span class="scoopable">off the wall."</span>
                <span class="scoopable">Jill and Bill</span>
                <span class="scoopable">did the big job.</span>
                <span class="scoopable">Did they fuss?</span>
                <span class="scoopable">They did not fuss</span>
                <span class="scoopable">at all.</span>
              </p>
              `,
            },
            {
              storyId: 3,
              title: 'Pam and Dan',
              text: `
              <p>
                <span class="scoopable">Pam and Dan</span>
                <span class="scoopable">sat on a hill</span>
                <span class="scoopable">in the sun.</span>
                <span class="scoopable">Then, </span>
                <span class="scoopable">Sam sat with them.</span>
                <span class="scoopable">Pam had a red and tan ball</span>
                <span class="scoopable">from the shop.</span>
                <span class="scoopable">"Can you toss it to me?"</span>
                <span class="scoopable">said Dan.</span>
                <span class="scoopable">"Pass it back to me."</span>
                <span class="scoopable">said Sam.</span>
                <span class="scoopable">Sam, Pam, and Dan</span>
                <span class="scoopable">had fun with the ball.</span>
              </p>
              `,
            },
            {
              storyId: 4,
              title: 'Mack and Bugs',
              text: `
              <p>
                <span class="scoopable">Mack is not a fan of bugs,</span>
                <span class="scoopable">not one bit!</span>
                <span class="scoopable">If a bug gets on Mack’s bed,</span>
                <span class="scoopable">Mack gets mad.</span>
                <span class="scoopable">Mack yells at the bug,</span>
                <span class="scoopable">“Get off this bed</span>
                <span class="scoopable">you big, bad bug!"</span>
                <span class="scoopable">If the bug does not <strong>go</strong>,</span>
                <span class="scoopable">Mack yells for his dad.</span>
                <span class="scoopable">If dad is not there,</span>
                <span class="scoopable">Mack runs.</span>
              </p>
              `,
            },
            {
              storyId: 5,
              title: 'King Sam',
              text: `
              <p>
                <span class="scoopable">King Sam</span>
                <span class="scoopable">sings to the kids.</span>
                <span class="scoopable">The king can not sing.</span>
                <span class="scoopable">His song is bad!</span>
                <span class="scoopable">The kids <strong>say</strong></span>
                <span class="scoopable">"Stop!"</span>
                <span class="scoopable">The king winks</span>
                <span class="scoopable">and the song stops.</span>
                <span class="scoopable">The kids <strong>say</strong>,</span>
                <span class="scoopable">"Thank you,</span>
                <span class="scoopable">King Sam."</span>
              </p>
              `,
            },
            {
              storyId: 6,
              title: 'The Pink Dress',
              text: `
              <p>
                <span class="scoopable">Mom said,</span>
                <span class="scoopable">"Jess,</span>
                <span class="scoopable">you have to get a dress</span>
                <span class="scoopable">at the shop.</span>
                <span class="scoopable"><strong>Let's go</strong></span>
                <span class="scoopable">and you can try</span>
                <span class="scoopable">some of them."</span>
                <span class="scoopable">They went to the shop.</span>
                <span class="scoopable">Jess held up</span>
                <span class="scoopable">a red dress.</span>
                <span class="scoopable">"Can I try this one?"</span>
                <span class="scoopable">That dress</span>
                <span class="scoopable">was just too small.</span>
                <span class="scoopable">Then Jess</span>
                <span class="scoopable">held up <strong>another</strong> one.</span>
                <span class="scoopable">It was a pink silk dress.</span>
              </p>
              <p>
                <span class="scoopable">"That is the best dress!"</span>
                <span class="scoopable">said Mom.</span>
                <span class="scoopable">"If it does fit you,</span>
                <span class="scoopable">I will get that one for you."</span>
                <span class="scoopable">The pink dress</span>
                <span class="scoopable">did fit Jess,</span>
                <span class="scoopable">but it had a small rip in it.</span>
                <span class="scoopable">Mom said,</span>
                <span class="scoopable">"I can fix that rip.</span>
                <span class="scoopable">I will mend it for you."</span>
                <span class="scoopable">Jess said,</span>
                <span class="scoopable">"Thank you, Mom!"</span>
                <span class="scoopable">She was <strong>so</strong> glad</span>
                <span class="scoopable">to get the pink silk dress.</span>
              </p>
              `,
            },
            {
              storyId: 7,
              title: 'Fred the Frog',
              text: `
              <p>
                <span class="scoopable">Fred the frog</span>
                <span class="scoopable"><strong>likes</strong> to hop,</span>
                <span class="scoopable">and jump.</span>
                <span class="scoopable">He hops over the twig</span>
                <span class="scoopable">and he jumps on the grass.</span>
                <span class="scoopable">He is quick!</span>
                <span class="scoopable">He jumps over the mud</span>
                <span class="scoopable">and between the two dogs.</span>
                <span class="scoopable">He jumps on the steps.</span>
              </p>
              <p>
                <span class="scoopable">Then</span>
                <span class="scoopable">I did not see him.</span>
                <span class="scoopable">Where did Fred <strong>go</strong>?</span>
                <span class="scoopable">Did Fred hop up</span>
                <span class="scoopable">on that bench?</span>
                <span class="scoopable">Yes he did.</span>
                <span class="scoopable">Then</span>
                <span class="scoopable">he went into my hut.</span>
                <span class="scoopable">Did Fred hop up</span>
                <span class="scoopable">on my bed?</span>
                <span class="scoopable">Yes he did.</span>
              </p>
              <p>
                <span class="scoopable">"Yuck!</span>
                <span class="scoopable">Get off Fred!"</span>
              </p>
              `,
            },
            {
              storyId: 8,
              title: 'The Skunk',
              text: `
              <p>
                <span class="scoopable">The kids</span>
                <span class="scoopable">were camping</span>
                <span class="scoopable">by the small pond.</span>
                <span class="scoopable">They each had to do a job</span>
                <span class="scoopable">that was on a long list.</span>
                <span class="scoopable">Some of the jobs were fun.</span>
                <span class="scoopable">Two kids</span>
                <span class="scoopable">had to put up the flag,</span>
                <span class="scoopable">which was the best task of all.</span>
              </p>
              <p>
                <span class="scoopable">Then the kids went for a swim</span>
                <span class="scoopable">and sat on the grass</span>
                <span class="scoopable">in the sun.</span>
                <span class="scoopable">Next, the kids</span>
                <span class="scoopable">went on the big swings</span>
                <span class="scoopable">that were down at the end</span>
                <span class="scoopable">of the camp.</span>
                <span class="scoopable">At last</span>
                <span class="scoopable">the day was over.</span>
              </p>
              <p>
                <span class="scoopable">They went into the tent</span>
                <span class="scoopable">and slept.</span>
                <span class="scoopable">The kids sat up</span>
                <span class="scoopable">one by one.</span>
                <span class="scoopable">"It stinks!"</span>
                <span class="scoopable">said the kids,</span>
                <span class="scoopable">"We smell a skunk!"</span>
                <span class="scoopable">The kids ran out of the tent</span>
                <span class="scoopable">in a rush.</span>
                <span class="scoopable">What did they see?</span>
                <span class="scoopable">A skunk</span>
                <span class="scoopable">was on a stump!</span>
                <span class="scoopable">"Help!"</span>
                <span class="scoopable">The kids were fast.</span>
                <span class="scoopable">They ran back into the tent</span>
                <span class="scoopable">and hid.</span>
                <span class="scoopable">The skunk did not <strong>go</strong> in</span>
                <span class="scoopable"><strong>so</strong> they rested at last.</span>
              </p>
              `,
            },
            {
              storyId: 9,
              title: 'Jake the Whale',
              text: `
              <p>
                <span class="scoopable">Jake the whale</span>
                <span class="scoopable">was very big.</span>
                <span class="scoopable">He had many whale friends.</span>
                <span class="scoopable">They all would ride the waves.</span>
              </p>
              <p>
                <span class="scoopable">One time,</span>
                <span class="scoopable">Jake and his friends</span>
                <span class="scoopable">were out on the waves</span>
                <span class="scoopable">and Jake dove down</span>
                <span class="scoopable">into the sea.</span>
                <span class="scoopable">He went to hide,</span>
                <span class="scoopable">and his friends did not see</span>
                <span class="scoopable">where he went.</span>
                <span class="scoopable">They could not get him.</span>
                <span class="scoopable">"I think that Jake is lost!"</span>
                <span class="scoopable">said one of his friends.</span>
              </p>
              <p>
                <span class="scoopable">But Jake was not lost</span>
                <span class="scoopable">at all.</span>
                <span class="scoopable">He did a flip</span>
                <span class="scoopable">and a jump</span>
                <span class="scoopable">and was back with his other whale friends.</span>
                <span class="scoopable">They all swam</span>
                <span class="scoopable">into the big waves</span>
                <span class="scoopable">and had fun</span>.
              </p>
              `,
            },
            {
              storyId: 10,
              title: 'Jackson',
              text: `
              <p>
                <span class="scoopable">Ed had a pet dog,</span>
                <span class="scoopable">Jackson.</span>
                <span class="scoopable">Jackson was Ed’s best friend.</span>
                <span class="scoopable">They got up</span>
                <span class="scoopable">at sunrise.</span>
                <span class="scoopable">Just when Ed</span>
                <span class="scoopable">was finishing a pancake,</span>
                <span class="scoopable">Jackson came to him with a ball.</span>
                <span class="scoopable">Ed put on his jacket</span>
                <span class="scoopable">and they went out.</span>
                <span class="scoopable">Jackson and Ed had fun</span>
                <span class="scoopable">with the ball.</span>
              </p>
              <p>
                <span class="scoopable">Then,</span>
                <span class="scoopable">the ball went into a bucket of mud</span>
                <span class="scoopable">in the sandbox.</span>
                <span class="scoopable">Jackson did not see it.</span>
                <span class="scoopable">He dug in the sand,</span>
                <span class="scoopable">but did not get the ball.</span>
                <span class="scoopable">It was not there.</span>
              </p>
              <p>
                <span class="scoopable">Then,</span>
                <span class="scoopable">the bucket fell over</span>
                <span class="scoopable">and the ball fell out.</span>
                <span class="scoopable">The mud fell out too,</span>
                <span class="scoopable">and it landed on Jackson!</span>
                <span class="scoopable">He was a mess!</span>
              </p>
              <p>
                <span class="scoopable">At sunset,</span>
                <span class="scoopable">Jackson and Ed had a nap.</span>
                <span class="scoopable">It was a fun day.</span>
              </p>
              `,
            },
            {
              storyId: 11,
              title: 'Brad\'s Lost Glasses',
              text: `
              <p>
                <span class="scoopable">Brad,</span>
                <span class="scoopable">Stan,</span>
                <span class="scoopable">and Jeff</span>
                <span class="scoopable">had their lunches in a bag</span>
                <span class="scoopable">to take to the park.</span>
                <span class="scoopable">At the park,</span>
                <span class="scoopable">they played many games.</span>
                <span class="scoopable">Brad had a basketball</span>
                <span class="scoopable">and they shot it</span>
                <span class="scoopable">into the net.</span>
                <span class="scoopable">At last they</span>
                <span class="scoopable">sat on a big rock</span>
                <span class="scoopable">and ate their lunches.</span>
              </p>
              <p>
                <span class="scoopable">When they were set</span>
                <span class="scoopable">to go have another contest,</span>
                <span class="scoopable">Brad said,</span>
                <span class="scoopable">"I lost my glasses!"</span>
                <span class="scoopable">He insisted</span>
                <span class="scoopable">that he put them on the big rock,</span>
                <span class="scoopable">but they were not there.</span>
                <span class="scoopable">Stan,</span>
                <span class="scoopable">Brad,</span>
                <span class="scoopable">and Jeff</span>
                <span class="scoopable">went on a hunt for the glasses.</span>
                <span class="scoopable">The kids must get the glasses.</span>
                <span class="scoopable">Brad's mom</span>
                <span class="scoopable">would be upset.</span>
              </p>
              <p>
                <span class="scoopable">Where could they look?</span>
                <span class="scoopable">Brad’s glasses</span>
                <span class="scoopable">were not on the steps.</span>
                <span class="scoopable">Brad's glasses</span>
                <span class="scoopable">were not on top</span>
                <span class="scoopable">of the hotrod.</span>
                <span class="scoopable">Then Stan went back</span>
                <span class="scoopable">to the spot where they had lunch.</span>
                <span class="scoopable">There was a shrub</span>
                <span class="scoopable">next to the rock</span>
                <span class="scoopable">where they had sat.</span>
                <span class="scoopable">Brad's glasses</span>
                <span class="scoopable">were in the branches</span>
                <span class="scoopable">of the shrub!</span>
                <span class="scoopable">Brad was so glad</span>
                <span class="scoopable">to get his glasses back.</span>
                <span class="scoopable">"Thanks, Stan!"</span>
                <span class="scoopable">he said.</span>
              </p>
              `,
            },
            {
              storyId: 12,
              title: 'The Big Splash',
              text: `
              <p>
                <span class="scoopable">Blake and Jess</span>
                <span class="scoopable">were at the pond.</span>
                <span class="scoopable">Blake did not want</span>
                <span class="scoopable">to get wet.</span>
                <span class="scoopable">He had on new pants</span>
                <span class="scoopable">and a good top</span>
                <span class="scoopable">and he did not plan to swim.</span>
                <span class="scoopable">Jess went</span>
                <span class="scoopable">into the water</span>
                <span class="scoopable">and was splashing Blake.</span>
                <span class="scoopable">That was a problem.</span>
              </p>
              <p>
                <span class="scoopable">Blake was mad at Jess</span>
                <span class="scoopable">and said,</span>
                <span class="scoopable">"Stop splashing!"</span>
                <span class="scoopable">Jess did stop.</span>
                <span class="scoopable">But then,</span>
                <span class="scoopable">Jess was jumping</span>
                <span class="scoopable">into the pond.</span>
                <span class="scoopable">Her big jumps</span>
                <span class="scoopable">made big splashes.</span>
                <span class="scoopable">Jess insisted</span>
                <span class="scoopable">that the splash was not big.</span>
                <span class="scoopable">Blake grunted</span>
                <span class="scoopable">and said,</span>
                <span class="scoopable">"I advise you</span>
                <span class="scoopable">to stop splashing!"</span>
                <span class="scoopable">Jess did stop.</span>
                <span class="scoopable">But then</span>
                <span class="scoopable">Jess got out of the water.</span>
              </p>
              <p>
                <span class="scoopable">She fell off a log</span>
                <span class="scoopable">and landed in the pond.</span>
                <span class="scoopable"><strong>Guess</strong> what?</span>
                <span class="scoopable">It made a big splash!</span>
                <span class="scoopable">Blake's new pants</span>
                <span class="scoopable">and good top</span>
                <span class="scoopable">were all wet.</span>
              </p>
              <p>
                <span class="scoopable">Jess had not wanted to splash him that time,</span>
                <span class="scoopable">but she did!</span>
              </p>
              `,
            },
          ],
          storyXref: [
            {
              weeks: ['3-1'],
              stories: [1],
            },
            {
              weeks: ['4-1'],
              stories: [2],
            },
            {
              weeks: ['5-1'],
              stories: [3],
            },
            {
              weeks: ['6-1'],
              stories: [4],
            },
            {
              weeks: ['7-1'],
              stories: [5],
            },
            {
              weeks: ['8-1'],
              stories: [6],
            },
            {
              weeks: ['9-1'],
              stories: [7],
            },
            {
              weeks: ['10-1'],
              stories: [8],
            },
            {
              weeks: ['11-1'],
              stories: [9],
            },
            {
              weeks: ['12-1'],
              stories: [10],
            },
            {
              weeks: ['13-1'],
              stories: [11],
            },
            {
              weeks: ['14-1'],
              stories: [12],
            },
          ],
        },
      },
      {
        program: 'fun2',
        storyData: {
          stories: [
            {
              storyId: 2,
              title: 'Skip is Sick',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Skip</span>
                                        <span class="scoopable">sat up in bed.</span>
                                        <span class="scoopable">He had to get up.</span>
                                        <span class="scoopable">He had a big quiz in math</span>
                                        <span class="scoopable">and he did not want</span>
                                        <span class="scoopable">to miss the bus.</span>
                                        <span class="scoopable">He had not slept well.</span>
                                        <span class="scoopable">He felt sick</span>
                                        <span class="scoopable">and his skin was hot.</span>
                                        <span class="scoopable">He left his quilt</span>
                                        <span class="scoopable">on the bed</span>
                                        <span class="scoopable">and went to get his mom.</span>
                                        <span class="scoopable">Mom had a shock</span>
                                        <span class="scoopable">when Skip came in.</span>
                                        <span class="scoopable">He had a red rash</span>
                                        <span class="scoopable">on his neck.</span>
                                        <span class="scoopable">“Back to bed,</span>
                                        <span class="scoopable">Skip,”</span>
                                        <span class="scoopable">said Mom.</span>
                                        <span class="scoopable">Skip went back</span>
                                        <span class="scoopable">and got in bed.</span>
                                        <span class="scoopable">Mom came in</span>
                                        <span class="scoopable">to check his temp.</span>
                                        <span class="scoopable">He was hot!</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">“I did pack your lunch,</span>
                                        <span class="scoopable">but I can not let you get</span>
                                        <span class="scoopable">on that bus,”</span>
                                        <span class="scoopable">said Mom.</span>
                                        <span class="scoopable">“You will just have to</span>
                                        <span class="scoopable">miss the quiz.”</span>
                                        <span class="scoopable">Skip slept the rest of the day.</span>
                                        <span class="scoopable">Skip had to rest all day.</span>
                                        <span class="scoopable">He slept a long time.</span>
                                        <span class="scoopable">Mom kept him in bed.</span>
                                        <span class="scoopable">She had him</span>
                                        <span class="scoopable">drink lots of <strong>water</strong>.</span>
                                        <span class="scoopable">He did not get up</span>
                                        <span class="scoopable">to play.</span>
                                        <span class="scoopable">The next day,</span>
                                        <span class="scoopable">Skip felt the best</span>
                                        <span class="scoopable">he had felt in a long time.</span>
                                        <span class="scoopable">It was a day off,</span>
                                        <span class="scoopable">too!</span>
                                        <span class="scoopable">He was glad</span>
                                        <span class="scoopable">that there was no class.</span>
                                        <span class="scoopable">He had a plan</span>
                                        <span class="scoopable">to fill his day with fun.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">He went out</span>
                                        <span class="scoopable">to get on the swing.</span>
                                        <span class="scoopable">When he sat on the swing</span>
                                        <span class="scoopable">he felt a sting on his leg.</span>
                                        <span class="scoopable">He let out a yell!</span>
                                        <span class="scoopable">He swung at the bug</span>
                                        <span class="scoopable">and fell off the swing.</span>
                                        <span class="scoopable">Now,</span>
                                        <span class="scoopable">there was a bump on his chin</span>
                                        <span class="scoopable">and his leg began to swell.</span>
                                        <span class="scoopable">He did not get up fast.</span>
                                        <span class="scoopable">He swept the dust</span>
                                        <span class="scoopable">off his legs.</span>
                                        <span class="scoopable">The bump on his chin</span>
                                        <span class="scoopable">felt big</span>
                                        <span class="scoopable">and his leg stung.</span>
                                        <span class="scoopable">He had to limp back in.</span>
                                        <span class="scoopable">“Mom, I think I <strong>need</strong> help,”</span>
                                        <span class="scoopable">he said.</span>
                                        <span class="scoopable">“That is a bad cut,</span>
                                        <span class="scoopable">Skip.</span>
                                        <span class="scoopable">I will get the stuff</span>
                                        <span class="scoopable">to put on it.</span>
                                        <span class="scoopable">Did you fall?”</span>
                                        <span class="scoopable">asked Mom.</span>
                                        <span class="scoopable">“Yes, I fell,”</span>
                                        <span class="scoopable">said Skip,</span>
                                        <span class="scoopable">“but I also got stung by a bug!</span>
                                        <span class="scoopable">I think I should get back in bed.</span>
                                        <span class="scoopable">I am not <strong>having</strong> luck!”</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 3,
              title: 'The Lost Colt',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Beth had wanted a pet</span>
                                        <span class="scoopable">for a long time.</span>
                                        <span class="scoopable">When her mom and dad</span>
                                        <span class="scoopable"><strong>asked</strong> her</span>
                                        <span class="scoopable">what she wanted for a gift,</span>
                                        <span class="scoopable">she said,</span>
                                        <span class="scoopable">“A pet!</span>
                                        <span class="scoopable">The kind I want most</span>
                                        <span class="scoopable">is a small colt,</span>
                                        <span class="scoopable">but if I cannot have a colt,</span>
                                        <span class="scoopable">I want a dog or cat.”</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Mom and Dad</span>
                                        <span class="scoopable">wanted to get a colt</span>
                                        <span class="scoopable">for their child,</span>
                                        <span class="scoopable">but they did not <strong>know</strong></span>
                                        <span class="scoopable">where to find one.</span>
                                        <span class="scoopable">They also did not have a stall.</span>
                                        <span class="scoopable">A colt could not be kept in the den!</span>
                                        <span class="scoopable">Then,</span>
                                        <span class="scoopable">a man at Dad’s job</span>
                                        <span class="scoopable">told them he had one to sell.</span>
                                        <span class="scoopable">It could <strong>stay</strong> in his stall</span>
                                        <span class="scoopable">if Beth fed it <strong>each</strong> day.</span>
                                        <span class="scoopable">It was not at all wild</span>
                                        <span class="scoopable">and would be a fun pet for a child.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">The man sold Mom and Dad the colt.</span>
                                        <span class="scoopable">The plan was to put it</span>
                                        <span class="scoopable">in the <strong>backyard</strong></span>
                                        <span class="scoopable">and then bring it to the stall</span>
                                        <span class="scoopable">at the end of the </strong>day</strong>.</span>
                                        <span class="scoopable">Mom had a thick string</span>
                                        <span class="scoopable">on the colt</span>
                                        <span class="scoopable">so that it would not bolt off</span>
                                        <span class="scoopable">and get lost.</span>
                                        <span class="scoopable">When Beth got up,</span>
                                        <span class="scoopable">Mom and Dad</span>
                                        <span class="scoopable">put a <strong>blindfold</strong> on her</span>
                                        <span class="scoopable">and led her to the <strong>yard</strong>.</span>
                                        <span class="scoopable">When they got to the back,</span>
                                        <span class="scoopable">they took the blindfold off</span>
                                        <span class="scoopable">and said,</span>
                                        <span class="scoopable">“Here is your gift,</span>
                                        <span class="scoopable">Beth!”</span>
                                        <span class="scoopable">But,</span>
                                        <span class="scoopable">there was no colt</span>-
                                        <span class="scoopable">just a string!</span>
                                        <span class="scoopable">“Where is the colt?</span>
                                        <span class="scoopable">We must find it!”</span>
                                        <span class="scoopable">said Mom and Dad.</span>
                                        <span class="scoopable">The colt was lost.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 4,
              title: 'Wish Come True',
              text: `
                  <p>
                      <span class="scoopable">Mom and Dad</span>
                      <span class="scoopable">were shocked</span>
                      <span class="scoopable">that the colt was not where</span>
                      <span class="scoopable">they had left him.</span>
                      <span class="scoopable">They checked the <strong>yard</strong></span>
                      <span class="scoopable">and the shed.</span>
                      <span class="scoopable">They <strong>even</strong> called the man</span>
                      <span class="scoopable">who had sold them the colt</span>
                      <span class="scoopable">to see if it had <strong>gone</strong> there.</span>
                      <span class="scoopable">The man went out</span>
                      <span class="scoopable">and checked the stalls</span>
                      <span class="scoopable">but the colt was not in there.</span>
                  </p>
                  <p>
                      <span class="scoopable">Beth called the cops</span>
                      <span class="scoopable">to tell them that the colt was missing.</span>
                      <span class="scoopable">The cops felt</span>
                      <span class="scoopable">that it was one of the oddest calls they got.</span>
                      <span class="scoopable">They told Beth</span>
                      <span class="scoopable">that no one had seen a small colt.</span></span>
                      <span class="scoopable">The cops said they would do their best.</span>
                  </p>
                  <p>
                      <span class="scoopable">Beth felt sad.</span>
                      <span class="scoopable">She had wished for a colt.</span>
                      <span class="scoopable">Most wishes do not come <strong>true</strong>,</span>
                      <span class="scoopable">but this one had.</span>
                      <span class="scoopable">It is sad to have a wish come <strong>true</strong></span>
                      <span class="scoopable">and then bolt!</span>
                  </p>
                  <p>
                      <span class="scoopable">She had a pet colt</span>
                      <span class="scoopable">but had not even met it yet. </span>
                      <span class="scoopable">Beth hung her chin</span>
                      <span class="scoopable">and <strong>began</strong> to sob.</span>
                      <span class="scoopable">Then she felt <strong>something</strong> cold</span>
                      <span class="scoopable">and wet on her neck.</span>
                      <span class="scoopable">She sat up</span>
                      <span class="scoopable">and there was her colt!</span>
                      <span class="scoopable">She hugged him</span>
                      <span class="scoopable">and he licked her hands.</span>
                      <span class="scoopable">“Mom and Dad”,</span>
                      <span class="scoopable">she yelled,</span>
                      <span class="scoopable">“My colt has come back!”</span>
                  </p>
              `,
            },
            {
              storyId: 5,
              title: 'Hopscotch',
              text: `
                  <div class="story-page" id="1">
                      <p>
                          <span class="scoopable">Hopscotch is lots of fun.</span>
                          <span class="scoopable">It was invented a long,</span>
                          <span class="scoopable">long time <strong>ago</strong>.</span>
                          <span class="scoopable">It was not a fun game</span>
                          <span class="scoopable">at that time.</span>
                          <span class="scoopable">It was used to make the men</span>
                          <span class="scoopable">in the British <strong>army</strong> stronger.</span>
                          <span class="scoopable">There were lots of boxes</span>
                          <span class="scoopable">in the old hopscotches.</span>
                          <span class="scoopable">The hopscotch could be hundreds of <strong>feet</strong> long.</span>
                          <span class="scoopable">The men had to hop</span>
                          <span class="scoopable">in all the boxes</span>
                          <span class="scoopable">with their splendid armor on.</span>
                          <span class="scoopable">The strongest and fastest men</span>
                          <span class="scoopable">finished <strong>first</strong>.</span>
                          <span class="scoopable">The men who did not finish</span>
                          <span class="scoopable">got upset.</span>
                          <span class="scoopable">They knew they would be punished.</span>
                      </p>
                      <p>
                          <span class="scoopable">The British children</span>
                          <span class="scoopable">wanted to mimic</span>
                          <span class="scoopable">the men in the <strong>army</strong>.</span>
                          <span class="scoopable">They made their own hopscotches.</span>
                      </p>
                      <p>
                          <span class="scoopable">Theirs were not as long</span>
                          <span class="scoopable">as the ones the men used.</span>
                          <span class="scoopable">They tossed small rocks</span>
                          <span class="scoopable">onto the boxes</span>
                          <span class="scoopable">and <strong>hopped</strong> to the end. </span>
                          <span class="scoopable">When the children <strong>played</strong>,</span>
                          <span class="scoopable">it was all for fun.</span>
                          <span class="scoopable"><strong>Even now</strong>,</span>
                          <span class="scoopable">there are children who think hopscotch</span>
                          <span class="scoopable">is a blast!</span>
                      </p>
                  </div>
              `,
            },
            {
              storyId: 6,
              title: 'Babe Ruth',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">On a hot day in June</span>
                                        <span class="scoopable">it is fun to go to a baseball game.</span>
                                        <span class="scoopable">Many kids in Boston</span>
                                        <span class="scoopable">like the Red Sox.</span>
                                        <span class="scoopable">Lots of people</span>
                                        <span class="scoopable">go to the games.</span>
                                        <span class="scoopable">They yell when someone on the home team</span>
                                        <span class="scoopable">smacks a home run</span>
                                        <span class="scoopable">or strikes out the side.</span>
                                        <span class="scoopable">They eat hot dogs and popcorn</span>
                                        <span class="scoopable">and drink lots of tonic.</span>
                                        <span class="scoopable">The fans like it best</span>
                                        <span class="scoopable">when the home team wins the game,</span>
                                        <span class="scoopable">but sometimes that does not happen.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">When the Red Sox are not winning many games,</span>
                                        <span class="scoopable">some people like to tell an old tale</span>
                                        <span class="scoopable">about Babe Ruth.</span>
                                        <span class="scoopable">Babe Ruth used to play baseball</span>
                                        <span class="scoopable">for the Red Sox from 1914-1919.</span>
                                        <span class="scoopable">He was one of the greatest hitters of all time.</span>
                                        <span class="scoopable">He once hit the ball</span>
                                        <span class="scoopable">more than six-hundred feet.</span>
                                        <span class="scoopable">He hit 714 homeruns</span>
                                        <span class="scoopable">in his lifetime.</span>
                                        <span class="scoopable">He was also a great pitcher.</span>
                                        <span class="scoopable">He could strike out lots of batters.</span>
                                        <span class="scoopable">All the fans loved the Babe,</span>
                                        <span class="scoopable">but the children loved him most of all.</span>
                                        <span class="scoopable">He would visit children</span>
                                        <span class="scoopable">who were not well.</span>
                                        <span class="scoopable">Babe knew how to make kids smile.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Babe went to play for the Yankees in 1920.</span>
                                        <span class="scoopable">The Red Sox did not trade him.</span>
                                        <span class="scoopable">They sold him for lots of cash.</span>
                                        <span class="scoopable">The fans were quite mad</span>
                                        <span class="scoopable">and many kids were very sad.</span>
                                        <span class="scoopable">Some fans said that selling Babe</span>
                                        <span class="scoopable">would bring the Red Sox bad luck.</span>
                                        <span class="scoopable">When the Red Sox do not win,</span>
                                        <span class="scoopable">some people think the tale is true</span>
                                        <span class="scoopable">even today.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 7,
              title: 'Adjectives are Handy!',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">By second grade,</span>
                                        <span class="scoopable">you can <strong>read</strong> and spell</span>
                                        <span class="scoopable">lots of words.</span>
                                        <span class="scoopable">We use words</span>
                                        <span class="scoopable">all the time</span>
                                        <span class="scoopable">to talk and to write.</span>
                                        <span class="scoopable">There are all kinds of words.</span>
                                        <span class="scoopable">Some words are people, places or things,</span>
                                        <span class="scoopable">like baby, Boston and bench.</span>
                                        <span class="scoopable">Some words are words for things we do,</span>
                                        <span class="scoopable">like swim, jump and cry.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">We are lucky</span>
                                        <span class="scoopable">to have lots of words</span>
                                        <span class="scoopable">we can use when we write.</span>
                                        <span class="scoopable">Some words are useful</span>
                                        <span class="scoopable">when we are trying to describe</span>
                                        <span class="scoopable">someone or something.</span>
                                        <span class="scoopable">These words are called <strong>adjectives</strong>.</span>
                                        <span class="scoopable"><strong>Adjectives</strong> are words like funny,</span>
                                        <span class="scoopable">silly,</span>
                                        <span class="scoopable">ugly,</span>
                                        <span class="scoopable">messy and chunky.</span>
                                        <span class="scoopable">These words tell us <strong>more</strong> about the people,</span>
                                        <span class="scoopable"><strong>places</strong> and things</span>
                                        <span class="scoopable">we talk and write <strong>about.</strong></span>
                                    </p>
                                    <p>
                                        <span class="scoopable">With <strong>adjectives</strong></span>
                                        <span class="scoopable">we can let others know</span>
                                        <span class="scoopable">if our lunch was yummy</span>
                                        <span class="scoopable">or yucky.</span>
                                        <span class="scoopable">We can tell our friends</span>
                                        <span class="scoopable">if our new puppy is skinny</span>
                                        <span class="scoopable">or chubby</span>
                                        <span class="scoopable">or if our kitty</span>
                                        <span class="scoopable">is black or white.</span>
                                        <span class="scoopable"><strong>Adjectives</strong> come in handy</span>
                                        <span class="scoopable">when we want to describe things!</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 8,
              title: 'Changing Places',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Tory Osborn and Carmel Gomez</span>
                                        <span class="scoopable">are best friends</span>
                                        <span class="scoopable"><strong>even though</strong></span>
                                        <span class="scoopable">they live very far <strong>away</strong></span>
                                        <span class="scoopable">from <strong>each</strong> other.</span>
                                        <span class="scoopable">Tory lives on a farm</span>
                                        <span class="scoopable">in upstate New York</span>
                                        <span class="scoopable">and Carmel lives in New York City.</span>
                                        <span class="scoopable">They only get to be together</span>
                                        <span class="scoopable"><strong>once</strong> a </strong>year</strong></span>
                                        <span class="scoopable">when their two <strong>families</strong> visit.</span>
                                        <span class="scoopable">Sometimes the Gomez family</span>
                                        <span class="scoopable"><strong>goes</strong> to upstate New York</span>
                                        <span class="scoopable">and sometimes the Osborns</span>
                                        <span class="scoopable">drive to the city.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Tory and Carmel</span>
                                        <span class="scoopable">try to write <strong>each</strong> other</span>
                                        <span class="scoopable">once a <strong>week</strong></span>
                                        <span class="scoopable">and they chat on-line <strong>even more</strong> often.</span>
                                        <span class="scoopable">Lately,</span>
                                        <span class="scoopable">they have been thinking</span>
                                        <span class="scoopable">that they would like to change places.</span>
                                        <span class="scoopable">Tory thinks the city</span>
                                        <span class="scoopable">is so much fun.</span>
                                        <span class="scoopable">She loves riding the <strong>subway</strong></span>
                                        <span class="scoopable">and <strong>playing</strong> with the kids </span>
                                        <span class="scoopable">from <strong>Carmel’s</strong> apartment complex.</span>
                                        <span class="scoopable">There are so many people there</span>
                                        <span class="scoopable">that a kid can never get lonely.</span>
                                        <span class="scoopable">There is always a game of jacks,</span>
                                        <span class="scoopable">or jump rope,</span>
                                        <span class="scoopable">or basketball</span>
                                        <span class="scoopable">going on.</span>
                                        <span class="scoopable"><strong>Tory’s</strong> farm</span>
                                        <span class="scoopable">is quite far from any of her friends</span>
                                        <span class="scoopable">so she is often <strong>bored.</strong></span>
                                        <span class="scoopable">She also gets <strong>tired</strong> of all her chores.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">Carmel thinks Tory is crazy</span>
                                        <span class="scoopable">to want to move <strong>away</strong></span>
                                        <span class="scoopable">from the farm.</span>
                                        <span class="scoopable">She thinks the farm</span>
                                        <span class="scoopable">is the best place to be.</span>
                                        <span class="scoopable">She loves the big,</span>
                                        <span class="scoopable">grassy yard</span>
                                        <span class="scoopable">and the barn full of animals.</span>
                                        <span class="scoopable">She likes</span>
                                        <span class="scoopable">to chase the chickens</span>
                                        <span class="scoopable">and help milk the <strong>cows</strong>.</span>
                                        <span class="scoopable">There aren’t many cars and buses</span>
                                        <span class="scoopable">so it is <strong>nice</strong> and quiet.</span>
                                        <span class="scoopable">She has been begging</span>
                                        <span class="scoopable">her mom and dad</span>
                                        <span class="scoopable">to visit <strong>more</strong> often.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Mrs. Gomez</span>
                                        <span class="scoopable">said she would call Mrs. Osborn</span>
                                        <span class="scoopable">to set up a time</span>
                                        <span class="scoopable">for the girls to visit.</span>
                                        <span class="scoopable">She did not think</span>
                                        <span class="scoopable">the girls would have much fun</span>
                                        <span class="scoopable">if they did change places</span>
                                        <span class="scoopable"><strong>because</strong> then they would not have <strong>each</strong> other.</span>
                                        <span class="scoopable">Mrs. Osborn</span>
                                        <span class="scoopable">said that she would be happy</span>
                                        <span class="scoopable">to have Carmel</span>
                                        <span class="scoopable">come and visit for a bit.</span>
                                        <span class="scoopable">Then Tory could go back</span>
                                        <span class="scoopable">and <strong>stay</strong> with Carmel</span>
                                        <span class="scoopable">for a while.</span>
                                        <span class="scoopable">Mr. Gomez and Mr. Osborn</span>
                                        <span class="scoopable">said they could share the driving.</span>
                                        <span class="scoopable">Carmel and Tory</span>
                                        <span class="scoopable">think this is the best plan ever!</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 9,
              title: 'The Red Planet',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Mars is a small,</span>
                                        <span class="scoopable">rocky planet.</span>
                                        <span class="scoopable">It is cold and lifeless.</span>
                                        <span class="scoopable">It is one of the <strong>closest</strong> planets</span>
                                        <span class="scoopable">to Earth.</span>
                                        <span class="scoopable">It travels <strong>around</strong> the sun</span>
                                        <span class="scoopable">just like our planet Earth.</span>
                                        <span class="scoopable">It also has volcanos</span>
                                        <span class="scoopable">like Earth,</span>
                                        <span class="scoopable">but the volcanos on Mars</span>
                                        <span class="scoopable">are much bigger. </span>
                                        <span class="scoopable">They can be one hundred times</span>
                                        <span class="scoopable">as large as the volcanos on Earth.</span>
                                        <span class="scoopable">Mars also has very strong winds</span>
                                        <span class="scoopable">and bad dust storms</span>
                                        <span class="scoopable">that can last for months.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable"><strong>Spacecrafts</strong> have been sent to Mars</span>
                                        <span class="scoopable">to <strong>learn more</strong> about it. </span>
                                        <span class="scoopable">The first one landed on Mars in 1976.</span>
                                        <span class="scoopable">A <strong>spacecraft</strong> called the Mars Pathfinder</span>
                                        <span class="scoopable">landed there in 1997.</span>
                                        <span class="scoopable">There were no people on these <strong>spacecrafts</strong>.</span>
                                        <span class="scoopable">Mars is <strong>too</strong> cold</span>
                                        <span class="scoopable">for humans to survive there.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Mars is known as the red planet</span>
                                        <span class="scoopable"><strong>because</strong> of its reddish <strong>color</strong></span>
                                        <span class="scoopable">and pink,</span>
                                        <span class="scoopable">hazy sky.</span>
                                        <span class="scoopable">The Romans were the first to call the planet Mars.</span>
                                        <span class="scoopable">Mars was the name of their god of <strong>War</strong>.</span>
                                        <span class="scoopable">Why do you think the Romans chose that name?</span>
                                    </p>
                               </div>
                            `,
            },
            {
              storyId: 10,
              title: 'Cursive Letters',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Caitlin was in the third grade.</span>
                                        <span class="scoopable">She was in a <strong>new school</strong>.</span>
                                        <span class="scoopable">She was happy</span>
                                        <span class="scoopable">with the change.</span>
                                        <span class="scoopable">The kids in her class</span>
                                        <span class="scoopable">were lots of fun. </span>
                                        <span class="scoopable">She had made friends</span>
                                        <span class="scoopable">by the end of the first day.</span>
                                        <span class="scoopable">The next day,</span>
                                        <span class="scoopable">Mrs. Clark said they were going to learn</span>
                                        <span class="scoopable">to write in cursive.</span>
                                        <span class="scoopable">“Oh, no”,</span>
                                        <span class="scoopable">thought Caitlin,</span>
                                        <span class="scoopable">“I like the way I print.</span>
                                        <span class="scoopable">I do not want to start making my letters a different way.</span>
                                        <span class="scoopable">I am afraid my papers will be messy.</span>
                                        <span class="scoopable">Mrs. Clark may not like messy papers.</span>
                                        <span class="scoopable"> Maybe she will think</span>
                                        <span class="scoopable">that I am not a smart girl.”</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Caitlin</span>
                                        <span class="scoopable">remembered the way Mrs. Smith</span>
                                        <span class="scoopable">had helped her learn to print her letters</span>
                                        <span class="scoopable">when she was in first grade.</span>
                                        <span class="scoopable">There was a large grid</span>
                                        <span class="scoopable">on the wall of the class.</span>
                                        <span class="scoopable">The grid had lines on it</span>
                                        <span class="scoopable">to help the children learn to form the letters.</span>
                                        <span class="scoopable">The lines had names</span>
                                        <span class="scoopable">like the sky line,</span>
                                        <span class="scoopable">the grass line</span>
                                        <span class="scoopable">and the plane line.</span>
                                        <span class="scoopable">Mrs. Smith</span>
                                        <span class="scoopable">used the lines to help kids learn</span>
                                        <span class="scoopable">to make <strong>each</strong> letter.</span>
                                        <span class="scoopable">She just told the kids</span>
                                        <span class="scoopable">to start at the sky line</span>
                                        <span class="scoopable">or the plane line</span>
                                        <span class="scoopable">and how to move their hands</span>
                                        <span class="scoopable">to the right places.</span>
                                        <span class="scoopable">Then,</span>
                                        <span class="scoopable">she told them all the steps</span>
                                        <span class="scoopable">for all of the letters.</span>
                                        <span class="scoopable">It was not hard</span>
                                        <span class="scoopable">to learn to print at all.</span>
                                        <span class="scoopable">Caitlin was starting to think</span>
                                        <span class="scoopable">she would like to return to first grade</span>
                                        <span class="scoopable">and be back in Mrs. <strong>Smith’s</strong> class.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">The next day,</span>
                                        <span class="scoopable">before class,</span>
                                        <span class="scoopable">Caitlin asked Mrs. Clark if she had to start cursive.</span>
                                        <span class="scoopable">She told her</span>
                                        <span class="scoopable">that her printing was great</span>
                                        <span class="scoopable">and that she was happy to print</span>
                                        <span class="scoopable">for the rest of her life.</span>
                                        <span class="scoopable">Mrs. Clark told her</span>
                                        <span class="scoopable">that all the kids had to begin cursive.</span>
                                        <span class="scoopable">She said it would not be hard</span>
                                        <span class="scoopable">at all.</span>
                                        <span class="scoopable">Caitlin said okay,</span>
                                        <span class="scoopable">but did not think it would be any fun.</span>
                                        <span class="scoopable">After lunch,</span>
                                        <span class="scoopable">Mrs. Clark said</span>
                                        <span class="scoopable">that it was time to begin cursive.</span>
                                        <span class="scoopable">Caitlin started to sink behind her desk.</span>
                                        <span class="scoopable">Then,</span>
                                        <span class="scoopable">Mrs. Clark hung some paper</span>
                                        <span class="scoopable">on the wall.</span>
                                        <span class="scoopable">When Caitlin saw the paper,</span>
                                        <span class="scoopable">she began to smile.</span>
                                        <span class="scoopable">It was the same grid</span>
                                        <span class="scoopable">Mrs. Smith had used</span>
                                        <span class="scoopable">when she was in first grade.</span>
                                        <span class="scoopable">“Stand up class”,</span>
                                        <span class="scoopable">said Mrs. Clark.</span>
                                        <span class="scoopable">“Make your arms stiff</span>
                                        <span class="scoopable">and <strong>point</strong> to the grass line.”</span>
                                        <span class="scoopable">Caitlin was very happy.</span>
                                        <span class="scoopable">Learning cursive would be a snap this way.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 11,
              title: 'Winter Daydream',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">It was a hot,</span>
                                        <span class="scoopable">steamy day.</span>
                                        <span class="scoopable">School was out</span>
                                        <span class="scoopable">for the summer.</span>
                                        <span class="scoopable">Most of the kids</span>
                                        <span class="scoopable">were thrilled,</span>
                                        <span class="scoopable">but Lee was not.</span>
                                        <span class="scoopable">He did not care</span>
                                        <span class="scoopable">for the heat.</span>
                                        <span class="scoopable">He did like to go for a swim</span>
                                        <span class="scoopable">with his friends,</span>
                                        <span class="scoopable">but he really missed the winter months.</span>
                                        <span class="scoopable">In the winter,</span>
                                        <span class="scoopable">Lee got to play hockey.</span>
                                        <span class="scoopable">He could skate all weekend</span>
                                        <span class="scoopable">and after school during the week.</span>
                                        <span class="scoopable">He had to be at the rink by five</span>
                                        <span class="scoopable">in the morning,</span>
                                        <span class="scoopable">but he did not mind at all.</span>
                                        <span class="scoopable">His dad drove him to the rink</span>
                                        <span class="scoopable">and they would eat donuts</span>
                                        <span class="scoopable">and have hot drinks on the way there.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Lee began to daydream</span>
                                        <span class="scoopable">about last season.</span>
                                        <span class="scoopable">His team had had a great year.</span>
                                        <span class="scoopable">They played well together</span>
                                        <span class="scoopable">and worked hard.</span>
                                        <span class="scoopable">They won most of their games</span>
                                        <span class="scoopable">and made the play-offs.</span>
                                        <span class="scoopable">Lee and his friend Pete</span>
                                        <span class="scoopable">were the key players on the team.</span>
                                        <span class="scoopable">They could both skate quite fast.</span>
                                        <span class="scoopable">It seemed that each time</span>
                                        <span class="scoopable">Lee passed the puck</span>
                                        <span class="scoopable">to Pete,</span>
                                        <span class="scoopable">Pete shot it in the net.</span>
                                        <span class="scoopable">One of the play-off games</span>
                                        <span class="scoopable">went into three overtimes.</span>
                                        <span class="scoopable">The next team to score</span>
                                        <span class="scoopable">would win the game.</span>
                                        <span class="scoopable">All the players felt weak.</span>
                                        <span class="scoopable">It is not easy to skate fast</span>
                                        <span class="scoopable">for a long time.</span>
                                        <span class="scoopable">Lee’s team</span>
                                        <span class="scoopable">was short one player</span>
                                        <span class="scoopable">because Pete was in the penalty box.</span>
                                        <span class="scoopable">It seemed like the other team</span>
                                        <span class="scoopable">was going to win the game.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">Lee stole the puck</span>
                                        <span class="scoopable">from the other team. </span>
                                        <span class="scoopable">He began to skate</span>
                                        <span class="scoopable">and picked up speed.</span>
                                        <span class="scoopable">He kept his eyes on the net.</span>
                                        <span class="scoopable">He snuck past three players</span>
                                        <span class="scoopable">from the other team.</span>
                                        <span class="scoopable">He shot the puck at the net</span>
                                        <span class="scoopable">and <strong>saw</strong> it fly into the top corner.</span>
                                        <span class="scoopable">Lee’s team won the big game.</span>
                                        <span class="scoopable">Lee jumped into the air</span>
                                        <span class="scoopable">and landed on the ice.</span>
                                        <span class="scoopable">All of his teammates leaped on top of him.</span>
                                        <span class="scoopable">Lee would never forget this game</span>-
                                        <span class="scoopable">not even on hot, steamy summer days.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 12,
              title: 'Surprise',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Mr. and Mrs. Carlo</span>
                                        <span class="scoopable">wanted to have a surprise birthday party</span>
                                        <span class="scoopable">for their <strong>daughter</strong> Carly.</span>
                                        <span class="scoopable">She was turning</span>
                                        <span class="scoopable">seven years old.</span>
                                        <span class="scoopable">It would be easy to plan it</span>
                                        <span class="scoopable">while she was away</span>
                                        <span class="scoopable">on a week-long trip</span>
                                        <span class="scoopable">with her brother,</span>
                                        <span class="scoopable">Lee,</span>
                                        <span class="scoopable">and her grandparents.</span>
                                        <span class="scoopable"><strong>Carly’s</strong> older brother,</span>
                                        <span class="scoopable">Jay,</span>
                                        <span class="scoopable">said he would enjoy</span>
                                        <span class="scoopable">helping to prepare for the party.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">First,</span>
                                        <span class="scoopable">they sent notes in the mail</span>
                                        <span class="scoopable">to ask friends and family</span>
                                        <span class="scoopable">to join them.</span>
                                        <span class="scoopable">Jay helped</span>
                                        <span class="scoopable">to get these</span>
                                        <span class="scoopable">into the mail.</span>
                                        <span class="scoopable">He then gave Dad</span>
                                        <span class="scoopable">a hand setting up a tent</span>
                                        <span class="scoopable">so that rain could not spoil the party.</span>
                                        <span class="scoopable">The next step</span>
                                        <span class="scoopable">was to get snacks</span>
                                        <span class="scoopable">for the party.</span>
                                        <span class="scoopable">They went to the store</span>
                                        <span class="scoopable">and got plenty of hotdogs,</span>
                                        <span class="scoopable">hamburgers</span>
                                        <span class="scoopable">and tonic.</span>
                                        <span class="scoopable">They also picked up some chips and dip.</span>
                                        <span class="scoopable">They knew that kids</span>
                                        <span class="scoopable">could eat quite a bit.</span>
                                        <span class="scoopable">Mom ordered the cake</span>
                                        <span class="scoopable">from a bake shop</span>
                                        <span class="scoopable">that makes cakes</span>
                                        <span class="scoopable">that are rich and moist.</span>
                                        <span class="scoopable">She chose one</span>
                                        <span class="scoopable">with whipped cream frosting.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">Mom checked</span>
                                        <span class="scoopable">with the other people</span>
                                        <span class="scoopable">in the apartment complex</span>
                                        <span class="scoopable">to see if they would mind</span>
                                        <span class="scoopable">a little <strong>noise</strong>.</span>
                                        <span class="scoopable">She told them</span>
                                        <span class="scoopable">the party would last until nine.</span>
                                        <span class="scoopable">All of the people</span>
                                        <span class="scoopable">said it was just fine.</span>
                                        <span class="scoopable">They did not mind</span>
                                        <span class="scoopable">a bit of <strong>noise</strong></span>
                                        <span class="scoopable">and said they hoped the kids</span>
                                        <span class="scoopable">would enjoy themselves.</span>
                                        <span class="scoopable">They knew Carly</span>
                                        <span class="scoopable">and her friends</span>
                                        <span class="scoopable">were not the kind of kids</span>
                                        <span class="scoopable">who would destroy things</span>
                                        <span class="scoopable">or run <strong>around</strong> in the halls.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">The only problem left</span>
                                        <span class="scoopable">was planning an activity</span>
                                        <span class="scoopable">for the kids’ enjoyment.</span>
                                        <span class="scoopable">They had some games</span>
                                        <span class="scoopable">the kids could play</span>
                                        <span class="scoopable">and they got some videos</span>
                                        <span class="scoopable">and CDs.</span>
                                        <span class="scoopable">The problem was</span>
                                        <span class="scoopable">that it was likely</span>
                                        <span class="scoopable">to be quite hot and humid</span>
                                        <span class="scoopable">and there was nowhere to swim.</span>
                                        <span class="scoopable">At last,</span>
                                        <span class="scoopable">Mr. Carlo came up with a super <strong>idea</strong></span> -
                                        <span class="scoopable">squirt guns!</span>
                                        <span class="scoopable">The kids would have a blast</span>
                                        <span class="scoopable">squirting each other</span>
                                        <span class="scoopable">and getting wet!</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 13,
              title: 'Kelly\'s Speed Quiz',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Kelly was studying</span>
                                        <span class="scoopable">her math facts.</span>
                                        <span class="scoopable">She was good</span>
                                        <span class="scoopable">at most things in <strong>school</strong></span>
                                        <span class="scoopable">but she was having a hard time</span>
                                        <span class="scoopable">remembering all these math facts.</span>
                                        <span class="scoopable">Her teacher,</span>
                                        <span class="scoopable">Mrs. Hurley,</span>
                                        <span class="scoopable">had said that they would have a speed quiz</span>
                                        <span class="scoopable">the next day.</span>
                                        <span class="scoopable">Kelly wanted to do well</span>
                                        <span class="scoopable">on the quiz</span>
                                        <span class="scoopable">so she kept working with her flashcards.</span>
                                        <span class="scoopable">She was trying to recall the answer</span>
                                        <span class="scoopable">to six times nine</span>
                                        <span class="scoopable">when she saw the first snowflake.</span>
                                        <span class="scoopable">“Mom,”</span>
                                        <span class="scoopable">she yelled,</span>
                                        <span class="scoopable">“Did you know that it is snowing out?”</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">“Yes,</span>
                                        <span class="scoopable">her mother answered,</span>
                                        <span class="scoopable">“The man on TV said</span>
                                        <span class="scoopable">there will be a big snowstorm.</span>
                                        <span class="scoopable">We could get fifteen</span>
                                        <span class="scoopable">or sixteen inches of snow</span>
                                        <span class="scoopable">before morning!</span>
                                        <span class="scoopable">I am a bit upset</span>
                                        <span class="scoopable">because I wanted to go</span>
                                        <span class="scoopable">to the mall later today.”</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Kelly went over to the window.</span>
                                        <span class="scoopable">It was starting to snow harder</span>
                                        <span class="scoopable">and the wind was blowing the snow</span>
                                        <span class="scoopable">across the yard.</span>
                                        <span class="scoopable">She was trying to make up her mind.</span>
                                        <span class="scoopable">Should she finish her homework</span>
                                        <span class="scoopable">or just wait and finish it the next day?</span>
                                        <span class="scoopable">She was quite sure</span>
                                        <span class="scoopable">it would be a snow day.</span>
                                        <span class="scoopable">Just then,</span>
                                        <span class="scoopable"><strong>Kelly’s</strong> father and her sisters,</span>
                                        <span class="scoopable">Katy and Kristin,</span>
                                        <span class="scoopable">came in the house.</span>
                                        <span class="scoopable">“BRRRRR!”</span>
                                        <span class="scoopable">he said.</span>
                                        <span class="scoopable">“It is freezing out.</span>
                                        <span class="scoopable">The roads are getting very slippery</span>
                                        <span class="scoopable">from all the snow.</span>
                                        <span class="scoopable">I think a lot of people will be calling</span>
                                        <span class="scoopable">to ask me to clean their driveways</span>
                                        <span class="scoopable">in the morning.”</span>
                                        <span class="scoopable"><strong>Kelly’s</strong> dad had a big truck</span>
                                        <span class="scoopable">that he used to clean the snow from driveways</span>
                                        <span class="scoopable">and parking lots.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">Kelly got on her winter</span>
                                        <span class="scoopable">coat and put on her hat</span>
                                        <span class="scoopable">and mittens.</span>
                                        <span class="scoopable">“Where are you going,</span>
                                        <span class="scoopable">Kelly?”</span>
                                        <span class="scoopable">asked her father.</span>
                                        <span class="scoopable">“To play in the snow</span>
                                        <span class="scoopable">before it gets too dark,”</span>
                                        <span class="scoopable">said Kelly.</span>
                                        <span class="scoopable">“I don’t need to do my homework</span>
                                        <span class="scoopable"><strong>because</strong> going to have a snow day!</span>
                                        <span class="scoopable">Now we will not have our speed quiz in math</span>
                                        <span class="scoopable">until Thursday!”</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">“I think you should study</span>
                                        <span class="scoopable">just in case,</span>
                                        <span class="scoopable">Kelly,”</span>
                                        <span class="scoopable">said Katy.</span>
                                        <span class="scoopable">“What if it stops snowing</span>
                                        <span class="scoopable">and there is no snow day?</span>
                                        <span class="scoopable">I made that mistake one time.</span>
                                        <span class="scoopable">I did not finish a book report</span>
                                        <span class="scoopable"><strong>because</strong> I thought there would be a day off,</span>
                                        <span class="scoopable">but the snow stopped</span>
                                        <span class="scoopable">and I had to tell my teacher</span>
                                        <span class="scoopable">that my report was not finished!“</span>
                                        <span class="scoopable">Kristin said,</span>
                                        <span class="scoopable">“Kelly,</span>
                                        <span class="scoopable">I know you want to get a good grade</span>
                                        <span class="scoopable">on that quiz</span>
                                        <span class="scoopable">and I know you want to play in the snow.</span>
                                        <span class="scoopable">Why <strong>don’t</strong> you go play for a while</span>
                                        <span class="scoopable">and then later</span>
                                        <span class="scoopable">Katy and I will help you study your facts?”</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">“Thanks a bunch!</span>
                                        <span class="scoopable">You two are the best sisters</span>
                                        <span class="scoopable">in the world!”</span>
                                        <span class="scoopable">said Kelly with a big smile.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 14,
              title: 'Ross Gets Help',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">When Ross was in second grade</span>
                                        <span class="scoopable">he was having some problems</span>
                                        <span class="scoopable">with reading and spelling.</span>
                                        <span class="scoopable">He was working very hard</span>
                                        <span class="scoopable">and trying to do his best,</span>
                                        <span class="scoopable">but he thought the work</span>
                                        <span class="scoopable">was very hard.</span>
                                        <span class="scoopable">Sometimes he could not finish his papers</span>
                                        <span class="scoopable">or understand how to read or spell</span>
                                        <span class="scoopable">a word.</span>
                                        <span class="scoopable">He felt tired and drowsy</span>
                                        <span class="scoopable">much of the time.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Each day seemed to get harder</span>
                                        <span class="scoopable">and harder.</span>
                                        <span class="scoopable">When his mother asked him</span>
                                        <span class="scoopable">how his day went,</span>
                                        <span class="scoopable">Ross wanted to shout, “Lousy!”</span>
                                        <span class="scoopable">Many days</span>
                                        <span class="scoopable">he came home with a frown.</span>
                                        <span class="scoopable">His mother</span>
                                        <span class="scoopable">wanted to see his big smile again,</span>
                                        <span class="scoopable">so she asked his teacher</span>
                                        <span class="scoopable">what she could do to help.</span>
                                        <span class="scoopable">His teacher thought</span>
                                        <span class="scoopable">that if he could get some more help</span>
                                        <span class="scoopable">after school</span>
                                        <span class="scoopable">the work would not seem so hard.</span>
                                        <span class="scoopable">She knew Ross was a smart kid</span>
                                        <span class="scoopable">and did not want him to get further behind.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Ross’s mother found a teacher</span>
                                        <span class="scoopable">who could help Ross</span>
                                        <span class="scoopable">after school.</span>
                                        <span class="scoopable">Her name was Robin.</span>
                                        <span class="scoopable">Robin and Ross</span>
                                        <span class="scoopable">hit it off right from the start.</span>
                                        <span class="scoopable">Ross would go to her house</span>
                                        <span class="scoopable">three times a week</span>
                                        <span class="scoopable">and work on reading and spelling.</span>
                                        <span class="scoopable">Robin showed Ross</span>
                                        <span class="scoopable">how to tap out the sounds</span>
                                        <span class="scoopable">to make reading and spelling easy!</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">It was not long before Ross</span>
                                        <span class="scoopable">became a whiz at reading</span>
                                        <span class="scoopable">and spelling.</span>
                                        <span class="scoopable">He learned the rules quickly</span>
                                        <span class="scoopable">and knew all of the sounds</span>
                                        <span class="scoopable">of the vowels.</span>
                                        <span class="scoopable">He did not mind working</span>
                                        <span class="scoopable">after school.</span>
                                        <span class="scoopable">He seemed to enjoy it.</span>
                                        <span class="scoopable">He always came with a smile</span>
                                        <span class="scoopable">and worked very hard;</span>
                                        <span class="scoopable">even if he had to miss part of his swim team.</span>
                                        <span class="scoopable">He never pouted</span>
                                        <span class="scoopable">or complained.</span>
                                        <span class="scoopable">He was happy to get the help.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Robin thinks Ross</span>
                                        <span class="scoopable">is an outstanding student</span>
                                        <span class="scoopable">and a great kid.</span>
                                        <span class="scoopable">As a matter of fact,</span>
                                        <span class="scoopable">he is one of the best students</span>
                                        <span class="scoopable">she has ever had.</span>
                                        <span class="scoopable">They have a fine time working together.</span>
                                        <span class="scoopable">Even with all of the hard work Ross does,</span>
                                        <span class="scoopable">they still find time to joke around</span>
                                        <span class="scoopable">and chat about sports.</span>
                                        <span class="scoopable">Before long,</span>
                                        <span class="scoopable">Ross will not need any help.</span>
                                        <span class="scoopable">He will do fine in school</span>
                                        <span class="scoopable">on his own.</span>
                                        <span class="scoopable">Robin will miss seeing Ross,</span>
                                        <span class="scoopable">but is glad that he is doing so well.</span>
                                        <span class="scoopable">She thinks she will always stay in <strong>touch</strong>.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 15,
              title: 'The Rescue Team',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Mr. Stroub</span>
                                        <span class="scoopable">works on a rescue team on the coast.</span>
                                        <span class="scoopable">He has been working there</span>
                                        <span class="scoopable">for years.</span>
                                        <span class="scoopable">He enjoys the work</span>
                                        <span class="scoopable">and likes the other members of the group.</span>
                                        <span class="scoopable">They all know how important their work is.</span>
                                        <span class="scoopable">Back when he was a kid in school,</span>
                                        <span class="scoopable">Mr. Stroub had worked at his town pool.</span>
                                        <span class="scoopable">One day he had to save a child</span>
                                        <span class="scoopable">from drowning.</span>
                                        <span class="scoopable">From that point on,</span>
                                        <span class="scoopable">he was <strong>hooked</strong> on rescue work.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">The crew does not go out on a rescue each day.</span>
                                        <span class="scoopable">Some days the job is <strong>even</strong> a bit dull.</span>
                                        <span class="scoopable">The crew is happy when this happens</span>
                                        <span class="scoopable"><strong>because</strong> it means that all of the boaters</span>
                                        <span class="scoopable">are safe.</span>
                                        <span class="scoopable">However,</span>
                                        <span class="scoopable">if a storm blows in,</span>
                                        <span class="scoopable">the crew knows they will be needed soon.</span>
                                        <span class="scoopable">It is their job</span>
                                        <span class="scoopable">to rescue boats that are stranded</span>
                                        <span class="scoopable">out in the sea</span>
                                        <span class="scoopable">and get everyone back safely to shore.</span>
                                        <span class="scoopable">They also teach classes</span>
                                        <span class="scoopable">on boat safety.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Many people on the coast have boats</span>
                                        <span class="scoopable">and enjoy fishing trips</span>
                                        <span class="scoopable">or just sailing on the clear,</span>
                                        <span class="scoopable">smooth blue waters.</span>
                                        <span class="scoopable">Most of the boaters are very careful.</span>
                                        <span class="scoopable">They remember</span>
                                        <span class="scoopable">to provide a life jacket for each person</span>
                                        <span class="scoopable">on their boat</span>
                                        <span class="scoopable">and they go slowly when there are other boats <strong>around</strong>.</span>
                                        <span class="scoopable">A few boaters are foolish.</span>
                                        <span class="scoopable">They scoot across the water at top speed</span>
                                        <span class="scoopable">and dart <strong>around</strong> the other boats.</span>
                                        <span class="scoopable">They crowd too many people on their boats</span>
                                        <span class="scoopable">and ignore the rules.</span>
                                        <span class="scoopable">They stay out in stormy seas</span>
                                        <span class="scoopable">and pile too much stuff on the boat.</span>
                                    </p>
                                </div>
                                <div class="story-page" id="2" style="visibility: hidden;">
                                    <p>
                                        <span class="scoopable">One cloudy afternoon,</span>
                                        <span class="scoopable">Mr. Stroub told a group</span>
                                        <span class="scoopable">that they should not take their small boat out to sea.</span>
                                        <span class="scoopable">The news was reporting that a large storm</span>
                                        <span class="scoopable">was expected soon.</span>
                                        <span class="scoopable">The driver of the boat</span>
                                        <span class="scoopable">began to argue with Mr. Stroub.</span>
                                        <span class="scoopable">Mr. Stroub was trying to explain</span>
                                        <span class="scoopable">that the strong winds would make it hard to get back in,</span>
                                        <span class="scoopable">when the boat took off</span>
                                        <span class="scoopable">and sped out to sea.</span>
                                        <span class="scoopable">Mr. Stroub just shook his head.</span>
                                        <span class="scoopable">He wished people knew</span>
                                        <span class="scoopable">that he was not trying to spoil their fun.</span>
                                        <span class="scoopable">He just wanted to keep them safe.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Shortly after the boat left the dock,</span>
                                        <span class="scoopable">thunderclouds formed in the sky</span>
                                        <span class="scoopable">and the waves began to pound the shore.</span>
                                        <span class="scoopable">Mr. Stroub frowned</span>
                                        <span class="scoopable">and looked out the window.</span>
                                        <span class="scoopable">He was hoping to see the small boat returning</span>
                                        <span class="scoopable">but there were not boats <strong>around</strong>.</span>
                                        <span class="scoopable">He told his crew</span>
                                        <span class="scoopable">that they should prepare for a rescue.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 16,
              title: 'The Sock Mystery',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Do you ever wonder</span>
                                        <span class="scoopable">what happens to your socks</span>
                                        <span class="scoopable">when you put them in the laundry?</span>
                                        <span class="scoopable">It seems to me</span>
                                        <span class="scoopable">that I put many more socks in the laundry</span>
                                        <span class="scoopable">than I get back.</span>
                                        <span class="scoopable">I know</span>
                                        <span class="scoopable">that when I put a pair of dirty socks in the hamper</span>
                                        <span class="scoopable">or laundry basket</span>
                                        <span class="scoopable">that I put both of the socks in together.</span>
                                        <span class="scoopable">But,</span>
                                        <span class="scoopable">when I get my clean laundry back,</span>
                                        <span class="scoopable">very often only one sock returns.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">I am not sure</span>
                                        <span class="scoopable">what causes this to happen.</span>
                                        <span class="scoopable">Perhaps my dryer is haunted.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Maybe there is some kind of a law</span>
                                        <span class="scoopable">that says only one sock</span>
                                        <span class="scoopable">can come back at a time.</span>
                                        <span class="scoopable">Maybe there is something in the dryer</span>
                                        <span class="scoopable">that grinds socks up into sawdust.</span>
                                        <span class="scoopable">All I know</span>
                                        <span class="scoopable">is that I get exhausted</span>
                                        <span class="scoopable">looking all over my house</span>
                                        <span class="scoopable">for my missing socks.</span>
                                        <span class="scoopable">Every time it happens</span>
                                        <span class="scoopable">my mother launches</span>
                                        <span class="scoopable">into a big long story</span>
                                        <span class="scoopable">about how I need to be more careful.</span>
                                        <span class="scoopable">I try to explain that it is not my fault,</span>
                                        <span class="scoopable">but she still gets mad at me.</span>
                                        <span class="scoopable">The funny thing is that many times</span>
                                        <span class="scoopable">the missing sock</span>
                                        <span class="scoopable">is later found back in my dresser <strong>drawer.</strong></span>
                                    </p>
                                    <p>
                                        <span class="scoopable">One day</span>
                                        <span class="scoopable">I was visiting my Aunt Dawn.</span>
                                        <span class="scoopable">I go over there all the time</span>
                                        <span class="scoopable">to play with my cousin Paul.</span>
                                        <span class="scoopable">It was raining so we were drawing</span>
                                        <span class="scoopable">and working on a jigsaw puzzle.</span>
                                        <span class="scoopable">Aunt Dawn was doing the laundry</span>
                                        <span class="scoopable">when I got there.</span>
                                        <span class="scoopable">All of a sudden,</span>
                                        <span class="scoopable">Aunt Dawn began to squawk</span>
                                        <span class="scoopable">at Paul.</span>
                                        <span class="scoopable">She was upset because she could not find all their socks.</span>
                                        <span class="scoopable">The kids said the last time they saw the socks</span>
                                        <span class="scoopable">they were in the laundry.</span>
                                        <span class="scoopable">I sort of crawled out of the way.</span>
                                        <span class="scoopable">There was no point in trying to help them find the socks.</span>
                                        <span class="scoopable">I was just glad that this time</span>
                                        <span class="scoopable">I was sure it was not my fault.</span>
                                    </p>
                                </div>
                            `,
            },
            {
              storyId: 17,
              title: 'Aunts & Uncles',
              text: `
                                <div class="story-page" id="1">
                                    <p>
                                        <span class="scoopable">Do you have an aunt or uncle</span>
                                        <span class="scoopable">who is very special to you?</span>
                                        <span class="scoopable">Sometimes kids have an aunt or uncle who lives close to them</span>
                                        <span class="scoopable">and spends lots of time with them.</span>
                                        <span class="scoopable">Other times</span>
                                        <span class="scoopable">an uncle or aunt</span>
                                        <span class="scoopable">may live far away but is still able to call,</span>
                                        <span class="scoopable">email,</span>
                                        <span class="scoopable">and send letters or gifts</span>
                                        <span class="scoopable">to the children who are special to them.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">I know some kids who think their Aunt Barb and Uncle Ed</span>
                                        <span class="scoopable">are the best aunt and uncle in the world.</span>
                                        <span class="scoopable">Barb and Ed do not have kids of their own,</span>
                                        <span class="scoopable">so they like to spoil the children</span>
                                        <span class="scoopable">of their brothers and sisters.</span>
                                        <span class="scoopable">They have big jobs that keep them quite busy,</span>
                                        <span class="scoopable">but they still make time for the kids.</span>
                                        <span class="scoopable">They even <strong>giggle</strong> at the kids’ silly jokes!</span>
                                        <span class="scoopable">The kids like the fact that Barb and Ed</span>
                                        <span class="scoopable">really <strong>listen</strong> to them.</span>
                                        <span class="scoopable">When the kids were <strong>young</strong></span>
                                        <span class="scoopable">they would never grumble about how much <strong>noise</strong> they made</span>
                                        <span class="scoopable">or complain about the messes they made</span>
                                        <span class="scoopable">when they jumped in puddles.</span>
                                        <span class="scoopable">As a matter of fact,</span>
                                        <span class="scoopable">Ed sometimes jumped in the puddles too!</span>
                                        <span class="scoopable">Spending time with Barb and Ed</span>
                                        <span class="scoopable">is a great treat.</span>
                                        <span class="scoopable">They live on the water</span>
                                        <span class="scoopable">so sometimes Uncle Ed</span>
                                        <span class="scoopable">takes the kids for a boat ride.</span>
                                        <span class="scoopable">Barb plays games like Scrabble with the kids</span>
                                        <span class="scoopable">or settles down with a good fable story</span>
                                        <span class="scoopable">or video with them.</span>
                                        <span class="scoopable">Best of all is playing with Barb and <strong>Ed’s</strong> dog.</span>
                                        <span class="scoopable">The first dog they had was a beagle</span>
                                        <span class="scoopable">named Barney.</span>
                                        <span class="scoopable">He <strong>died</strong> a few years <strong>ago</strong>.</span>
                                        <span class="scoopable">The kids,</span>
                                        <span class="scoopable">and Barb and Ed miss him a lot.</span>
                                        <span class="scoopable">The new <strong>dog’s</strong> name is Jackson.</span>
                                        <span class="scoopable">Jackson is lots of fun</span>
                                        <span class="scoopable">and is always happy to see the kids.</span>
                                    </p>
                                    <p>
                                        <span class="scoopable">Now that the kids are older,</span>
                                        <span class="scoopable">they are busy</span>
                                        <span class="scoopable">and do not visit with Aunt Barb</span>
                                        <span class="scoopable">and Uncle Ed as much.</span>
                                        <span class="scoopable">Barb and Ed</span>
                                        <span class="scoopable">try to stay close to them</span>
                                        <span class="scoopable">and get to see them from time to time.</span>
                                        <span class="scoopable">Aunts and uncles can be very special parts of a family.</span>
                                    </p>
                                </div>
                            `,
            },
          ],
          storyXref: [
            {
              weeks: ['2-1', '2-2'],
              stories: [2],
            },
            {
              weeks: ['3-1'],
              stories: [3],
            },
            {
              weeks: ['4-1', '4-2'],
              stories: [4],
            },
            {
              weeks: ['5-1', '5-2'],
              stories: [5],
            },
            {
              weeks: ['6-1', '6-2'],
              stories: [6],
            },
            {
              weeks: ['7-1', '7-2', '7-3'],
              stories: [7],
            },
            {
              weeks: ['8-1'],
              stories: [8],
            },
            {
              weeks: ['9-1', '9-2'],
              stories: [9],
            },
            {
              weeks: ['10-1', '10-2'],
              stories: [10],
            },
            {
              weeks: ['11-1', '11-2'],
              stories: [11],
            },
            {
              weeks: ['12-1'],
              stories: [12],
            },
            {
              weeks: ['13-1', '13-2'],
              stories: [13],
            },
            {
              weeks: ['14-1', '14-2'],
              stories: [14],
            },
            {
              weeks: ['15-1', '15-2'],
              stories: [15],
            },
            {
              weeks: ['16-1'],
              stories: [16],
            },
            {
              weeks: ['17-1', '17-2'],
              stories: [17],
            },
          ],
        },
      },
    ],
  },
};
