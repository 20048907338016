import Vuex from 'vuex';
import Vue from 'vue';

// find and import all modules. No need to list them here.
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    ...modules,
  },
});
