<template>
  <div id="positioner" class="lsc drillsounds">
    <ActivityHeader
      activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
      activity-name="Large Sound Cards"
      :back-button-title="backButtonTitle"
      back-button-route="choosedrillsounds"
    />
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner">
        <!-- <transition name="cardfade" appear> -->
          <div class="large-card-container" id="cards-container">
            <LargeSoundCard
              v-for="card in allCards"
              :key="card.cardId"
              :card="card"
              :isSelected="selectedLargeCards.includes(card.cardId)"
              :class="card.color + ' large-card' "
              :isSelectable="selectionMode"
              @cardClicked="cardClicked"
              :isNewCard="newCards.includes(card.cardId)"
              :showNewCards="showNewCards"
              :programId="programId"
              data-automationId="large_sound_cards_text"
            ></LargeSoundCard>
          </div>
        <!-- </transition> -->
      </div>
    </div>
    <div id="large-card-buttons" v-bind:class="{ hidden : maxCardsMessage }">
      <!-- intentionally left commented - not sure if this will be needed eventually -->
      <!-- <button v-if="newCards.length > 0" @click="toggleShowNew">
        <span class='star new-star'></span>New Sounds
      </button> -->
      <button v-if="selectedLargeCards.length > 0" @click="showModal">
        <span class='star selected-star' data-automationId="large_sound_cards_buttons"></span>Show as Flash Cards
      </button>
      <div class="explainer-labels" v-if="selectionMode && selectedLargeCards.length == 0">
        <span class="tap-instruction explainer-label" v-bind:class="{ active: showTapLabel }"><span>tap cards to select for review</span></span>
        <span v-if="weekId !== '0'" class="challenge-instruction explainer-label" v-bind:class="{ active: showChallengesLabel }"><span>Cards marked with "</span><div class="star new-star"></div><span>" are challenges for this lesson</span></span>
      </div>
      <button v-if="selectionMode && selectedLargeCards.length > 0" @click="resetSelection">Reset Selection</button>
    </div>
    <PrevNextButtons v-if="(programId !== 'funk' && programId !== 'fun1')" activity="LC"></PrevNextButtons>
    <modal-dialog v-show="isModalVisible" @close="closeModal" class="transparent-modal-overlay">
      <template v-slot:body>
        <div id="carousel-wrapper" class="trickwords-carousel">
          <carousel-3d
            ref="carousel"
            :controls-visible="true"
            :count="selectedLargeCards.length"
            :space="100"
            :perspective="0"
            :clickable="false"
          >
            <slide v-for="(card, i) in cardsForModal" :index="i" :key="i">
              <div :class="card.color + ' in-carousel large-card'" v-bind:aria-label="accessibleLabel(card)">
                <span class="lc-letter" data-automationId="large_sound_cards_selected" aria-hidden="true" v-html="card.label"></span>
                <img class="lc-img" v-bind:alt="card.keyword" :src="getImagePath(card.image)" />
                <span class="lc-sound" v-html="card.lks"></span>
                <span class="counter">{{ i + 1 }} of {{ selectedLargeCards.length }}</span>
              </div>
            </slide>
          </carousel-3d>
        </div>
      </template>
    </modal-dialog>
    <div class="MCM-wrapper">
      <span v-bind:class="{ shown : maxCardsMessage }" class="maxCardsMessage slideOutMessage">Try to keep it to a few new and/or challenging sounds, as you'll use the Standard Sound Cards to review sounds as well</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import LargeSoundCard from '@/components/LargeSoundCard.vue';
import cardSizer from '@/mixins/cardsizer';
import ModalDialog from '@/components/ModalDialog.vue';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'LargeSoundCards',
  components: { PrevNextButtons, ActivityHeader, LargeSoundCard, ModalDialog, Carousel3d, Slide },
  mixins: [cardSizer],

  data() {
    const isModalVisible = false;
    const selectionMode = false;
    const allCards = [];
    const newCards = [];
    const showNewCards = true;
    const showTapLabel = true;
    const showChallengesLabel = false;
    const maxCardsMessage = false;
    const initialResize = 0;
    const backButtonTitle = '';
    return { isModalVisible, selectionMode, allCards, newCards, showNewCards, showTapLabel, showChallengesLabel, maxCardsMessage, initialResize, backButtonTitle };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  created() {
    setTimeout(() => {
      this.toggleExplainerLabels();
    }, 5000);

  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  mounted() {
    this.getCardsForDisplay();
    this.clearSelectedLargeCards();
    this.selectionMode = true;
    window.addEventListener('resize', this.resizeMethods);
    this.backButtonTitle = this.weekId === '0' ? 'Back' : 'Drill Sounds';
    setTimeout(() => {
      this.resizeMethods();
    });

  },

  updated() {
    this.initialResizeTest();
  },
  methods: {
    accessibleLabel(card) {
      return card.label.match(/.{1,1}/g).join('-');
    },
    toggleExplainerLabels() {
      this.showTapLabel = false;
      this.showChallengesLabel = true;
    },
    ...mapActions('appState', ['setSelectedLargeCards', 'addSelectedLargeCard', 'removeSelectedLargeCard', 'clearSelectedLargeCards']),

    getCardsForDisplay() {
      const cards = this.cardData.find((c) => c.program === ((this.programId === 'funk' || this.programId === 'fun1') ? 'fun2' : this.programId));
      let dayNumber = 0;
      if (this.programId !== 'fun1') {
        dayNumber = this.dayNumber(this.programId, this.unitId, this.weekId, this.dayId, false);
      } else {
        dayNumber = this.dayNumber('fun2', '6', '1', '2', false);
      }
      if (this.programId !== 'fun1') {
        this.newCards = cards.cards.filter((c) => dayNumber > 1 && c.include === dayNumber).map((c) => c.cardId);
      }
      this.allCards = cards.cards.filter((c) => c.include <= dayNumber);
      if (this.programId === 'fun1') {
        // remove cards specific for level 1
        this.allCards = this.allCards.filter((c) => !(c.cardId >= 49 && c.cardId <= 53));
      }
    },

    cardClicked(card) {
      if (this.selectionMode) {
        const didSelectedCard = this.selectedLargeCards.find((c) => c === card.cardId);
        if (!didSelectedCard) {
          if (this.selectedLargeCards.length < 10) {
            this.maxCardsMessage = false;
            this.addSelectedLargeCard({ cardId: card.cardId });
          } else {
            this.maxCardsMessage = true;
            setTimeout(() => {
              this.maxCardsMessage = false;
            }, 5000);
          }
        } else {
          this.removeSelectedLargeCard({ cardId: card.cardId });
        }
      }
    },

    resizeFonts() {
      const cardWidth = document.getElementsByClassName('large-card')[0].clientWidth;
      const x = document.getElementsByClassName('letter');
      for (let i = 0; i < x.length; i += 1) {
        x[i].setAttribute('style', `font-size: ${cardWidth * 0.3}px`);
      }
    },
    resetSelection() {
      this.clearSelectedLargeCards();
    },
    toggleShowNew() {
      this.showNewCards = !this.showNewCards;
    },
    showModal() {
      this.isModalVisible = true;
      this.$refs.carousel.goSlide(0);
      this.hideTrayButtons();
      // this.resizeCarouselFonts();
    },
    hideTrayButtons() {
      document.getElementById('large-card-buttons').classList.add('hide-buttons');
    },
    showTrayButtons() {
      document.getElementById('large-card-buttons').classList.remove('hide-buttons');
    },
    closeModal() {
      this.isModalVisible = false;
      this.showTrayButtons();
    },
    getImagePath(image) {
      return `/images${image}.svg`;
    },
    resizeMethods() {
      this.resizeCards();
      this.resizeFonts();
    },
    initialResizeTest() {
      if (this.initialResize === 0) {
        this.resizeMethods();
      }
      this.initialResize = 1;
    },
    toggleCardSelection() {
      if (!this.selectionMode) {
        // turn selection mode on
        this.clearSelectedLargeCards();
      }
      this.selectionMode = !this.selectionMode;
    },
  },
  computed: {
    ...mapState('largeSoundCards', ['cardData']),
    ...mapGetters('dataStore', ['weekNumber', 'dayNumber']),
    ...mapGetters('appState', ['selectedLargeCards']),
    selectButtonLabel() {
      return this.selectionMode ? 'Done' : 'Select Cards';
    },
    cardsForModal() {
      if (!this.allCards || this.allCards.length === 0) {
        return [];
      }
      const s = this.selectedLargeCards.map((c) => this.allCards.find((f) => f.cardId === c));
      return s;
    },
  },
};
</script>

<style scoped lang="scss">
  // #card-positioner{
  //   // bottom: 5%;
  // }
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0);
  }
  .large-card-container {
    display: grid;
    grid-gap: 1rem 1rem;
    // align-items: center;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
  }

  .large-card-container > * { align-self: center }

  #large-card-buttons {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow:hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding-bottom: 1rem;
    width: 100%;
    opacity: 1;
    transition: all 1s ease-in-out;
    z-index: 1;

    &.hidden{
      opacity: .1;
    }
    button {
      margin: .5rem;
      padding-left: 3rem;
      padding-right: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .5s ease-in-out;

      .star{
        position: relative;
        margin: 0 .4rem .4rem 0;
      }
    }
    &.hide-buttons{
      button{
        transform:translateY(10rem);
      }
    }
  }
  .lc-img {
    width: 100px;
    height: 100px;
  }
  .MCM-wrapper{
    position: absolute;
    display:grid;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    align-content: flex-end;
    z-index: 1;

    .maxCardsMessage{
      position: relative;
      margin: 0 auto;
      max-width: 25%;
      transform: translateY(100%);
    }

  }

  @media (max-width: 900px) {
    #large-card-buttons {
      margin-left: $activity-frame-lateral-padding;
    }
  }
</style>
<style lang="scss">
  .lsc {
    .modal {
      #carousel-wrapper {
        .carousel-3d-container {
          width: 50% !important;
          .carousel-3d-slider{
            height: 100% !important;
            .carousel-3d-slide {
              .large-card {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                border-radius: 5px;
                box-shadow: 4px 3px 7px rgba(0, 0, 0, 0.5);

                .lc-letter {
                  font-family: wmanuscript;
                  font-size: 700%;
                  line-height: 1;
                  text-align: center;
                  z-index: 1;
                  margin-top: auto;
                }
                .lc-img {
                  width: 100%;
                  max-width: 80%;
                  -webkit-box-flex: 0;
                  flex: 0 0 30%;
                  margin: auto;
                }
                .lc-sound {
                  font-family: wbookman;
                  font-size: 120%;
                  margin: auto;
                }
                .counter {
                  color: black;
                  font-style: italic;
                  text-transform: unset;
                  font-family: open sans;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
  .explainer-labels{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    height: 30px;
    width: 100%;
    .explainer-label{
      transition: all 2s ease-in-out;
      display: inline-block;
      &.tap-instruction{
        background-image: url('/images/pointer.svg');
        background-size: 30px;
        background-position: center left;
        background-repeat: no-repeat;
        padding: 1rem 0 1rem 4rem;
        transform: translateY(-100%);
        opacity: 0;
        &.active{
          transform: translateY(0);
          opacity: 1;
        }
      }
      &.challenge-instruction{
          transform: translateY(0);
          opacity: 0;
          margin: 0 auto;
        .star{
          position: relative;
          display: inline-block;
          margin-bottom: 4px;
          margin-left: 4px;
          margin-right: -4px;
        }
        &.active{
          transform: translateY(-100%);
          opacity: 1;
        }
      }
    }
  }
</style>
