<template>
  <div id="positioner">
    <ActivityHeader
      activity-name="Basic Keywords"
      activity-type="Reference Posters"
      back-button-title="Posters"
      back-button-route="chooseposters"
      :show-nav-buttons="false"
    />
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner" data-automationId="reference_posters_basic_keywords_with_img">
        <div id="cards-container" class="basic-keywords-grid">
              <div class="large-card" id="a">
                <span class="sc-letter">Aa</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/apple.svg" alt="apple" /></div>
                <span class="lks">a - apple - /ă/</span>
              </div>
              <div class="large-card" id="b">
                <span class="sc-letter">Bb</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/bat.svg" alt="bat" /></div>
                <span class="lks">b - bat - /b/</span>
              </div>
              <div class="large-card" id="c">
                <span class="sc-letter">Cc</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/cat.svg" alt="cat" /></div>
                <span class="lks">c - cat - /c/</span>
              </div>
              <div class="large-card" id="d">
                <span class="sc-letter">Dd</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/dog.svg" alt="dog" /></div>
                <span class="lks">d - dog - /d/</span>
              </div>
              <div class="large-card" id="e">
                <span class="sc-letter">Ee</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/ed.svg" alt="ed" /></div>
                <span class="lks">e - ed - /ĕ/</span>
              </div>
              <div class="large-card" id="f">
                <span class="sc-letter">Ff</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/fun.svg" alt="fun" /></div>
                <span class="lks">f - fun - /f/</span>
              </div>
              <div class="large-card" id="g">
                <span class="sc-letter">Gg</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/game.svg" alt="game" /></div>
                <span class="lks">g - game - /g/</span>
              </div>
              <div class="large-card" id="h">
                <span class="sc-letter">Hh</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/hat.svg" alt="hat" /></div>
                <span class="lks">h - hat - /h/</span>
              </div>
              <div class="large-card" id="i">
                <span class="sc-letter">Ii</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/itch.svg" alt="itch" /></div>
                <span class="lks">i - itch - /ĭ/</span>
              </div>
              <div class="large-card" id="j">
                <span class="sc-letter">Jj</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/jug.svg" alt="jug" /></div>
                <span class="lks">j - jug - /j/</span>
              </div>
              <div class="large-card" id="k">
                <span class="sc-letter">Kk</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/kite.svg" alt="kite" /></div>
                <span class="lks">k - kite -/k/</span>
              </div>
              <div class="large-card" id="l">
                <span class="sc-letter">Ll</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/lamp.svg" alt="lamp" /></div>
                <span class="lks">l - lamp - /l/</span>
              </div>
              <div class="large-card" id="m">
                <span class="sc-letter">Mm</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/man.svg" alt="man" /></div>
                <span class="lks">m - man - /m/</span>
              </div>
              <div class="large-card" id="n">
                <span class="sc-letter">Nn</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/nut.svg" alt="nut" /></div>
                <span class="lks">n - nut -/n/</span>
              </div>
              <div class="large-card" id="o">
                <span class="sc-letter">Oo</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/octopus.svg" alt="octopus" /></div>
                <span class="lks">o - octopus - <span style="white-space: nowrap;">/ŏ/</span></span>
              </div>
              <div class="large-card" id="p">
                <span class="sc-letter">Pp</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/pan.svg" alt="pan" /></div>
                <span class="lks">p - pan - /p/</span>
              </div>
              <div class="large-card" id="qu">
                <span class="sc-letter">
                  QU
                  <br />qu
                </span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/queen.svg" alt="queen" /></div>
                <span class="lks">q - queen - /kw/</span>
              </div>
              <div class="large-card" id="r">
                <span class="sc-letter">Rr</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/rat.svg" alt="rat" /></div>
                <span class="lks">r - rat - /r/</span>
              </div>
              <div class="large-card" id="s">
                <span class="sc-letter">Ss</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/snake.svg" alt="snake" /></div>
                <span class="lks">s - snake - /s/</span>
                <span class="lks">s - bugs - /z/</span>
              </div>
              <div class="large-card" id="t">
                <span class="sc-letter">Tt</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/top.svg" alt="top" /></div>
                <span class="lks">t - top - /t/</span>
              </div>
              <div class="large-card" id="u">
                <span class="sc-letter">Uu</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/up.svg" alt="up" /></div>
                <span class="lks">u - up - /ŭ/</span>
              </div>
              <div class="large-card" id="v">
                <span class="sc-letter">Vv</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/van.svg" alt="van" /></div>
                <span class="lks">v - van - /v/</span>
              </div>
              <div class="large-card" id="w">
                <span class="sc-letter">Ww</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/wind.svg" alt="wind" /></div>
                <span class="lks">w - wind - /w/</span>
              </div>
              <div class="large-card" id="x">
                <span class="sc-letter">Xx</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/fox.svg" alt="fox" /></div>
                <span class="lks">x - fox /ks/</span>
              </div>
              <div class="large-card" id="y">
                <span class="sc-letter">Yy</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/yellow.svg" alt="yellow" /></div>
                <span class="lks">y - yellow - /y/</span>
              </div>
              <div class="large-card" id="z">
                <span class="sc-letter">Zz</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/zebra.svg" alt="zebra" /></div>
                <span class="lks">z - zebra - /z/</span>
              </div>
              <div class="large-card" id="wh">
                <span class="sc-letter">wh</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/whistle.svg" alt="whistle" /></div>
                <span class="lks">wh - whistle - /w/</span>
              </div>
              <div class="large-card" id="ch">
                <span class="sc-letter">ch</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/chin.svg" alt="chin" /></div>
                <span class="lks">ch - chin - /ch/</span>
              </div>
              <div class="large-card" id="sh">
                <span class="sc-letter">sh</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/ship.svg" alt="ship" /></div>
                <span class="lks">sh - ship /sh/</span>
              </div>
              <div class="large-card" id="th">
                <span class="sc-letter">th</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/thumb.svg" alt="thumb" /></div>
                <span class="lks">th - thumb /th/</span>
              </div>
              <div class="large-card" id="ck">
                <span class="sc-letter">ck</span>
                <div class="imgWrapper"><img src="/images/Fun1/illustrations/sock.svg" alt="sock" /></div>
                <span class="lks">ck - sock - /k/</span>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardSizer from '@/mixins/cardsizer';
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'BasicKeywords',
  components: { ActivityHeader },
  mixins: [cardSizer],
  data() {
    const initialResize = 0;
    return { initialResize };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  mounted() {
    this.resizeMethods();
    window.addEventListener('resize', this.resizeMethods);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  methods: {

    resizeFonts() {
      const cardWidth = document.getElementsByClassName('large-card')[0].clientWidth;
      const x = document.getElementsByClassName('sc-letter');
      const l = document.getElementsByClassName('lks');

      for (let i = 0; i < x.length; i += 1) {
        x[i].setAttribute('style', `font-size: ${cardWidth * 0.25}px`);
      }
      for (let i = 0; i < l.length; i += 1) {
        l[i].setAttribute('style', `font-size: ${cardWidth * 0.14}px`);
      }
    },
    initialResizeTest() {
      if (this.initialResize === 0) {
        this.resizeMethods();
      }
      this.initialResize = 1;
    },
    resizeMethods() {
      this.resizeCards();
      this.resizeFonts();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/standardcards';

.basic-keywords-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 1rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    // align-items: center;
    text-align: center;
    max-width: 100%;
    margin-top: 0.5rem;
}

.basic-keywords-grid > * { align-self: center }

.large-card {
    background-repeat: no-repeat;
    font-size: 1.5rem;
    font-family: wManuscript;
    text-align: center;
    cursor: pointer;
    border-radius: 1px;
    box-shadow: rgba(0, 0, 0, 0.33) 1px 1px 2px 0px;
    box-sizing: border-box;
    justify-content: center;
    align-content: center;
    box-shadow: rgba(0, 0, 0, 0.33) 1px 0 1px 1px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    &:after{
          height: 0;
    display: inline-block;
    width: 1px;
    padding-bottom: 130%;
    content: '';
    }

  &>span:first-child {
    font-family: wManuscript;
    font-size: 2rem;
    top: 10%;
    position: absolute;
    width: 100%;
  }
  .lks {
    font-family: wbookman;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 60% 60%;
    font-size: 66%;
    width: 100%;
    text-align: center;
    position: absolute;
    &:nth-of-type(2) {
      bottom: 2%;
    }
    &:nth-of-type(3) {
        bottom: 15%;
    }
  }
  .imgWrapper {
    position: absolute;
    top: 45%;
    height: 30%;
    width: 100%;

    img{
      border-style: none;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
}
#a {
  grid-row: 1;
  grid-column: 1;
  background-color: $red-card;
}
#b {
  grid-row: 1;
  grid-column: 2;
  background-color: $yellow-card;
}
#c {
  grid-row: 1;
  grid-column: 3;
  background-color: $yellow-card;
}
#d {
  grid-row: 1;
  grid-column: 4;
  background-color: $yellow-card;
}
#e {
  grid-row: 1;
  grid-column: 5;
  background-color: $red-card;
}
#f {
  grid-row: 1;
  grid-column: 6;
  background-color: $yellow-card;
}
#g {
  grid-row: 2;
  grid-column: 1;
  background-color: $yellow-card;
}
#h {
  grid-row: 2;
  grid-column: 2;
  background-color: $yellow-card;
}
#i {
  grid-row: 2;
  grid-column: 3;
  background-color: $red-card;
}
#j {
  grid-row: 2;
  grid-column: 4;
  background-color: $yellow-card;
}
#k {
  grid-row: 2;
  grid-column: 5;
  background-color: $yellow-card;
}
#l {
  grid-row: 2;
  grid-column: 6;
  background-color: $yellow-card;
}
#m {
  grid-row: 3;
  grid-column: 1;
  background-color: $yellow-card;
}
#n {
  grid-row: 3;
  grid-column: 2;
  background-color: $yellow-card;
}
#o {
  grid-row: 3;
  grid-column: 3;
  background-color: $red-card;
}
#p {
  grid-row: 3;
  grid-column: 4;
  background-color: $yellow-card;
}
#qu {
  grid-row: 3;
  grid-column: 5;
  background-color: $yellow-card;
  .imgWrapper{
    top: 45%;
  }
  span:first-child {
    line-height: 0.7;
  }
}
#r {
  grid-row: 3;
  grid-column: 6;
  background-color: $yellow-card;
}
#s {
  grid-row: 3;
  grid-column: 7;
  background-color: $yellow-card;
}
#t {
  grid-row: 4;
  grid-column: 1;
  background-color: $yellow-card;
}
#u {
  grid-row: 4;
  grid-column: 2;
  background-color: $red-card;
}
#v {
  grid-row: 4;
  grid-column: 3;
  background-color: $yellow-card;
}
#w {
  grid-row: 4;
  grid-column: 4;
  background-color: $yellow-card;
}
#x {
  grid-row: 4;
  grid-column: 5;
  background-color: $yellow-card;
}
#y {
  grid-row: 4;
  grid-column: 6;
  background-color: $yellow-card;
}
#z {
  grid-row: 4;
  grid-column: 7;
  background-color: $yellow-card;
}
#wh {
  grid-row: 5;
  grid-column: 1;
  background-color: $yellow-card;
}
#ch {
  grid-row: 5;
  grid-column: 2;
  background-color: $yellow-card;
}
#sh {
  grid-row: 5;
  grid-column: 3;
  background-color: $yellow-card;
}
#th {
  grid-row: 5;
  grid-column: 4;
  background-color: $yellow-card;
}
#ck {
  grid-row: 5;
  grid-column: 5;
  background-color: $yellow-card;
}

@media (max-width: 650px) {
  .basic-keywords-grid {
    .large-card {
      img {
        width: 35%;
        left: 32.5%;
      }
    }
  }
}
</style>
