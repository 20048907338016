<template>
  <div id="positioner">
    <ActivityHeader
      activityType="Reference Posters"
      activityName="Vowel Extension"
      back-button-title="Posters"
      :show-nav-buttons="false"
      back-button-route=""
    ></ActivityHeader>
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner" data-automationId="reference_posters_vowel_extension_with_img">
        <img src="/images/vowel-extension-bg.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'SpellingRules',
  components: { ActivityHeader },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },

};
</script>

<style lang="scss" scoped>
  #card-positioner{
    display: flex;
    img{
      flex: 1 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
</style>
