<template>
  <div id="positioner">
        <img alt="error" src="/images/403-error.svg" width="236" height="150" />
        <h1>Unauthorized.</h1>
        <p>Please <a href="https://www.wilsonlanguage.com/contact-us/" rel="noopener noreferrer" target="_BLANK">contact customer support</a> with any questions.</p>

  </div>
</template>

<script>
export default {
  name: 'unauthorizedpage',
};
</script>

<style lang="scss" scoped>
#positioner{
    align-content: center;
    align-items: center;
    justify-content: center;
}
</style>
