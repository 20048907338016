<template>
  <div id="positioner">
    <ActivityHeader activity-type="Reference Posters"
    activity-name="Mark My Words" back-button-title="Posters"
    :show-nav-buttons="false"
    back-button-route="chooseposters" />
    <div id="mmw-grid" data-automationId="reference_posters_mark_my_words_with_img">
      <img src="/images/Fun2/mark-my-words/1.svg" alt />
      <img src="/images/Fun2/mark-my-words/2.svg" alt />
      <img src="/images/Fun2/mark-my-words/3.svg" alt />
      <img src="/images/Fun2/mark-my-words/4.svg" alt />
      <img src="/images/Fun2/mark-my-words/5.svg" alt />
      <img src="/images/Fun2/mark-my-words/6.svg" alt />
      <img src="/images/Fun2/mark-my-words/7.svg" alt />
    </div>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'MarkMyWords',
  components: { ActivityHeader },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
};
</script>

<style lang="scss">
#mmw-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1.5%;
  grid-row-gap: 2%;
  margin: auto;
  width: 100%;

  img {
    width: 100%;
    &:nth-child(1) {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
    }
    &:nth-child(2) {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;
    }
    &:nth-child(3) {
      grid-column: 1 / span 2;
      grid-row: 3 / span 2;
    }
    &:nth-child(4) {
      grid-column: 3 / span 1;
      grid-row: 3 / span 3;
    }
    &:nth-child(5) {
      grid-column: 4 / span 1;
      grid-row: 3 / span 3;
    }
    &:nth-child(6) {
      grid-column: 1 / span 2;
      grid-row: 5 / span 2;
    }
    &:nth-child(7) {
      grid-column: 1 / span 2;
      grid-row: 7 / span 2;
    }
  }
}
</style>
