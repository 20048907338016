<template>

  <div id="positioner">
    <ActivityHeader
      activityType="Reference Posters"
      activityName="Welded Sounds"
      back-button-title="Posters"
      :show-nav-buttons="false"
      back-button-route="chooseposters"
    ></ActivityHeader>
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner" style="display: flex;" data-automationId="reference_posters_welded_sounds_with_img">
        <div id="cards-container" class="welded-sounds" v-images-loaded:on="imageProgress()">
          <div class="ws-row">
            <div class="welded-sound">
              <div class="concept"><span>all</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/ball.svg" /></div>
              <div class="keyword"><span>ball</span></div>
              <div class="sound"><span>/&#559;l/</span></div>
            </div>

            <div class="welded-sound">
              <div class="concept"><span>am</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/ham.svg" /></div>
              <div class="keyword"><span>ham</span></div>
              <div class="sound"><span>/am/</span></div>
            </div>

            <div class="welded-sound">
              <div class="concept"><span>an</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/fan.svg" /></div>
              <div class="keyword"><span>fan</span></div>
              <div class="sound"><span>/an/</span></div>
            </div>
          </div>
          <div class="ws-row">
            <div class="welded-sound">
              <div class="concept"><span>ang</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/fang.svg" /></div>
              <div class="keyword"><span>fang</span></div>
              <div class="sound"><span>/ang/</span></div>
            </div>

            <div class="welded-sound">
              <div class="concept"><span>ing</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/ring.svg" /></div>
              <div class="keyword"><span>ring</span></div>
              <div class="sound"><span>/ing/</span></div>
            </div>

            <div class="welded-sound">
              <div class="concept"><span>ong</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/song.svg" /></div>
              <div class="keyword"><span>song</span></div>
              <div class="sound"><span>/ong/</span></div>
            </div>
            <div class="welded-sound">
              <div class="concept"><span>ung</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/lung.svg" /></div>
              <div class="keyword"><span>lung</span></div>
              <div class="sound"><span>/ung/</span></div>
            </div>
          </div>
          <div class="ws-row">
            <div class="welded-sound">
              <div class="concept"><span>ank</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/bank.svg" /></div>
              <div class="keyword"><span>bank</span></div>
              <div class="sound"><span>/ank/</span></div>
            </div>
            <div class="welded-sound">
              <div class="concept"><span>ink</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/pink.svg" /></div>
              <div class="keyword"><span>pink</span></div>
              <div class="sound"><span>/ink/</span></div>
            </div>
            <div class="welded-sound">
              <div class="concept"><span>onk</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/honk.svg" /></div>
              <div class="keyword"><span>honk</span></div>
              <div class="sound"><span>/onk/</span></div>
            </div>
            <div class="welded-sound">
              <div class="concept"><span>unk</span></div>
              <div class="keyword-image"><img src="/images/Fun1/illustrations/junk.svg" /></div>
              <div class="keyword"><span>junk</span></div>
              <div class="sound"><span>/unk/</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
ŭ - &ubreve;
ū - &umacr;
ü - &uuml;
ĭ - &#301;
ə - &#601;
ĕ - &#277;
ē - &emacr;
ā - &amacr;
ī - &imacr;
ŏ - &#335;
ō - &omacr;
ȯ - &#559;
*/
import imagesLoaded from 'vue-images-loaded';
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'WeldedSounds',
  components: { ActivityHeader },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  directives: {
    imagesLoaded,
  },
  methods: {
    revealImageWrapper() {
      document.getElementById('card-positioner').classList.add('images-loaded');
    },
    imageProgress() {
      return {
        always: () => {
          this.revealImageWrapper();
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.welded-sounds{
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  margin: auto;
  height: 100%;

  .ws-row{
    display: flex;
    margin: 2% 0;
    flex-basis: 29%;
    flex-grow: 0;
    flex-shrink: 1;
    justify-content: center;

    .welded-sound{
      display: grid;
      grid-template-rows: 1fr 3fr 1fr 1fr;
      grid-template-columns: 1fr;
      flex: 0 0 25%;
      align-items: center;
      padding: 1% 0;
      box-sizing: border-box;
      text-align: center;
      background-image: linear-gradient(0deg, white, $fun-salmon-lt);
      border-top: solid $red-accent 0.2rem;
      border-bottom: solid $red-accent 0.2rem;
      &:first-child{
        border-left: solid $red-accent 0.2rem;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }
      &:last-child{
        border-right: solid $red-accent 0.2rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      &:not(:last-child){
        .concept,
        .keyword-image,
        .keyword,
        .sound {
          @extend %red-tile-border;
        }
      }
      .concept,
      .keyword,
      .sound{
        display: flex;
        height: 100%;
        span {
          font-family: wBookman;
          margin: auto;
        }
      }
      .concept,
      .sound{
        font-size: 2.5rem;
      }
      .keyword{
        font-size: 1.5rem;
      }
      .keyword-image{
        width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
        display: flex;
        box-sizing: border-box;
        img{
          position: absolute;
          max-width: 80%;
          max-height: 80%;
          left: 10%;
          top: 10%;
        }
      }
    }
  }
}
@media (max-height: 825px) {
  .concept,
  .sound{
    span{
      font-size: 1.5rem;
    }
  }
  .keyword{
    span {
      font-size: 1rem;
    }
  }
}
</style>
