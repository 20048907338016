<template>
  <div id="positioner">
    <header class="inner-column">
      <span id="Welcome">Welcome</span>
      <span id="Welcome-subtext">Choose a program to get started</span>
      <img src="/images/Fun2/illustrations/her.svg" alt="her" />
    </header>
    <div id="logo-heros" class="inner-column">
      <div class="funk logo-hero" @click="chooseProgram('funk')" data-automationId="landing-page-tile-funk">
        <div class="inner" aria-label="Fundations Level K">
          <img alt="" class="logo" src="/images/Fundations_Logo_Color_simple.svg" />
          <span class="level-heading">Level<br /><span class="level-number">K</span></span>
        </div>
      </div>
      <div aria-label="Fundations Level 1" class="fun1 logo-hero" @click="chooseProgram('fun1')" data-automationId="landing-page-tile-fun1">
        <div class="inner">
          <img alt="" class="logo" src="/images/Fundations_Logo_Color_simple.svg" />
          <span class="level-heading">Level<br /><span class="level-number">1</span></span>
        </div>
      </div>
      <div aria-label="Fundations Level 2" class="fun2 logo-hero" @click="chooseProgram('fun2')" data-automationId="landing-page-tile-fun2">
        <div class="inner">
          <img alt="" class="logo" src="/images/Fundations_Logo_Color_simple.svg" />
          <span class="level-heading">Level<br /><span class="level-number">2</span></span>
        </div>
      </div>
      <div aria-label="Fundations Level 3" class="fun3 logo-hero" @click="chooseProgram('fun3')" data-automationId="landing-page-tile-fun3">
        <div class="inner">
          <img alt="" class="logo" src="/images/Fundations_Logo_Color_simple.svg" />
          <span class="level-heading">Level<br /><span class="level-number">3</span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'ChooseProgram',
  mounted() {
    // set initial generic style
    this.setCurrentProgram({ program: 'fun' });
  },
  methods: {
    ...mapActions('appState', ['setCurrentProgram']),
    chooseProgram(program) {
      mixpanel.track('Program Changed', {
        'New Program': this.getMixpanelLevel(program),
      });
      this.setCurrentProgram({ program });
      if (program === 'fun2' || program === 'fun3') {
        router.push({ name: 'chooseunit', params: { programId: program } });
      } else {
        router.push({ name: 'chooseactivity', params: { programId: program, unitId: '0', weekId: '0', dayId: '0' } });
      }
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 4%;
  margin-top: 5%;
  @extend %inner-column;

  #Welcome {
    @extend %headings-shared;
    color: $blue;
    font-size: 8rem;
    line-height: 1;
    grid-row: 1;
    grid-column: 1;
  }
  #Welcome-subtext {
    @extend %headings-shared;
    font-size: 4rem;
    grid-row: 2;
    grid-column: 1;
  }
  img {
    grid-row: 1 / span 2;
    grid-column: 2;
    width: 55%;
    min-width: 18rem;
  }
}

#logo-heros {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
  @extend %inner-column;
  .logo-hero {
    cursor: pointer;
    flex: 1 0 48%;
    height: 0;
    padding-bottom: 20%;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @extend %headings-shared;
    color: white;
    margin: 1%;

    .inner {
      position: absolute;
      width: 100%;
      display: flex;
      height: 100%;
    }
    .logo {
      width: 60%;
      margin: auto;
      align-self: center;
      position: relative;
    }
    .level-heading {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      text-align: center;
      font-size: 2rem;
      line-height: 0.8;
    }
    .level-number {
      font-size: 4rem;
    }
    &.mini-logo-hero {
      max-width: 25rem;
      height: 10rem;
    }
    &.funk {
      background-color: $fun-blue;
    }
    &.fun1 {
      background-color: $fun-salmon;
    }
    &.fun2 {
      background-color: $fun-green;
    }
    &.fun3 {
      background-color: $fun-purple;
    }
    &:before {
      content: '';
      width: 100%;
      height: 80%;
      background-color: $white-overlay;
      left: -15%;
      transform: rotateZ(-70deg);
      bottom: 0%;
      position: absolute;
    }
    &:after {
      content: '';
      width: 100%;
      height: 80%;
      background-color: #ffffff33;
      left: 10%;
      transform: rotateZ(-70deg);
      bottom: 0%;
      position: absolute;
    }
  }
}

@media (max-width: 1150px) {
  header {
    #Welcome {
      font-size: 6rem;
    }
    #Welcome-subtext {
      font-size: 4rem;
    }
  }
}
@media (max-width: 1000px) {
  header {
    #Welcome-subtext {
      font-size: 3rem;
    }
  }
}
@media (max-width: 900px) {
  .logo-hero {
    .inner {
      .level-heading {
        font-size: 1rem;
      }
      .level-number {
        font-size: 3rem;
      }
    }
  }
}
@media (max-height: 750px) {
  #logo-heros {
    width: 95vh;
  }
}
</style>
