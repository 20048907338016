// export default {
//   methods: {
//     resizeCards() {
//       const cardsContainer = document.getElementById('cards-container');
//       const cardPositioner = document.getElementById('card-positioner');

//       // If the aspect of the card positioner is wider than the card container,
//       // (therefore causing the card container to overflow because it's width is set to 100%)
//       if (cardPositioner.clientWidth / cardPositioner.clientHeight > cardsContainer.clientWidth / cardsContainer.clientHeight) {
//         // Reduce the width of the cardcontainer by programatically setting the width so that it's height amounts to 100% of the container
//         cardsContainer.style.width = `${cardPositioner.clientHeight * (cardsContainer.clientWidth / cardsContainer.clientHeight)}px`;
//       } else {
//         cardsContainer.removeAttribute('style');
//       }
//     },
//   },
// };

export default {
  methods: {
    resizeCards() {
      const cardsContainer = document.getElementById('cards-container');
      const cardPositioner = document.getElementById('card-positioner');
      const containerBox = cardsContainer.getBoundingClientRect();
      const positionerBox = cardPositioner.getBoundingClientRect();

      const containerHeight = containerBox.height;
      const containerWidth = containerBox.width;

      const positionerHeight = positionerBox.height;
      const positionerWidth = positionerBox.width;

      // If the aspect of the card positioner is wider than the card container,
      // (therefore causing the card container to overflow because it's width is set to 100%)
      if ((positionerWidth / positionerHeight) > (containerWidth / containerHeight)) {

        // Reduce the width of the cardcontainer by programatically setting the width so that it's height amounts to 100% of the container
        cardsContainer.style.width = `${positionerHeight * (containerWidth / containerHeight)}px`;
      } else {
        cardsContainer.removeAttribute('style');
      }
    },
  },
};
