<template>
  <div id="positioner">
    <ActivityHeader
      :activity-type=activityType
      activity-name="Vowel Teams"
      :back-button-title=backButtonTitle
      :show-nav-buttons=showNavButtons
      :back-button-route=backButtonRoute></ActivityHeader>
    <div style="position: relative; flex: 1 0 auto;">

      <div id="card-positioner" data-automationId="reference_posters_vowel_teams_with_img">
        <div id="cards-container" class="vowel-teams" v-images-loaded:on="imageProgress()">
          <div
            v-for="card in getCardsForDisplay"
            :key="card.cardId"
            class="vowel-team"
            :id="card.uniqueLabel ? card.uniqueLabel : card.label"
          >
            <span class="keyword">{{ card.keyword }}</span>
            <span class="letter-team">{{ card.label }}</span>
            <div class="vt-img">
              <img :src="card.image" :alt="card.keyword" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- JOHN: these buttons need to only show if they are in drill sounds (not reference posters) -->

    <PrevNextButtons v-if="showNavButtons" activity="VT"></PrevNextButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import imagesLoaded from 'vue-images-loaded';
import cardSizer from '@/mixins/cardsizer';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'VowelTeams',
  components: { ActivityHeader, PrevNextButtons },
  mixins: [cardSizer],
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },

  data() {
    return {
      prevRoute: null,
      backButtonTitle: '',
      backButtonRoute: '',
      activityType: '',
      showNavButtons: false,
    };
  },

  beforeRouteEnter(to, from, next) {

    next((vm) => {
      vm.prevRoute = from;
      if (from !== null) {
        if (from.name === 'referenceposters' || to.params.unitId === undefined || to.params.unitId === '0') {
          vm.backButtonTitle = 'Posters';
          vm.backButtonRoute = from.name === 'referenceposters' ? 'chooseposters' : '';
          vm.activityType = 'Reference Posters';
          vm.showNavButtons = false;
        } else {
          vm.backButtonTitle = 'Drill Sounds';
          vm.backButtonRoute = 'choosedrillsounds';
          vm.activityType = "<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>";
          vm.showNavButtons = true;
        }
        if (to.params.programId === 'funk' || to.params.programId === 'fun1') {
          vm.showNavButtons = false;
        }
      }
    });
  },

  computed: {
    ...mapState('vowelteams', ['vowelteamsData']),
    ...mapGetters('dataStore', ['weekNumber', 'dayNumber']),

    getCardsForDisplay() {
      const dayNumber = this.prevRoute && this.prevRoute.name === 'referenceposters' ? 365 : this.dayNumber(this.programId, this.unitId, this.weekId, this.dayId, false);
      const cards = this.vowelteamsData.find((c) => c.program === this.programId);
      const vtData = cards.cards.filter((c) => c.include <= dayNumber);
      return vtData;
    },
  },
  created() {
    const st = this;
    window.addEventListener('load', () => {
      st.resizeMethods();
    });
    window.addEventListener('resize', this.resizeMethods);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  directives: {
    imagesLoaded,
  },
  mounted() {
    this.resizeMethods();
  },
  methods: {
    resizeFonts() {
      const cardWidth = document.getElementsByClassName('vowel-team')[0].clientWidth;
      const cardHeight = document.getElementsByClassName('vowel-team')[0].clientHeight;

      const x = document.getElementsByClassName('letter-team');
      for (let i = 0; i < x.length; i += 1) {
        x[i].setAttribute('style', `font-size: ${cardWidth * 0.035}rem`);
      }
      const z = document.getElementsByClassName('keyword');
      for (let i = 0; i < z.length; i += 1) {
        z[i].setAttribute('style', `font-size:${cardHeight * 0.013}rem`);
      }
      if (document.getElementsByClassName('fun3')[0] !== undefined) {
        const y = document.getElementsByClassName('vowel-team');
        for (let i = 0; i < y.length; i += 1) {
          y[i].setAttribute('style', `margin-top:${cardHeight * 0.035}rem`);
        }
      }
    },
    resizeMethods() {
      if (this.getCardsForDisplay.length > 0) {
        this.resizeCards();
        this.resizeFonts();
      }
    },
    revealImageWrapper() {
      document.getElementById('cards-container').classList.add('images-loaded');
    },
    imageProgress() {
      return {
        always: () => {
          this.resizeMethods();
          this.revealImageWrapper();
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
#card-positioner{
  display: flex;
}
#cards-container{
  opacity: 0;
  &.images-loaded{
    opacity: 1;
  }
}
.vowel-teams {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-columns: auto;
  // place-items: start;
  width: 100%;
  max-width: 90rem;
  align-self: center;
  justify-self: center;
  margin: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;

  .fun1 &, .fun2 & {
    grid-gap: 2rem;
  }
  .fun3 & {
    grid-column-gap: 1rem;
  }

  .vowel-team {
    width: 100%;
    .fun1 &, .fun2 & {
      height: 100%;
    }
    font-family: wBookman;
    padding: 0.5rem;
    box-sizing: border-box;
    position: relative;

    .fun1 &, .fun2 & {
      display: grid;
      @extend %yellow-tile;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 2fr 1fr 6fr;
      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 0;
        padding-bottom: 60%;
        grid-row: 1 / span 2;
        grid-column: 1 / span 3;
      }
    }
    .fun3 & {
      @extend %purple-tile;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      margin-top: 3rem;
      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 0;
        padding-bottom: 90%;
        grid-row: 1;
        grid-column: 1;
      }
    }

    .keyword {
      position: absolute;
      .fun1 &, .fun2 & {
        grid-column: 1 / span 3;
        font-size: 1.2rem;
      }
      .fun3 & {
        right: 0;
        top: -20%;
        font-size: 1.4rem;
      }
      grid-row: 1;
      transform-origin: top left;
      transition: all 250ms ease-in-out;
    }
    .letter-team {
      margin-top: auto;
      line-height: 0.8;
      transition: all 250ms ease-in-out;
      z-index: 1;
      .fun1 &, .fun2 & {
        font-size: 4rem;
        grid-row: 2;
        grid-column: 1 / span 3;
      }
      .fun3 & {
        font-size: 5rem;
        position: absolute;
        top: 83%;
        left: 1rem;
        text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
      }
    }
    .vt-img {
      .fun1 &, .fun2 & {
        grid-column: 2 / span 2;
        grid-row: 1 / span 2;
        position: relative;
        img {
          margin-left: auto;
          position: absolute;
        }
      }
      .fun3 & {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-content: center;
        img {
          margin: auto;
        }
      }
      img {
        display: block;
        transition: all 250ms ease-in-out;
      }
    }
    &:hover {
      img {
        transform: translate3d(-1%, 1%, 0);
      }
      .keyword {
        transform: scale(1.1);
      }
      .letter-team {
        transform: scale(0.9);
      }
    }
  }
}

.vowel-teams > * { place-self: start }

#ai {
  grid-area: 1 / 1 / auto / auto;
  img {
    .fun1 &, .fun2 & {
      top: -30%;
      right: 10%;
      width: 50%;
    }
    .fun3 & {
      margin-top: -20%;
      width: 55%;
    }
  }
}
#ay {
  grid-area: 1 / 2 / auto / auto;

  img {
    top: -8%;
    width: 75%;
    right: -3%;
    .fun3 &{
      margin-top: -5%;
    }
  }
}
#eigh {
  .fun3 & {
    grid-area: 1 / 3 / auto / auto;
    img {
      width: 55%;
      margin-top: -5%;
    }
  }
  img {
    top: -10%;
  }
}

#ee {
  grid-area: 2 / 1 / auto / auto;

  img {
    .fun1 &, .fun2 & {
      right: 0;
      top: -12%;
      width: 90%;
    }
    .fun3 & {
      top: 10%;
      width: 90%;
    }
  }
}
#ea {
  grid-area: 2 / 2 / auto / auto;

  img {
    top: -10%;
    width: 70%;
    right: -5%;
  }
}
#ea1 {
  grid-area: 1 / 5 / auto / auto;
  img {
    width: 75%;
    top: -10%;
    .fun3 &{
      margin-top: -5%;
    }
  }
}
#ea2 {
  grid-area: 2 / 5 / auto / auto;
  img {
    width: 80%;
    margin-top: -10%;
  }
}
#ea3 {
  grid-area: 2 / 6 / auto / auto;
  img {
    width: 80%;
    margin-top: 0%;
  }
}
#ie {
  grid-area: 2 / 3 / auto / auto;
  img {
    .fun3 & {
      width: 80%;
      margin-top: -5%;
    }
  }
}
#ey {
  .fun1 &, .fun2 & {
    grid-area: 2 / 3 / auto / auto;
    img {
      top: -20%;
      width: 80%;
      right: 5%;
    }
  }
  .fun3 & {
    grid-area: 2/2 / auto/auto;
    img {
      margin-top: -5%;
      width: 80%;
    }
  }
}
#ei1 {
  img {
    margin-top: -10%;
    width: 55%;
  }
}
#ei2 {
  grid-area: 2 / 4 / auto / auto;
  img {
    .fun1 &, .fun2 & {
      width: 80%;
      top: -10%;
    }
    .fun3 & {
      width: 75%;
      margin-top: -5%;
    }
  }
}
#igh {
  grid-area: 3 / 1 / auto / auto;
  img {
    width: 45%;
    top: -15%;
    .fun3 &{
      margin-top: -10%;
    }
  }
}
#oi {
  .fun1 &, .fun2 & {
    grid-area: 3 / 1 / auto / auto;
    img {
      width: 80%;
      right: 0;
      top: -15%;
    }
  }
  .fun3 & {
    grid-area: 5 / 1 / auto / auto;
    img {
      width: 80%;
      margin-top: -5%;
    }
  }
}
#oy {
  .fun1 &, .fun2 & {
    grid-area: 3 / 2 / auto / auto;
    img {
      top: -15%;
      right: 0;
      width: 70%;
    }
  }
  .fun3 & {
    grid-area: 5 / 2 / auto / auto;

    img {
      margin-top: -10%;
      width: 65%;
    }
  }
}
#oa {
  .fun1 &, .fun2 & {
    grid-area: 4 / 1 / auto / auto;
  }
  .fun3 & {
    grid-area: 3 / 3 / auto / auto;
  }

  img {
    width: 80%;
    right: 0;
    top: 5%;
    .fun3 &{
          margin-top: -5%;
    }
  }
}
#oe {
  .fun1 &, .fun2 & {
    grid-area: 4 / 2 / auto / auto;
  }
  .fun3 & {
    grid-area: 3 / 4 / auto / auto;
    img {
      margin-top: -10%;
    }
  }

  img {
    top: -20%;
    width: 80%;
    right: 0;
  }
}
#ow {
  .fun1 &, .fun2 & {
    grid-area: 4 / 3 / auto / auto;
    img {
      top: -15%;
      right: -1.5rem;
      width: 80%;
    }
  }
  .fun3 & {
    grid-area: 3 / 5 / auto / auto;
    img {
      top: 1%;
      width: 95%;
      right: 2.5%;
    }
  }
}
#ou {
  .fun1 &, .fun2 & {
    grid-area: 5 / 1 / auto / auto;
    img {
      width: 75%;
      right: -10%;
      top: 0%;
    }
  }
  .fun3 & {
    grid-area: 4 / 1 / auto / auto;
    img {
      width: 80%;
      margin-top: -5%;
    }
  }
}
#ui {
  grid-area: 4 / 2 / auto / auto;
  img {
    width: 50%;
    top: -20%;
    .fun3 &{
      margin-top: -10%;
    }
  }
}
#oo {
  .fun1 &, .fun2 & {
    grid-area: 5 / 2 / auto / auto;
    img {
      right: -15%;
      top: -20%;
      width: 90%;
    }
  }
  .fun3 & {
    grid-area: 4 / 5 / auto / auto;
    img {
      right: -10%;
      top: 0;
      width: 100%;
    }
  }
}
#ue {
  .fun1 &, .fun2 & {
    grid-area: 5 / 3 / auto / auto;
    img {
      right: -10%;
      width: 85%;
      top: -10%;
    }
  }
  .fun3 & {
    grid-area: 4 / 3 / auto / auto;
    img {
      width: 90%;
      margin-top: -1%;
    }
  }
}
#ew {
  .fun1 &, .fun2 & {
    grid-area: 5 / 4 / auto / auto;
    img {
      width: 70%;
      top: -10%;
      right: -10%;
    }
  }
  .fun3 & {
    grid-area: 4 / 4 / auto / auto;
    img {
      width: 70%;
      margin-top: -15%;
    }
  }
}
#au {
  grid-area: 6 / 1 / auto / auto;

  .fun1 &, .fun2 & {
    img {
      width: 50%;
      top: -10%;
      right: 5%;
    }
  }
  .fun3 & {
    img {
      width: 55%;
      margin-top: -5%;
    }
  }
}
#aw {
  grid-area: 6 / 2 / auto / auto;
  .fun1 &, .fun2 & {
    img {
      width: 100%;
      right: -10%;
      top: -10%;
    }
  }
  .fun3 & {
    img {
      width: 90%;
      top: 10%;
    }
  }
}
@media (max-height: 860px) {
  .vowel-teams {
    .vowel-team {
      &:after {
        padding-bottom: 50%;
      }
    }
  }
}

@media (max-height: 860px), (max-width: 1000px) {
  .vowel-teams {
    .vowel-team {
      .keyword {
        font-size: 1rem;
      }
      .letter-team {
        font-size: 3rem;
      }
    }
    .fun1 &, .fun2 & {
      grid-gap: 1rem;
    }
  }
}
</style>
