<template>
  <div id="positioner" class="drillsounds">
    <img alt="owl searching" src="/images/owl-searching-color.svg" />
    <p><strong>404</strong> - Page not found</p>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style lang="scss" scoped>
#positioner{
  align-content: center;
  justify-content: center;
}
  img{
    margin: 0 auto;
    width: 25%;
  }
  p{
    margin: 20px auto;
    font-size: 3rem;
  }
</style>
