export default {
  namespaced: true,

  state: {
    rcontrolledData: [
      {
        program: 'fun1',
        cardData: {
          cards: [
            { include: 1, cardId: 1, secondaryLabel: 'ar', label: 'ar', keyword: 'car', image: '/images/Fun2/illustrations/car.svg' },
            { include: 1, cardId: 2, secondaryLabel: 'or', label: 'or', keyword: 'horn', image: '/images/Fun2/illustrations/horn.svg' },
            { include: 1, cardId: 3, secondaryLabel: 'er', label: 'ǝr', keyword: 'her', image: '/images/Fun2/illustrations/her.svg' },
            { include: 1, cardId: 4, secondaryLabel: 'ir', label: 'ǝr', keyword: 'bird', image: '/images/Fun2/illustrations/bird.svg' },
            { include: 1, cardId: 5, secondaryLabel: 'ur', label: 'ǝr', keyword: 'burn', image: '/images/Fun2/illustrations/burn.svg' },
          ],
        },
      },
      {
        program: 'fun2',
        cardData: {
          cards: [
            { include: 13, cardId: 1, secondaryLabel: 'ar', label: 'ar', keyword: 'car', image: '/images/Fun2/illustrations/car.svg' }, // day 3
            { include: 13, cardId: 2, secondaryLabel: 'or', label: 'or', keyword: 'horn', image: '/images/Fun2/illustrations/horn.svg' }, // day 3
            { include: 20, cardId: 3, secondaryLabel: 'er', label: 'ǝr', keyword: 'her', image: '/images/Fun2/illustrations/her.svg' }, // day 4
            { include: 20, cardId: 4, secondaryLabel: 'ir', label: 'ǝr', keyword: 'bird', image: '/images/Fun2/illustrations/bird.svg' }, // day 4
            { include: 20, cardId: 5, secondaryLabel: 'ur', label: 'ǝr', keyword: 'burn', image: '/images/Fun2/illustrations/burn.svg' }, // day 4
          ],
        },
      },
      {
        program: 'fun3',
        cardData: {
          cards: [
            { include: 1, cardId: 1, secondaryLabel: 'ar', label: 'ar', keyword: 'car', image: '/images/Fun2/illustrations/car.svg' },
            { include: 1, cardId: 2, secondaryLabel: 'or', label: 'or', keyword: 'horn', image: '/images/Fun2/illustrations/horn.svg' },
            { include: 1, cardId: 3, secondaryLabel: 'er', label: 'ǝr', keyword: 'her', image: '/images/Fun2/illustrations/her.svg' },
            { include: 1, cardId: 4, secondaryLabel: 'ir', label: 'ǝr', keyword: 'bird', image: '/images/Fun2/illustrations/bird.svg' },
            { include: 1, cardId: 5, secondaryLabel: 'ur', label: 'ǝr', keyword: 'burn', image: '/images/Fun2/illustrations/burn.svg' },
          ],
        },
      },
      {
        program: 'fun1',
        cardData: {
          cards: [
            { include: 1, cardId: 1, secondaryLabel: 'ar', label: 'ar', keyword: 'car', image: '/images/Fun2/illustrations/car.svg' },
            { include: 1, cardId: 2, secondaryLabel: 'or', label: 'or', keyword: 'horn', image: '/images/Fun2/illustrations/horn.svg' },
            { include: 1, cardId: 3, secondaryLabel: 'er', label: 'ǝr', keyword: 'her', image: '/images/Fun2/illustrations/her.svg' },
            { include: 1, cardId: 4, secondaryLabel: 'ir', label: 'ǝr', keyword: 'bird', image: '/images/Fun2/illustrations/bird.svg' },
            { include: 1, cardId: 5, secondaryLabel: 'ur', label: 'ǝr', keyword: 'burn', image: '/images/Fun2/illustrations/burn.svg' },
          ],
        },
      },
    ],
  },
};
