import { AuthService } from '@/auth/AuthService';

const auth = new AuthService();

export default {
  namespaced: true,

  state: {
    authenticated: !!sessionStorage.getItem('access_token'),
    accessToken: sessionStorage.getItem('access_token'),
    idToken: sessionStorage.getItem('idToken'),
    expiresAt: sessionStorage.getItem('expires_at'),
    idTokenPayload: sessionStorage.getItem('idToken_payload'),
    redirectUrl: sessionStorage.getItem('redirect_url') !== 'undefined' ? JSON.parse(sessionStorage.getItem('redirect_url')) : { name: '', params: '' },
    isFunHub: sessionStorage.getItem('is_funhub'),
  },

  mutations: {
    HANDLE_AUTHENTICATION: (state, authResult) => {
      state.accessToken = authResult.accessToken;
      state.idToken = authResult.idToken;
      state.expiresAt = authResult.idTokenPayload.exp * 1000;
      state.idTokenPayload = authResult.idTokenPayload;
      state.authenticated = true;

      sessionStorage.setItem('idToken', state.idToken);
      sessionStorage.setItem('access_token', state.accessToken);
      sessionStorage.setItem('idToken_payload', JSON.stringify(state.idTokenPayload));
      sessionStorage.setItem('expires_at', state.expiresAt);
    },

    SET_REDIRECT_URL: (state, redirectUrl) => {
      state.redirectUrl = redirectUrl;
      sessionStorage.setItem('redirect_url', JSON.stringify(redirectUrl));
    },

    SET_IS_FUNHUB: (state, isFunHub) => {
      state.isFunHub = isFunHub;
      sessionStorage.setItem('is_funhub', isFunHub);
    },

    RESET_REDIRECT_URL: (state) => {
      state.redirectUrl = null;
      sessionStorage.removeItem('redirect_url');
    },

    LOGOUT: (state) => {
      state.accessToken = null;
      state.idToken = null;
      state.expiresAt = null;
      state.idTokenPayload = null;
      state.authenticated = false;
      state.redirectUrl = null;
      state.isFunHub = null;

      sessionStorage.removeItem('idToken');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('idToken_payload');
      sessionStorage.removeItem('redirect_url');
      sessionStorage.removeItem('is_funhub');
    },
  },

  actions: {
    // toItemFromRouter is from the 'to' parameter of the router beforeEach
    login: (context, toItemFromRouter) => {
      if (toItemFromRouter !== '') {
        context.commit('SET_REDIRECT_URL', toItemFromRouter);
      }
      if (process.env.VUE_APP_FUNHUB_URL !== '') {
        window.location = process.env.VUE_APP_FUNHUB_URL;
        return;
      }
      auth.login();
    },

    logout: (context) => {
      context.commit('LOGOUT');
    },

    setFunHub: (context, isFunHub) => {
      context.commit('SET_IS_FUNHUB', isFunHub);
    },

    renewSession: (context, toItemFromRouter) => new Promise((resolve, reject) => {
      if ((context.state.expiresAt && new Date().getTime() < context.state.expiresAt) || (context.state.isFunHub && context.state.isFunHub === 'true')) {
        // if authenticated, and token is not expired, no need to renew token
        resolve();
      } else {
        if (toItemFromRouter && toItemFromRouter !== '') {
          context.commit('SET_REDIRECT_URL', toItemFromRouter);
        }
        auth
          .renewSession()
          .then((authResult) => {
            context.commit('HANDLE_AUTHENTICATION', authResult);
            resolve(authResult);
          })
          .catch((err) => {
            if (err.code !== 'login_required') {
              console.error('Renew session', err);
            }
            reject(err);
          });
      }
    }),

    handleAuthentication: (context) => new Promise((resolve, reject) => {
      auth
        .handleAuthentication()
        .then((authResult) => {
          context.commit('HANDLE_AUTHENTICATION', authResult);
          resolve(authResult);

        })
        .catch((err) => {
          if (err.code !== 'login_required') {
            console.error('handleAuthentication', err);
          }
          reject(err);
        });
    }),
    clearRedirect: (context) => {
      context.commit('RESET_REDIRECT_URL');
    },
  },

  getters: {
    isAuthenticated: (state) => () => {
      if ((state.isFunHub && state.isFunHub === 'true') || state.authenticated) {
        return true;
      }
      return false;
    },

    isTokenExpired: (state) => () => {
      if (state.expiresAt) {
        const isExpired = new Date().getTime() > state.expiresAt;
        return isExpired;
      }
      return false;
    },
    access_token: (state) => state.accessToken,
    redirect_url: (state) => state.redirectUrl,
  },
};
