export default {
  namespaced: true,

  state: {
    silentletterData: [
      {
        program: 'fun3',
        cardData: {
          cards: [
            { include: 32, cardId: 1, label: 'wr', keyword: 'wrist', image: '/images/Fun3/silent-letters/wrist.svg' },
            { include: 32, cardId: 2, label: 'mn', keyword: 'column', image: '/images/Fun3/silent-letters/column.svg' },
            { include: 32, cardId: 3, label: 'rh', keyword: 'rhyme', image: '/images/Fun3/silent-letters/rhyme.svg' },
            { include: 32, cardId: 4, label: 'mb', keyword: 'lamb', image: '/images/Fun3/silent-letters/lamb.svg' },
            { include: 32, cardId: 5, label: 'gn', keyword: 'gnat', image: '/images/Fun3/silent-letters/gnat.svg' },
            { include: 32, cardId: 6, label: 'gh', keyword: 'ghost', image: '/images/Fun3/silent-letters/ghost.svg' },
            { include: 32, cardId: 7, label: 'kn', keyword: 'knife', image: '/images/Fun3/silent-letters/knife.svg' },
          ],
          // cardXref: [
          //   {
          //     weeks: ['13-2'],
          //     cards: [1, 2, 3, 4, 5, 6, 7],
          //   },
          // ],
        },
      },
    ],
  },
};
