<template>
  <div class="spelling-options-popunder"
    id="sopPU"
    dropzone
    @dragenter="dragEnter"
    @drop.self="onDrop"
    @dragover="onDragOver">
    <div
      class="sc move"
      :style="{ top: card.position }"
      :class="card.card.color"
      v-for="(card, $idx) of cards"
      :key="$idx"
      :data-index="$idx + 1"
      @click="promoteSopCard">
      <div class="letter-holder">
        <span class="sc-letter" v-if="card.card.cardId < 900" v-html="card.card.label"></span>
        <span class="sc-letter" v-else>&nbsp;</span>
      </div>
      <span class="sop-card-explainer explainer-label" v-show="showSopLabelsToday()"><span>tap an option to test it<br></span></span>
    </div>
    <div class="label" @click="removeSpellingOptionsPopunder" id="sop-label">
      <span id="so-label">Spelling Options</span>
      <span id="so-close">close</span>
    </div>
    <span class="drag-explainer explainer-label" v-show="showDragInfo && showSopLabelsToday()"><span>drag spelling options here<br></span></span>
  </div>
</template>

<script>
export default {
  name: 'SpellingOption',
  props: {
    clearCards: { type: Boolean, default: false },
    currentDay: { type: Number },
    currentProgram: { type: String },
  },
  watch: {
    clearCards() {
      this.cards = [];
    },
  },
  data() {
    const showDragInfo = true;
    const showSopCardInfo = true;
    return {
      cards: [],
      showDragInfo,
      showSopCardInfo,
    };
  },
  mounted() {
    // add touch enter listener
    const el = document.getElementById('sopPU');
    el.ontouchenter = this.onDragEnter;
  },
  methods: {

    promoteSopCard(e) {

      // helpers:
      // remove the helper text early
      this.showSopCardInfo = false;

      // add sop-covered class to 'has-popunder' card
      document.getElementsByClassName('has-popunder')[0].classList.add('sop-covered');

      // remove any cloned cards
      const existingClone = document.getElementsByClassName('clonedSC')[0];
      if (existingClone) {
        existingClone.remove();
      }

      // get the clicked card, even if a child element is clicked on:

      const clickedCard = this.closestSC(e.target, 'sc');

      // make sure we're not duplicating sop-promoted
      if (!clickedCard.classList.contains('sop-promoted')) {

        const clickedCardIndex = this.getElementIndex(clickedCard);

        const cln = clickedCard.cloneNode(true);
        document.getElementById('sopPU').insertBefore(cln, document.getElementById('sop-label'));

        cln.classList.add('clonedSC');
        cln.style.gridRow = clickedCardIndex + 1;
      }

      // if the clicked card is already promoted:
      if (clickedCard.classList.contains('sop-promoted')) {

        // remove sop-promoted class from everything
        const elems = Array.from(document.querySelectorAll('.sc.sop-promoted'));

        elems.forEach((el) => {
          el.classList.remove('sop-promoted');
        });

        document.getElementsByClassName('has-popunder')[0].classList.remove('sop-covered');

      } else { // if the clicked card is NOT already promoted
        // remove sop-promoted class from everything
        const elems = Array.from(document.querySelectorAll('.sc.sop-promoted'));
        elems.forEach((el) => {
          el.classList.remove('sop-promoted');
        });

        // add scale to clicked card
        clickedCard.classList.add('emphasized-card');
        clickedCard.classList.add('sop-promoted');
        setTimeout(() => {
          clickedCard.classList.remove('emphasized-card');
        }, 500);
      }
    },

    closestSC(el, cssClass) {
      while (!el.classList.contains(cssClass)) {
        // Increment the loop to the parent node
        el = el.parentNode;
        if (!el) {
          return null;
        }
      }
      return el;
    },

    getElementIndex(node) {
      let index = 0;
      while ((node)) {
        index += 1;
        node = node.previousElementSibling;
      }
      return index;
    },

    showSopLabelsToday() {
      if (this.showSopCardInfo && ((this.currentDay === 49 && this.currentProgram === 'fun2') || (this.currentDay === 27 && this.currentProgram === 'fun3'))) {
        return true;
      }
      return false;
    },
    unPromoteCard() {
      const promoted = document.getElementsByClassName('sop-promoted')[0];

      if (promoted) {
        promoted.classList.remove('sop-promoted');
        document.getElementsByClassName('has-popunder')[0].classList.remove('sop-covered');
      }
    },

    removeSpellingOptionsPopunder() {
      if (typeof document.getElementsByClassName('clonedSC')[0] !== 'undefined') {
        document.getElementsByClassName('clonedSC')[0].remove();
      }
      this.cards = [];
      this.$emit('removeSpellingOptionsPopunder');
      this.showDragInfo = false;
      document.getElementsByClassName('has-popunder')[0].classList.remove('sop-covered');
      document.getElementsByClassName('has-popunder')[0].classList.remove('has-popunder');
    },

    dragEnter(e) {
      let effect = 'move';
      if (this.cards.length < 3) {
        e.preventDefault();
        effect = 'copy';
        e.dataTransfer.dropEffect = effect;
        e.dataTransfer.effectAllowed = effect;
      } else {
        e.dataTransfer.dropEffect = effect;
        e.dataTransfer.effectAllowed = effect;
      }

      return effect;
    },
    onDragOver(e) {
      if (this.cards.length < 3) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
        e.dataTransfer.effectAllowed = 'copy';
      } else {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
      }
    },
    onDrop(e) {
      this.showDragInfo = false;
      if (this.cards.length > 1) {
        this.showSopCardInfo = false;
      }
      if (this.cards.length < 3) {
        const payload = { card: JSON.parse(e.dataTransfer.getData('cardPayload') || '{}') };
        this.cards.push(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.explainer-label {
  position: absolute;
  display: none;
  background-position: center left;
  background-repeat: no-repeat;
  font-size: 2rem;
  opacity: 0;
  &.drag-explainer {
    padding: 1rem 0 1rem 4rem;
    top: 28%;
    left: 70%;
    background-image: url(/images/drag.svg);
    background-size: 35px;
  }
  &.sop-card-explainer {
    background-image: url(/images/sop-swoosh.svg);
    background-size: contain;
    padding: 1.5rem 0 1.5rem 4.5rem;
    left: 105%;
    top: 0;
  }
}
@keyframes fadeout {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  75% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0;
  }
}
.drop-row {
  .spelling-options-popunder {
    width: 3em;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: -7%;
    z-index: 9999;
    border-radius: 0.7rem;
    box-shadow: 0 0 0.6rem 0.1rem rgba(0, 0, 0, 0.3);
    transition: top 300ms ease-out, opacity 300ms ease-out, height 300ms ease-out;
    display: grid;
    grid-template-rows: 1.1fr 1fr 1fr 1fr 4.5rem;

    &.hidden {
      height: 100%;
      opacity: 0;
      z-index: -1;
      .label {
        bottom: -100px;
      }
    }
    &.visible {
      height: 500%;
      opacity: 1;
      .label {
        bottom: 0;
      }
      .explainer-label {
        animation: fadeout 3s ease-out;
        display: block;
      }
    }
    .label {
      transition: inherit;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      color: white;
      padding: 0.5rem 0;
      line-height: 1;
      font-size: 1.5rem;
      background: $fun-yellow;
      cursor: pointer;
      height: 4rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 0.7rem;
      border-bottom-right-radius: 0.7rem;
      > * {
        margin: auto;
      }
      #so-close {
        display: none;
      }
      &:hover {
        #so-close {
          display: block;
        }
        #so-label {
          display: none;
        }
      }
    }
    .sc {
      width: 66% !important;
      left: -2%;
      -ms-flex-item-align: center;
      align-self: center;
      justify-self: center;
      top: unset;
      bottom: unset;
      position: relative;
      transform: scale(1);
      &:not(:first-child) {
        .sop-card-explainer {
          display: none;
        }
      }
      .letter-holder {
        position: absolute;
        width: 100%;
      }

      .sc-letter {
        position: relative;
        font-size: 0.7em;
        .increased-row-height & {
          font-size: 0.9em;
        }
        // .decreased-row-height & {

        // }
      }
      &:nth-of-type(1) {
        grid-row: 2;
      }
      &:nth-of-type(2) {
        grid-row: 3;
      }
      &:nth-of-type(3) {
        grid-row: 4;
      }
      &.sop-promoted {
        grid-row: 1;
        transition: all 0.5s;
        &.emphasized-card {
          transform: scale(1.15);
        }
      }
      &.clonedSC {
        opacity: 0.3;
      }
    }
  }
}
@media (max-width: 1300px) {
  .drop-row {
    .spelling-options-popunder {
      .label {
        font-size: 1.2rem;
      }
    }
  }
}
@media (max-width: 1050px) {
  .drop-row {
    .spelling-options-popunder {
      .label {
        font-size: 1rem;
      }
    }
  }
}
</style>
