import { WebAuth } from 'auth0-js';
import apiService from '@/api/apiService';

export class AuthService {
  auth0 = new WebAuth({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientID: process.env.VUE_APP_AUTH0_CLIENTID,
    redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
    responseType: 'token id_token',
    // audience: 'https://interactivities.wilsonacademy.com',
    scope: 'openid',
  });

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      const useDbAuth = process.env.VUE_APP_SERVER_AUTH; // set to false to use auth0 auth, for local dev

      this.auth0.parseHash((err, authResult) => {
        if (authResult) {
          if (authResult.accessToken && authResult.idToken) {
            const contactId = authResult.idTokenPayload['https://interactivities.wilsonacademy.com/contactId'];
            if (useDbAuth === 'true') {
              apiService.callValidateUserApi(contactId)
                .then((response) => {
                  if (response.data === 'Valid') {
                    resolve(authResult);
                  } else {
                    reject(new Error('UNAUTHORIZED'));
                  }
                })
                .catch((apiErr) => {
                  // const responseData = apiErr.response.data;
                  // const responseError = apiErr.response.status;
                  reject(new Error(`UNAUTHORIZED`));
                  console.error(apiErr);
                });
            } else {
              const payload = authResult.idTokenPayload['https://interactivities.wilsonacademy.com/roles'];
              // console.log(payload);
              if (payload && payload.some((f) => f === 'interactivities_3')) {
                resolve(authResult);
              } else {
                reject(new Error('UNAUTHORIZED'));
              }
            }

          } else {
            reject(new Error('INVALID AUTH'));
          }
        } else if (err) {
          if (err.code === 'login_required') {
            this.login();
          }
          reject(err);
          console.log('handleAuth Error', err);
        }
      });
    });
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      const useDbAuth = process.env.VUE_APP_SERVER_AUTH; // set to false to use auth0 auth, for local dev
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult) {
          if (authResult.accessToken && authResult.idToken) {

            const contactId = authResult.idTokenPayload['https://interactivities.wilsonacademy.com/contactId'];
            if (useDbAuth === 'true') {
              apiService.callValidateUserApi(contactId)
                .then((response) => {
                  if (response.data === 'Valid') {
                    resolve(authResult);
                  } else {
                    reject(new Error('UNAUTHORIZED'));
                  }
                })
                .catch((apiErr) => {
                  // const responseData = apiErr.response.data;
                  // const responseError = apiErr.response.status;
                  reject(new Error(`UNAUTHORIZED`));
                  console.error(apiErr);
                });
            } else {
              const payload = authResult.idTokenPayload['https://interactivities.wilsonacademy.com/roles'];
              if (payload && payload.some((f) => f === 'interactivities_3')) {
                resolve(authResult);
              } else {
                reject(new Error('UNAUTHORIZED'));
              }
            }
          } else {
            reject(new Error('INVALID AUTH'));
          }

        } else if (err) {
          reject(err);
          console.error('renweSession Error', err);
        }
      });
    });
  }
}
