<template>
  <div id="positioner">
    <ActivityHeader
      activityType="Reference Posters"
      activityName="Letter Formation"
      back-button-title="Posters"
      :show-nav-buttons="false"
      back-button-route=""
    ></ActivityHeader>
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner" style="display: flex;" data-automationId="reference_posters_letter_formation_with_img">
        <img style="flex: 1 0 auto;" src="/images/letter-formation.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'LetterFormation',
  components: { ActivityHeader },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
};
</script>

<style lang="scss" scoped></style>
