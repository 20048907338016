<template>
  <div id="positioner" class="drillsounds">
    <ActivityHeader activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
      activity-name="Suffix Cards"
      :back-button-title="backButtonTitle"
      back-button-route="choosedrillsounds" />

    <div style="position: relative; flex: 1 0 auto;">

      <div id="suffix-cards">
        <div v-for="card in getCardsForDisplay"
        data-automationId="suffix_cards"
        v-bind:aria-label="accessibleLabel(card)"
        :key="card.cardId"
        @click="cardClicked(card)"
        :class="[{ selectable: selectionMode, selected: selectedSuffixCards.includes(card.cardId) }]"
        class="suffix-card"
        :style="{ gridArea: card.row + '/' + card.col, }">{{
          card.label
        }}
          <div class="star selected-star" v-if="selectedSuffixCards.includes(card.cardId)">
          </div>
        </div>
      </div>

      <div id="suffix-card-buttons">
        <!-- intentionally left commented in case of future necessity -->
        <!-- <button @click="selectCards"><span class='star selected-star'></span>{{ selectButtonLabel }}</button> -->
        <span class="tap-instruction explainer-label"><span>tap cards to select for review</span></span>
        <button v-if="selectionMode" @click="resetSelection">Reset Selection</button>
        <button v-if="selectedSuffixCards.length > 0" @click="showModal">
          <span class='star selected-star' data-automationId="suffix_cards_show_selected"></span>Show as Flash Cards
        </button>
      </div>
    </div>
    <modal-dialog v-show="isModalVisible" @close="closeModal" class="transparent-modal-overlay">
      <template v-slot:body>
        <div id="carousel-wrapper" class="suffixcards-carousel">
          <carousel-3d
            ref="carousel"
            :controls-visible="true"
            :count="selectedSuffixCards.length"
            :space="100"
            :perspective="0"
            :clickable="false"
          >
            <slide v-for="(card, i) in cardsForModal" :index="i" :key="i">
              <div :class="' in-carousel suffix-card'" v-bind:aria-label="accessibleLabel(card)" data-automationId="suffix_cards_seleted_carousel">
                {{ card.label }}
              </div>
            </slide>
          </carousel-3d>
        </div>
      </template>
    </modal-dialog>
    <PrevNextButtons activity="SUFF"></PrevNextButtons>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'SuffixCards',
  components: { PrevNextButtons, ActivityHeader, ModalDialog, Carousel3d, Slide },
  data() {
    const selectionMode = true;
    const backButtonTitle = '';
    const isModalVisible = false;
    return { selectionMode, backButtonTitle, isModalVisible };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  methods: {
    ...mapActions('appState', ['setSelectedSuffixCards', 'addSelectedSuffixCard', 'removeSelectedSuffixCard', 'clearSelectedSuffixCards']),

    accessibleLabel(card) {
      return card.label.match(/.{1,1}/g).join('-');
    },
    selectCards() {
      if (!this.selectionMode) {
        this.clearSelectedSuffixCards();
      }
      this.selectionMode = !this.selectionMode;
    },

    cardClicked(card) {
      if (this.selectionMode) {
        const didSelectedCard = this.selectedSuffixCards.find((c) => c === card.cardId);
        if (!didSelectedCard) {
          if (this.selectedSuffixCards.length < 10) {
            this.addSelectedSuffixCard({ cardId: card.cardId });
          }
        } else {
          this.removeSelectedSuffixCard({ cardId: card.cardId });
        }
      }
    },
    resetSelection() {
      this.clearSelectedSuffixCards();
    },
    showModal() {
      this.isModalVisible = true;
      this.$refs.carousel.goSlide(0);
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  mounted() {
    this.backButtonTitle = this.weekId === '0' ? 'Back' : 'Drill Sounds';
  },
  computed: {
    ...mapState('suffixCards', ['suffixCardData']),
    ...mapGetters('dataStore', ['weekNumber']),
    ...mapGetters('appState', ['selectedSuffixCards']),

    selectButtonLabel() {
      return this.selectionMode ? 'Done' : 'Select Cards';
    },
    cardsForModal() {
      if (!this.selectedSuffixCards || this.selectedSuffixCards.length === 0) {
        return [];
      }
      const s = this.selectedSuffixCards.map((c) => this.getCardsForDisplay.find((f) => f.cardId === c));
      return s;
    },
    getCardsForDisplay() {
      const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      const lookup = parseFloat(`${weekNumber}.${this.dayId === '0' ? '5' : this.dayId}`);

      let cardsToShow = this.suffixCardData.cards;
      // for fundations 2, select cards based on unit/week
      if (this.programId === 'fun2') {
        cardsToShow = cardsToShow.filter((f) => f.include <= lookup);
        return cardsToShow;
      }
      // fundations 3, show all cards
      return cardsToShow;
    },
  },
};
</script>
<style lang="scss">
 .suffixcards-carousel{
   .carousel-3d-slider{
      padding-bottom: 40%;
      height: 0 !important;
      .carousel-3d-slide {
        display: flex;
        .suffix-card{
          box-shadow: 4px 3px 7px rgba(0, 0, 0, 0.5);
          flex: 1;
        }
      }
    }
  }
</style>
<style scoped lang="scss">
#suffix-cards {
  max-width: 30rem;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
    .suffix-card {
    padding: 2rem;
    font-size: 2rem;
  }
}
.suffix-card {
  background-color: $suffix-yellow;
  font-family: wManuscript;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
  font-size: 15rem;
}

.modal {
  .modal-body {
    height: 80%;
  }
  #carousel-wrapper {
    .carousel-3d-container {
      width: 70% !important;
    }
  }
}

#suffix-card-buttons {
  position: absolute;
  left: 70%;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: $activity-frame-lateral-padding;
  button {
    margin: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .star{
      position: relative;
      margin: 0 .4rem .4rem 0;
    }
  }
}

.selectable {
  // border: 2px dashed blue;
  opacity: 0.3;
}

.selected {
  opacity: 1;
}
@media (max-width: 1000px) {
  .suffix-card{
    font-size: 10rem;
  }
}
@media (max-width: 1400px) {
  .modal #carousel-wrapper .carousel-3d-container{
    width: 90% !important;
  }
}
@media (max-height: 1000px) {
  #suffix-cards {
    max-width: 24rem;
    .suffix-card {
      padding: 1.4rem;
    }
  }
}

@media (max-height: 870px) {
  #suffix-cards {
    max-width: 22rem;
    .suffix-card {
      padding: 1.2rem;
    }
  }
}
@media (max-height: 815px) {
  #suffix-cards {
    max-width: 20rem;
    .suffix-card {
      padding: 1rem;
    }
  }
}

@media (max-height: 750px) {
  #suffix-cards {
    .suffix-card {
      font-size: 1.4rem;
    }
  }
}
.tap-instruction{
  background-image: url('/images/pointer.svg');
  background-size: 30px;
  background-position: center left;
  background-repeat: no-repeat;
  padding: 1rem 0 1rem 4rem;
  font-size: 2rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  &.explainer-label{
    span{
      text-align: left;
      display: block;
      white-space: normal;
    }
  }
}
</style>
