export default {
  methods: {
    getMixpanelLevel(iLevel) {
      if (!iLevel) return '';
      return {
        fun: 'fundations',
        funk: 'fundations-k',
        fun1: 'fundations-1',
        fun2: 'fundations-2',
        fun3: 'fundations-3',
      }[iLevel];
    },
  },
};
