<template>
  <div
    :style="{ gridArea: card.displayPosition.row + '/' + card.displayPosition.col +' / auto / ' + cardEnd(card), backgroundColor: card.color }"
    :class="[{ selectable: isSelectable, selected: isSelected } , cardClass]"
    :data-cardId="card.cardId"
    @click="clickCard(card)"
    :draggable="isDraggable"
    @dragstart.self="onDrag"
    @dragend.self="onDragEnd"
    @touchstart="startTouchDrag"
    @touchmove="onTouchDrag"
    @touchend="onTouchEnd"
    v-bind:aria-label="accessibleLabel(card)"
  >
    <transition name="bounce">
      <div class="star new-star" v-if="showNewCards && isNewCard">
      </div>
    </transition>
    <div class="star selected-star" v-if="isSelected">
    </div>
    <div class="letter-holder">
      <span class="sc-letter" v-if="card.cardId < 900" v-html="card.label" aria-hidden="true"></span>
      <span class="sc-letter" v-else>&nbsp;</span>
    </div>
  </div>
</template>

<script>
import dragHelper from '@/helpers/checkNativeDrag';

export default {
  name: 'LetterCard',
  props: {
    card: {},
    cardClass: { type: String, default: '' },
    isSelectable: { type: Boolean, default: false },
    isNewCard: { type: Boolean, default: false },
    showNewCards: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    isDraggable: { type: Boolean, default: true },
    isSoundCard: { type: Boolean, default: false },
  },
  data() {
    return {
      touchTimeout: null,
      clone: null,
      dragEvent: null,
    };
  },
  mounted() {},
  methods: {
    clickCard(card) {
      this.$emit('cardClicked', card);
    },
    accessibleLabel(card) {
      return card.label ? card.label.match(/.{1,1}/g).join('-') : card.label;
    },
    cardEnd(card) {
      let endValue = 'auto';
      if ((card.color) === 'suffix-yellow') {
        endValue = card.displayPosition.col + 2;
      }
      return endValue;
    },

    runTouchTimeout() {
      this.touchTimeout = setTimeout(() => {
        this.stopTouchTimeout();
      }, 250);
    },

    stopTouchTimeout() {
      clearTimeout(this.touchTimeout);
      this.touchTimeout = null;
    },

    startTouchDrag(e) {
      if (!dragHelper.isNativeDragSupported()) return;

      // stop the touch from being mapped to a click
      if (e.cancelable) e.preventDefault();

      // make sure it's not a click
      this.runTouchTimeout();

      // if it's a sound card, stop here
      if (this.isSoundCard) return;

      // if there is another clone, don't do it
      const existingClone = document.querySelector('.ghost-element');
      if (existingClone) return;

      // grab the whole card
      let element = e.srcElement;
      while (!element.hasAttribute('data-cardId')) {
        element = element.parentElement;
      }

      // clone the element and add it to the DOM
      const clone = element.cloneNode(true);
      const crtRawHeight = document.getElementsByClassName('drop-row')[0].offsetHeight - 17;
      clone.classList.add('ghost-element');
      clone.style.opacity = '0.8';
      clone.style.pointerEvents = 'none';
      clone.style.position = 'absolute';

      const elementTop = element.getBoundingClientRect().top;
      const elementLeft = element.getBoundingClientRect().left;
      clone.style.top = `${elementTop}px`;
      clone.style.left = `${elementLeft}px`;
      clone.style.height = `${crtRawHeight}px`;
      clone.style.width = `${crtRawHeight * (element.offsetWidth / element.offsetHeight)}px`;

      const { clientX, clientY } = e.touches[0];
      const cursorXoffset = clientX - elementLeft;
      const cursorYoffset = clientY - elementTop;

      clone.setAttribute('data-offsetX', cursorXoffset);
      clone.setAttribute('data-offsetY', cursorYoffset);
      this.clone = clone;

      document.body.appendChild(clone);
    },

    onTouchDrag(e) {
      if (!dragHelper.isNativeDragSupported() || this.isSoundCard) return;

      // stop a timeout if it's going
      this.stopTouchTimeout();

      // grab the original element
      let element = e.target;
      while (!element.hasAttribute('data-cardId')) {
        element = element.parentElement;
      }

      // if there is a clone, move it around
      // and create a drag event to match
      if (this.clone) {
        const { clientX, clientY } = e.touches[0];
        const cursorXoffset = clientX - +this.clone.getAttribute('data-offsetX');
        const cursorYoffset = clientY - +this.clone.getAttribute('data-offsetY');
        this.clone.style.left = `${cursorXoffset}px`;
        this.clone.style.top = `${cursorYoffset}px`;

        // create a drag event
        this.dragEvent = new DragEvent('dragstart', {
          clientX,
          clientY,
          dataTransfer: new DataTransfer(),
        });

        // set the item type
        this.dragEvent.dataTransfer.setData('itemType', 'card');
        this.dragEvent.dataTransfer.effectAllowed = 'copy';

        // trigger the drag
        element.dispatchEvent(this.dragEvent);

        // get any elements the current one is over
        const overElement = document.elementFromPoint(clientX, clientY);
        if (!overElement.hasAttribute('dropzone')) return;

        if (overElement.ontouchenter) {
          // trigger a drag over event
          const effect = overElement.ontouchenter(this.dragEvent);
          this.dragEvent.dataTransfer.effectAllowed = effect;

          const evt = new DragEvent('dragenter', {
            clientX,
            clientY,
            dataTransfer: this.dragEvent.dataTransfer,
            target: overElement,
          });
          overElement.dispatchEvent(evt);
        }
      }
    },

    onTouchEnd(e) {
      if (!dragHelper.isNativeDragSupported()) return;

      // stop the timeout
      if (this.touchTimeout) {
        this.stopTouchTimeout();
        this.clickCard(this.card);

        if (this.clone) {
          // remove the clone
          this.dragEvent = null;
          document.body.removeChild(this.clone);
          this.clone = null;
        }

        return;
      }

      if (this.clone) {
        // grab the current ghost element coords
        const { clientX, clientY } = e.changedTouches[0];
        const offsetX = clientX - this.clone.getBoundingClientRect().left;

        // find any elements that the clone is over
        const elements = document.elementsFromPoint(clientX, clientY);
        const element = elements.find((el) => el.hasAttribute('dropzone'));
        if (element) {
          // create a drop event
          const dropEvent = new DragEvent('drop', {
            clientX,
            clientY,
            dataTransfer: this.dragEvent.dataTransfer,
          });
          dropEvent.dataTransfer.setData('itemType', 'card');
          dropEvent.dataTransfer.setData('cursorOffset', offsetX);

          // trigger the drop event on that element
          element.dispatchEvent(dropEvent);
        }

        // remove the clone
        this.dragEvent = null;
        document.body.removeChild(this.clone);
        this.clone = null;
        this.onDragEnd();
      }
    },

    onDrag(e) {
      const crt = e.srcElement.cloneNode(true);
      crt.style.position = 'absolute';
      crt.style.top = '-150px';
      const crtRawHeight = document.getElementsByClassName('drop-row')[0].offsetHeight - 17;
      crt.style.height = `${crtRawHeight}px`;
      crt.style.width = `${crtRawHeight * (e.srcElement.offsetWidth / e.srcElement.offsetHeight)}px`;
      const cursorXoffset = e.clientX - e.srcElement.getBoundingClientRect().left;
      const cursorYoffset = e.clientY - e.srcElement.getBoundingClientRect().top;
      e.dataTransfer.setDragImage(e.srcElement, cursorXoffset, cursorYoffset);

      e.dataTransfer.setData('cursorOffset', cursorXoffset);
      e.dataTransfer.effectAllowed = 'copy';
      e.dataTransfer.dropEffect = 'copy';
      this.card.itemType = 'card';
      const payload = JSON.stringify(this.card);
      e.dataTransfer.setData('cardPayload', payload);

      // update the component's drag event
      this.dragEvent = e;

      document.getElementsByClassName('rht-1')[0].classList.add('standby-for-in-use');
      document.getElementsByClassName('rht-2')[0].classList.add('standby-for-in-use');
    },
    onDragEnd() {
      this.$emit('cardDropped');
    },
  },
};
</script>

<style lang="scss" scoped>

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0) rotateZ(0deg);
  }
  50% {
    transform: scale(3) rotateZ(180deg);
  }
  100% {
    transform: scale(1) rotateZ(359deg);
  }
}
.sc {
  position: relative;

  & > .letter-holder{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.selectable {
  opacity: 0.35;
}
.selected {
  opacity: 1;
}
</style>
