<template>
  <div id="positioner">
    <ActivityHeader
      activityType="Reference Posters"
      activityName="Syllable Types"
      back-button-title="Posters"
      :show-nav-buttons="false"
      back-button-route="chooseposters"
    ></ActivityHeader>
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner" v-images-loaded:on="imageProgress()" data-automationId="reference_posters_syllable_types_with_img">
        <div id="cards-container" class="syllable-types-grid" v-if="programId === 'fun2'">
          <div><img src="/images/Fun2/spelling-options/1.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/2.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/3.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/4.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/5.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/6.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/7.svg" alt /></div>
          <div><img src="/images/Fun2/spelling-options/8.svg" alt /></div>
        </div>
        <div id="cards-container" class="syllable-types-grid" v-if="programId === 'fun3'">
          <div><img src="/images/Fun3/spelling-options/1.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/2.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/3.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/4.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/5.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/6.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/7.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/8.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/9.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/10.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/11.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/12.svg" alt /></div>
          <div><img src="/images/Fun3/spelling-options/13.svg" alt /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesLoaded from 'vue-images-loaded';
import cardSizer from '@/mixins/cardsizer';
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'SyllableTypes',
  components: { ActivityHeader },
  mixins: [cardSizer],

  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  created() {
    window.addEventListener('resize', this.resizeMethods);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  directives: {
    imagesLoaded,
  },
  methods: {
    resizeMethods() {
      this.resizeCards();
    },
    revealImageWrapper() {
      document.getElementById('card-positioner').classList.add('images-loaded');
    },
    imageProgress() {
      return {
        always: () => {
          this.resizeMethods();
          this.revealImageWrapper();
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#card-positioner {
  display: flex;
  opacity: 0;
  &.images-loaded{
    opacity: 1;
  }
}
.syllable-types-grid {
  margin: auto;
  display: grid;
  width: 100%;

  .fun2 & {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 2fr 0.9fr 2fr;
    grid-gap: 1rem 2rem;
    div {
      width: 100%;
      margin-top: auto;
      &:nth-child(2),
      &:nth-child(5) {
        margin-top: 0;
      }
    }
    div:nth-child(8) {
      grid-row: 3;
      grid-column: 3;
    }
  }
  .fun3 & {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 3fr 1.3fr 1.3fr;
    grid-gap: 1rem 2rem;

    div {
      //margin-top: 1rem;
      box-sizing: border-box;
      margin-right: 1rem;
      min-width: 0;
      min-height: 0;
      img {
        margin-top: auto;
        min-width: 0;
        min-height: 0;
      }

      // &:nth-child(2),
      // &:nth-child(4),
      // &:nth-child(6),
      // &:nth-child(7),
      // &:nth-child(9),
      // &:nth-child(11),
      // &:nth-child(13) {
      //   margin-right: 0;
      // }

      &:nth-child(1) {
        grid-row: 1;
        grid-column: 1;
      }
      &:nth-child(2) {
        grid-row: 1;
        grid-column: 2;
      }
      &:nth-child(3) {
        grid-row: 2;
        grid-column: 1;
      }
      &:nth-child(4) {
        grid-row: 2;
        grid-column: 2;
      }
      &:nth-child(5) {
        grid-row: 3;
        grid-column: 1;
      }
      &:nth-child(6) {
        grid-row: 3;
        grid-column: 2;
      }
      &:nth-child(7) {
        grid-row: 3;
        grid-column: 3;
      }
      &:nth-child(8) {
        grid-row: 4;
        grid-column: 1;
      }
      &:nth-child(9) {
        grid-row: 4;
        grid-column: 2;
      }
      &:nth-child(10) {
        grid-row: 5;
        grid-column: 1;
      }
      &:nth-child(11) {
        grid-row: 5;
        grid-column: 2;
      }
      &:nth-child(12) {
        grid-row: 6;
        grid-column: 1;
      }
      &:nth-child(13) {
        grid-row: 6;
        grid-column: 2;
      }
    }
  }
}
</style>
