<template>
  <div id="activity-header" v-bind:class="{ compressedActivityHeader: headerIsCompressed }">
    <button id="activity-back" @click="goback">
      {{ (programId === 'funk' || programId === 'fun1') ? 'Back' : backButtonTitle }}
      <span id="back-arrow"></span>
    </button>
    <div id="activity-type" v-html="activityType"></div>
    <div v-if="showNavButtons" class="nav-buttons">
      <div class="nav-button-item">
        <button v-if="unitId && unitId != '0'" @click="chooseWeek">{{ unitName(unitId) }}</button>
      </div>
      <div class="nav-button-item">
        <button v-if="weekId && weekId != '0'" @click="chooseDay">Week {{ weekId }}</button>
      </div>
      <div class="nav-button-item">
        <button v-if="dayId && dayId != '0'" @click="chooseActivity">Day {{ dayId }}</button>
      </div>
    </div>
    <div v-if="activityName" id="activity-sticker">{{ activityName }}</div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'ActivityHeader',
  data() {
    const { programId, weekId, unitId, dayId } = this.$route.params;
    return { programId, weekId, unitId, dayId };
  },
  props: {
    activityType: { type: String, default: '' },
    activityName: { type: String, default: '' },
    backButtonTitle: { type: String, default: '' },
    headerIsCompressed: { type: String, default: '' },
    backButtonRoute: { type: String, default: '' },
    showNavButtons: { type: Boolean, default: true },
  },
  methods: {
    unitName(unit) {
      return unit !== 'Bonus' ? `Unit ${unit}` : 'Bonus Unit';
    },
    chooseUnit() {
      router.push({ name: 'chooseunit', params: { programId: this.programId } });
    },
    chooseWeek() {
      router.push({ name: 'chooseweek', params: { programId: this.programId, unitId: this.unitId } });
    },
    chooseDay() {
      router.push({ name: 'chooseday', params: { programId: this.programId, unitId: this.unitId, weekId: this.weekId } });
    },
    chooseActivity() {
      router.push({ name: 'chooseactivity', params: { programId: this.programId, unitId: this.unitId, weekId: this.weekId, dayId: this.dayId } });
    },
    chooseDictation() {
      router.push({ name: 'dictation', params: { programId: this.programId, unitId: this.unitId, weekId: this.weekId, dayId: this.dayId } });
    },
    chooseDrillsounds() {
      router.push({ name: 'drillsounds', params: { programId: this.programId, unitId: this.unitId, weekId: this.weekId, dayId: this.dayId } });
    },
    choosePosters() {
      router.push({ name: 'referenceposters', params: { programId: this.programId, unitId: this.unitId, weekId: this.weekId, dayId: this.dayId } });
    },
    chooseProgram() {
      router.push({ name: 'chooseprogram' });
    },
    goback() {
      if (this.backButtonRoute === '') {
        router.go(-1);
      } else if (this.programId === 'funk' || this.programId === 'fun1') {
        if (this.backButtonRoute === 'chooseposters') {
          this.choosePosters();
        } else if (this.backButtonRoute !== 'chooseprogram') {
          this.chooseActivity();
        } else {
          this.chooseProgram();
        }
      } else if (this.weekId === '0') {
        this.chooseWeek();
      } else {
        switch (this.backButtonRoute) {
          case 'chooseunit':
            this.chooseUnit();
            break;
          case 'chooseweek':
            this.chooseWeek();
            break;
          case 'chooseday':
            this.chooseDay();
            break;
          case 'chooseactivity':
            this.chooseActivity();
            break;
          case 'choosedictation':
            this.chooseDictation();
            break;
          case 'choosedrillsounds':
            this.chooseDrillsounds();
            break;
          case 'chooseposters':
            this.choosePosters();
            break;
          default:
            router.push(this.backButtonRoute);
        }
      }
    },
  },
};
</script>

<style lang="scss">
#activity-header {
  width: 70%;
  @extend %headings-shared;
  display: flex;
  flex: 0 0 6rem;
  @media (max-height: 650px) {
    flex: 0 0 4.5rem;
  }
  background-color: $whitesmoke;
  margin: 1% 0 3% auto;
  #activity-type {
    display: flex;
    align-items: center;
    margin-left: 3%;
    background-color: transparent;
    font-size: 2.2rem;
  }
  #activity-back {
    border: none;
    color: black;
    flex-basis: 8rem;
    padding: 0;
    background-color: $lightgray;
    @extend %headings-shared;
    font-size: 1.4rem;
    cursor: pointer;
    #back-arrow {
      height: 0.8rem;
      width: 2rem;
      background-color: gray;
      display: block;
      position: relative;
      left: 0.5rem;
      margin: 0.75rem auto auto auto;
      &:after {
        content: '';
        width: 1rem;
        height: 1rem;
        box-sizing: border-box;
        display: block;
        border: solid transparent 0.8rem;
        border-right: solid 1rem gray;
        left: -1.5rem;
        position: absolute;
        top: -0.37rem;
      }
    }
  }
  #activity-sticker {
    margin-left: auto;
    align-items: center;
    display: flex;
    font-size: 2rem;
    background-color: $fun-yellow-lt;
    padding: 0.5rem 2rem;
  }
  &.compressedActivityHeader{
    margin: 1% 0 1% auto;
    .nav-buttons{
      padding: 0rem;
    }
  }
}
.twoline-title {
  line-height: 0.8;
  span:last-of-type {
    font-weight: 400;
    display: block;
    font-size: 2rem;
    margin-top: 0.2rem;
  }
}
.nav-buttons {
  display: flex;
  align-items: center;
  padding: 1rem;
  flex: 0 0 auto;
}
.nav-button-item {
  padding: 0.5rem;
  flex-shrink: 0;
  button:not(.shell-button) {
    font-size: 1.5rem;
    padding: 0.75rem 1rem;
  }
}
.header-bar {
  display: flex;
}
.activity-type {
  display: flex 1;
}
.activity-name {
  background-color: lightblue;
}
@media (max-width: 1150px) {
  #activity-header {
    #activity-sticker {
      font-size: 1.6rem;
    }
    #activity-type {
      font-size: 2rem;
    }
  }
}
@media (max-width: 1000px) {
  .nav-button-item {
    padding: 0.25rem;

    button:not(.shell-button) {
      font-size: 1.2rem;
      padding: 0.5rem;
    }
  }
}
</style>
