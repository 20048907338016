export default {
  namespaced: true,

  state: {
    trickWords: [
      {
        program: 'fun2',
        units: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        words: [
          // { unit: 1, words: ['they', 'one', 'said', 'you', 'your', 'was'] }, // Unit 1, Week 2, Day 2
          { unit: 2, words: ['shall', 'full', 'pull', 'both', 'talk', 'walk'] }, // U2
          { unit: 3, words: ['done', 'goes', 'pretty'] }, // U3
          { unit: 4, words: ['again', 'please', 'animal', 'sure', 'use', 'used'] }, // U4
          { unit: 5, words: ['against', 'knew', 'know', 'always', 'often', 'once'] }, // U5
          { unit: 6, words: ['only', 'house', 'move', 'right', 'place', 'together'] }, // U6
          { unit: 7, words: ['eight', 'large', 'change', 'city', 'every', 'family', 'night', 'carry', 'something'] }, // U7
          { unit: 8, words: ['world', 'answer', 'different'] }, // U8
          { unit: 9, words: ['picture', 'learn', 'earth', 'father', 'brother', 'mother'] }, // U9
          { unit: 10, words: ['great', 'country', 'away', 'America', 'school', 'thought'] }, // U10
          { unit: 11, words: ['whose', 'won', 'son', 'breakfast', 'head', 'ready'] }, // U11
          { unit: 12, words: ['favorite', 'early', 'ocean'] }, // U12
          { unit: 13, words: ['Monday', 'Tuesday', 'cousin', 'lose', 'tomorrow', 'beautiful'] }, // U13
          { unit: 14, words: ['Wednesday', 'Thursday', 'Saturday', 'bought', 'brought', 'piece'] }, // U14
          { unit: 15, words: ['January', 'February', 'July', 'enough', 'special', 'December'] }, // U15
          { unit: 16, words: ['August', 'laugh', 'daughter'] }, // U16
          { unit: 17, words: ['trouble', 'couple', 'young'] }, // U17

          // { weekXref: 2, words: ['they', 'one', 'said'], days: 2 }, // Unit 1, Week 2, Day 2
          // { weekXref: 2, words: ['you', 'your', 'was'], days: 3 }, // Unit 1, Week 2, Day 3
          // { weekXref: 3, words: ['shall', 'full', 'pull'], days: 1 }, // U2
          // { weekXref: 3, words: ['both', 'talk', 'walk'], days: 2 },
          // { weekXref: 5, words: ['done', 'goes', 'pretty'], days: 2 }, // U3
          // { weekXref: 6, words: ['again', 'please', 'animal'], days: 2 }, // U4
          // { weekXref: 7, words: ['sure', 'use', 'used'], days: 1 },
          // { weekXref: 8, words: ['against', 'knew', 'know'], days: 1 }, // U5
          // { weekXref: 9, words: ['always', 'often', 'once'], days: 2 },
          // { weekXref: 10, words: ['only', 'house', 'move'], days: 1 }, // U6
          // { weekXref: 11, words: ['right', 'place', 'together'], days: 2 },
          // { weekXref: 12, words: ['eight', 'large', 'change'], days: 1 }, // U7
          // { weekXref: 13, words: ['city', 'every', 'family'], days: 1 },
          // { weekXref: 14, words: ['night', 'carry', 'something'], days: 1 },
          // { weekXref: 15, words: ['world', 'answer', 'different'], days: 1 }, // U8
          // { weekXref: 16, words: ['picture', 'learn', 'earth'], days: 1 }, // U9
          // { weekXref: 17, words: ['father', 'brother', 'mother'], days: 1 },
          // { weekXref: 18, words: ['great', 'country', 'away'], days: 1 }, // U10
          // { weekXref: 19, words: ['America', 'school', 'thought'], days: 1 },
          // { weekXref: 20, words: ['whose', 'won', 'son'], days: 1 }, // U11
          // { weekXref: 21, words: ['breakfast', 'head', 'ready'], days: 1 },
          // { weekXref: 22, words: ['favorite', 'early', 'ocean'], days: 1 }, // U12
          // { weekXref: 23, words: ['Monday', 'Tuesday', 'cousin'], days: 1 }, // U13
          // { weekXref: 24, words: ['lose', 'tomorrow', 'beautiful'], days: 1 },
          // { weekXref: 25, words: ['Wednesday', 'Thursday', 'Saturday'], days: 1 }, // U14
          // { weekXref: 26, words: ['bought', 'brought', 'piece'], days: 1 },
          // { weekXref: 27, words: ['January', 'February', 'July'], days: 1 }, // U15
          // { weekXref: 28, words: ['enough', 'special', 'December'], days: 1 },
          // { weekXref: 29, words: ['August', 'laugh', 'daughter'], days: 1 }, // U16
          // { weekXref: 30, words: ['trouble', 'couple', 'young'], days: 1 }, // U17
        ],
      },
      {
        program: 'funk',
        units: [3, 4, 5],
        words: [
          {
            unit: 3,
            words: [
              'the',
              'a',
              'and',
              'are',
              'to',
              'is',
              'his',
              'as',
              'has',
              'was',
            ],
          },
          {
            unit: 4,
            words: [
              'we',
              'she',
              'he',
              'be',
              'me',
              'I',
              'you',
              'they',
            ],
          },
          {
            unit: 5,
            words: [
              'or',
              'for',
              'of',
              'have',
              'from',
              'by',
              'my',
              'do',
              'one',
            ],
          },

        ],
      },
      {
        program: 'fun1',
        units: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        words: [

          {
            unit: 2,
            words: [
              'the', 'a', 'and', 'is', 'his', 'of',
            ],
          },
          {
            unit: 3,
            words: [
              'as', 'has', 'to', 'into',
              'we', 'he', 'she', 'be', 'me', 'for', 'or',
            ],
          },
          {
            unit: 4,
            words: [
              'you', 'your', 'I', 'they',
              'was', 'one', 'said',
            ],
          },
          {
            unit: 5,
            words: [
              'from', 'have', 'do', 'does',
            ],
          },
          {
            unit: 6,
            words: [
              'were', 'are',
              'who', 'what', 'when',
              'where', 'there', 'here',
            ],
          },
          {
            unit: 7,
            words: [
              'why', 'by', 'my', 'try',
              'put', 'two',
              'too', 'very', 'also', 'some', 'come',
            ],
          },
          {
            unit: 8,
            words: [
              'would', 'could', 'should',
              'her', 'over', 'number',
            ],
          },
          {
            unit: 9,
            words: [
              'say', 'says',
              'see', 'between', 'each',
            ],
          },
          {
            unit: 10,
            words: [
              'any', 'many',
              'how', 'now', 'down',
              'out', 'about', 'our',
            ],
          },
          {
            unit: 11,
            words: [
              'friend', 'other', 'another',
              'none', 'nothing',
            ],
          },
          {
            unit: 12,
            words: [
              'people', 'month',
              'little', 'been',
              'own', 'want', 'Mr.', 'Mrs.',
            ],
          },
          {
            unit: 13,
            words: [
              'work', 'word', 'write',
              'being', 'their', 'first',
              'look', 'good', 'new',
            ],
          },
          {
            unit: 14,
            words: [
              'water', 'called',
              'day', 'may', 'way',
            ],
          },
        ],
      },
    ],

  },
};
