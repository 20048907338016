import axios from 'axios';

// axios.interceptors.request.use((config) => {
//   const token = store.getters['authentication/access_token'];

//   console.log('setting interceptor', token);
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

export default {

  callValidateUserApi(contactId) {
    const headers = {
      'Content-Type': 'application/json',
    };
    const url = `${process.env.VUE_APP_SERVER_AUTH_URL}application=fundinteractivities`;
    const apiParams = JSON.stringify({ contactId });

    return axios.post(url, apiParams, { headers });
  }
};
