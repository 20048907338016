<template>
  <div
    :class="[{ selectable: isSelectable, selected: isSelected }, cardClass]"
    :data-cardId="card.cardId"
    :style="{ gridArea: card.row + ' / ' +computedColumn, backgroundColor: card.color }"
    @click="clickCard(card)"
    v-bind:aria-label="accessibleLabel(card)"
  >
    <transition name="bounce">
      <div class="star new-star" v-if="showNewCards && isNewCard"></div>
    </transition>
    <div class='star selected-star' v-if="isSelected && !isNewCard"></div>
    <span class="letter" aria-hidden="true" v-html="card.label"></span>
    <div class="lc-img">
      <img :alt="accessibleLabel(card)" :src="getImagePath(card.image)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LargeSoundCard',
  components: {},
  props: {
    card: { type: Object },
    cardClass: { type: String, default: '' },
    isSelectable: { type: Boolean, default: false },
    isNewCard: { type: Boolean, default: false },
    showNewCards: { type: Boolean, default: true },
    isSelected: { type: Boolean, default: false },
    programId: { type: String, default: '' },
  },
  data() {
    const computedColumn = '';
    return { computedColumn };
  },
  methods: {
    accessibleLabel(card) {
      return card.label.match(/.{1,1}/g).join('-');
    },
    clickCard(card) {
      this.$emit('cardClicked', card);
    },
    getImagePath(image) {
      return `/images${image}.svg`;
    },
    alignGreenCards() {
      // const firstColumnCards = [7, 15, 24, 33, 41, 55];
      // const secondColumnCards = [8, 16, 25, 34, 42, 56];
      const fun2CardColumnOffsets = [
        { card: 7, offset: 1 },
        { card: 15, offset: 1 },
        { card: 24, offset: 1 },
        { card: 33, offset: 1 },
        { card: 8, offset: 0 },
        { card: 16, offset: 0 },
        { card: 25, offset: 0 },
        { card: 34, offset: 0 },
        { card: 42, offset: 0 },
        { card: 41, offset: 1 },
        { card: 46, offset: 2 },
        { card: 47, offset: 1 },
        { card: 48, offset: 0 },
        { card: 55, offset: 1 },
        { card: 56, offset: 0 },
        { card: 62, offset: 2 },
        { card: 63, offset: 1 },
        { card: 64, offset: 0 },
        { card: 65, offset: 0 },
        { card: 66, offset: 3 },
        { card: 67, offset: 2 },
        { card: 68, offset: 1 },
        { card: 69, offset: 1 },
        { card: 70, offset: 0 },
      ];
      const fun3CardColumnOffsets = [
        { card: 7, offset: 1 },
        { card: 15, offset: 1 },
        { card: 24, offset: 1 },
        { card: 33, offset: 1 },
        // { card: 42, offset: 0 },
        // { card: 41, offset: 1 },
        // { card: 43, offset: 2 },
        // { card: 44, offset: 1 },
        // { card: 45, offset: 0 },
        { card: 8, offset: 0 },
        { card: 16, offset: 0 },
        { card: 25, offset: 0 },
        { card: 34, offset: 0 },
        // { card: 55, offset: 3 },
        // { card: 56, offset: 2 },
        // { card: 62, offset: 2 },
        // { card: 63, offset: 1 },
        // { card: 64, offset: 0 },
        // { card: 46, offset: 2 },
        // { card: 47, offset: 1 },
        // { card: 48, offset: 0 },
        // { card: 65, offset: 3 },
        // { card: 66, offset: 2 },
        // { card: 67, offset: 1 },
        // { card: 68, offset: 0 },
        // { card: 69, offset: 1 },
        // { card: 70, offset: 0 },
      ];
      const cardColumnOffsets = this.programId === 'fun2' ? fun2CardColumnOffsets : fun3CardColumnOffsets;
      const offset = cardColumnOffsets.find((c) => c.card === this.card.cardId);

      if (offset) {
        const lastColumn = window.getComputedStyle(document.getElementById('cards-container')).getPropertyValue('grid-template-columns').split(' ').length;
        this.computedColumn = lastColumn - offset.offset;
      } else {
        this.computedColumn = 'auto';
      }
    },
  },
  mounted() {
    this.alignGreenCards();
  },
  updated() {
    this.alignGreenCards();
  },
};
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0) rotateZ(0deg);
  }
  50% {
    transform: scale(2.5) rotateZ(180deg);
  }
  100% {
    transform: scale(1) rotateZ(359deg);
  }
}
.large-card {
  background-repeat: no-repeat;
  font-size: 1.5rem;
  font-family: wManuscript, sans-serif;
  text-align: center;
  cursor: pointer;
  border-radius: 1px;
  box-sizing: border-box;
  justify-content: center;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.33) 1px 0 1px 1px;
  position: relative;
  display: flex;
  flex-direction: column;

  .letter {
    position: absolute;
    top: 5%;
    width: 100%;
    text-align: center;
    line-height: 0.7;
    margin-top: 0.5rem;
  }
  .lc-img {
    width: 100%;
    position: absolute;
    bottom: 5%;
    top: 40%;
    img {
      margin: auto;
      max-width: 60%;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  &:after {
    height: 0;
    display: inline-block;
    width: 1px;
    padding-bottom: 130%;
    content: '';
  }
  &.selectable:not(.selected){
    &.yellow-card{
      background-color: $yellow-card-disabled;
    }
    &.red-card{
      background-color: $red-card-disabled;
    }
    &.green-card{
      background-color: $green-card-disabled;
    }
    img, span{
      opacity: .35;
    }
  }
}
.selected {
  background-color: orange;
  opacity: 1;
}
</style>
