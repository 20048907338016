import Vue from 'vue';
import Paintable from 'vue-paintable';
import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';
import App from './App.vue';
import router from './router';
// eslint-disable-next-line import/order
import VueAnalytics from 'vue-analytics';
import store from './store';
import Mixpanellevel from './mixins/mixpanel';

Vue.mixin(Mixpanellevel);

if (process.env.VUE_APP_DD_RUM_ENV && process.env.VUE_APP_DD_RUM_CLIENT_TOKEN) {
  let datdogSampleRate = 100;
  if (process.env.VUE_APP_DD_RUM_SAMPLE_RATE) {
    datdogSampleRate = parseInt(process.env.VUE_APP_DD_RUM_SAMPLE_RATE, 10);
  }
  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_RUM_APPID,
    clientToken: process.env.VUE_APP_DD_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'funactivities',
    env: process.env.VUE_APP_DD_RUM_ENV,
    sampleRate: datdogSampleRate,
    trackInteractions: true,
  });
}

Vue.config.productionTip = false;

const analytics = process.env.NODE_ENV === 'production' ? 'UA-171262608-11' : 'UA-171262608-12';
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: analytics,
  router,
});

const prodToken = 'ba49ee92e74530223c8cb96c2def29ae';
const devToken = 'd4ad2e94d03d69386a4072dda5221e1d';
const key = process.env.NODE_ENV === 'production' ? prodToken : devToken;

mixpanel.init(key);
mixpanel.register({ Application: 'Interactivities' });

Vue.use(Paintable, {
  // eslint-disable-next-line no-unused-vars
  setItem(_key, _image) {
    // localStorage.setItem(key, image);
  },
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

