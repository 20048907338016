<template>
  <div id="prevnext-wrapper">
    <button v-if="prevNextActivities.prevActivity!==''" class="prev prevnext" :class="prevNextActivities.prevActivity" @click="clickPrev()">
      <span class="activity-direction">&lt; Prev</span>
      <div class="activity-mini-tile"></div>
      <span class="btn-activity-name">{{ this.prevName }}</span>
    </button>
    <button v-if="prevNextActivities.nextActivity!==''" class="next prevnext" :class="prevNextActivities.nextActivity" @click="clickNext()">
      <span class="activity-direction">Next &gt;</span>
      <div class="activity-mini-tile"></div>
      <span class="btn-activity-name">{{ this.nextName }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
  name: 'PrevNextButtons',
  computed: {
    ...mapGetters('activityControl', ['nextPrevActivity']),
  },
  data() {
    const { programId, weekId, unitId, dayId } = this.$route.params;
    const prevNextActivities = {};
    const lookup = [
      { code: 'INC', name: 'Introduce New Concepts', route: 'buildwords' },
      { code: 'LC', name: 'Large Cards', route: 'largesoundcards' },
      { code: 'SC', name: 'Standard Cards', route: 'standardsoundcards' },
      { code: 'TW', name: 'Trick Words', route: 'trickwords' },
      { code: 'SUFF', name: 'Suffix Cards', route: 'suffixcards' },
      { code: 'VT', name: 'Vowel Teams', route: 'vowelteams' },
      { code: 'RC', name: 'R-Conrolled', route: 'rcontrolledvowels' },
      { code: 'VS', name: 'Vowel Sounds', route: 'vowelsounds' },
      { code: 'ST', name: 'Storytime', route: 'storytime' },
      { code: 'GWO', name: 'Guess Which One', route: 'guesswhichone' },
      { code: 'SA', name: 'Sound Alike', route: 'soundalike' },
      { code: 'SL', name: 'Silent Letters', route: 'silentletters' },
      { code: 'DictComp', name: 'Composition Book', route: 'compositionbook' },
      { code: 'DictDry', name: 'Dry Erase Tablet', route: 'dryerasetablet' },
      { code: 'CURSIVE', name: 'Cursive', route: 'cursive' },
    ];
    return { prevName: '', nextName: '', lookup, programId, weekId, unitId, dayId, prevNextActivities };
  },
  props: {
    activity: { type: String },
  },
  mounted() {
    this.prevNextActivities = this.nextPrevActivity(this.programId, this.activity, `${this.unitId}-${this.weekId}-${this.dayId}`);
    if (this.prevNextActivities.prevActivity !== '') {
      this.prevName = this.lookup.find((f) => f.code === this.prevNextActivities.prevActivity).name;
    }
    if (this.prevNextActivities.nextActivity !== '') {
      this.nextName = this.lookup.find((f) => f.code === this.prevNextActivities.nextActivity).name;
    }
  },
  methods: {
    clickNext() {
      const rte = this.lookup.find((f) => f.code === this.prevNextActivities.nextActivity).route;
      router.push({ name: rte, params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } });
    },
    clickPrev() {
      const rte = this.lookup.find((f) => f.code === this.prevNextActivities.prevActivity).route;
      router.push({ name: rte, params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } });
    },
  },
};

</script>

<style lang="scss">
  #prevnext-wrapper{
    position: absolute;
    bottom: 1%;
    left: -2%;
    right: -2%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    button{
      &:not(.shell-button){
        &:not(.plain-button){
          &.prevnext{
            font-size: 12px;
            max-width: 90px;
            background-color: $fun-yellow-lt;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            color: black;
            box-sizing: content-box;
            .btn-activity-name{
              z-index: 1;
              text-transform: none;
              line-height: .9;
              font-size: 14px;
              font-weight: 400;
              width: 100%;
              flex-grow: 0;
              flex-shrink: 0;
            }
            .activity-direction{
              font-weight: 700;
              text-transform: uppercase;
              margin-bottom: 3px;
              width: 100%;
            }
            &.prev{
              text-align: left;
              position: absolute;
              left: 0;
              bottom: 0;
              box-shadow: 2px 2px 4px -3px black;

              .btn-activity-name{
                padding-left: 13px;
              }
            }
            &.next{
              text-align: right;
              position: absolute;
              right: 0;
              bottom: 0;
              box-shadow: -2px 2px 4px -3px black;

              .btn-activity-name{
                padding-right: 13px;
              }
            }
          }
        }
      }
    }
  }
  .activity-mini-tile {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: bottom center;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    .LC & {
      background-image: url('/images/poster-tiles/large-sound-cards.svg');
      bottom: 66%;
    }
    .SC &{
      background-image: url('/images/poster-tiles/standard-sound-cards.svg');
      bottom: 60%;
      width: 4rem;
    }
    .TW &{
      background-image: url('/images/poster-tiles/trick-words.svg');
      bottom: 60%;
    }
    .SUFF &{
      background-image: url('/images/poster-tiles/suffix-cards.svg');
      bottom: 65%;
    }
    .VT &{
      background-image: url('/images/poster-tiles/vowel-teams.svg');
      bottom: 60%;
    }
    .RC &{
      background-image: url('/images/poster-tiles/r-controlled.svg');
      bottom: 60%;
    }
    .VS &{
      bottom: 65%;
      .fun2 &{
        background-image: url('/images/poster-tiles/vowel-sounds-2.svg');
      }
      .fun3 &{
        background-image: url('/images/poster-tiles/vowel-sounds-3.svg');
      }
    }
    .SL &{
      background-image: url('/images/poster-tiles/silent-letters.svg');
      bottom: 65%;
    }
    .SA &,
    .GWO & {
      background-image: url('/images/poster-tiles/sound-alike.svg');
    }
    .SA & {
      bottom: 60%;
    }
    .GWO & {
      bottom: 70%;
    }
    .ST &{
      background-image: url('/images/storytimescoop.svg');
      bottom: 50%;
    }
    .INC &{
      background-image: url('/images/pointer.svg');
      bottom: 70%;
      width: 20px;
    }
    .DictDry &{
      background-image: url('/images/poster-tiles/skyline.png');
      bottom: 60%;
      background-size: contain;
      width: 45%;
    }
    .DictComp &{
      background-image: url('/images/dg.svg');
      bottom: 0%;
      width: 30px;
    }
    .CURSIVE &{
      background-image: url('/images/cursive.svg');
      bottom: 40%;
      width: 30px;
    }
  }
  .INC.prev{
    .activity-mini-tile{
      right: 5%;
    }
  }
  .LC.prev{
    .activity-mini-tile{
      right: 4%;
    }
  }
  .SC.prev{
    .activity-mini-tile{
      right: 10%;
    }
  }
  .TW.prev{
    .activity-mini-tile{
      right: 5%;
    }
  }
  .SUFF.prev{
    .activity-mini-tile{
      right: 0;
    }
  }
  .VT.prev{
    .activity-mini-tile{
      right: 5%;
    }
  }
  .RC.prev{
    .activity-mini-tile{
      right: 0;
    }
  }
  .VS.prev{
    .activity-mini-tile{
      right: -5%;
    }
  }
  .SL.prev{
    .activity-mini-tile{
      right: -5%;
    }
  }
  .SA.prev,
  .GWO.prev{
    .activity-mini-tile{
      right: 0;
    }
  }
  .ST.prev{
    .activity-mini-tile{
      right: -5%;
      transform: rotate(10deg);
    }
  }
  .DictComp.prev{
    .activity-mini-tile{
      right: -5%;
      transform: rotate(10deg);
    }
  }
  .DictDry.prev{
    .activity-mini-tile{
      right: 0;
      transform: rotate(10deg);
    }
  }
  .CURSIVE.prev{
    .activity-mini-tile{
      right: 10%;
    }
  }

  .INC.next{
    .activity-mini-tile{
      left: 5%;
    }
  }
  .TW.next,
  .SA.next,
  .GWO.next {
    .activity-mini-tile{
      left:0%
    }
  }
  .SUFF.next{
    .activity-mini-tile{
      left: 1%;
    }
  }
  .VT.next{
    .activity-mini-tile{
      left: 5%;
    }
  }
  .RC.next{
    .activity-mini-tile{
      left: 0;
    }
  }
  .VS.next{
    .activity-mini-tile{
      left: -5%;
    }
  }
  .SL.next{
    .activity-mini-tile{
      left: -5%;
    }
  }
  .ST.next{
    .activity-mini-tile{
      left: -5%;
      transform: rotate(-10deg);
    }
  }
  .DictComp.next{
    .activity-mini-tile{
      left: 0;
      transform: rotate(-10deg);
    }
  }
  .DictDry.next{
    .activity-mini-tile{
      left: -5%;
      transform: rotate(-10deg);
    }
  }
  .CURSIVE.next{
    .activity-mini-tile{
      left: 10%;
    }
  }
</style>
