<template>
  <div id="positioner">
    <ActivityHeader
      activity-type="reference posters"
      activity-name="Spelling Options"
      back-button-title="Posters"
      :show-nav-buttons="false"
      back-button-route="chooseposters"></ActivityHeader>
    <div id="spelling-options-grid" data-automationId="reference_posters_spelling_options">
      <div class="spelling-option-row">
        <span>/ǝr/</span>
        <span>er</span>
        <span>ir</span>
        <span>ur</span>
        <span v-if="programId === 'fun3'">ar</span>
        <span v-if="programId === 'fun3'">or</span>
      </div>
      <div class="spelling-option-row">
        <span>/ū/</span>
        <span>u-e</span>
        <span>u</span>
        <span>ue</span>
      </div>
      <div class="spelling-option-row">
        <span>/ā/</span>
        <span>a-e</span>
        <span>a</span>
        <span>ai</span>
        <span>ay</span>
        <span v-if="programId === 'fun3'">eigh</span>
        <span v-if="programId === 'fun3'">ei</span>
        <span v-if="programId === 'fun3'">ea</span>
      </div>
      <div class="spelling-option-row">
        <span>/ü/</span>
        <span>u-e</span>
        <span>u</span>
        <span>ue</span>
        <span v-if="programId === 'fun3'">ew</span>
        <span>ou</span>
        <span>oo</span>
        <span v-if="programId === 'fun2'">ew</span>
        <span v-if="programId === 'fun3'">ui</span>
      </div>
      <div class="spelling-option-row">
        <span>/ē/</span>
        <span>e-e</span>
        <span>e</span>
        <span>y</span>
        <span v-if="programId === 'fun3'">i</span>
        <span>ee</span>
        <span v-if="programId === 'fun2'">ea</span>
        <span>ey</span>
        <span v-if="programId === 'fun3'">ea</span>
        <span v-if="programId === 'fun3'">ie</span>
        <span v-if="programId === 'fun3'">ei</span>
      </div>
      <div class="spelling-option-row">
        <span>/oi/</span>
        <span>oi</span>
        <span>oy</span>
      </div>
      <div class="spelling-option-row">
        <span>/ī/</span>
        <span>i-e</span>
        <span>i</span>
        <span>y</span>
        <span v-if="programId === 'fun3'">igh</span>
      </div>
      <div class="spelling-option-row">
        <span>/ou/</span>
        <span>ow</span>
        <span>ou</span>
      </div>
      <div class="spelling-option-row">
        <span>/ō/</span>
        <span>o-e</span>
        <span>o</span>
        <span>oa</span>
        <span>oe</span>
        <span>ow</span>
      </div>
      <div class="spelling-option-row">
        <span>/ȯ/</span>
        <span v-if="programId === 'fun3'">a</span>
        <span>au</span>
        <span>aw</span>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'SpellingOptions',
  components: { ActivityHeader },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },

  methods: {},
};
</script>

<style lang="scss">
#spelling-options-grid {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  .spelling-option-row {
    flex-basis: 50%;
    font-family: wbookman;
    display: flex;
    margin: 0.5vw;
    box-sizing: border-box;
    flex: 1 0 48%;
    transition: transform 250ms ease-in-out;
    transform-origin: left;
    &:hover {
      transform: scale(1.02);
    }
    span {
      padding: 2rem;
      flex: 0 0 auto;

      border-left: none;
      width: calc(100% / 7);
      text-align: center;
      padding: 1rem 0;
      box-sizing: border-box;
      font-size: 2.5vw;
      letter-spacing: 0.01rem;
      padding: 0.8rem 0;
      border-width: 0.1rem 0.05rem 0.1rem 0.05rem;

      .fun2 & {
        border-style: solid;
        border-color: $fun-green;
        background-image: linear-gradient(to right, white, $fun-green-lt);
        flex-basis: calc(100% / 7);
        font-size: 2.5vw;
        &:first-child {
          border-left: solid 0.1rem $fun-green;
        }
      }
      .fun3 & {
        border-style: solid;
        border-color: $fun-purple;
        background-image: linear-gradient(to right, white, $fun-purple-lt);
        flex-basis: calc(100% / 10);
        font-size: 3rem;
        @media(max-width: 1200px){
          font-size: 2rem;
        }
        &:first-child {
          border-left: solid 0.1rem $fun-purple;
        }
      }

      &:first-child {
        border-top-left-radius: 0.25vw;
        border-bottom-left-radius: 0.25vw;
        background-image: none;
        background-color: $fun-yellow;
      }
      &:last-child {
        border-top-right-radius: 0.25vw;
        border-bottom-right-radius: 0.25vw;
      }
    }
  }
}
</style>
