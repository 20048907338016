<template>
  <div id="positioner">
    <ActivityHeader :activity-type="activityType" back-button-title="Units" :show-nav-buttons="true" back-button-route="chooseunit" />
    <div id="wiz-shortcuts">
      <div v-if="programId === 'fun3'" class="button wiz-shortcut" id="cursive-shortcut" @click="selectActivity('cursive')">
        <img alt="cursive letter formation" src="/images/poster-tiles/letter-formation.svg" />
        <span>Cursive Letter Formation</span>
      </div>

      <div
        @click="selectActivity('referenceposters')"
        class="button wiz-shortcut"
        id="reference-posters-shortcut"
        v-if="programId !== 'funk' && programId !== 'fun1'"
      >
        <img alt="reference posters" src="/images/poster-tiles/posters.svg" />
        <span>Reference Posters</span>
      </div>
    </div>
    <div class="wiz-mod-heading" v-if="showWeekButtons">
      <h1 class="wiz-heading">Select a Week</h1>
    </div>

    <ul class="weeks-container wiz-buttons" v-if="showWeekButtons">
      <li v-for="week in weeksForUnit(`${programId}Data`, unitId)" :key="week">
        <div class="week wiz-button" v-bind:aria-label="'week ' + week" @click="clickWeek(week)">Week {{ week }}</div>
      </li>
    </ul>

    <div class="wiz-mod-heading quick-links">
      <h1 class="wiz-heading">Quick Links</h1>
    </div>

    <div class="activity-tiles drillsounds-menu select-week-menu wiz-buttons">
      <router-link
        v-if="isAvailable"
        :to="{ name: 'largesoundcards', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile drillsounds-activity-tile"
        id="largesoundcards"
      >
        <img alt="" src="/images/poster-tiles/large-sound-cards.svg" />
        <span
          >Large
          <br />
          <span>Sound Cards</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: 'standardsoundcards', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile drillsounds-activity-tile"
        id="standardsoundcards"
      >
        <img alt="" src="/images/poster-tiles/standard-sound-cards.svg" />
        <span
          >Standard
          <br />
          <span>Sound Cards</span>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'trickwords', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile fun2"
        id="trickwords"
        v-if="this.programId === 'fun2' && this.unitId > 1"
      >
        <img alt="" src="/images/poster-tiles/trick-words.svg" />
        <span
          >Trick
          <br />
          <span>Words</span>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'suffixcards', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile drillsounds-activity-tile"
        id="suffixcards"
        v-if="this.programId === 'fun2' && parseInt(this.unitId) >= 4"
      >
        <img alt="" src="/images/poster-tiles/suffix-cards.svg" />
        <span
          >Suffix
          <br />
          <span>Frames</span>
        </span>
      </router-link>
      <router-link
        v-if="this.programId === 'fun3'"
        :to="{ name: 'soundalike', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile fun3"
        id="soundalike"
      >
        <img alt="" src="/images/poster-tiles/sound-alike.svg" />
        <span
          >Sound
          <br />
          <span>Alike</span>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'buildwords', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile drillsounds-activity-tile"
        id="buildwords"
      >
        <img alt="" src="/images/poster-tiles/build-words.svg" />
        <span
          >Build
          <br />
          <span>Words</span>
        </span>
      </router-link>
      <router-link
        v-if="(programId === 'fun2' && +unitId > 1) || programId === 'fun1'"
        :to="{ name: 'storytime', params: { programId: this.programId, weekId: '0', unitId: this.unitId, dayId: '0' } }"
        class="activity-tile drillsounds-activity-tile"
        id="storytime"
      >
        <img alt="" src="/images/poster-tiles/storytime.svg" />
        <span
          >Storytime
          <br />
          <span>&#8203;</span>
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';
import router from '@/router';
import mixpanel from 'mixpanel-browser';

export default {
  name: 'ChooseWeek',
  components: { ActivityHeader },
  // data() {
  //   const activityType = '';
  //   return { activityType };
  // },
  props: {
    programId: { type: String, default: '' },
    unitId: { type: String, default: '' },
  },

  computed: {
    ...mapGetters('dataStore', ['allUnits', 'weeksForUnit']),
    isAvailable() {
      return this.programId === 'fun2' || this.programId === 'fun3' /* && !['3', '4', 'Bonus', '11'].includes(this.unitId) */;
    },
    showWeekButtons() {
      return process.env.VUE_APP_HIDE_WEEK_BUTTONS === 'false';
    },
    activityType() {
      return this.showWeekButtons ? 'Choose Week' : '';
    },
  },

  methods: {
    selectActivity(activity) {
      router.push({ name: activity, params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } });
    },
    clickWeek(week) {
      mixpanel.track('Week Selected', { Unit: this.unitId, Week: week });
      router.push({ name: 'chooseday', params: { programId: this.programId, unitId: this.unitId, weekId: week.toString() } });
    },
  },
};
</script>

<style scoped lang="scss">
.activity-tiles {
  &.drillsounds-menu {
    .fun2 & {
      grid-template-columns: repeat(5, minmax(15rem, 25rem));
    }
    .fun3 & {
      grid-template-columns: repeat(4, minmax(15rem, 25rem));
    }
    .fun2 &,
    .fun3 & {
      width: 80%;
      @media (max-height: 750px) {
        width: 100%;
      }
      .activity-tile {
        // @at-root .activity-tiles .activity-tile.drillsounds-activity-tile span {
        //   background-color: $blue;
        // }
        &#largesoundcards {
          grid-column: auto / span 1;
          grid-row: 1 / span 1;
        }
        &#standardsoundcards {
          grid-column: auto / span 1;
          grid-row: 1 / span 1;
        }
        &#suffixcards {
          grid-column: auto / span 1;
          grid-row: 1 / span 1;
        }
        &#trickwords,
        &#soundalike {
          grid-column: auto / span 1;
          grid-row: 2 / span 1;
        }
      }
    }
  }
  &.select-week-menu {
    .fun2 &,
    .fun3 & {
      @media (max-height: 830px) {
        //grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      }
      .activity-tile {
        &#trickwords,
        &#soundalike {
          grid-row: 1 / span 1 !important;
        }
      }
    }
  }
}
</style>
