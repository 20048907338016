export default {
  namespaced: true,
  state: {
    soundAlikeWords: [
      {
        program: 'fun3',
        units: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],

        data: [
          { // U1
            unit: '1',
            unitNumber: 1,
            wordPairs: [
              {
                idx: 0,
                words: ['write', 'right'],
              },
              {
                idx: 1,
                words: ['know', 'no'],
              },
              {
                idx: 2,
                words: ['which', 'witch'],
              },
            ],
          },
          { // U2
            unit: '2',
            unitNumber: 2,
            wordPairs: [
              {
                idx: 3,
                words: ['son', 'sun'],
              },
              {
                idx: 4,
                words: ['some', 'sum'],
              },
              {
                idx: 5,
                words: ['banned', 'band'],
              },
              {
                idx: 6,
                words: ['guest', 'guessed'],
              },
              {
                idx: 7,
                words: ['missed', 'mist'],
              },
            ],
          },
          { // U3
            unit: '3',
            unitNumber: 3,
            wordPairs: [
              {
                idx: 8,
                words: ['plain', 'plane'],
              },
            ],
          },
          { // U4
            unit: '4',
            unitNumber: 4,
            wordPairs: [
              {
                idx: 9,
                words: ['mail', 'male'],
              },
              {
                idx: 10,
                words: ['mind', 'mined'],
              },
              {
                idx: 11,
                words: ['find', 'fined'],
              },
            ],
          },
          { // U5
            unit: '5',
            unitNumber: 5,
            wordPairs: [
              {
                idx: 12,
                words: ['weather', 'whether'],
              },
              {
                idx: 13,
                words: ['father', 'farther'],
              },
            ],
          },
          { // U6
            unit: '6',
            unitNumber: 6,
            wordPairs: [
              {
                idx: 14,
                words: ['hi', 'high'],
              },
              {
                idx: 15,
                words: ['by', 'buy', 'bye'],
              },
              {
                idx: 16,
                words: ['oh', 'owe'],
              },
            ],
          },
          { // U7
            unit: '7',
            unitNumber: 8,
            wordPairs: [
              {
                idx: 17,
                words: ['flour', 'flower'],
              },
              {
                idx: 18,
                words: ['thrown', 'throne'],
              },
            ],
          },
          { // U8
            unit: '8',
            unitNumber: 9,
            wordPairs: [
              {
                idx: 19,
                words: ['principal', 'principle'],
              },
              {
                idx: 20,
                words: ['wear', 'where'],
              },
              {
                idx: 21,
                words: ['weight', 'wait'],
              },
              {
                idx: 22,
                words: ['week', 'weak'],
              },
              {
                idx: 23,
                words: ['meet', 'meat'],
              },
            ],
          },
          { // U9
            unit: '9',
            unitNumber: 10,
            wordPairs: [
              {
                idx: 24,
                words: ['heard', 'herd'],
              },
              {
                idx: 25,
                words: ['berry', 'bury'],
              },
              {
                idx: 26,
                words: ['warn', 'worn'],
              },
            ],
          },
          { // U10
            unit: '10',
            unitNumber: 11,
            wordPairs: [
              {
                idx: 27,
                words: ['sail', 'sale'],
              },
              {
                idx: 28,
                words: ['led', 'lead'],
              },
              {
                idx: 29,
                words: ['break', 'brake'],
              },
              {
                idx: 30,
                words: ['pail', 'pale'],
              },
            ],
          },
          { // U11
            unit: '11',
            unitNumber: 12,
            wordPairs: [
              {
                idx: 31,
                words: ['it\'s', 'its'],
              },
              {
                idx: 32,
                words: ['there', 'their', 'they\'re'],
              },
            ],
          },

          { // u12
            unit: '12',
            unitNumber: 13,
            wordPairs: [
              {
                idx: 33,
                words: ['cell', 'sell'],
              },
              {
                idx: 34,
                words: ['peace', 'piece'],
              },
              {
                idx: 35,
                words: ['cent', 'sent', 'scent'],
              },
              {
                idx: 36,
                words: ['scene', 'seen'],
              },
            ],
          },
          { // U13
            unit: '13',
            unitNumber: 14,
            wordPairs: [
              {
                idx: 37,
                words: ['knew', 'new'],
              },
              {
                idx: 38,
                words: ['knight', 'night'],
              },
              {
                idx: 39,
                words: ['knows', 'nose'],
              },
            ],
          },

          { // U14
            unit: '14',
            unitNumber: 15,
            wordPairs: [
              {
                idx: 40,
                words: ['stationary', 'stationery'],
              },
              {
                idx: 41,
                words: ['eight', 'ate'],
              },
              {
                idx: 42,
                words: ['straight', 'strait'],
              },
              {
                idx: 43,
                words: ['side', 'sighed'],
              },
            ],
          },

        ],
      },
    ],
  },
};
