<template>
  <div class="pair-screen-wrapper">
    <div class="pair-container">
      <div class="silent-letter-card" v-for="(word, wordIndex) in wordPair" :key="wordIndex">
        <div class="word">
          {{ word }}
        </div>
      </div>
    </div>
    <div>
      <button @click="done">Close</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SoundAlikePairs',
  props: {
    programId: { type: String },
    wordPair: { type: Array },
  },
  methods: {
    done() {
      this.$emit('closed');
    },
  },
};
</script>

<style lang="scss" scoped>
.pair-screen-wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button{
    margin: 6rem auto;
  }
}
.pair-container {
  display: flex;
  height: 30%;
  width: 80%;
  margin: 0 auto;
  .silent-letter-card{
    width: 46% !important;
    height: 100% !important;
    margin: auto;
    position: relative;
      border: solid 1px rgba(0, 0, 0, .4);

    .label{
      bottom: 3%;
      left: 2%;
    }
  }
}

.word {
  text-transform: lowercase;
  font-family: wManuscript, sans-serif;
  font-size: 6vw;
  margin: auto;
  color: $blue;
}
</style>
