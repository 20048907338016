export default {
  namespaced: true,

  state: {
    suffixCardData: {
      cards: [
        { include: 6, cardId: 1, label: 'es', col: 1, row: 1 },
        { include: 6, cardId: 2, label: 's', col: 2, row: 1 },
        { include: 6, cardId: 3, label: 'ing', col: 1, row: 2 },
        { include: 6, cardId: 5, label: 'ed', col: 1, row: 3 },
        { include: 6, cardId: 7, label: 'er', col: 1, row: 4 },
        { include: 6, cardId: 9, label: 'est', col: 1, row: 5 },
        { include: 9, cardId: 6, label: 'less', col: 2, row: 3 },
        { include: 9, cardId: 4, label: 'ful', col: 2, row: 2 },
        { include: 9, cardId: 8, label: 'ness', col: 2, row: 4 },
        { include: 9, cardId: 10, label: 'ment', col: 2, row: 5 },
        { include: 9, cardId: 11, label: 'ish', col: 1, row: 6 },
        { include: 9, cardId: 13, label: 'en', col: 1, row: 7 },
        { include: 9, cardId: 15, label: 'able', col: 1, row: 8 },
        { include: 11.2, cardId: 16, label: 'ive', col: 1, row: 9 },
        { include: 14, cardId: 12, label: 'ly', col: 2, row: 6 },
        { include: 14, cardId: 14, label: 'ty', col: 2, row: 7 },
        { include: 14, cardId: 17, label: 'y', col: 1, row: 10 },
      ],
      // cardXref: [
      //   {
      //     weeks: ['4-1', '4-2', '5-1'],
      //     cards: [1, 2, 3, 5, 7, 9],
      //   },
      //   {
      //     weeks: ['4-1', '4-2', '5-1', '5-2', '6-1'],
      //     cards: [1, 2, 3, 5, 7, 9, 11, 13, 15, 6, 8, 10, 4],
      //   },
      //   {
      //     weeks: ['4-1', '4-2', '5-1', '5-2', '6-1', '6-2', '7-1', '7-2'],
      //     cards: [1, 2, 3, 5, 7, 9, 11, 13, 15, 6, 8, 10, 4, 16],
      //   },
      //   {
      //     weeks: ['4-1', '4-2', '5-1', '5-2', '6-1', '6-2', '7-1', '7-2', '7-3', '8-1', '9-1', '9-2', '10-1', '10-2', '11-1', '11-2', '12-1', '13-1', '13-2', '14-1', '15-1', '15-2', '16-1', '17-1', '17-2'],
      //     cards: [1, 2, 3, 5, 7, 9, 11, 13, 15, 6, 8, 10, 4, 16, 12, 14, 17],
      //   },
      // ],
    },
  },
};
