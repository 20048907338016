<template>
  <div id="positioner">
    <ActivityHeader activity-type="Dictation"
    back-button-title="Activities"
    back-button-route="chooseactivity"/>
    <p class="get-started">Pick an activity to get started.</p>

    <div class="activity-tiles dictation-menu">
      <router-link
        :to="{ name: 'compositionbook', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        v-if="isAvailable('DictComp')"
        class="activity-tile dictation-activity-tile"
        id="compositionbook"
      >
        <img src="/images/poster-tiles/dictation-worksheet.png" />
        <span>
          Composition
          <br />
          <span>Book</span>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'dryerasetablet', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId } }"
        v-if="isAvailable('DictDry')"
        class="activity-tile dictation-activity-tile"
        id="dryerasetablet"
      >
        <img src="/images/poster-tiles/dictation-grid.png" />
        <span>
          Dry-Erase
          <br />
          <span>Tablet</span>
        </span>
      </router-link>
    </div>
    <div id="menu-bg-image" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'Dictation',
  components: { ActivityHeader },
  data() {
    const available = [];
    return { available };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  mounted() {
    this.available = this.availableActivities(this.programId, `${this.unitId}-${this.weekId}-${this.dayId}`);
  },
  computed: {
    ...mapGetters('activityControl', ['availableActivities']),
  },
  methods: {
    isAvailable(activity) {
      if (this.available.length === 0) {
        return true;
      }
      return this.available.includes(activity);
    },
  },
};
</script>

<style scoped lang="scss">
#menu-bg-image {
  background-image: url('/images/Fun1/illustrations/queen.svg');
}
.activity-tiles {
  &.dictation-menu {
    grid-template-columns: repeat(4, minmax(15rem, 25rem));
    width: 90%;
    .activity-tile {
      &#dryerasetablet {
        img {
        width: 60%;
        left: 20%;
        top: 0%;
        }
      }
      &#compositionbook {
        img {
          width: 80%;
          left: 10%;
          top: 0;
        }
      }
    }
  }
}
</style>
