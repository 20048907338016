<template>
  <div id="positioner" class="drillsounds">
    <ActivityHeader activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
     activity-name="Guess Which One"
     back-button-title="Drill Sounds"
     back-button-route="choosedrillsounds" />

    <div id="carousel-wrapper" class="soundalike-carousel" v-if="!showConfig">
      <carousel-3d :controls-visible="true" :count="allWords.length" :space="100" :perspective="0" :clickable="false">
        <slide v-for="(word, i) in allWords" :index="i" :key="i" :data-pair-id="word.idx">
          <div class="silent-letter-card box">
            <div class="word">{{ word.word }}</div>
            <span class="label">
              Fundations
              <span class="tw-fun-level">Level 3</span>
            </span>
            <span class="counter">{{ i + 1 }} of {{ allWords.length }}</span>
          </div>
        </slide>
      </carousel-3d>
      <span class="explainer-label" v-bind:class="{ showExplainer: showExplainer }"><span>Use the arrows to cycle through the flash cards</span></span>
    </div>

    <sound-alike-pairs
      :word-list="allWords"
      :program-id="programId"
      :word-pair-id="selectedWordPairId"
      v-if="showConfig"
      @closed="hideExplainer"
    ></sound-alike-pairs>
    <PrevNextButtons activity="GWO"></PrevNextButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import SoundAlikePairs from '@/components/SoundAlikePairs.vue';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'GuessWhichOne',
  components: { ActivityHeader, PrevNextButtons, Carousel3d, Slide, SoundAlikePairs },
  data() {
    return {
      allWords: [],
      wordPairs: [],
      wordList: [],
      showConfig: false,
      showExplainer: true,
      selectedWordPairId: 0,
    };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  created() {
    this.getDataForDisplay();
    setTimeout(() => {
      this.hideExplainer();
    }, 4000);
  },
  computed: {
    ...mapState('soundalike', ['soundAlikeWords']),
    ...mapGetters('dataStore', ['weekNumber', 'unitAndWeek']),
  },
  methods: {
    hideExplainer() {
      this.showExplainer = false;
    },
    getDataForDisplay() {
      const soundAlikeForProgram = this.soundAlikeWords.find((c) => c.program === this.programId);
      const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      const prevFiveUnits = Math.max(Number(this.unitId) - 5, 1);
      const prevFineWeekNumber = this.weekNumber(this.programId, prevFiveUnits, '1');
      this.wordList = soundAlikeForProgram.words.filter((w) => w.weekXref >= prevFineWeekNumber && w.weekXref <= weekNumber);
      this.wordPairs = this.wordList.map((w) => w.wordPairs).flatMap((f) => f);

      this.wordPairs.forEach((pair, idx) => {
        pair.words.forEach((w) => {
          this.allWords.push({ word: w, idx });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .soundalike-carousel{
    display: flex;
    flex-direction: column;
    .explainer-label{
      background-image: url('/images/pointer.svg');
      background-size: 25px;
      background-position: center left;
      background-repeat: no-repeat;
      padding: 1rem 0 1rem 3rem;
      font-size: 2rem;
      position: absolute;
      top: 100%;
      align-self: center;
      transition: opacity 1s ease-in-out;
      opacity: 0;
      span{
        background-image: radial-gradient(circle, #fffca7, white);
      }
      &.showExplainer{
        opacity: 1;
      }
    }
  }

</style>
