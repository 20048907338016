<template>
  <div id="positioner">
    <ActivityHeader activity-type="Sentence & Syllable Frames"
      :back-button-title="backButtonTitle"
      back-button-route="chooseactivity"/>
    <div class="sentenceframes">
      <div class="help-text-wrapper">
        <span class="help-text bounce-7">Drag frames here to build</span>
      </div>
      <div class="upper help-text-wrapper">
        <span class="help-text bounce-8">Arrange frames here to snap to a row</span>
      </div>

      <div
        class="frame-drop-row drop-area"
        id="frame-drop-row"
        dropzone
        @dragover.self.prevent
        @drop.self.prevent="handleDrop($event)"
        @dragend="onDragEnd($event)"></div>
      <div
        class="drop-area"
        id="drop-area"
        dropzone
        @dragover.self.prevent
        @drop.self.prevent="handleDrop($event)"
        @dragend="onDragEnd($event)"
      ></div>

      <div class="controls">
        <div id="double-tap-helper">
          <span>double-tap frames<br>to add text</span>
        </div>
        <div class="inner">
          <div
            class="senFrame frame"
            id="senFrame"
            draggable
            ondrop="return false;"
            @dragstart.self="onDragStart($event)"
            @dragend="onDragEnd($event)"
            @touchstart="startTouchDrag"
            @touchmove="onTouchDrag"
            @touchend="onTouchEnd"
          >
            <div class="height-sizer"></div>
            <input class="frameText"
              draggable="false"
              @blur="inputBlur($event)"
              @click.self="inputClick($event)"
              style="font-size: 100%;"
              @keyup="fitText($event)" />
            <span class="sf-border"></span>
          </div>
          <div
            class="sylFrame frame"
            id="sylFrame"
            draggable
            ondrop="return false;"
            @dragstart.self="onDragStart($event)"
            @dragend="onDragEnd($event)"
            @touchstart="startTouchDrag"
            @touchmove="onTouchDrag"
            @touchend="onTouchEnd"
          >
            <div class="height-sizer"></div>
            <input class="frameText"
              draggable="false"
              @blur="inputBlur($event)"
              @click.self="inputClick($event)"
              style="font-size: 100%;"
              @keyup="fitText($event)" />
            <span class="sf-border"></span>
          </div>

          <div
            class="punctFrame-lg frame"
            id="punctFrame-lg"
            draggable
            ondrop="return false;"
            @dragstart.self="onDragStart($event)"
            @dragend="onDragEnd($event)"
            @touchstart="startTouchDrag"
            @touchmove="onTouchDrag"
            @touchend="onTouchEnd"
          >
            <div class="height-sizer"></div>
            <input class="frameText"
              draggable="false"
              @blur="inputBlur($event)"
              @click.self="inputClick($event)"
              style="font-size: 100%;"
              @keyup="fitText($event)" />
            <span class="sf-border"></span>
          </div>

          <div
            class="punctFrame-sm frame"
            id="punctFrame-sm"
            draggable
            ondrop="return false;"
            @dragstart.self="onDragStart($event)"
            @dragend="onDragEnd($event)"
            @touchstart="startTouchDrag"
            @touchmove="onTouchDrag"
            @touchend="onTouchEnd"
          >
            <div class="height-sizer"></div>
            <input class="frameText"
              draggable="false"
              @blur="inputBlur($event)"
              @click.self="inputClick($event)"
              style="font-size: 100%;"
              @keyup="fitText($event)" />
            <span class="sf-border"></span>
          </div>
          <div
            class="sylFrame white frame"
            id="sylFrame-white"
            ondrop="return false;"
            draggable
            @dragstart.self="onDragStart($event)"
            @dragend="onDragEnd($event)"
            @touchstart="startTouchDrag"
            @touchmove="onTouchDrag"
            @touchend="onTouchEnd"
          >
            <div class="height-sizer"></div>
            <input
              class="frameText"
              draggable="false"
              @blur="inputBlur($event)"
              @click.self="inputClick($event)"
              style="font-size: 100%;"
              @keyup="fitText($event)" />
            <span class="sf-border"></span>
          </div>
          <button type="button" id="clear-frames" @click="clear">Clear Frames</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ActivityHeader from '@/components/ActivityHeader.vue';
import dragHelper from '@/helpers/checkNativeDrag';

export default {
  name: 'SentenceFrames',
  components: { ActivityHeader },
  data() {
    const backButtonTitle = '';
    const frameClone = '';
    const cursorXoffset = '';
    const cursorYoffset = '';
    const currentFontSize = '';
    return {
      backButtonTitle,
      frameClone,
      cursorXoffset,
      cursorYoffset,
      currentFontSize,

      touchTimeout: null,
      dragEvent: null,
    };
  },
  props: {
    programId: { type: String },
  },
  methods: {
    makeDraggable(event) {
      event.target.parentElement.setAttribute('draggable', 'true');
    },
    droppedInDropzone(event) {
      this.preventSelect();
      const x = event.clientX;
      const y = event.clientY;
      // if the frame was dropped from the drop area above:
      // if mouse is over dropzone OR it came from controls below
      const parent = event.srcElement.parentNode;
      if (parent && (parent.classList.contains('drop-area') || parent === document.elementFromPoint(x, y) || parent.classList.contains('frame-drop-row'))) {
        return true;
      }
      // dropped OUTSIDE the drop zone
      return false;
    },

    isModifierKey(key) {
      return (key === 'Meta' || key === 'Control' || key === 'Alt' || key === 'Shift' || key.startsWith('Arrow'));
    },

    fitText(e) {
      if (this.isModifierKey(e.key)) {
        // not a letter key
        return;
      }
      const scrollBox = e.target;
      let fontSizeToChange;
      let safety = 0;

      if ((scrollBox.scrollWidth > Math.ceil(scrollBox.getBoundingClientRect().width))) {
        // overflowing
        while (scrollBox.scrollWidth > Math.ceil(scrollBox.getBoundingClientRect().width) && safety < 20) {
          fontSizeToChange = parseFloat(scrollBox.style.fontSize);
          scrollBox.style.fontSize = `${fontSizeToChange - 1}%`;
          safety += 1; // hack to guard against infinite loop
        }
      } else if (scrollBox.scrollWidth <= Math.ceil(scrollBox.getBoundingClientRect().width)) {
        // NOT overflowing
        safety = 0;
        fontSizeToChange = parseFloat(scrollBox.style.fontSize);
        while ((fontSizeToChange < 100) && (scrollBox.scrollWidth <= Math.ceil(scrollBox.getBoundingClientRect().width)) && safety < 20) {
          scrollBox.style.fontSize = `${fontSizeToChange + 1}%`;
          fontSizeToChange = parseFloat(scrollBox.style.fontSize);
          safety += 1; // hack to guard against infinite loop
        }
      }
    },
    resizeFonts() {
      const frameWidth = document.getElementById('senFrame').offsetWidth;
      this.currentFontSize = frameWidth * 0.25;
      // set the global font multiplier
      document.getElementById('positioner').setAttribute('style', `font-size: ${this.currentFontSize}px`);
    },
    clear() {
      const dropRegion = document.getElementById('drop-area');
      dropRegion.innerHTML = '';
      const upperDropRegion = document.getElementById('frame-drop-row');
      upperDropRegion.innerHTML = '';
      const toolFrames = Array.from(document.getElementsByClassName('frameText'));
      toolFrames.forEach((f) => {
        f.value = '';
      });
    },
    convertToPercentageWidthInBox(position, box) {
      return `${(position / parseFloat(getComputedStyle(box).width, 10)) * 100}%`;
    },
    convertToPercentageHeightInBox(position) {
      return `${(position / parseFloat(getComputedStyle(document.getElementById('drop-area')).height, 10)) * 100}%`;
    },
    handleDrop(evt) {
      this.frameClone.remove();
      const data = evt.dataTransfer.getData('text');
      const dropStart = evt.dataTransfer.getData('dragFrom');
      const orig = document.getElementById(data);
      const nodeCopy = orig.cloneNode(true);
      const textNode = nodeCopy.getElementsByTagName('input')[0];

      let target;
      if (dropStart === 'controls') {
        nodeCopy.id = Math.floor(Math.random() * 1000).toString();
        nodeCopy.style.position = 'absolute';
        if (evt.target.classList.contains('drop-area')) {
          target = evt.target;
        } else {
          target = evt.target.parentElement;
          // find the row this card is on
          while (!target.classList.contains('drop-area')) {
            target = target.parentElement;
          }
        }
        if (target.id === 'drop-area') {
          nodeCopy.style.top = `${this.convertToPercentageHeightInBox(evt.clientY - target.getBoundingClientRect().top - this.cursorYoffset)}`;
          nodeCopy.style.left = `${this.convertToPercentageWidthInBox(evt.clientX - target.getBoundingClientRect().left - this.cursorXoffset, document.getElementById('drop-area'))}`;
          nodeCopy.style.zIndex = this.frontIndex() + 1;
        } else if (target.id === 'frame-drop-row') {
          nodeCopy.style.top = `unset`;
          nodeCopy.style.left = `${this.convertToPercentageWidthInBox(evt.clientX - target.getBoundingClientRect().left - this.cursorXoffset, document.getElementById('frame-drop-row'))}`;
        }

        if (!nodeCopy.classList.contains('placed')) {
          nodeCopy.classList.add('placed');
        }

      } else {

        if (evt.target.classList.contains('drop-area') || evt.target.classList.contains('frame-drop-row')) {
          target = evt.target;
        } else {
          target = evt.target.parentElement;
          // find the row this card is on
          while (!target.classList.contains('drop-area')) {
            target = target.parentElement;
          }
        }

        if (target.id === 'drop-area') {
          nodeCopy.style.top = `${this.convertToPercentageHeightInBox(evt.clientY - target.getBoundingClientRect().top - this.cursorYoffset)}`;
          nodeCopy.style.left = `${this.convertToPercentageWidthInBox(evt.clientX - target.getBoundingClientRect().left - this.cursorXoffset, document.getElementById('drop-area'))}`;
          nodeCopy.style.zIndex = this.frontIndex() + 1;
        } else if (target.id === 'frame-drop-row') {
          nodeCopy.style.top = `unset`;
          nodeCopy.style.left = `${this.convertToPercentageWidthInBox(evt.clientX - target.getBoundingClientRect().left - this.cursorXoffset, document.getElementById('frame-drop-row'))}`;
        }
      }
      nodeCopy.addEventListener('dragover', (e) => {
        e.preventDefault();
      });
      nodeCopy.addEventListener('dragstart', this.onTopDragStart);
      nodeCopy.addEventListener('dragend', this.onDragEnd);
      nodeCopy.addEventListener('drop', () => false);
      nodeCopy.addEventListener('touchstart', (e) => { this.startTouchDrag(e, 'top'); });
      nodeCopy.addEventListener('touchmove', (e) => { this.onTouchDrag(e, 'top'); });
      nodeCopy.addEventListener('touchend', (e) => { this.onTouchEnd(e, 'top'); });

      textNode.addEventListener('keyup', (e) => this.fitText(e));
      nodeCopy.style.zIndex = this.frontIndex() + 1;

      target.appendChild(nodeCopy);
      nodeCopy.classList.remove('hide');
      if (dropStart !== 'controls' && dropStart !== target) {
        orig.parentNode.removeChild(orig);
      }
      document.activeElement.blur();
    },

    frontIndex() {
      const placedItems = Array.from(document.getElementsByClassName('placed'));
      let topIndex = 0;
      placedItems.forEach((e) => {
        const zIndex = parseInt(e.style.zIndex, 10);
        if (zIndex > topIndex) {
          topIndex = zIndex;
        }
      });
      return topIndex;

    },

    runTouchTimeout() {
      this.touchTimeout = setTimeout(() => {
        this.stopTouchTimeout();
      }, 250);
    },

    stopTouchTimeout() {
      clearTimeout(this.touchTimeout);
      this.touchTimeout = null;
    },

    startTouchDrag(e, source = 'controls') {
      // make sure it's not a click
      this.runTouchTimeout();

      if (!dragHelper.isNativeDragSupported()) return;

      // stop the touch from being mapped to a click
      if (e.cancelable) e.preventDefault();

      // if there is another clone, don't do it
      const existingClone = document.querySelector('.ghost-element');
      if (existingClone) return;

      let element = e.target;
      while (element.getAttribute('draggable') !== 'true') {
        element = element.parentElement;
      }

      // clone the element
      const clone = element.cloneNode(true);
      const elementTop = element.getBoundingClientRect().top;
      const elementLeft = element.getBoundingClientRect().left;

      // hide original if in top
      if (source === 'top') element.classList.add('hide');

      let upperBump = 0;
      if (element.classList.contains('senFrame')) {
        upperBump = element.offsetHeight * 0.33;
      }
      if (element.classList.contains('punctFrame-sm')) {
        upperBump = element.offsetHeight * 0.5;
      }

      clone.style.position = 'fixed';
      clone.style.pointerEvents = 'none';
      clone.style.top = `${elementTop - upperBump}px`;
      clone.style.left = `${elementLeft}px`;
      clone.style.zIndex = this.frontIndex() + 1;
      clone.style.opacity = 0;
      clone.classList.add('ghost-element');

      this.cursorXoffset = e.touches[0].clientX - elementLeft;
      this.cursorYoffset = e.touches[0].clientY - elementTop;
      this.frameClone = clone;

      document.getElementById('positioner').appendChild(this.frameClone);
    },

    onTouchDrag(e, source = 'controls') {
      this.stopTouchTimeout();

      if (!dragHelper.isNativeDragSupported()) return;

      let element = e.target;
      while (element.getAttribute('draggable') !== 'true') {
        element = element.parentElement;
      }

      // if there is a clone, move it around
      // and create a drag event to match
      if (this.frameClone) {
        const xOffset = e.touches[0].clientX - this.cursorXoffset;
        const yOffset = e.touches[0].clientY - this.cursorYoffset;
        this.frameClone.style.left = `${xOffset}px`;
        this.frameClone.style.top = `${yOffset}px`;
        this.frameClone.style.opacity = 1;

        // get any elements the current one is over
        const overElement = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
        if (overElement.getAttribute('dropzone') !== 'true') return;

        if (overElement.ontouchenter) {
          // trigger a drag over event
          const effect = overElement.ontouchenter(this.dragEvent);
          this.dragEvent.dataTransfer.effectAllowed = effect;

          this.dragEvent = new DragEvent('dragenter', {
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY,
            dataTransfer: this.dragEvent.dataTransfer,
            target: overElement,
          });
          this.dragEvent.dataTransfer.setData('text', element.id);
          this.dragEvent.dataTransfer.effectAllowed = 'copyMove';
          this.dragEvent.dataTransfer.setData('dragFrom', source);
          overElement.dispatchEvent(this.dragEvent);
        }
      }
    },

    onTouchEnd(e, source = 'controls') {
      // stop the timeout
      if (this.touchTimeout) {
        this.stopTouchTimeout();
        let item = e.srcElement;
        while (item.getAttribute('draggable') !== 'true') {
          item = item.parentElement;
        }

        // find the input in the element and focus it
        const input = item.querySelector('input');
        if (input) input.focus();
      }

      if (!dragHelper.isNativeDragSupported()) return;

      if (this.frameClone) {
        // grab the current ghost element coords
        // const coords = this.frameClone.getBoundingClientRect();
        const { clientX, clientY } = e.changedTouches[0];
        // const clientY = coords.top;

        // find any elements that the clone is over
        const element = document.elementFromPoint(clientX, clientY);
        // grab the whole card/frame
        let item = e.srcElement;
        while (item.getAttribute('draggable') !== 'true') {
          item = item.parentElement;
        }
        if (element.hasAttribute('dropzone')) {
          // create a drop event
          const dropEvent = new DragEvent('drop', {
            clientX,
            clientY,
            dataTransfer: this.dragEvent ? this.dragEvent.dataTransfer : new DataTransfer(),
          });
          dropEvent.dataTransfer.setData('text', item.id);
          dropEvent.dataTransfer.setData('dragFrom', source);

          // trigger the drop event on that element
          element.dispatchEvent(dropEvent);

          this.cursorXoffset = 0;
          this.cursorYoffset = 0;
        }

        // remove the clone
        item.classList.remove('hide');
        this.dragEvent = null;
        this.frameClone.style.position = 'absolute';
        this.frameClone.classList.remove('ghost-element');
        this.onDragEnd({ ...e, clientX, clientY, srcElement: item, target: item });
        this.frameClone = null;
      }
    },

    onDragEnd(e) {
      const element = e.srcElement;
      if (this.droppedInDropzone(e)) {
        element.classList.remove('hide');
        this.listenForFrameClick(e);

      } else {
        this.frameClone.style.display = 'none';
      }
    },
    onDragStart(e) {
      const boundingRect = e.srcElement.getBoundingClientRect();
      console.log(boundingRect);
      this.cursorXoffset = e.clientX - boundingRect.left;
      this.cursorYoffset = e.clientY - boundingRect.top;

      // create the clone
      this.frameClone = e.srcElement.cloneNode(true);
      this.frameClone.style.position = 'absolute';
      this.frameClone.style.top = '-500px';

      document.getElementById('positioner').appendChild(this.frameClone);
      let upperBump = 0;
      if (e.srcElement.classList.contains('senFrame')) {
        upperBump = e.srcElement.offsetHeight * 0.33;
      }
      if (e.srcElement.classList.contains('punctFrame-sm')) {
        upperBump = e.srcElement.offsetHeight * 0.5;
      }
      e.dataTransfer.setDragImage(this.frameClone, this.cursorXoffset, this.cursorYoffset + upperBump);
      e.dataTransfer.setData('text', e.target.id);
      e.dataTransfer.effectAllowed = 'copyMove';
      e.dataTransfer.setData('dragFrom', 'controls');
    },

    onTopDragStart(e) {
      this.cursorXoffset = e.clientX - e.srcElement.getBoundingClientRect().left;
      this.cursorYoffset = e.clientY - e.srcElement.getBoundingClientRect().top;

      // create the clone
      this.frameClone = e.srcElement.cloneNode(true);
      this.frameClone.style.top = '-5000px';
      this.frameClone.style.position = 'absolute';
      setTimeout(() => {
        e.target.classList.add('hide');
      });
      document.getElementById('positioner').appendChild(this.frameClone);

      let upperBump = 0;
      if (e.srcElement.classList.contains('senFrame')) {
        upperBump = e.srcElement.offsetHeight * 0.33;
      }
      if (e.srcElement.classList.contains('punctFrame-sm')) {
        upperBump = e.srcElement.offsetHeight * 0.5;
      }
      e.dataTransfer.setDragImage(this.frameClone, this.cursorXoffset, this.cursorYoffset + upperBump);
      e.dataTransfer.setData('text', e.target.id);
      e.dataTransfer.effectAllowed = 'copyMove';
      e.dataTransfer.setData('dragFrom', 'top');
    },
    parentMove(e) {
      e.target.setAttribute('draggable', true);
    },
    inputClick(e) {
      if (this.isMobile()) {
        e.target.parentElement.setAttribute('draggable', false);
        this.listenForFrameBlur();
      }
    },
    isMobile() {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i)) {
        return true;
      }
      return false;
    },
    popTouchTips() {
      if (this.isMobile()) {
        document.getElementById('double-tap-helper').classList.add('isMobile');
        setTimeout(() => {
          document.getElementById('double-tap-helper').classList.add('hide-tap-helper');
        }, 3000);
      }
    },

    inputBlur(e) {
      e.target.parentElement.setAttribute('draggable', true);
    },
    listenForFrameClick() {
      const elements = document.getElementsByClassName('frame');
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].addEventListener('click', this.inputClick);
      }
    },
    listenForFrameBlur() {
      const elements = document.getElementsByClassName('frameText');
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].addEventListener('blur', this.inputBlur);
      }
    },
    preventSelect() {
      const elements = document.getElementsByClassName('frameText');
      for (let i = 0; i < elements.length; i += 1) {
        // eslint-disable-next-line func-names
        elements[i].addEventListener('select', function () {
          this.selectionStart = this.selectionEnd;
        }, false);
      }
    },
  },
  mounted() {
    this.backButtonTitle = 'Back';
    this.resizeFonts();
    this.popTouchTips();

    // and ontouchenter event
    const el = document.querySelector(`[dropzone]`);
    el.ontouchenter = this.onDragEnter;
  },
  updated() {
    this.preventSelect();
  },
  created() {
    // window.addEventListener('keyup', this.fitText);
  },
  destroyed() {
    // window.removeEventListener('keyup', this.fitText);
  },
};
</script>
<style scoped lang="scss">
body{
  &>.senFrame,
  &>.sylFrame{
    width: 11%;
  }
  &>.punctFrame-sm,
  &>.punctFrame-lg{
    width: 2.8%;
  }
}
.hide {
  transform: translateX(-9999px);
}
.sentenceframes{
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-top: -3%;
  margin-left: -$activity-frame-lateral-padding;
  margin-right: -$activity-frame-lateral-padding;
  margin-bottom: 0;
  .help-text-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    &.upper{
      bottom: calc(100% - 163px);
      top: 20px;
      .help-text{
        font-size: 1.5vh;
      }
    }
    .help-text{
      @extend %headings-shared;
      animation-iteration-count: 1;
      margin: auto;
      transform-origin: bottom;
      font-size: 3vh;
      opacity: 0;
      color: gray;
      &.bounce-7 {
        animation-name: bounce-7;
        animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
        opacity: 0;
        animation-duration: 4s;
      }
      &.bounce-8 {
        animation-name: bounce-8;
        animation-timing-function: cubic-bezier(1.280, 1.840, 1.420, 2);
        opacity: 0;
        animation-duration: 5s;
      }
    }
  }
  .drop-area {
    position: relative;
    flex: 1 0 auto;
    display: flex;

    @keyframes bounce-7 {
        0%   { transform: scale(1,1)      translateY(0); opacity: 1;}
        50%   { transform: scale(1,1)      translateY(0); opacity: 1;}
        55%  { transform: scale(1.1,.9)   translateY(0); }
        65%  { transform: scale(.9,1.1)   translateY(-100px); opacity: 1; }
        75%  { transform: scale(1.05,.95) translateY(0); }
        77%  { transform: scale(1,1)      translateY(-7px); }
        82%  { transform: scale(1,1)      translateY(0); opacity: 0; }
        100% { transform: scale(1,1)      translateY(0); display: none;}
    }
    @keyframes bounce-8 {
        0%   { transform: scale(1,1)      translateY(0); opacity: 1; z-index: 1;}
        60%  { transform: scale(1,1)      translateY(0); opacity: 1; z-index: 1;}
        65%  { transform: scale(1.1,.9)   translateY(0);  z-index: 1;}
        75%  { transform: scale(.9,1.1)   translateY(-50px); opacity: 1;  z-index: 1;}
        85%  { transform: scale(1.05,.95) translateY(0);  z-index: 1;}
        87%  { transform: scale(1,1)      translateY(-7px);  z-index: 1;}
        92%  { transform: scale(1,1)      translateY(0); opacity: 0;  z-index: 1;}
        100% { transform: scale(1,1)      translateY(0); display: none; z-index: 1;}
    }
  }
  #double-tap-helper{
    position: absolute;
    @extend %headings-shared;
    bottom: 100%;
    width: 100%;
    display: none;
    transition: all 1s ease-in-out;
    &.hide-tap-helper {
      opacity: 0;
      transform: translateY(30px);
    }
    &.isMobile{
      display: flex;
    }
    span{
      margin: auto;
      text-align: center;
      // @extend %button;
      background-image: url('/images/pointer.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center left;
      padding-left: 35px;
      font-size: 20px;
    }
  }
  .frame-drop-row{
    background: whitesmoke;
    margin: 20px auto 0 auto;
    flex: 0 0 145px;
    width: calc(100% - 12rem);
    display: flex;
    align-items: center;
  }
  .controls {
    flex: 0 0 145px;
    width: calc(100% - 12rem);
    padding: 10px 6rem;
    position: relative;
    margin: auto;
    z-index: 1;
    .inner{
      width: 100%;
      height: 100%;
      background: whitesmoke;
      display: flex;
      .frames-wrapper{
        flex: 1 0 auto;
        position: relative;
      }
    }
    .senFrame{
      left: 8%;
      top: 32%;
    }
    .sylFrame{
      left: 21%;
      top: 32%;

      &.white{
        right: 24%;
        left: unset;
      }
    }
    .punctFrame-lg{
      left: 35%;
      top: 32%;
    }
    .punctFrame-sm{
      left: 39.5%;
      top: 51%;
    }
  }
}

.senFrame,
.sylFrame,
.punctFrame-lg,
.punctFrame-sm {
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  background-color: $senFrame-blue;
  border: solid gray 1px;
  input{
    width: 95%;
    height: 100%;
    margin: 0 2%;
    box-sizing: border-box;
    font-family: wManuscript;
    font-size: 100%;
    overflow: visible;
    outline-width: 0;
    position: absolute;
    bottom: 0;
    border: none;
    background: transparent;
  }
  &.white{
    background-color: white;
  }
}

  .height-sizer{
    width: 100%;
    position: relative;
  }
  .senFrame{
    width: 12%;
    // this prop is used for then the frame is dragging and it is appended to the root element during that time.
    max-width: 162px;
    .sf-border{
      position: absolute;
      width: 100%;
      border-top: solid gray 1px;
      bottom: 100%;
    }

    &:after{
    content: '';
      width: 40%;
      height: 33%;
      position: absolute;
      bottom: 100%;
      left: -1px;
      background-color: $senFrame-blue;
      border-top: solid gray 1px;
      border-left: solid gray 1px;
      border-right: solid gray 1px;
    }
    .height-sizer{
      padding-bottom: 50%;
    }
  }
  .sylFrame{
    width: 12%;
    // this prop is used for then the frame is dragging and it is appended to the root element during that time.
    max-width: 162px;
    .height-sizer{
      padding-bottom: 50%;
    }
  }
  .punctFrame-lg{
    // this prop is used for then the frame is dragging and it is appended to the root element during that time.
    max-width: 38px;
    width: 3%;
    input{
      padding: 30% 0 0 30%;
      box-sizing: border-box;
    }
    .height-sizer{
      padding-bottom: 213%;
    }
  }
  .punctFrame-sm{

    // this prop is used for then the frame is dragging and it is appended to the root element during that time.
    max-width: 38px;
    width: 3%;
    input{
      padding: 0 0 30% 30%;
    }
    .height-sizer{
      padding-bottom: 100%;
    }
  }
  #clear-frames{
    margin: auto 1% auto auto;
  }

</style>
