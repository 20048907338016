<template>
  <div id="positioner" class="drillsounds">
    <ActivityHeader
    :activity-type="activityType"
    activity-name="Vowel Sounds"
    :back-button-title="backButtonTitle"
    :back-button-route="backButtonRoute" />
    <div id="vowel-sounds" data-automationId="reference_posters_vowel_sounds_with_img">
      <div v-for="card in getCardsForDisplay" :key="card.cardId" class="vowel-sound" :id="card.label">
        <span class="vowel">
          <span>{{ card.label }}</span>
        </span>
        <div v-for="sound in card.lks" :key="sound.keyword" class="lks">
          <div class="lks-image">
            <img :src="sound.image" :alt="sound.alt" />
          </div>
          <div class="keyword" v-html="sound.keyword"></div>
        </div>
      </div>
    </div>
    <PrevNextButtons v-if="!isFunk_1" activity="VS"></PrevNextButtons>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'VowelSounds',
  components: { ActivityHeader, PrevNextButtons },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  data() {
    return {
      backButtonRoute: '',
      backButtonTitle: '',
      activityType: '',
    };
  },

  beforeRouteEnter(to, from, next) {

    next((vm) => {
      vm.prevRoute = from;
      if (from !== null) {
        if (from.name === 'referenceposters' || to.params.unitId === undefined) {
          vm.backButtonTitle = 'Posters';
          vm.backButtonRoute = 'chooseposters';
          vm.activityType = 'Reference Posters';
          vm.showNavButtons = false;
        } else {
          vm.backButtonTitle = 'Drill Sounds';
          vm.backButtonRoute = 'choosedrillsounds';
          vm.activityType = "<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>";
          vm.showNavButtons = true;
        }
        if (to.params.programId === 'funk' || to.params.programId === 'fun1') {
          vm.showNavButtons = false;
        }
      }
    });
  },

  computed: {
    ...mapState('vowelsounds', ['vowelsoundsData']),
    ...mapGetters('dataStore', ['weekNumber']),
    isFunk_1() {
      return this.programId === 'funk' || this.programId === 'fun1';
    },
    getCardsForDisplay() {
      const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      const cards = this.vowelsoundsData.find((c) => c.program === this.programId);

      const vsData = cards.cards.filter((c) => c.include <= weekNumber);
      return vsData;
    },
  },
};
</script>

<style scoped lang="scss">
#vowel-sounds {
  display: grid;
  grid-gap: 3rem 3rem;
  margin: auto;
  padding: 3rem 0;
  width: 100%;
  font-family: wBookman;
  grid-template-columns: repeat(4, 1fr);

  .vowel-sound {
    .fun1 & {
      @extend %salmon-tile;
    }
    .fun2 & {
      @extend %green-tile;
    }
    .fun3 & {
      @extend %purple-tile;
    }
    display: flex;
    &:after {
      content: '';
      padding-bottom: 18%;
      height: 0;
    }

    .vowel {
      flex-basis: 8rem;
      text-align: center;
      font-size: 5rem;
      line-height: 1;
      display: flex;

      .funk & {
        border-right: solid $fun-blue 0.2rem;
        color: $fun-blue;
      }
      .fun1 & {
        border-right: solid $fun-salmon 0.2rem;
        color: $fun-salmon;
      }
      .fun2 & {
        border-right: solid $fun-green 0.2rem;
        color: $fun-green;
      }
      .fun3 & {
        border-right: solid $fun-purple 0.2rem;
        color: $fun-purple;
      }

      span {
        align-self: center;
        justify-self: center;
        margin: auto;
      }
    }
    .lks {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        .funk & {
          border-right: solid $fun-blue 0.2rem;
        }
        .fun1 & {
          border-right: solid $fun-salmon 0.2rem;
        }
        .fun2 & {
          border-right: solid $fun-green 0.2rem;
        }
        .fun3 & {
          border-right: solid $fun-purple 0.2rem;
        }
      }
      .keyword {
        text-align: center;
        justify-self: flex-end;
        font-size: 1.4rem;
      }
      .lks-image {
        position: relative;
        justify-self: flex-start;
        flex-grow: 1;
        margin: 0 auto;
        width: 70%;
        img {
          border-style: none;
          position: absolute;
          width: 100%;
          left: 5%;
          max-height: 120%;
          bottom: 10%;
        }
      }
    }
  }
  #a {
    grid-area: 1 / 1 / 1 / span 2;
  }
  #e {
    grid-area: 1 / 3 / 1 / span 2;
  }
  #i {
    grid-area: 2 / 1 / 2 / span 2;
  }
  #o {
    grid-area: 2 / 3 / 2 / span 2;
  }
  #u {
    grid-area: 3 / 1 / 3 / span 2;
  }
  #y {
    grid-area: 3 / 3 / 3 / span 2;
  }
}
@media (max-width: 850px) {
  #vowel-sounds {
    max-width: 400px;
    grid-gap: 3rem 2rem;
    .vowel-sound {
      &:after {
        padding-bottom: 14%;
      }
      &#a {
        grid-area: 1 / 1 / span 1 / span 4;
      }
      &#e {
        grid-area: 2 / 1 / span 1 / span 4;
      }
      &#i {
        grid-area: 3 / 1 / span 1 / span 4;
      }
      &#o {
        grid-area: 4 / 1 / span 1 / span 4;
      }
      &#u {
        grid-area: 5 / 1 / span 1 / span 4;
      }
      &#y {
        grid-area: 6 / 1 / span 1 / span 4;
      }
    }
  }
}
</style>
