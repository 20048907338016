<template>
  <div id="positioner">
    <ActivityHeader activity-name="Composition Book"
     activity-type="Dictation"
      back-button-title="Dictation"
      back-button-route="choosedictation" />

    <div style="position: relative; flex: 1 0 auto;" v-images-loaded:on="imageProgress()">
      <div v-if="programId === 'fun2'" id="card-positioner" class="dictation-worksheet-layout">
        <div id="cards-container">
          <div class="worksheet-slide" id="1" v-show="showOne">
            <img src="/images/dictation/worksheets/dw2a.svg" />
          </div>
          <div class="worksheet-slide" id="2" v-show="showTwo">
            <img src="/images/dictation/worksheets/dw2b.svg" />
          </div>
        </div>
      </div>
      <div v-if="programId === 'fun3'" id="card-positioner" class="dictation-worksheet-layout">
        <div id="cards-container">
          <div class="worksheet-slide" id="1" v-show="showOne">
            <img src="/images/dictation/worksheets/dw3a.svg" />
          </div>
          <div class="worksheet-slide" id="2" v-show="showTwo">
            <img src="/images/dictation/worksheets/dw3b.svg" />
          </div>
        </div>
      </div>
    </div>
    <nav id="dw-toggles">
      <button class="button button-sm" @click="toggle" :disabled="showOne">
        <div class="caret caret-left"></div>
        sound &amp; words
      </button>
      <button class="button button-sm" @click="toggle" :disabled="showTwo">
        sentences
        <div class="caret caret-right"></div>
      </button>
    </nav>
    <PrevNextButtons activity="DictComp"></PrevNextButtons>
  </div>
</template>

<script>
import imagesLoaded from 'vue-images-loaded';
import cardSizer from '@/mixins/cardsizer';
import ActivityHeader from '@/components/ActivityHeader.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'CompositionBook',
  components: { ActivityHeader, PrevNextButtons },
  mixins: [cardSizer],
  data() {
    return { showOne: true, showTwo: false };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  created() {
    window.addEventListener('resize', this.resizeMethods);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  directives: {
    imagesLoaded,
  },
  mounted() {
    this.resizeMethods();
  },
  methods: {
    resizeMethods() {
      this.resizeCards();
    },
    revealImageWrapper() {
      document.getElementById('card-positioner').classList.add('images-loaded');
    },
    imageProgress() {
      return {
        always: () => {
          this.resizeMethods();
          this.revealImageWrapper();
        },
      };
    },
    toggle() {
      this.showOne = !this.showOne;
      this.showTwo = !this.showTwo;
      const t = this;

      // listen for when the visibility of dictation worksheet pages changes because vue doesn't do that out the box
      const observer = new MutationObserver(((mutations) => {
        mutations.forEach(() => {
          // fire the resize method so that the newly shown page best fits on-screen.
          t.resizeCards();
        });
      }));

      const target = document.getElementById('1');
      observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    },
  },
};
</script>

<style lang="scss" scoped>
.dictation-worksheet-layout{
  opacity: 0;
  &.images-loaded{
    opacity: 1;
  }
}
#dw-toggles {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  margin: 0 auto -4rem auto;
  padding-top: 1rem;
  button {
    margin: 0 0.5rem;
  }
}
#cards-container {
  margin: auto;
}
</style>
