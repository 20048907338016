export default {
  namespaced: true,
  state: {
    wordLists: [
      {
        program: 'fun2',
        words: [
          {
            day: '1-1-1',
            words: [
              { word: 'mat', cards: [13, 1, 20] },
              { word: 'job', cards: [10, 15, 2] },
              { word: 'yap', cards: [25, 1, 16] },
              { word: 'wax', cards: [23, 1, 24] },
              { word: 'quit', cards: [17, 9, 20] },
              { word: 'shed', cards: [30, 5, 4] },
              { word: 'chop', cards: [29, 15, 16] },
              { word: 'duck', cards: [4, 21, 32] },
              { word: 'thud', cards: [31, 21, 4] },
              { word: 'whip', cards: [27, 9, 16] },
              { word: 'quick', cards: [17, 9, 32] },
              { word: 'shack', cards: [30, 1, 32] },
              { word: 'chip', cards: [29, 9, 16] },
              { word: 'lish', cards: [12, 9, 30] },
              { word: 'dex', cards: [4, 5, 24] },
              { word: 'ath', cards: [1, 31] },
              { word: 'quin', cards: [17, 9, 14] },
            ],
          },
          {
            day: '1-1-2',
            words: [
              { word: 'mat', cards: [13, 1, 20] },
              { word: 'quiz', cards: [17, 9, 26] },
              { word: 'win', cards: [23, 9, 14] },
              { word: 'wig', cards: [23, 9, 7] },
              { word: 'with', cards: [23, 9, 31] },
              { word: 'wag', cards: [23, 1, 7] },
              { word: 'whip', cards: [27, 9, 16] },
              { word: 'whiz', cards: [27, 9, 26] },
              { word: 'rich', cards: [18, 9, 29] },
              { word: 'yet', cards: [25, 5, 20] },
              { word: 'sob', cards: [19, 15, 2] },
              { word: 'shut', cards: [30, 21, 20] },
              { word: 'path', cards: [16, 1, 31] },
            ],
          },
          {
            day: '1-1-3',
            words: [
              { word: 'ship', cards: [30, 9, 16] },
              { word: 'slip', cards: [19, 12, 9, 16] },
              { word: 'slush', cards: [19, 12, 21, 30] },
              { word: 'lunch', cards: [12, 21, 14, 29] },
              { word: 'shred', cards: [30, 18, 5, 4] },
              { word: 'strip', cards: [19, 20, 18, 9, 16] },
              { word: 'blend', cards: [2, 12, 5, 14, 4] },
              { word: 'munch', cards: [13, 21, 14, 29] },
              { word: 'split', cards: [19, 16, 12, 9, 20] },
              { word: 'quilt', cards: [17, 9, 12, 20] },
              { word: 'drench', cards: [4, 18, 5, 14, 29] },
              { word: 'brag', cards: [2, 18, 1, 7] },
              { word: 'sent', cards: [19, 5, 14, 20] },
              { word: 'slump', cards: [19, 12, 21, 13, 16] },
              { word: 'mask', cards: [13, 1, 19, 11] },
              { word: 'scrap', cards: [19, 3, 18, 1, 16] },
              { word: 'crash', cards: [3, 18, 1, 30] },
            ],
          },
          {
            day: '1-1-5',
            words: [
              { word: 'cash', cards: [503, 501, 530] },
              { word: 'thump', cards: [531, 521, 513, 516] },
            ],
          },
          {
            day: '1-2-1',
            words: [
              { word: 'thick', cards: [31, 9, 32] },
              { word: 'mask', cards: [13, 1, 19, 11] },
              { word: 'silk', cards: [19, 9, 12, 11] },
              { word: 'sick', cards: [19, 9, 32] },
              { word: 'task', cards: [20, 1, 19, 11] },
              { word: 'shack', cards: [30, 1, 32] },
              { word: 'cat', cards: [3, 1, 20] },
              { word: 'crash', cards: [3, 18, 1, 30] },
              { word: 'skim', cards: [19, 11, 9, 13] },
              { word: 'clump', cards: [3, 12, 21, 13, 16] },
              { word: 'cost', cards: [3, 15, 19, 20] },
              { word: 'kept', cards: [11, 5, 16, 20] },
              { word: 'sock', cards: [19, 15, 32] },
              { word: 'milk', cards: [13, 9, 12, 11] },
            ],
          },
          {
            day: '1-2-3',
            words: [
              { word: 'bat', cards: [2, 1, 20] },
              { word: 'bath', cards: [2, 1, 31] },
              { word: 'such', cards: [19, 21, 29] },
              { word: 'she', cards: [30, 5] },
              { word: 'it', cards: [9, 20] },
              { word: 'boat', cards: [2, 15, 1, 20] },
              { word: 'check', cards: [29, 5, 32] },
              { word: 'twig', cards: [20, 23, 9, 7] },
              { word: 'squid', cards: [19, 17, 9, 4] },
              { word: 'ask', cards: [1, 1, 11] },
            ],
          },
          {
            day: '2-1-1',
            words: [
              { word: 'fall', cards: [6, 36] },
              { word: 'mall', cards: [13, 36] },
              { word: 'wall', cards: [23, 36] },
              { word: 'call', cards: [3, 36] },
              { word: 'miss', cards: [13, 9, 19, 19] },
              { word: 'shelf', cards: [30, 5, 12, 6] },
              { word: 'buzz', cards: [2, 21, 26, 26] },
              { word: 'quiz', cards: [17, 9, 26] },
              { word: 'toss', cards: [20, 15, 19, 19] },
              { word: 'off', cards: [15, 6, 6] },
              { word: 'puff', cards: [16, 21, 6, 6] },
              { word: 'mess', cards: [13, 5, 19, 19] },
              { word: 'ill', cards: [9, 12, 12] },
            ],
          },
          {
            day: '2-1-2',
            words: [
              { word: 'puff', cards: [16, 21, 6, 6] },
              { word: 'hall', cards: [8, 36] },
              { word: 'chess', cards: [29, 5, 19, 19] },
              { word: 'hill', cards: [8, 9, 12, 12] },
              { word: 'well', cards: [23, 5, 12, 12] },
              { word: 'boss', cards: [2, 15, 19, 19] },
              { word: 'tall', cards: [20, 36] },
              { word: 'fil', cards: [6, 9, 12] },
              { word: 'shelf', cards: [30, 5, 12, 6] },
            ],
          },
          {
            day: '2-2-1',
            words: [
              { word: 'can', cards: [3, 38] },
              { word: 'ram', cards: [18, 37] },
              { word: 'am', cards: [37] },
              { word: 'an', cards: [38] },
              { word: 'fan', cards: [6, 38] },
              { word: 'yam', cards: [25, 37] },
              { word: 'hang', cards: [8, 39] },
              { word: 'honk', cards: [8, 44] },
              { word: 'hung', cards: [8, 45] },
              { word: 'sung', cards: [19, 45] },
              { word: 'sing', cards: [19, 41] },
              { word: 'rink', cards: [18, 42] },
              { word: 'rank', cards: [18, 40] },
              { word: 'long', cards: [12, 43] },
              { word: 'dunk', cards: [4, 46] },

            ],
          },
          {
            day: '3-1-1',
            words: [
              { word: 'cob', cards: [3, 15, 2] },
              { word: 'cold', cards: [3, 49] },
              { word: 'mold', cards: [13, 49] },
              { word: 'most', cards: [13, 51] },
              { word: 'molt', cards: [13, 50] },
              { word: 'mild', cards: [13, 48] },
              { word: 'mind', cards: [13, 47] },
              { word: 'lost', cards: [12, 15, 19, 20] },
              { word: 'song', cards: [19, 43] },
              { word: 'sold', cards: [19, 49] },
            ],
          },
          {
            day: '4-1-1',
            words: [
              { word: 'map', cards: [13, 1, 16] },
              { word: 'rings', cards: [18, 41, 57] },
              { word: 'bells', cards: [2, 5, 12, 12, 57] },
              { word: 'sticks', cards: [19, 20, 9, 32, 57] },
              { word: 'colts', cards: [3, 50, 57] },
              { word: 'box', cards: [2, 15, 24] },
              { word: 'wishes', cards: [23, 9, 30, 52] },
              { word: 'benches', cards: [2, 5, 14, 29, 52] },
              { word: 'glasses', cards: [7, 12, 1, 19, 19, 52] },
              { word: 'rent', cards: [18, 5, 14, 20] },
              { word: 'fold', cards: [6, 49] },
              { word: 'swing', cards: [19, 23, 41] },
              { word: 'bunt', cards: [2, 21, 14, 20] },
              { word: 'tall', cards: [20, 36] },
              { word: 'strong', cards: [19, 20, 18, 43] },
              { word: 'kind', cards: [11, 47] },
              { word: 'small', cards: [19, 13, 36] },
              { word: 'sing', cards: [19, 41] },
              { word: 'runs', cards: [18, 21, 14, 19] },
              { word: 'fixes', cards: [6, 9, 24, 52] },
              { word: 'bosses', cards: [2, 15, 19, 19, 52] },
            ],
          },
          {
            day: '4-1-2',
            words: [
              { word: 'bells', cards: [2, 5, 12, 12, 57] },
              { word: 'toss', cards: [20, 15, 19, 19] },
              { word: 'kind', cards: [11, 47] },
              { word: 'blend', cards: [2, 12, 5, 14, 4] },
              { word: 'sick', cards: [19, 9, 32] },
              { word: 'swing', cards: [19, 23, 41] },
              { word: 'puff', cards: [16, 21, 6, 6] },
              { word: 'black', cards: [2, 12, 1, 32] },
              { word: 'old', cards: [49] },
              { word: 'benches', cards: [2, 5, 14, 29] },
            ],
          },
          {
            day: '4-2-1',
            words: [
              { word: 'rent', cards: [18, 5, 14, 20] },
              { word: 'rented', cards: [18, 5, 14, 20, 54] },
              { word: 'jump', cards: [10, 21, 13, 16] },
              { word: 'fill', cards: [6, 9, 12, 12] },
              { word: 'spill', cards: [19, 16, 9, 12, 12] },
              { word: 'spilled', cards: [19, 16, 9, 12, 12, 54] },
              { word: 'hunt', cards: [8, 21, 14, 20] },
              { word: 'hunted', cards: [8, 21, 14, 20, 54] },
              { word: 'blink', cards: [2, 12, 42] },
              { word: 'blinked', cards: [2, 12, 42, 54] },
            ],
          },
          {
            day: '5-1-1',
            words: [
              { word: 'mascot', cards: [13, 1, 19, 3, 15, 20] },
              { word: 'habit', cards: [8, 1, 2, 9, 20] },
              { word: 'jacket', cards: [10, 1, 32, 5, 20] },
              { word: 'children', cards: [29, 9, 12, 4, 18, 5, 14] },
              { word: 'admit', cards: [1, 4, 13, 9, 20] },
              { word: 'relish', cards: [18, 5, 12, 9, 30] },
              { word: 'solid', cards: [19, 15, 12, 9, 4] },
              { word: 'bucket', cards: [2, 21, 32, 5, 20] },
              { word: 'impress', cards: [9, 13, 16, 18, 5, 19, 19] },
              { word: 'himself', cards: [8, 9, 13, 19, 5, 12, 6] },
              { word: 'intend', cards: [9, 14, 20, 5, 14, 4] },
            ],
          },
          {
            day: '5-1-2',
            words: [
              { word: 'index',
                cards: [
                  { syl: [9, 14] },
                  { syl: [4, 5, 24] },
                ] },
              { word: 'polish',
                cards: [
                  { syl: [16, 15, 12] },
                  { syl: [9, 30] },
                ] },
              { word: 'napkin',
                cards: [
                  { syl: [14, 1, 16] },
                  { syl: [11, 9, 14] },
                ] },
              { word: 'complex',
                cards: [
                  { syl: [3, 15, 13] },
                  { syl: [16, 12, 5, 24] },
                ] },
              { word: 'dentist',
                cards: [
                  { syl: [4, 5, 14] },
                  { syl: [20, 9, 19, 20] },
                ] },
              { word: 'disrupt',
                cards: [
                  { syl: [4, 9, 19] },
                  { syl: [18, 21, 16, 20] },
                ] },
            ],
          },
          {
            day: '5-1-3',
            words: [
              { word: 'lick', cards: [12, 9, 32] },
              { word: 'public', cards: [16, 21, 2, 12, 9, 3] },
            ],
          },
          {
            day: '5-2-1',
            words: [
              { word: 'invent',
                cards: [
                  { syl: [9, 14] },
                  { syl: [22, 5, 14, 20] },
                ] },
              { word: 'thank', cards: [31, 40] },

              { word: 'ship',
                cards: [
                  { syl: [30, 9, 16] },
                ] },
              { word: 'kind', cards: [11, 47] },
              { word: 'child', cards: [29, 48] },
              { word: 'fresh', cards: [6, 18, 5, 30] },
              { word: 'expand',
                cards: [
                  { syl: [5, 24] },
                  { syl: [16, 1, 14, 4] },
                ] },
              { word: 'upset',
                cards: [
                  { syl: [21, 16] },
                  { syl: [19, 5, 20] },
                ] },
            ],
          },
          {
            day: '6-1-1',
            words: [
              { word: 'hop', cards: [8, 15, 16] },
              { word: 'eve', cards: [5, 22, 5] },
              { word: 'rope', cards: [18, 15, 16, 5] },
              { word: 'tune', cards: [20, 21, 14, 5] },
              { word: 'time', cards: [20, 9, 13, 5] },
              { word: 'cap', cards: [3, 1, 16] },
              { word: 'tub', cards: [20, 21, 2] },
              { word: 'cut', cards: [3, 21, 20] },
              { word: 'rid', cards: [18, 9, 4] },
              { word: 'fin', cards: [6, 9, 14] },
            ],
          },
          {
            day: '6-1-2',
            words: [
              { word: 'ate', cards: [1, 20, 5] },
              { word: 'hope', cards: [8, 15, 16, 5] },
              { word: 'lick', cards: [12, 9, 32] },
              { word: 'like', cards: [12, 9, 11, 5] },
              { word: 'hop', cards: [8, 15, 16] },
            ],
          },
          {
            day: '6-1-3',
            words: [
              { word: 'cakes', cards: [3, 1, 11, 5, 57] },
              { word: 'hopes', cards: [8, 15, 16, 5, 57] },
            ],
          },
          {
            day: '6-1-4',
            words: [
              { word: 'nose', cards: [14, 15, 519, 5] },
              { word: 'hose', cards: [8, 15, 519, 5] },
              { word: 'froze', cards: [6, 18, 15, 526, 5] },
              { word: 'rise', cards: [18, 9, 519, 5] },
              { word: 'maze', cards: [13, 1, 526, 5] },
              { word: 'bugs', cards: [2, 21, 7, 57] },
              { word: 'wise', cards: [23, 9, 19, 5] },
              { word: 'chase', cards: [29, 1, 19, 5] },
            ],
          },
          {
            day: '6-2-1',
            words: [
              { word: 'mistake', cards: [13, 9, 19, 20, 1, 11, 5] },
              { word: 'fireman', cards: [6, 9, 18, 5, 13, 38] },
              { word: 'life time',
                cards: [
                  { syl: [12, 9, 6, 5] },
                  { syl: [20, 9, 13, 5] },
                ] },
              { word: 'base ball',
                cards: [
                  { syl: [2, 1, 19, 5] },
                  { syl: [2, 36] },
                ] },
              { word: 'cave man',
                cards: [
                  { syl: [3, 1, 22, 5] },
                  { syl: [13, 38] },
                ] },
              { word: 'invite',
                cards: [
                  { syl: [9, 14] },
                  { syl: [22, 9, 20, 5] },
                ] },
            ],
          },
          {
            day: '6-2-2',
            words: [
              { word: 'give', cards: [7, 66] },
              { word: 'olive', cards: [15, 12, 65] },
              { word: 'captive', cards: [3, 1, 16, 20, 65] },
              { word: 'active', cards: [1, 3, 20, 66] },
            ],
          },
          {
            day: '7-1-1',
            words: [
              { word: 'got', cards: [7, 15, 20] },
              { word: 'met', cards: [13, 5, 20] },
              { word: 'he', cards: [8, 5] },
              { word: 'shy', cards: [30, 67] },
              { word: 'my', cards: [13, 67] },
              { word: 'scome', cards: [19, 3, 15, 13, 5] },
            ],
          },
          {
            day: '7-1-2',
            words: [
              { word: 'got', cards: [7, 15, 20] },
              { word: 'try', cards: [20, 18, 67] },
            ],
          },
          {
            day: '7-1-4',
            words: [
              { word: 'relish', cards: [18, 5, 12, 9, 30] },
              { word: 'beside', cards: [2, 5, 19, 9, 4, 5] },
              { word: 'program', cards: [16, 18, 15, 7, 18, 37] },
              { word: 'cabin', cards: [3, 1, 2, 9, 14] },
              { word: 'pretend', cards: [16, 18, 5, 20, 5, 14, 4] },
              { word: 'protest', cards: [16, 18, 15, 20, 5, 19, 16] },
              { word: 'basic', cards: [2, 1, 19, 9, 3] },
              { word: 'topic', cards: [16, 18, 15, 7, 18, 1, 13] },
              { word: 'menu',
                cards: [
                  { syl: [13, 5, 14] },
                  { syl: [21] },
                ] },
              { word: 'bingo', cards: [2, 41, 15] },
            ],
          },
          {
            day: '7-2-1',
            words: [
              { word: 'pony', cards: [16, 15, 14, 67] },
              { word: 'candy', cards: [3, 38, 4, 67] },
              { word: 'penny', cards: [16, 5, 14, 14, 67] },
              { word: 'silly', cards: [19, 9, 12, 12, 67] },
              { word: 'taffy', cards: [20, 1, 6, 6, 67] },
            ],
          },
          {
            day: '7-3-1',
            words: [
              { word: 'chill', cards: [29, 9, 12, 12] },
              { word: 'safely', cards: [19, 1, 6, 5, 69] },
              { word: 'bravely', cards: [2, 18, 1, 22, 5, 69] },
              { word: 'ninety', cards: [14, 9, 14, 5, 70] },
              { word: 'safety', cards: [19, 1, 6, 5, 70] },
              { word: 'grumpy', cards: [7, 18, 21, 13, 16, 68] },
            ],
          },
          {
            day: '8-1-1',
            words: [
              { word: 'car', cards: [3, 71] },
              { word: 'horn', cards: [8, 72, 14] },
              { word: 'barn', cards: [2, 71, 14] },
              { word: 'army', cards: [71, 13, 67] },
              { word: 'party', cards: [16, 71, 20, 67] },
              { word: 'shortstop', cards: [30, 72, 20, 19, 20, 15, 16] },
              { word: 'forty', cards: [6, 72, 20, 67] },
              { word: 'garlic', cards: [7, 71, 12, 9, 3] },
              { word: 'cargo', cards: [3, 71, 7, 15] },
              { word: 'hornet', cards: [8, 72, 14, 5, 20] },
            ],
          },
          {
            day: '8-1-2',
            words: [
              { word: 'card', cards: [3, 71, 4] },
              { word: 'cord', cards: [3, 72, 4] },
              { word: 'marching', cards: [13, 71, 29, 53] },
              { word: 'corn', cards: [3, 72, 14] },
              { word: 'core', cards: [3, 15, 18, 5] },
              { word: 'tore', cards: [20, 15, 18, 5] },
              { word: 'sore', cards: [19, 15, 18, 5] },
              { word: 'born', cards: [2, 72, 14] },
              { word: 'port', cards: [16, 72, 20] },
              { word: 'chore', cards: [29, 15, 18, 5] },
              { word: 'sort', cards: [19, 72, 20] },
              { word: 'score', cards: [19, 3, 15, 18, 5] },
            ],
          },
          {
            day: '9-1-1',
            words: [
              { word: 'first', cards: [6, 574, 19, 20] },
            ],
          },
          {
            day: '9-2-1',
            words: [
              { word: 'hunger',
                cards: [
                  { syl: [8, 45] },
                  { syl: [73] },
                ] },
              { word: 'temper',
                cards: [
                  { syl: [20, 5, 13] },
                  { syl: [16, 73] },
                ] },
              { word: 'enter',
                cards: [
                  { syl: [5, 14] },
                  { syl: [20, 73] },
                ] },
              { word: 'termite',
                cards: [
                  { syl: [20, 573] },
                  { syl: [13, 9, 20, 5] },
                ] },
            ],
          },
          {
            day: '10-1-1',
            words: [
              { word: 'bait', cards: [2, 76, 20] },
              { word: 'play', cards: [16, 12, 77] },
            ],
          },
          {
            day: '10-1-2',
            words: [
              { word: 'play', cards: [16, 12, 77] },
              { word: 'bait', cards: [2, 76, 20] },
              { word: 'mate', cards: [13, 1, 20, 5] },
              { word: 'paint', cards: [16, 76, 14, 20] },
              { word: 'bank', cards: [2, 1, 14, 11] },
            ],
          },
          {
            day: '10-1-3',
            words: [
              { word: 'main', cards: [13, 76, 14] },
              { word: 'mane', cards: [13, 1, 14, 5] },
            ],
          },
          {
            day: '10-2-1',
            words: [
              { word: 'baby', cards: [2, 1, 2, 67] },
            ],
          },
          {
            day: '11-1-1',
            words: [
              { word: 'jeep', cards: [10, 78, 16] },
              { word: 'eat', cards: [79, 20] },
              { word: 'key', cards: [11, 80] },
            ],
          },
          {
            day: '11-1-2',
            words: [
              { word: 'feed', cards: [6, 578, 4] },
            ],
          },
          {
            day: '11-1-3',
            words: [
              { word: 'week', cards: [23, 78, 11] },
              { word: 'weak', cards: [23, 79, 11] },
              { word: 'weke', cards: [23, 5, 11, 5] },
            ],
          },
          {
            day: '11-2-1',
            words: [
              { word: 'silly',
                cards: [
                  { syl: [19, 9, 12] },
                  { syl: [12, 67] },
                ] },
              { word: 'chimney',
                cards: [
                  { syl: [29, 9, 13] },
                  { syl: [14, 80] },
                ] },
            ],
          },
          {
            day: '11-2-2',
            words: [
              { word: 'clap', cards: [3, 12, 1, 16] },
              { word: 'fort', cards: [6, 72, 20] },
              { word: 'bike', cards: [2, 9, 11, 5] },
              { word: 'steep', cards: [19, 20, 78, 16] },
              { word: 'she', cards: [30, 5] },
            ],
          },
          {
            day: '12-1-1',
            words: [
              { word: 'coin', cards: [3, 81, 14] },
              { word: 'boy', cards: [2, 82] },
            ],
          },
          {
            day: '12-1-2',
            words: [
              { word: 'boil', cards: [2, 581, 12] },
              { word: 'boy', cards: [2, 582] },
              { word: 'toys', cards: [20, 82, 57] },
            ],
          },
          {
            day: '13-1-1',
            words: [
              { word: 'boat', cards: [2, 83, 20] },
              { word: 'toe', cards: [20, 84] },
              { word: 'snow', cards: [19, 14, 85] },
              { word: 'below',
                cards: [
                  { syl: [2, 5] },
                  { syl: [12, 85] },
                ] },
              { word: 'tiptoe',
                cards: [
                  { syl: [20, 9, 16] },
                  { syl: [20, 84] },
                ] },
              { word: 'groaning', cards: [7, 18, 83, 14, 53] },

            ],
          },
          {
            day: '13-1-2',
            words: [
              { word: 'toe', cards: [20, 84] },
              { word: 'boat', cards: [2, 83, 20] },
              { word: 'show', cards: [30, 85] },
              { word: 'grown', cards: [7, 18, 85, 14] },
              { word: 'float', cards: [6, 12, 83, 20] },
              { word: 'flowt', cards: [6, 12, 585, 20] },
            ],
          },
          {
            day: '13-1-3',
            words: [
              { word: 'toe', cards: [20, 84] },
              { word: 'tow', cards: [20, 85] },
              { word: 'groan', cards: [7, 18, 83, 14] },
              { word: 'grown', cards: [7, 18, 85, 14] },
            ],
          },
          {
            day: '14-1-1',
            words: [
              { word: 'snow', cards: [19, 14, 85] },
              { word: 'brown', cards: [2, 18, 85, 14] },
              { word: 'flow', cards: [6, 12, 85] },
              { word: 'tow', cards: [20, 85] },
              { word: 'show', cards: [30, 85] },
              { word: 'crow', cards: [3, 18, 85] },
              { word: 'row', cards: [18, 85] },
              { word: 'bow', cards: [2, 85] },
              { word: 'plow', cards: [2, 85] },
              { word: 'flower', cards: [6, 12, 85, 73] },
              { word: 'tower', cards: [20, 85, 73] },
              { word: 'shower', cards: [30, 85, 73] },
              { word: 'crowd', cards: [3, 18, 85, 4] },
              { word: 'rowdy', cards: [18, 85, 67] },
            ],
          },
          {
            day: '14-1-2',
            words: [
              { word: 'trait', cards: [20, 18, 76, 20] },
              { word: 'plow', cards: [16, 12, 85] },
              { word: 'cow', cards: [3, 85] },
              { word: 'crowd', cards: [3, 18, 85, 4] },
              { word: 'cloud', cards: [3, 12, 584, 4] },
              { word: 'flower', cards: [6, 12, 85, 73] },
              { word: 'trout', cards: [20, 18, 86, 20] },
            ],
          },
          {
            day: '15-1-1',
            words: [
              { word: 'flute', cards: [6, 12, 21, 20, 5] },
              { word: 'flu', cards: [6, 12, 21] },
            ],
          },
          {
            day: '15-1-2',
            words: [
              { word: 'booth', cards: [2, 587, 31] },
            ],
          },
          {
            day: '16-1-1',
            words: [
              { word: 'aunt', cards: [90, 14, 20] },
              { word: 'straw', cards: [19, 20, 18, 91] },
            ],
          },
          {
            day: '16-1-2',
            words: [
              { word: 'lawn', cards: [12, 591, 14] },
            ],
          },
          {
            day: '17-1-1',
            words: [
              { word: 'got', cards: [7, 15, 20] },
              { word: 'hope', cards: [8, 15, 16, 5] },
              { word: 'go', cards: [7, 15] },
              { word: 'barn', cards: [2, 71, 14] },
              { word: 'rain', cards: [18, 76, 14] },
              { word: 'maple', cards: [13, 1, 16, 12, 5] },
              { word: 'apple', cards: [1, 16, 16, 12, 5] },
            ],
          },
          {
            day: '17-1-2',
            words: [
              { word: 'tumble', cards: [20, 21, 13, 2, 12, 5] },
              { word: 'turn', cards: [20, 75, 14] },
            ],
          },
          {
            day: '17-2-1',
            words: [
              { word: 'tack', cards: [20, 1, 32] },
            ],
          },
        ],
      },
      {
        program: 'fun3',
        words: [
          {
            day: '1-1-1',
            words: [
              { word: 'bath', cards: [2, 1, 31] },
              { word: 'best', cards: [2, 5, 19, 20] },
              { word: 'clump', cards: [3, 12, 21, 13, 16] },
              { word: 'luck', cards: [12, 21, 3, 11] },
              { word: 'we', cards: [23, 5] },
              { word: 'at', cards: [1, 20] },
              { word: 'bat', cards: [2, 1, 20] },
              { word: 'foam', cards: [6, 15, 1, 13] },
              { word: 'solve', cards: [19, 15, 12, 22, 5] },
            ],
          },
          {
            day: '1-1-2',
            words: [
              { word: 'catch', cards: [3, 1, 20, 29] },
            ],
          },
          {
            day: '1-2-1',
            words: [
              { word: 'ball', cards: [2, 36] },
              { word: 'wash', cards: [23, 1, 30] },
              { word: 'squash', cards: [19, 17, 1, 30] },
            ],
          },
          {
            day: '1-2-2',
            words: [
              { word: 'pink', cards: [16, 42] },
              { word: 'honk', cards: [8, 44] },
              { word: 'land', cards: [12, 38, 4] },
              { word: 'blink', cards: [2, 12, 42] },
            ],
          },
          {
            day: '2-1-1',
            words: [
              { word: 'block', cards: [2, 12, 15, 32] },
              { word: 'bug', cards: [2, 21, 7] },
              { word: 'bench', cards: [2, 5, 14, 29] },
              { word: 'bosses', cards: [2, 15, 19, 19, 52] },
              { word: 'boxes', cards: [2, 15, 24, 52] },
              { word: 'buzzes', cards: [2, 21, 26, 26, 52] },
              { word: 'lunches', cards: [12, 21, 14, 29, 52] },
              { word: 'dishes', cards: [4, 9, 30, 52] },
              { word: 'witches', cards: [23, 9, 311, 52] },
              { word: 'half', cards: [8, 1, 12, 6] },
              { word: 'wolf', cards: [23, 15, 12, 6] },
              { word: 'man', cards: [13, 38] },
              { word: 'woman', cards: [23, 15, 13, 38] },
              { word: 'child', cards: [29, 48] },
            ],
          },
          {
            day: '2-1-2',
            words: [
              { word: 'bells', cards: [2, 5, 12, 12, 19] },
              { word: 'glasses', cards: [7, 12, 1, 19, 19, 52] },
              { word: 'runs', cards: [18, 21, 14, 19] },
              { word: 'fixes', cards: [6, 9, 24, 52] },

              { word: 'dust', cards: [4, 21, 19, 20] },
              { word: 'jump', cards: [10, 21, 13, 16] },
              { word: 'fill', cards: [6, 9, 12, 12] },
            ],
          },
          {
            day: '2-1-3',
            words: [
              { word: 'tall', cards: [20, 36] },
              { word: 'thank', cards: [31, 40] },
            ],
          },
          {
            day: '2-2-1',
            words: [
              { word: 'run', cards: [18, 21, 14] },
              { word: 'boat', cards: [2, 15, 1, 20] },
              { word: 'fig', cards: [6, 9, 7] },
              { word: 'mesh', cards: [13, 5, 30] },
              { word: 'sing', cards: [19, 41] },
              { word: 'cake', cards: [3, 1, 11, 5] },
            ],
          },
          {
            day: '2-2-2',
            words: [
              { word: 'cup', cards: [3, 21, 16] },
              { word: 'hopping', cards: [8, 15, 16, 16, 53] },
            ],
          },
          {
            day: '2-3-1',
            words: [
              { word: 'thin', cards: [31, 9, 14] },
            ],
          },
          {
            day: '2-3-2',
            words: [
              { word: 'tax', cards: [20, 1, 24] },
              { word: 'mix', cards: [13, 9, 24] },
            ],
          },
          {
            day: '2-3-3',
            words: [
              { word: 'mixed', cards: [13, 9, 24, 54] },
            ],
          },
          {
            day: '3-1-1',
            words: [
              { word: 'hop', cards: [8, 15, 16] },
              { word: 'nose', cards: [14, 15, 19, 5] },
              { word: 'wise', cards: [23, 9, 19, 5] },
              { word: 'chase', cards: [29, 1, 19, 5] },
              { word: 'hope', cards: [8, 15, 16, 5] },
            ],
          },
          {
            day: '3-1-2',
            words: [
              { word: 'lick', cards: [12, 9, 32] },
              { word: 'nose', cards: [14, 15, 19, 5] },
              { word: 'rose', cards: [18, 15, 19, 5] },
            ],
          },
          {
            day: '3-1-3',
            words: [
              { word: 'napkin',
                cards: [
                  { syl: ['nap'] },
                  { syl: ['kin'] },
                ] },
              { word: 'sunfish',
                cards: [
                  { syl: ['sun'] },
                  { syl: ['fish'] },
                ] },
              { word: 'reptile',
                cards: [
                  { syl: ['rep'] },
                  { syl: ['tile'] },
                ] },
              { word: 'habit',
                cards: [
                  { syl: ['hab'] },
                  { syl: ['it'] },
                ] },
              { word: 'relish',
                cards: [
                  { syl: ['rel'] },
                  { syl: ['ish'] },
                ] },
              { word: 'nutshell',
                cards: [
                  { syl: ['nut'] },
                  { syl: ['shell'] },
                ] },
              { word: 'rocket',
                cards: [
                  { syl: ['rock'] },
                  { syl: ['et'] },
                ] },
              { word: 'contract',
                cards: [
                  { syl: ['con'] },
                  { syl: ['tract'] },
                ] },
              { word: 'explode',
                cards: [
                  { syl: ['ex'] },
                  { syl: ['plode'] },
                ] },
              { word: 'catnip',
                cards: [
                  { syl: ['cat'] },
                  { syl: ['nip'] },
                ] },
              { word: 'invite',
                cards: [
                  { syl: ['in'] },
                  { syl: ['vite'] },
                ] },
              { word: 'fireman', cards: [6, 9, 18, 5, 13, 38] },
              { word: 'mistake', cards: [13, 9, 19, 20, 1, 11, 5] },
            ],
          },
          {
            day: '3-1-4',
            words: [
              { word: 'invite',
                cards: [
                  { syl: ['in'] },
                  { syl: ['vite'] },
                ] },
            ],
          },
          {
            day: '4-1-1',
            words: [
              { word: 'giv', cards: [7, 9, 22] },
              { word: 'five', cards: [6, 9, 22, 5] },
              { word: 'cave', cards: [3, 1, 22, 5] },
              { word: 'have', cards: [8, 1, 22, 5] },
              { word: 'act', cards: [1, 3, 20] },
            ],
          },
          {
            day: '4-1-3',
            words: [
              { word: 'cake', cards: [3, 1, 11, 5] },
              { word: 'hope', cards: [8, 15, 16, 5] },
              { word: 'bike', cards: [2, 9, 11, 5] },
              { word: 'hopes', cards: [8, 15, 16, 5, 57] },
              { word: 'hopeful', cards: [8, 15, 16, 5, 58] },
              { word: 'hoping', cards: [8, 15, 16, 53] },
              { word: 'bravest', cards: [2, 18, 1, 22, 56] },
              { word: 'giving', cards: [7, 9, 22, 53] },
              { word: 'confusing', cards: [3, 15, 14, 6, 21, 19, 53] },
            ],
          },
          {
            day: '4-2-1',
            words: [
              { word: 'shop', cards: [30, 15, 16] },
              { word: 'hope', cards: [8, 15, 16, 5] },
              { word: 'clap', cards: [3, 12, 1, 16] },
              { word: 'brave', cards: [2, 18, 1, 22, 5] },
            ],
          },
          {
            day: '4-2-3',
            words: [
              { word: 'hoping', cards: [8, 15, 16, 53] },
            ],
          },
          {
            day: '5-1-1',
            words: [
              { word: 'travel', cards: [20, 18, 1, 22, 5, 12] },
              { word: 'wagon', cards: [23, 1, 7, 15, 14] },
            ],
          },
          {
            day: '5-2-1',
            words: [
              { word: 'jacket',
                cards: [
                  { syl: ['jack'] },
                  { syl: ['et'] },
                ] },
              { word: 'rocket',
                cards: [
                  { syl: ['rock'] },
                  { syl: ['et'] },
                ] },
            ],
          },
          {
            day: '5-2-3',
            words: [
              { word: 'elephant',
                cards: [
                  { syl: ['elephant'] },
                ] },
              { word: 'candy',
                cards: [
                  { syl: ['candy'] },
                ] },
              { word: 'apple',
                cards: [
                  { syl: ['apple'] },
                ] },
              { word: 'first',
                cards: [
                  { syl: ['first'] },
                ] },
              { word: 'car',
                cards: [
                  { syl: ['car'] },
                ] },
              { word: 'cotton',
                cards: [
                  { syl: ['cotton'] },
                ] },
              { word: 'clash',
                cards: [
                  { syl: ['clash'] },
                ] },
              { word: 'crazy',
                cards: [
                  { syl: ['crazy'] },
                ] },
            ],
          },
          {
            day: '6-1-1',
            words: [
              { word: 'not', cards: [14, 15, 20] },
              { word: 'met', cards: [13, 5, 20] },
              { word: 'flu', cards: [6, 12, 21] },
              { word: 'relish', cards: [18, 5, 12, 9, 30] },
              { word: 'pretend', cards: [16, 18, 5, 20, 5, 14, 4] },
              { word: 'protest', cards: [16, 18, 15, 20, 5, 19, 20] },
              { word: 'cabin', cards: [3, 1, 2, 9, 14] },
              { word: 'beside', cards: [2, 5, 19, 9, 4, 5] },
              { word: 'program', cards: [16, 18, 15, 7, 18, 1, 13] },
              { word: 'robot',
                cards: [
                  { syl: ['ro'] },
                  { syl: ['bot'] },
                ] },
              { word: 'unit',
                cards: [
                  { syl: ['u'] },
                  { syl: ['nit'] },
                ] },
              { word: 'program',
                cards: [
                  { syl: ['pro'] },
                  { syl: ['gram'] },
                ] },
            ],
          },
          {
            day: '6-1-2',
            words: [
              { word: 'my', cards: [13, 67] },
              { word: 'menu',
                cards: [
                  { syl: ['men'] },
                  { syl: ['u'] },
                ] },
              { word: 'bingo',
                cards: [
                  { syl: ['bing'] },
                  { syl: ['o'] },
                ] },
              { word: 'candy', cards: [3, 1, 14, 4, 67] },
              { word: 'pony', cards: [16, 15, 14, 67] },
              { word: 'penny', cards: [16, 5, 14, 14, 67] },
              { word: 'silly', cards: [19, 9, 12, 12, 67] },
              { word: 'taffy', cards: [20, 1, 6, 6, 67] },
            ],
          },
          {
            day: '6-1-3',
            words: [
              { word: 'buny', cards: [2, 21, 14, 67] },
            ],
          },
          {
            day: '6-1-4',
            words: [
              { word: 'ca', cards: [3, 1] },
              { word: 'cag', cards: [3, 1, 7] },
              { word: 'ga', cards: [7, 1] },
            ],
          },
          {
            day: '6-2-1',
            words: [
              { word: 'extra', cards: [5, 24, 20, 18, 1] },
              { word: 'alone',
                cards: [
                  { syl: ['a'] },
                  { syl: ['lone'] },
                ] },
              { word: 'amaze',
                cards: [
                  { syl: ['a'] },
                  { syl: ['maze'] },
                ] },
              { word: 'extra',
                cards: [
                  { syl: ['ex'] },
                  { syl: ['tra'] },
                ] },
              { word: 'Alaska',
                cards: [
                  { syl: ['a'] },
                  { syl: ['las'] },
                  { syl: ['ka'] },
                ] },
            ],
          },
          {
            day: '6-3-1',
            words: [
              { word: 'compliment',
                cards: [
                  { syl: ['com'] },
                  { syl: ['pli'] },
                  { syl: ['ment'] },
                ] },
            ],
          },
          {
            day: '6-3-3',
            words: [
              { word: 'apricot',
                cards: [
                  { syl: ['ap'] },
                  { syl: ['ri'] },
                  { syl: ['cot'] },
                ] },
              { word: 'champion',
                cards: [
                  { syl: ['cham'] },
                  { syl: ['pi'] },
                  { syl: ['on'] },
                ] },
            ],
          },
          {
            day: 'Bonus-1-1',
            words: [
              { word: 'connect',
                cards: [
                  { syl: ['con'] },
                  { syl: ['nect'] },
                ] },
              { word: 'connected',
                cards: [
                  { syl: ['con'] },
                  { syl: ['nect'] },
                  { syl: ['ed'] },
                ] },
              { word: 'connects',
                cards: [
                  { syl: ['con'] },
                  { syl: ['nect'] },
                  { syl: ['s'] },
                ] },
              { word: 'connecting',
                cards: [
                  { syl: ['con'] },
                  { syl: ['nect'] },
                  { syl: ['ing'] },
                ] },
              { word: 'disconnect',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['con'] },
                  { syl: ['nect'] },
                ] },
              { word: 'disagree',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['a'] },
                  { syl: ['gree'] },
                ] },
              { word: 'disrespect',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['re'] },
                  { syl: ['spect'] },
                ] },
              { word: 'disappear',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['ap'] },
                  { syl: ['pear'] },
                ] },
              { word: 'disorganize',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['or'] },
                  { syl: ['gan'] },
                  { syl: ['ize'] },
                ] },
              { word: 'con',
                cards: [
                  { syl: ['con'] },
                ] },
              { word: 'dis',
                cards: [
                  { syl: ['dis'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'em',
                cards: [
                  { syl: ['em'] },
                ] },
              { word: 'en',
                cards: [
                  { syl: ['en'] },
                ] },
              { word: 'ex',
                cards: [
                  { syl: ['ex'] },
                ] },
              { word: 'in',
                cards: [
                  { syl: ['in'] },
                ] },
              { word: 'im',
                cards: [
                  { syl: ['im'] },
                ] },
              { word: 'il',
                cards: [
                  { syl: ['il'] },
                ] },
              { word: 'mis',
                cards: [
                  { syl: ['mis'] },
                ] },
              { word: 'non',
                cards: [
                  { syl: ['non'] },
                ] },
              { word: 'sub',
                cards: [
                  { syl: ['sub'] },
                ] },
              { word: 'trans',
                cards: [
                  { syl: ['trans'] },
                ] },
              { word: 'de',
                cards: [
                  { syl: ['de'] },
                ] },
              { word: 'e',
                cards: [
                  { syl: ['e'] },
                ] },
              { word: 'pre',
                cards: [
                  { syl: ['pre'] },
                ] },
              { word: 'pro',
                cards: [
                  { syl: ['pro'] },
                ] },
              { word: 're',
                cards: [
                  { syl: ['re'] },
                ] },
            ],
          },
          {
            day: 'Bonus-1-2',
            words: [
              { word: 'stuck',
                cards: [
                  { syl: ['stuck'] },
                ] },
              { word: 'misstep',
                cards: [
                  { syl: ['mis'] },
                  { syl: ['step'] },
                ] },
              { word: 'entrap',
                cards: [
                  { syl: ['en'] },
                  { syl: ['trap'] },
                ] },
              { word: 'impolite',
                cards: [
                  { syl: ['im'] },
                  { syl: ['po'] },
                  { syl: ['lite'] },
                ] },
              { word: 'unstuck',
                cards: [
                  { syl: ['un'] },
                  { syl: ['stuck'] },
                ] },
              { word: 'disrespect',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['re'] },
                  { syl: ['spect'] },
                ] },
              { word: 'nonslip',
                cards: [
                  { syl: ['non'] },
                  { syl: ['slip'] },
                ] },
              { word: 'con',
                cards: [
                  { syl: ['con'] },
                ] },
              { word: 'dis',
                cards: [
                  { syl: ['dis'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'em',
                cards: [
                  { syl: ['em'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'ex',
                cards: [
                  { syl: ['ex'] },
                ] },
              { word: 'in',
                cards: [
                  { syl: ['in'] },
                ] },
              { word: 'im',
                cards: [
                  { syl: ['im'] },
                ] },
              { word: 'il',
                cards: [
                  { syl: ['il'] },
                ] },
              { word: 'mis',
                cards: [
                  { syl: ['mis'] },
                ] },
              { word: 'non',
                cards: [
                  { syl: ['non'] },
                ] },
              { word: 'sub',
                cards: [
                  { syl: ['sub'] },
                ] },
              { word: 'trans',
                cards: [
                  { syl: ['trans'] },
                ] },
              { word: 'de',
                cards: [
                  { syl: ['de'] },
                ] },
              { word: 'e',
                cards: [
                  { syl: ['e'] },
                ] },
              { word: 'pre',
                cards: [
                  { syl: ['pre'] },
                ] },
              { word: 'pro',
                cards: [
                  { syl: ['pro'] },
                ] },
              { word: 're',
                cards: [
                  { syl: ['re'] },
                ] },
            ],
          },
          {
            day: 'Bonus-1-3',
            words: [
              { word: 'fill',
                cards: [
                  { syl: ['fill'] },
                ] },
              { word: 'remake',
                cards: [
                  { syl: ['re'] },
                  { syl: ['make'] },
                ] },
              { word: 'preplan',
                cards: [
                  { syl: ['pre'] },
                  { syl: ['plan'] },
                ] },
              { word: 'defrost',
                cards: [
                  { syl: ['de'] },
                  { syl: ['frost'] },
                ] },
              { word: 'dislike',
                cards: [
                  { syl: ['dis'] },
                  { syl: ['like'] },
                ] },
              { word: 'unkind',
                cards: [
                  { syl: ['un'] },
                  { syl: ['kind'] },
                ] },
              { word: 'con',
                cards: [
                  { syl: ['con'] },
                ] },
              { word: 'dis',
                cards: [
                  { syl: ['dis'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'em',
                cards: [
                  { syl: ['em'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'ex',
                cards: [
                  { syl: ['ex'] },
                ] },
              { word: 'in',
                cards: [
                  { syl: ['in'] },
                ] },
              { word: 'im',
                cards: [
                  { syl: ['im'] },
                ] },
              { word: 'il',
                cards: [
                  { syl: ['il'] },
                ] },
              { word: 'mis',
                cards: [
                  { syl: ['mis'] },
                ] },
              { word: 'non',
                cards: [
                  { syl: ['non'] },
                ] },
              { word: 'sub',
                cards: [
                  { syl: ['sub'] },
                ] },
              { word: 'trans',
                cards: [
                  { syl: ['trans'] },
                ] },
              { word: 'de',
                cards: [
                  { syl: ['de'] },
                ] },
              { word: 'e',
                cards: [
                  { syl: ['e'] },
                ] },
              { word: 'pre',
                cards: [
                  { syl: ['pre'] },
                ] },
              { word: 'pro',
                cards: [
                  { syl: ['pro'] },
                ] },
              { word: 're',
                cards: [
                  { syl: ['re'] },
                ] },
            ],
          },
          {
            day: 'Bonus-2-2',
            words: [
              { word: 'destruct',
                cards: [
                  { syl: ['de'] },
                  { syl: ['struct'] },
                ] },
              { word: 'unclip',
                cards: [
                  { syl: ['un'] },
                  { syl: ['clip'] },
                ] },
              { word: 'predict',
                cards: [
                  { syl: ['pre'] },
                  { syl: ['dict'] },
                ] },
              { word: 'con',
                cards: [
                  { syl: ['con'] },
                ] },
              { word: 'dis',
                cards: [
                  { syl: ['dis'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'em',
                cards: [
                  { syl: ['em'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'ex',
                cards: [
                  { syl: ['ex'] },
                ] },
              { word: 'in',
                cards: [
                  { syl: ['in'] },
                ] },
              { word: 'im',
                cards: [
                  { syl: ['im'] },
                ] },
              { word: 'il',
                cards: [
                  { syl: ['il'] },
                ] },
              { word: 'mis',
                cards: [
                  { syl: ['mis'] },
                ] },
              { word: 'non',
                cards: [
                  { syl: ['non'] },
                ] },
              { word: 'sub',
                cards: [
                  { syl: ['sub'] },
                ] },
              { word: 'trans',
                cards: [
                  { syl: ['trans'] },
                ] },
              { word: 'de',
                cards: [
                  { syl: ['de'] },
                ] },
              { word: 'e',
                cards: [
                  { syl: ['e'] },
                ] },
              { word: 'pre',
                cards: [
                  { syl: ['pre'] },
                ] },
              { word: 'pro',
                cards: [
                  { syl: ['pro'] },
                ] },
              { word: 're',
                cards: [
                  { syl: ['re'] },
                ] },
              { word: 'dict',
                cards: [
                  { syl: ['dict'] },
                ] },
              { word: 'duct',
                cards: [
                  { syl: ['duct'] },
                ] },
              { word: 'fect',
                cards: [
                  { syl: ['fect'] },
                ] },
              { word: 'fract',
                cards: [
                  { syl: ['fract'] },
                ] },
              { word: 'ject',
                cards: [
                  { syl: ['ject'] },
                ] },
              { word: 'junct',
                cards: [
                  { syl: ['junct'] },
                ] },
              { word: 'lect',
                cards: [
                  { syl: ['lect'] },
                ] },
              { word: 'min',
                cards: [
                  { syl: ['min'] },
                ] },
              { word: 'scrib',
                cards: [
                  { syl: ['scrib'] },
                ] },
              { word: 'sist',
                cards: [
                  { syl: ['sist'] },
                ] },
              { word: 'spec',
                cards: [
                  { syl: ['spec'] },
                ] },
              { word: 'struct',
                cards: [
                  { syl: ['struct'] },
                ] },
              { word: 'tract',
                cards: [
                  { syl: ['tract'] },
                ] },
            ],
          },
          {
            day: 'Bonus-2-3',
            words: [
              { word: 'remake',
                cards: [
                  { syl: ['re'] },
                  { syl: ['make'] },
                ] },
              { word: 'predict',
                cards: [
                  { syl: ['pre'] },
                  { syl: ['dict'] },
                ] },
              { word: 'demote',
                cards: [
                  { syl: ['de'] },
                  { syl: ['mote'] },
                ] },
              { word: 'con',
                cards: [
                  { syl: ['con'] },
                ] },
              { word: 'dis',
                cards: [
                  { syl: ['dis'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'em',
                cards: [
                  { syl: ['em'] },
                ] },
              { word: 'un',
                cards: [
                  { syl: ['un'] },
                ] },
              { word: 'ex',
                cards: [
                  { syl: ['ex'] },
                ] },
              { word: 'in',
                cards: [
                  { syl: ['in'] },
                ] },
              { word: 'im',
                cards: [
                  { syl: ['im'] },
                ] },
              { word: 'il',
                cards: [
                  { syl: ['il'] },
                ] },
              { word: 'mis',
                cards: [
                  { syl: ['mis'] },
                ] },
              { word: 'non',
                cards: [
                  { syl: ['non'] },
                ] },
              { word: 'sub',
                cards: [
                  { syl: ['sub'] },
                ] },
              { word: 'trans',
                cards: [
                  { syl: ['trans'] },
                ] },
              { word: 'de',
                cards: [
                  { syl: ['de'] },
                ] },
              { word: 'e',
                cards: [
                  { syl: ['e'] },
                ] },
              { word: 'pre',
                cards: [
                  { syl: ['pre'] },
                ] },
              { word: 'pro',
                cards: [
                  { syl: ['pro'] },
                ] },
              { word: 're',
                cards: [
                  { syl: ['re'] },
                ] },
              { word: 'dict',
                cards: [
                  { syl: ['dict'] },
                ] },
              { word: 'duct',
                cards: [
                  { syl: ['duct'] },
                ] },
              { word: 'fect',
                cards: [
                  { syl: ['fect'] },
                ] },
              { word: 'fract',
                cards: [
                  { syl: ['fract'] },
                ] },
              { word: 'ject',
                cards: [
                  { syl: ['ject'] },
                ] },
              { word: 'junct',
                cards: [
                  { syl: ['junct'] },
                ] },
              { word: 'lect',
                cards: [
                  { syl: ['lect'] },
                ] },
              { word: 'min',
                cards: [
                  { syl: ['min'] },
                ] },
              { word: 'scrib',
                cards: [
                  { syl: ['scrib'] },
                ] },
              { word: 'sist',
                cards: [
                  { syl: ['sist'] },
                ] },
              { word: 'spec',
                cards: [
                  { syl: ['spec'] },
                ] },
              { word: 'struct',
                cards: [
                  { syl: ['struct'] },
                ] },
              { word: 'tract',
                cards: [
                  { syl: ['tract'] },
                ] },
            ],
          },
          {
            day: 'Bonus-2-4',
            words: [
              { word: 'duct',
                cards: [
                  { syl: ['duct'] },
                ] },
              { word: 'con',
                cards: [
                  { syl: ['con'] },
                ] },
            ],
          },
          {
            day: '7-1-1',
            words: [
              { word: 'empty',
                cards: [
                  { syl: ['emp'] },
                  { syl: ['ty'] },
                ] },
              { word: 'cry', cards: [3, 18, 67] },
            ],
          },
          {
            day: '7-1-2',
            words: [
              { word: 'cry', cards: [3, 18, 67] },
              { word: 'emptiness',
                cards: [
                  { syl: ['emp'] },
                  { syl: ['ti'] },
                  { syl: ['ness'] },
                ] },
            ],
          },
          {
            day: '7-2-1',
            words: [
              { word: 'baby', cards: [2, 1, 2, 67] },
              { word: 'potato', cards: [16, 15, 20, 1, 20, 15] },
              { word: 'problem',
                cards: [
                  { syl: ['prob'] },
                  { syl: ['lem'] },
                ] },
              { word: 'candy',
                cards: [
                  { syl: ['can'] },
                  { syl: ['dy'] },
                ] },
              { word: 'lady',
                cards: [
                  { syl: ['la'] },
                  { syl: ['dy'] },
                ] },
              { word: 'tomato',
                cards: [
                  { syl: ['to'] },
                  { syl: ['ma'] },
                  { syl: ['to'] },
                ] },
              { word: 'family',
                cards: [
                  { syl: ['fam'] },
                  { syl: ['i'] },
                  { syl: ['ly'] },
                ] },
              { word: 'galaxy',
                cards: [
                  { syl: ['gal'] },
                  { syl: ['ax'] },
                  { syl: ['y'] },
                ] },
              { word: 'valentine',
                cards: [
                  { syl: ['val'] },
                  { syl: ['en'] },
                  { syl: ['tine'] },
                ] },
            ],
          },
          {
            day: '7-2-2',
            words: [
              { word: 'chill',
                cards: [
                  { syl: ['chill'] },
                ] },
            ],
          },
          {
            day: '8-1-1',
            words: [
              { word: 'mat', cards: [13, 1, 20] },
              { word: 'hope', cards: [8, 15, 16, 5] },
              { word: 'go', cards: [7, 15] },
            ],
          },
          {
            day: '8-1-2',
            words: [
              { word: 'tum', cards: [20, 21, 13] },
            ],
          },
          {
            day: '8-1-3',
            words: [
              { word: 'tack', cards: [20, 1, 32] },
              { word: 'give', cards: [7, 65] },
              { word: 'fake', cards: [6, 1, 11, 5] },
              { word: 'settle',
                cards: [
                  { syl: ['set'] },
                  { syl: ['tle'] },
                ] },
            ],
          },
          {
            day: '8-2-1',
            words: [
              { word: 'find', cards: [6, 47] },
              { word: 'give', cards: [7, 65] },
              { word: 'ago', cards: [1, 7, 15] },
              { word: 'castle',
                cards: [
                  { syl: ['cas'] },
                  { syl: ['tle'] },
                ] },
            ],
          },
          {
            day: '8-2-2',
            words: [
              { word: 'bugle',
                cards: [
                  { syl: ['bu'] },
                  { syl: ['gle'] },
                ] },
              { word: 'bagel',
                cards: [
                  { syl: ['bag'] },
                  { syl: ['gel'] },
                ] },
            ],
          },
          {
            day: '8-3-1',
            words: [
              { word: 'mansion',
                cards: [
                  { syl: ['man'] },
                  { syl: ['sion'] },
                ] },
              { word: 'vacation',
                cards: [
                  { syl: ['va'] },
                  { syl: ['ca'] },
                  { syl: ['tion'] },
                ] },
            ],
          },
          {
            day: '9-1-1',
            words: [
              { word: 'car', cards: [3, 71] },
              { word: 'horn', cards: [8, 72, 14] },
              { word: 'bird', cards: [2, 74, 4] },
              { word: 'barn',
                cards: [
                  { syl: ['barn'] },
                ] },
              { word: 'army',
                cards: [
                  { syl: ['ar'] },
                  { syl: ['my'] },
                ] },
              { word: 'party', cards: [16, 71, 20, 67] },
              { word: 'garlic', cards: [7, 71, 12, 9, 3] },
              { word: 'cargo', cards: [3, 71, 7, 15] },
              { word: 'shortstop', cards: [30, 71, 20, 19, 20, 15, 16] },
            ],
          },
          {
            day: '9-1-3',
            words: [
              { word: 'car', cards: [3, 71] },
              { word: 'carry', cards: [3, 71, 18, 67] },
            ],
          },
          {
            day: '9-2-1',
            words: [
              { word: 'beggar',
                cards: [
                  { syl: ['beg'] },
                  { syl: ['gar'] },
                ] },
              { word: 'doctor',
                cards: [
                  { syl: ['doc'] },
                  { syl: ['tor'] },
                ] },
            ],
          },
          {
            day: '9-3-3',
            words: [
              { word: 'spin', cards: [19, 16, 9, 14] },
              { word: 'swap', cards: [19, 23, 1, 16] },
              { word: 'run', cards: [18, 21, 14] },
              { word: 'chop', cards: [29, 15, 16] },
              { word: 'wag', cards: [23, 1, 7] },
              { word: 'tar', cards: [20, 71] },
            ],
          },
          {
            day: '10-1-1',
            words: [
              { word: 'bait', cards: [2, 321, 20] },
              { word: 'play', cards: [16, 12, 322] },
              { word: 'remain',
                cards: [
                  { syl: ['re'] },
                  { syl: ['main'] },
                ] },
            ],
          },
          {
            day: '10-1-3',
            words: [
              { word: 'play', cards: [16, 12, 322] },
              { word: 'bait', cards: [2, 321, 20] },
              { word: 'mate', cards: [13, 1, 20, 5] },
              { word: 'vein', cards: [22, 324, 14] },
              { word: 'break', cards: [2, 18, 328, 11] },
              { word: 'eight', cards: [323, 20] },
              { word: 'baby', cards: [2, 1, 2, 67] },
            ],
          },
          {
            day: '10-2-1',
            words: [
              { word: 'goalie', cards: [7, 83, 12, 327] },
              { word: 'rookie', cards: [18, 87, 11, 327] },
              { word: 'remind', cards: [18, 5, 13, 47] },
              { word: 'baby', cards: [2, 1, 2, 67] },
              { word: 'champion', cards: [29, 37, 16, 9, 15, 14] },
            ],
          },
          {
            day: '10-2-2',
            words: [
              { word: 'bread', cards: [2, 18, 79, 4] },
            ],
          },
          {
            day: '10-3-1',
            words: [
              { word: 'light', cards: [12, 329, 20] },
              { word: 'night', cards: [14, 329, 20] },
              { word: 'sight', cards: [19, 329, 20] },
              { word: 'fright', cards: [6, 18, 329, 20] },
            ],
          },
          {
            day: '10-3-2',
            words: [
              { word: 'empty',
                cards: [
                  { syl: ['emp'] },
                  { syl: ['ty'] },
                ] },
              { word: 'enjoy',
                cards: [
                  { syl: ['en'] },
                  { syl: ['joy'] },
                ] },
            ],
          },
          {
            day: '10-3-3',
            words: [
              { word: 'create',
                cards: [
                  { syl: ['cre'] },
                  { syl: ['ate'] },
                ] },
            ],
          },
          {
            day: '11-1-1',
            words: [
              { word: 'does',
                cards: [
                  { syl: ['does'] },
                ] },
              { word: 'should',
                cards: [
                  { syl: ['should'] },
                ] },
              { word: 'could',
                cards: [
                  { syl: ['could'] },
                ] },
              { word: 'would',
                cards: [
                  { syl: ['would'] },
                ] },
              { word: 'what',
                cards: [
                  { syl: ['what'] },
                ] },
              { word: 'not', cards: [14, 15, 20] },
              { word: 'is', cards: [9, 19] },
            ],
          },
          {
            day: '11-1-2',
            words: [
              { word: 'does',
                cards: [
                  { syl: ['does'] },
                ] },
              { word: 'must',
                cards: [
                  { syl: ['must'] },
                ] },
              { word: 'can',
                cards: [
                  { syl: ['can'] },
                ] },
              { word: 'will',
                cards: [
                  { syl: ['will'] },
                ] },
              { word: 'not', cards: [14, 15, 20] },
            ],
          },
          {
            day: '11-2-1',
            words: [
              { word: 'they',
                cards: [
                  { syl: ['they'] },
                ] },
              { word: 'we',
                cards: [
                  { syl: ['we'] },
                ] },
              { word: 'you',
                cards: [
                  { syl: ['you'] },
                ] },
              { word: 'he',
                cards: [
                  { syl: ['he'] },
                ] },
              { word: 'she',
                cards: [
                  { syl: ['she'] },
                ] },
              { word: 'I',
                cards: [
                  { syl: ['I'] },
                ] },
              { word: 'it',
                cards: [
                  { syl: ['it'] },
                ] },
            ],
          },
          {
            day: '11-2-2',
            words: [
              { word: 'we',
                cards: [
                  { syl: ['we'] },
                ] },
              { word: 'you',
                cards: [
                  { syl: ['you'] },
                ] },
              { word: 'he',
                cards: [
                  { syl: ['he'] },
                ] },
              { word: 'she',
                cards: [
                  { syl: ['she'] },
                ] },
              { word: 'I',
                cards: [
                  { syl: ['I'] },
                ] },
            ],
          },
          {
            day: '11-2-3',
            words: [
              { word: 'let',
                cards: [
                  { syl: ['let'] },
                ] },
              { word: 'I',
                cards: [
                  { syl: ['I'] },
                ] },
            ],
          },
          {
            day: '12-1-1',
            words: [
              { word: 'gad', cards: [7, 1, 4] },
              { word: 'cag', cards: [3, 1, 7] },
              { word: 'race', cards: [18, 1, 3, 5] },
              { word: 'citizen', cards: [3, 9, 20, 9, 26, 5, 14] },
            ],
          },
          {
            day: '12-1-2',
            words: [
              { word: 'concentrate',
                cards: [
                  { syl: ['con'] },
                  { syl: ['cent'] },
                  { syl: ['rate'] },
                ] },
              { word: 'suggest',
                cards: [
                  { syl: ['sug'] },
                  { syl: ['gest'] },
                ] },
            ],
          },
          {
            day: '12-2-1',
            words: [
              { word: 'price', cards: [16, 18, 9, 3, 5] },
              { word: 'plung', cards: [16, 12, 21, 14, 7] },
            ],
          },
          {
            day: '12-2-2',
            words: [
              { word: 'fuj', cards: [6, 21, 10] },
            ],
          },
          {
            day: '12-2-3',
            words: [
              { word: 'dans', cards: [4, 1, 14, 19] },
            ],
          },
          {
            day: '13-1-3',
            words: [
              { word: 'phone', cards: [356, 15, 14, 5] },
            ],
          },
          {
            day: '13-2-1',
            words: [
              { word: 'ghost', cards: [7, 8, 51] },
              { word: 'wr', cards: [23, 18] },
              { word: 'rh', cards: [18, 8] },
              { word: 'gn', cards: [7, 14] },
              { word: 'kn', cards: [11, 14] },
              { word: 'mn', cards: [13, 14] },
              { word: 'mb', cards: [13, 2] },
              { word: 'gh', cards: [7, 8] },
            ],
          },
          {
            day: '14-1-1',
            words: [
              { word: 'pasture', cards: [16, 1, 19, 357] },
              { word: 'actual', cards: [1, 3, 358, 1, 12] },
            ],
          },
          {
            day: '14-1-2',
            words: [
              { word: 'pasture', cards: [16, 1, 19, 357] },
              { word: 'actual',
                cards: [
                  { syl: ['ac'] },
                  { syl: ['tu'] },
                  { syl: ['al'] },
                ] },
              { word: 'spatula',
                cards: [
                  { syl: ['spa'] },
                  { syl: ['tu'] },
                  { syl: ['la'] },
                ] },
            ],
          },
        ],
      },
    ],
  },
};
