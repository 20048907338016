<template>
  <div id="positioner">
    <ActivityHeader activity-type="Reference Posters"
    :back-button-title="backButtonTitle"
    :show-nav-buttons="false"
    :back-button-route="cameFromRoute"></ActivityHeader>
    <p class="get-started">Pick a poster to get started.</p>
    <div class="activity-tiles reference-posters-menu">

      <router-link
        v-if="programId === 'fun1' || programId === 'fun2' || programId === 'fun3'"
        :to="{ name: 'largedictationgrid', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="largedictationgrid"
      >
        <img alt="" src="/images/poster-tiles/large-dictation-grid.svg" />
        <span
          >Large
          <br />
          <span>Dictation Grid</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'funk' || programId === 'fun1' || programId === 'fun2'"
        :to="{ name: 'basickeywords', params: { programId: programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="basic-keywords"
      >
        <img alt="" src="/images/poster-tiles/basic-keywords.svg" />
        <span>
          Basic
          <br />
          <span>Keywords</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun1' || programId === 'fun2' || programId === 'fun3'"
        :to="{ name: 'rcontrolledvowels', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="rcontrolledvowels"
      >
        <img alt="" src="/images/poster-tiles/r-controlled.svg" />
        <span
          >R-Controlled
          <br />
          <span>Vowels</span>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'largeletterformationgrid', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="largeletterformationgrid"
      >
        <img alt="" src="/images/poster-tiles/grid.svg" />
        <span
          >Large
          <br />
          <span>Letter Formation Grid</span>
        </span>
      </router-link>

      <router-link
        v-if="programId === 'fun1' || programId === 'funk'"
        :to="{ name: 'vowelextension', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="vowelextension"
      >
        <img alt="" src="/images/poster-tiles/vowel-extension.svg" />
        <span
          >Vowel
          <br />
          <span>Extension</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun1' || programId === 'fun2' || programId === 'fun3'"
        :to="{ name: 'vowelteams', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        :class="vowelTeamsClass"
        class="activity-tile"
        id="vowelteams"
      >
        <img alt="" :src="'/images/poster-tiles/vowel-teams-' + programNumber + '.svg'">
        <span
          >Vowel
          <br />
          <span>Teams</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun1' || programId === 'funk'"
        :to="{ name: 'letterformation', params: { programId: programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="letter-formation"
      >
        <img alt="" src="/images/poster-tiles/letter-formation-K1.svg" />
        <span>
          Letter
          <br />
          <span>Formation</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun1'"
        :to="{ name: 'weldedsounds', params: { programId: this.programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile"
        id="weldedsounds"
      >
        <img alt="" src="/images/poster-tiles/welded-sounds.svg" />
        <span
          >Welded
          <br />
          <span>Sounds</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun1' || programId === 'fun2'"
        :to="{ name: 'markmywords', params: { programId: programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile all-fun"
        id="mark-my-words"
      >
        <img alt="" src="/images/poster-tiles/mark-my-words.svg" />
        <span>
          Mark
          <br />
          <span>My Words</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun1' || programId === 'fun2' || programId === 'fun3'"
        :to="{ name: 'vowelsoundsposter', params: { programId: this.programId } }"
        class="activity-tile"
        id="vowelsounds"
      >
        <img alt="" :src="'/images/poster-tiles/vowel-sounds-' + programNumber + '.svg'">
        <span
          >Vowel
          <br />
          <span>Sounds</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun3' || programId === 'fun2'"
        :to="{ name: 'spellingoptions', params: { programId: programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile"
        id="spelling-options"
      >
        <img alt="" v-if="programId === 'fun2'" src="/images/poster-tiles/spelling-options-2.svg" />
        <img alt="" v-if="programId === 'fun3'" src="/images/poster-tiles/spelling-options-3.svg" />
        <span>
          Spelling
          <br />
          <span>Options</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun3' || programId === 'fun2'"
        :to="{ name: 'syllabletypes', params: { programId: programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile"
        id="syllable-types"
      >
        <img alt="" v-if="programId === 'fun2'" src="/images/poster-tiles/syllable-types-2.svg" />
        <img alt="" v-if="programId === 'fun3'" src="/images/poster-tiles/syllable-types-3.svg" />
        <span>
          Syllable
          <br />
          <span>Types</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun3'"
        :to="{ name: 'spellingrules', params: { programId: programId, weekId: this.weekId, unitId: this.unitId, dayId: this.dayId } }"
        class="activity-tile"
        id="spelling-rules"
      >
        <img alt="" src="/images/poster-tiles/spelling-rules.svg" />
        <span>
          Spelling
          <br />
          <span>Rules</span>
        </span>
      </router-link>
      <router-link
        v-if="programId === 'fun3'"
        :to="{ name: 'silentletters', params: { programId: programId, weekId: this.weekId, unitId: this.unitId } }"
        class="activity-tile fun3"
        id="silentletters"
      >
        <img alt="" src="/images/poster-tiles/silent-letters.svg" />
        <span
          >Silent
          <br />
          <span>Letters</span>
        </span>
      </router-link>
    </div>
    <div id="menu-bg-image" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ActivityHeader from '@/components/ActivityHeader.vue';

export default {
  name: 'reference-posters',
  components: { ActivityHeader },
  data() {
    const cameFromRoute = '';
    const programNumber = this.programId.replace(/\D/g, '');
    return { cameFromRoute, programNumber };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 'chooseunit' or 'chooseactivity'
      vm.setPosterPrevPage(from.name);
      vm.cameFromRoute = vm.postersPrevPage === '' ? 'chooseunit' : vm.postersPrevPage;
    });
  },
  computed: {
    ...mapState('appState', ['postersPrevPage']),

    backButtonTitle() {
      return (this.cameFromRoute === 'chooseunit' || this.cameFromRoute === '') ? 'Units' : 'Activities';
    },
    vowelTeamsClass() {
      return ((this.programNumber === '3') ? '' : 'all-fun');
    },
  },

  methods: {
    ...mapActions('appState', ['setPosterPrevPage']),
    // calculateProgramNumber() {
    //   this.programNumber = this.programId.replace(/\D/g, '');
    // },
  },
  // mounted() {
  //   this.calculateProgramNumber();
  // },
};
</script>

<style lang="scss">

#menu-bg-image {
  background-image: url('/images/Fun2/illustrations/student.svg');
}
.reference-posters-menu {
  grid-template-columns: repeat(3, minmax(15rem, 25rem));
  .fun2 &{
    grid-template-columns: repeat(3, minmax(15rem, 25rem));
    width: 85%;
    .activity-tile {
      &#basic-keywords {
        grid-row: 1;
        grid-column: 1;
      }
      &#mark-my-words {
        grid-row: 1;
        grid-column: 2;
      }
      &#syllable-types{
        grid-row: 1;
        grid-column: 3;
      }
      &#rcontrolledvowels{
        grid-row: 2;
        grid-column: 1;
      }
      &#vowelteams{
        grid-row: 2;
        grid-column: 2;
      }
      &#spelling-options{
        grid-row: 2;
        grid-column: 3;
      }
      &#vowelsounds{
        grid-row: 3;
        grid-column: 1;
      }
      &#largeletterformationgrid{
        grid-row: 3;
        grid-column: 2;
      }
      &#largedictationgrid{
        grid-row: 3;
        grid-column: 3;
      }
    }
  }
  .fun3 &{
    grid-template-columns: repeat(3, minmax(15rem, 25rem));
    width: 85%;
    .activity-tile {
      &#syllable-types{
        grid-row: 1;
        grid-column: 1;
      }
      &#rcontrolledvowels{
        grid-row: 1;
        grid-column: 2;
      }
      &#vowelteams{
        grid-row: 1;
        grid-column: 3;
      }
      &#spelling-options{
        grid-row: 2;
        grid-column: 1;
      }
      &#vowelsounds{
        grid-row: 2;
        grid-column: 2;
      }
      &#spelling-rules{
        grid-row: 2;
        grid-column: 3;
      }
      &#silentletters{
        grid-row: 3;
        grid-column: 1;
      }
      &#largeletterformationgrid{
        grid-row: 3;
        grid-column: 2;
      }
      &#largedictationgrid{
        grid-row: 3;
        grid-column: 3;
      }
    }
  }
  .fun1 &{
    grid-template-columns: repeat(4, minmax(15rem, 25rem));
    width: 85%;
    .activity-tile {
      &#basic-keywords {
        grid-row: 1;
        grid-column: 1;
      }
      &#vowelextension{
        grid-row: 1;
        grid-column: 2;
      }
      &#weldedsounds{
        grid-row: 1;
        grid-column: 3;
      }
      &#mark-my-words {
        grid-row: 1;
        grid-column: 4;
      }
      &#rcontrolledvowels{
        grid-row: 2;
        grid-column: 1;
      }
      &#vowelteams{
        grid-row: 2;
        grid-column: 2;
      }
      &#vowelsounds{
        grid-row: 2;
        grid-column: 3;
      }
      &#largedictationgrid{
        grid-row: 3;
        grid-column: 1;
      }
      &#largeletterformationgrid{
        grid-row: 3;
        grid-column: 2;
      }
      &#letter-formation{
        grid-row: 3;
        grid-column: 3;
      }
    }
  }
  .funk & {
    #vowelextension {
      grid-area: 1/1/1/1;
    }
  }
  @media (max-height: 850px){
    max-width: 600px;
  }
  @media (max-height: 720px){
    .fun2 &,
    .fun1 &,
    .fun3 & {
      // max-width: 480px;
      grid-template-columns: repeat(5, minmax(15rem, 25rem));
      max-width: 80%;
      @media (max-width: 1000px) {
        grid-template-columns: repeat(4, minmax(15rem, 25rem));
      }
    }
    grid-template-columns: repeat(3, minmax(15rem, 25rem));
    .fun1 &{
      .activity-tile {
        &#basic-keywords {
          grid-row: 1;
          grid-column: 1;
        }
        &#vowelextension{
          grid-row: 1;
          grid-column: 2;
        }
        &#weldedsounds{
          grid-row: 1;
          grid-column: 3;
        }
        &#mark-my-words {
          grid-row: 1;
          grid-column: 4;
        }
        &#rcontrolledvowels{
          grid-row: 1;
          grid-column: 5;
        }
        &#vowelteams{
          grid-row: 2;
          grid-column: 1;
        }
        &#vowelsounds{
          grid-row: 2;
          grid-column: 2;
        }
        &#largedictationgrid{
          grid-row: 2;
          grid-column: 3;
        }
        &#largeletterformationgrid{
          grid-row: 2;
          grid-column: 4;
        }
        &#letter-formation{
          grid-row: 2;
          grid-column: 5;
        }
      }
    }
    .fun2 &{
      width: 85%;
      .activity-tile {
        &#basic-keywords {
          grid-row: 1;
          grid-column: 1;
        }
        &#mark-my-words {
          grid-row: 1;
          grid-column: 2;
        }
        &#syllable-types{
          grid-row: 1;
          grid-column: 3;
        }
        &#rcontrolledvowels{
          grid-row: 1;
          grid-column: 4;
        }
        &#vowelteams{
          grid-row: 1;
          grid-column: 5;
        }
        &#spelling-options{
          grid-row: 2;
          grid-column: 1;
        }
        &#vowelsounds{
          grid-row: 2;
          grid-column: 2;
        }
        &#largeletterformationgrid{
          grid-row: 2;
          grid-column: 3;
        }
        &#largedictationgrid{
          grid-row: 2;
          grid-column: 4;
        }
      }
    }
    .fun3 &{
      width: 85%;
      .activity-tile {
        &#syllable-types{
          grid-row: 1;
          grid-column: 1;
        }
        &#rcontrolledvowels{
          grid-row: 1;
          grid-column: 2;
        }
        &#vowelteams{
          grid-row: 1;
          grid-column: 3;
        }
        &#spelling-options{
          grid-row: 1;
          grid-column: 4;
        }
        &#vowelsounds{
          grid-row: 1;
          grid-column: 5;
        }
        &#spelling-rules{
          grid-row: 2;
          grid-column: 1;
        }
        &#silentletters{
          grid-row: 2;
          grid-column: 2;
        }
        &#largeletterformationgrid{
          grid-row: 2;
          grid-column: 3;
        }
        &#largedictationgrid{
          grid-row: 2;
          grid-column: 4;
        }
      }
    }
  }
  @media (max-height: 630px){
    .fun2 &,
    .fun1 & {
      // max-width: 480px;
      grid-template-columns: repeat(5, minmax(15rem, 25rem));
      max-width: 80%;
      @media (max-width: 1000px) {
        grid-template-columns: repeat(5, minmax(15rem, 25rem));
      }
    }
  }
}
.activity-tile {
  &#largedictationgrid{
    img{
      width: 85%;
      top: 10%;
      left: 7.5%;
    }
  }
  &#rcontrolledvowels{
    // grid-column: auto / span 1;
    // grid-row: 2 / span 1;
    img {
      width: 105%;
      top: 20%;
      left: -2.5%;
    }
  }
  &#letter-formation{
    img{
      top: 10%;
    }
  }
  &#vowelteams{
    // grid-column: auto / span 1;
    // grid-row: 2 / span 1;
    img {
      @extend %svg-shadow;
      width: 70%;
      left: 15%;
      .fun3 & {
        width: 50%;
        left: 25%;
        top: -7%;
      }
    }
  }
  &#basic-keywords {
    // grid-column: auto / span 1;
    // grid-row: 1 / span 1;
    img {
      width: 30%;
      left: 35%;
      top: 10%;
    }
    .funk &{
      grid-area: 2/1/2/1;
    }
  }

  &#mark-my-words {
    // grid-column: auto / span 1;
    // grid-row: 1 / span 1;
    img {
      width: 70%;
      left: 15%;
      top: 5%;
    }
  }
  &#vowelsounds{
    img{
      top: 15%;
      width: 105%;
      left: -2%;
    }
  }
  &#vowelextension{
    img {
      top: 25%;
      width: 90%;
      left: 5%;
    }
  }
  &#weldedsounds{
    img{
      width: 75%;
      left: 12.5%;
      top: 5%;
    }
  }
  &#largeletterformationgrid{
    img{
      top: 25%;
      width: 90%;
      left: 5%;
    }
    span {
      span {
        font-size: 1.5rem;
      }
    }
  }
  &#spelling-options {
    // grid-column: auto / span 1;
    grid-row: 1 / span 1;

    .fun2 & {
      // grid-column: auto / span 1;
      img {
        width: 95%;
        left: 5%;
        top: 30%;
      }
    }
    .fun3 & {
      // grid-column: auto / span 1;
      img {
        width: 95%;
        left: 5%;
        top: 15%;
      }
    }
  }
  &#syllable-types {
    // grid-column: auto / span 1;

    .fun2 & {
      // grid-row: 2 / span 1;
      img {
        width: 95%;
        left: 5%;
        top: 30%;
      }
    }
    .fun3 & {
      // grid-row: 1 / span 1;
      img {
        width: 95%;
        left: 5%;
        top: 15%;
      }
    }
  }
  &#spelling-rules {
    // grid-column: auto / span 1;
    // grid-row: 1 / span 1;
    img {
      width: 60%;
      left: 20%;
      top: 0%;
    }
  }
  &#silentletters {
    img {
      width: 105%;
      top: 19%;
    }
  }
}
</style>
